/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { userActivitySessionModel } from '../../../../services/User/User.type'
import moment from 'moment'
import { applyUserSpecificDateFormat, applyUserSpecificTimeWithoutMeridiemFormat } from '../../../../helper/utility'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

type Props = {
  className: string
  latestUserActivity: userActivitySessionModel[]
}

const ListsWidget5: React.FC<Props> = ({ className, latestUserActivity }) => {
  const navigate = useNavigate();
  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>User Activities</span>
          <span className='text-muted fw-semibold fs-7'></span>
        </h3>
      </div>
      <div className='card-body pt-0'>
        <div className='timeline-label'>
          {latestUserActivity.map((x, index) => {
            return (
              <>
                <div className='timeline-item mb-6'>
                  <div className='timeline-label fw-bold text-gray-800 fs-6'>{moment(x.startDate).format(applyUserSpecificTimeWithoutMeridiemFormat())}</div>
                  <div className='timeline-badge'>
                    <i className={clsx(
                      'fa fa-genderless ',
                      'fs-1',
                      x.eventTypeID === 1 ? 'text-warning' : x.eventTypeID === 2 ? 'text-success' : 'text-primary'
                    )}></i>
                  </div>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    <button className='btn-link btn text-gray-700 text-hover-primary fw-bold fs-6 p-0' onClick={() => {
                    const additionalData = {
                      tabName: 'Activity', // Additional data you want to pass
                    };
                    navigate(`/users/detail/` + x?.userID,{state:additionalData})
                  }}>
                      {x.userName}
                    </button>
                    {x.eventTypeID === 1 ? ` cycled` : x.eventTypeID === 2 ? ` walked` : ` ran`} for <span className='fw-bolder'> {x.timeDuration} </span> on {moment(x.startDate).format(applyUserSpecificDateFormat())}.
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export { ListsWidget5 }
