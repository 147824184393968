export interface HomeSlider {
    id: number
    title: string | null,
    description: string | null,
    image: string | null,
    externalLink: string | null,
    isActive: boolean
    createdDate: any | null,
    modifiedDate: any | null,
    modifiedBy: number | null,
    createdBy: number | null
}

export interface AddEditHomeSlideModel extends HomeSlider{
    imageBase64 : string | null
}


export interface AddEditOption{
    id : number,
    isOpen : boolean,
    isEdit : boolean
}


export const GetAllHomeSlider = "/adminapi/getallhomeslider"
export const UpsertHomeSlider = "/adminapi/upserthomeslider"
export const GetHomeSlider  = "/adminapi/gethomeslider"
export const DeleteHomeSlider = "/adminapi/deletehomeslider"