/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import Login from './components/Login'

const AuthLayout = () => {

	useEffect(() => {

		var themeMode = localStorage.getItem('kt_theme_mode_value');
		if (themeMode != null) {
			if (themeMode === "dark") {
				document.body.style.backgroundImage = `url(${toAbsoluteUrl(`/media/auth/header-bg-dark.png`)})`
			} else {
				document.body.style.backgroundImage = `url(${toAbsoluteUrl(`/media/auth/header-bg.jpg`)})`
			}
		}
		else {
			document.body.style.backgroundImage = `url(${toAbsoluteUrl(`/media/auth/header-bg.png`)})`
		}

		document.body.style.backgroundPosition = `top`
		document.body.style.backgroundSize = `cover`
		const root = document.getElementById('root')
		if (root) {
			root.style.height = 'auto'
		}
		return () => {
			document.body.style.backgroundImage = `url(${toAbsoluteUrl(`/media/auth/header-bg.jpg`)})`
			if (root) {
				root.style.height = 'auto'
			}
		}
	}, [])

	return (
		<div className="d-flex flex-column flex-column-fluid flex-lg-row">
			<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
				<div className="d-flex flex-center flex-lg-start flex-column">
					<div className="mb-7 d-flex justify-content-center">
						<img alt="Logo" className='img-fluid w-400px' src={`${toAbsoluteUrl(`/media/logos/Move-logo-BG-transparent-White.svg`)}`} />
					</div>
				</div>
			</div>
			<div className="d-flex flex-center w-lg-50 p-10">
				<div className="card rounded-3 w-md-550px">
					<div className="card-body d-flex flex-column p-10 p-lg-20 pb-lg-10">
						<div className="d-flex flex-center flex-column-fluid">
							<Login />
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}
export { AuthLayout }
