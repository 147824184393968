import React, { FC } from 'react';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { PushNotificationPage } from './PushNotificationPage';

const PushNotificationPageWrapper: React.FC = (props: any) => {
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Notifications',
            path: '/notifications',
            isSeparator: true,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator : true,
            isActive: true,
        }
    ];

    return(
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}> Notification Options </PageTitle>
            <PushNotificationPage/>
        </>
    )
}

export default PushNotificationPageWrapper