/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import {BASE_ENDPOINT} from '../../../../shared/apiConfig'
import {USER_DEFAULT_IMAGE_PATH, USER_IMAGE_UPLOAD_PATH} from '../../../../shared/constants'
import showConfirmationDialog from '../../../../helper/showConfirmationDialogUtility'
const API_URL = BASE_ENDPOINT

const HeaderUserMenu: FC = () => {
  const {currentUser, auth, logout} = useAuth()
  const [id, setUserId] = useState<any>()
  const confirmation = async () => {
    return await showConfirmationDialog('Confirmation', 'Are you sure you want to Sign out?', 'Sign Out')
  }

  const ConfirmationModal = async () => {
    const isConfirmed = await confirmation()
    if (isConfirmed) {
      logout();
    }
  }
  useEffect(() => {
    if (auth != null) {
      setUserId(auth.result.id)
    }
  }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={
                auth?.result?.image
                  ? API_URL + USER_IMAGE_UPLOAD_PATH + auth?.result?.image
                  : toAbsoluteUrl(USER_DEFAULT_IMAGE_PATH)
              }
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {auth?.result?.firstName} {auth?.result?.surname}
            </div>
            <a href='javascript:void(0)' className='fw-semibold text-muted text-hover-primary fs-7'>
              {auth?.result?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/changePassword'} className='menu-link px-5'>
          Change Password
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={ConfirmationModal} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
