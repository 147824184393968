import React, { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal as BModal } from 'react-bootstrap';
import Cropper from 'react-easy-crop'
import { cropImage } from "./cropUtils";
import "cropperjs/dist/cropper.css";
type PropsType = {
  visible: boolean;
  imagesrc: any
  onCancel: () => void;
  onComplete: any
  containerStyle: any
  imageModule: any
  setareaPixels : any | null
};
const ImageCrop: React.FC<React.PropsWithChildren<PropsType>> = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [aspect, setAspect] = useState<any>();

  useEffect(() => {
    if (props.imageModule) {
      if (props.imageModule === "UserModule") {
        setAspect(1);
      }
      else if (props.imageModule === "webBannerImage") {
        setAspect(3 / 2);
      }
      else if (props.imageModule === "mobileBanner") {
        setAspect(16 / 9);
      }
    }
  }, [props.imageModule])


  const onComplete = () => {
    if (props.onComplete) {
      props.onComplete(cropImage(props.imagesrc, croppedAreaPixels, console.log));
      props.onCancel();
    }
  };

  return (
    <>
      <BModal show={props.visible} maxWidth="sm" fullWidth onHide={props.onCancel} centered >
        <BModal.Header closeButton>
          <BModal.Title> Crop Profile Image </BModal.Title>
        </BModal.Header>
        <BModal.Body >
          <div>
            <div style={{ width: "100%" }}>
              <div style={props.containerStyle}>
                <Cropper
                  image={props.imagesrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onCropComplete={(_, croppedAreaPixels) => {
                    setCroppedAreaPixels(croppedAreaPixels);
                    props.setareaPixels(croppedAreaPixels);
                  }}
                  onZoomChange={setZoom}
                  {...props}
                />
              </div>
            </div>
          </div>
        </BModal.Body>
        <BModal.Footer>
          <div className="d-flex justify-content-end">
            <button type="button" id="kt_ecommerce_add_event_cancel" className="btn btn-light me-5" onClick={props.onCancel}>Cancel</button>
            <button type="submit" id="kt_sign_in_submit" className="btn btn-primary" onClick={() =>
              onComplete()
            }>
              <span className="indicator-label">Crop Image</span>
            </button>
          </div>
        </BModal.Footer>
      </BModal>
    </>
  );
};

export default ImageCrop;
