import { KTCard } from "../../../_metronic/helpers";
import React from "react";
import ContactInquiryTable from "./ContactInquiryTable";

const OtpRequestPage: React.FC = (Props: any) => {
    return (
        <>
            <KTCard>
                <ContactInquiryTable />
            </KTCard>
        </>
    )
}

export default OtpRequestPage