import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  DropdownModel,
  EventModel,
  AddEditEventModel,
  AddEditEvent,
  EventRuleModel,
  EditEvent,
  GetEventForEdit,
} from '../../../../services/Event/Event.type'
import { Form, Formik } from 'formik'
import EventGeneralPage from './EventGeneralPage'
import EventRulesPage from './EventRulesPage'
import { KTIcon } from '../../../../_metronic/helpers'
import { Form as BForm, Spinner } from 'react-bootstrap'
import { usePostQuery, useGetQuery } from '../../../../hooks/reactQueryHelper'
import { useNavigate } from 'react-router'
import { BASE_ENDPOINT } from '../../../../shared/apiConfig'
import {
  EVENT_CYCLING_IMAGE_PATH,
  EVENT_DEFAULT_IMAGE_PATH,
  EVENT_RUNNING_IMAGE_PATH,
  EVENT_WALKING_IMAGE_PATH,
} from '../../../../shared/constants'
import toastNotification from '../../../../helper/toastUtils'
import EventDescriptionPage from './EventDescriptionPage'
import { FocusError } from '../../../../helper/utility'
import { useLocation } from 'react-router-dom';
import moment from 'moment'
const API_URL = BASE_ENDPOINT

const AddEditEventPage = (props: any) => {
  const { id } = props
  const navigate = useNavigate()
  const addEditEventQuery: any = usePostQuery('addEditEvent', AddEditEvent, {})
  const getEventQuery: any = useGetQuery('getEventForEdit', GetEventForEdit)
  const editEventQuery: any = usePostQuery('editEvent', EditEvent, {})
  const [selectImage, setSelectedImage] = useState<any>(EVENT_CYCLING_IMAGE_PATH)
  const [eventImageList, setEventImageList] = useState<any>([])
  const statusDropDown: DropdownModel[] = [
    {
      value: 1,
      label: 'Active',
    },
    {
      value: 0,
      label: 'Inactive',
    },
  ]

  const uintDropDown: DropdownModel[] = [
    {
      value: 'm',
      label: 'Meter',
    },
    {
      value: 'km',
      label: 'Kilometers',
    },
  ]

  const distanceTypeDropDown: DropdownModel[] = [
    {
      value: 'Fixed',
      label: 'Fixed',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ]

  const distanceDropDown: DropdownModel[] = [
    {
      value: 10,
      label: '10k',
    },
    {
      value: 21,
      label: '21km',
    },
    {
      value: 42,
      label: '42km',
    },
    {
      value: 5,
      label: '5km',
    },
    {
      value: 100,
      label: '100km',
    },
    {
      value: 1500,
      label: '1500km',
    },
    {
      value: 'other',
      label: 'Other'
    }
  ]

  const eventTypeDropdown: DropdownModel[] = [
    {
      value: 1,
      label: 'Cycling',
    },
    {
      value: 2,
      label: 'Walking',
    },
    {
      value: 3,
      label: 'Running',
    },
  ]
  const [bgClass, setBgClass] = useState<string>('bg-success')
  const [eventRulesRow, setEventRulesRow] = useState<EventRuleModel[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [descriptionValidationMessage, setDescriptionValidationMessage] = useState(false)
  const [isWebBannerImageRequired, setIsWebBannerImageRequired] = useState<boolean>(false)
  const [isMobileBannerImageRequired, setIsMobileBannerImageRequired] = useState<boolean>(false)

  const location = useLocation();
  const additionalData: any = location.state;
  function brToNl(text) {

    return text.replaceAll('<br />', '');
  }
  useEffect(() => {
    if (id) {
      getEventQuery.mutate(id)
    }
  }, [id])

  useEffect(() => {
    if (additionalData?.currentPage != null) {
      setCurrentPage(2);
    }
  })

  useEffect(() => {
    if (getEventQuery.isSuccess) {
      const getEvent: EventModel = getEventQuery.data.result
      addEditEventModel.id = getEvent.id
      addEditEventModel.description = brToNl(getEvent.description)
      addEditEventModel.startDate = getEvent.startDate.toString();
      addEditEventModel.endDate = getEvent.endDate.toString();
      addEditEventModel.image = getEvent.image
      addEditEventModel.eventTypeID = getEvent.eventTypeID
      addEditEventModel.joiningFees = Number(getEvent.joiningFees)
      addEditEventModel.maxParticipants = Number(getEvent.maxParticipants)
      addEditEventModel.isFixedParticpated = Number(getEvent.maxParticipants) > 0 ? true : false
      addEditEventModel.name = getEvent.name
      addEditEventModel.isActive = getEvent.isActive
      addEditEventModel.isChallenge = getEvent.isChallenge
      addEditEventModel.isPrivate = getEvent.isPrivate
      addEditEventModel.rewards = getEvent.rewards
      addEditEventModel.totalDistance = Number(getEvent.totalDistance)
      addEditEventModel.eventTypeName = getEvent.eventTypeName
      addEditEventModel.duration = getEvent.duration
      addEditEventModel.unit = getEvent.unit
      addEditEventModel.location = getEvent.location
      addEditEventModel.isGift = getEvent.isGift
      addEditEventModel.mobileBannerImage = getEvent.mobileBannerImage
      addEditEventModel.webBannerImage = getEvent.webBannerImage
      addEditEventModel.selectedStatus = statusDropDown[getEvent.isActive === true ? 0 : 1]
      addEditEventModel.selectedUnit = uintDropDown.filter((x) => x.value === getEvent.unit)[0]
      addEditEventModel.selectedFixedDistance = getEvent.isFixed ? distanceDropDown.filter((x) => x.value === getEvent.totalDistance)[0] : distanceDropDown.filter((x) => x.value === 'other')[0]
      addEditEventModel.isFixed = getEvent.isFixed
      addEditEventModel.selectedDistanceType = true
      addEditEventModel.isFree = Number(getEvent.joiningFees) > 0 ? false : true
      addEditEventModel.selectedEventType = eventTypeDropdown.filter(
        (x) => x.value === getEvent.eventTypeID
      )[0]
      addEditEventModel.eventRules = getEvent.eventRules
      addEditEventModel.eventImageList = getEvent.eventImageList
      setEventRulesRow(getEvent.eventRules ?? [])
      setEventImageList(getEvent.eventImageList ?? [])
      setBgClass(getEvent.isActive === true ? 'bg-success' : 'bg-danger')
      addEditEventModel.isPublish = getEvent.isPublish
      addEditEventModel.registrationClosingDate = getEvent.registrationClosingDate != null ? getEvent.registrationClosingDate.toString().split('T')[0] : null
      setAddEditEventModel({ ...addEditEventModel })
    }
  }, [getEventQuery?.isSuccess])

  const initAddEditEventModel: AddEditEventModel = {
    id: 0,
    description: '',
    startDate: moment(new Date()).add(10, 'minute').format('YYYY-MM-DDTHH:mm'),
    endDate: moment(new Date()).add(24, 'hours').add(1, 'minute').format('YYYY-MM-DDTHH:mm'),
    eventTypeID: 1,
    image: null,
    isActive: true,
    joiningFees: 0.0,
    maxParticipants: 0,
    name: null,
    rewards: null,
    totalDistance: distanceDropDown[0].value,
    eventTypeName: 'Cycling',
    duration: '',
    createdDate: null,
    modifiedDate: null,
    modifiedBy: null,
    createdBy: null,
    selectedStatus: statusDropDown[0],
    selectedDistanceType: true,
    selectedUnit: distanceDropDown[0],
    selectedFixedDistance: distanceDropDown[0],
    unit: uintDropDown[1].value,
    selectedEventType: eventTypeDropdown[0],
    eventRules: [],
    fileBase64Data: null,
    eventParticipentCount: 0,
    eventImageList: [],
    isGift: false,
    location: null,
    mobileBannerImage: null,
    webBannerImage: null,
    pageURL: null,
    filebase64WebBannerImage: null,
    filebase64mobileBannerImage: null,
    isPublish: false,
    isFixed: true,
    isPrivate: false,
    registrationClosingDate: null,
    isFree: true,
    isFixedParticpated: false,
    isChallenge: false
  }

  const [addEditEventModel, setAddEditEventModel] =
    useState<AddEditEventModel>(initAddEditEventModel)

  const eventSchema = Yup.object().shape({
    name: Yup.string().trim().required('Please enter event name'),

    startDate: id ? Yup.date()
      .required('Please select event start date')
      //.min(new Date(), 'Start date cannot be before the current date')
      .test(
        'startDate',
        'Start date should not be the same as or after event end date',
        function (startDate) {
          const endDate = this.parent.endDate
          return !endDate || startDate < endDate
        }
      ) : Yup.date()
        .required('Please select event start date')
        .min(new Date(), 'Start date cannot be earlier than current date')
        .test(
          'startDate',
          'Start date should not be the same as or after event end date',
          function (startDate) {
            const endDate = this.parent.endDate
            return !endDate || startDate < endDate
          }
        ),
    endDate: Yup.date()
      .required('Please select event end date')
      .test(
        'endDate',
        'End date should not be the same or earlier than event start date',
        function (endDate) {
          const startDate = this.parent.startDate
          return !startDate || endDate > startDate
        }
      ),
      registrationClosingDate: Yup.date()
      .nullable() // Allow null values
      .test(
        'registrationClosingDate',
        'Registration closing date must be on or before event end date',
        function (registrationClosingDate : any) {
          const endDate = this.parent.endDate;
          // Check if endDate is defined and perform comparison
           const result = registrationClosingDate == null   ? true : ((registrationClosingDate < endDate ) ? true : false );
          return(result);
        }
      ),
    isFree: Yup.boolean(),
    joiningFees: Yup.number()
      .when('isFree', (isFree, schema) => {
        if (isFree.includes(false)) {
          return schema.min(1, 'Please enter valid joining fee')
            .required('Please enter joining fee')
            .test('len', 'Please enter valid joining fee', val => val.toString().length <= 7)
        }
        return schema
      }),
    unit: Yup.string().required('Please select unit'),
    isFixedParticpated: Yup.boolean(),
    maxParticipants: Yup.number()
      .when('isFixedParticpated', (isFixedParticpated, schema) => {
        if (isFixedParticpated.includes(true)) {
          return schema.min(1, 'Please enter min 1 participant')
            .required('Please enter participant')
            .test('len', 'Please enter valid participants', val => val.toString().length <= 7)
        }
        return schema.min(0)
      }),
    webBannerImage: Yup.string().trim().required('Please upload web banner image'),
    mobileBannerImage: Yup.string().trim().required('Please upload mobile banner image'),
    isChallenge: Yup.boolean(),
    totalDistance: Yup.number()
      .when('isChallenge', (isChallenge, schema) => {
        if (isChallenge.includes(false)) {
          return schema.min(1, 'Please enter valid distance')
            .required('Please enter distance').min(1, 'Please enter valid distance')
            .test('len', 'Please enter valid distance', val => val.toString().length <= 7)
        }
        return schema
      })
  })

  //dropdown actions
  const handleDropDown = (row: any, type: string) => {
    if (type === 'Status') {
      addEditEventModel.selectedStatus = statusDropDown.filter(
        (x) => x.value === row.target.value
      )[0]
      if (row.target.value === '0') {
        addEditEventModel.isActive = false
        setBgClass('bg-danger')
      } else {
        addEditEventModel.isActive = true
        setBgClass('bg-success')
      }
    }
    if (type === 'EventType') {
      addEditEventModel.eventTypeID = parseInt(row.target.value)
      addEditEventModel.selectedEventType = eventTypeDropdown.filter(
        (x) => x.value === row.target.value
      )[0]
      if (
        selectImage === EVENT_CYCLING_IMAGE_PATH ||
        selectImage === EVENT_RUNNING_IMAGE_PATH ||
        selectImage === EVENT_WALKING_IMAGE_PATH ||
        selectImage === EVENT_DEFAULT_IMAGE_PATH
      ) {
        if (addEditEventModel.eventTypeID === 1) {
          setSelectedImage(EVENT_CYCLING_IMAGE_PATH)
        } else if (addEditEventModel.eventTypeID === 2) {
          setSelectedImage(EVENT_WALKING_IMAGE_PATH)
        } else {
          setSelectedImage(EVENT_RUNNING_IMAGE_PATH)
        }
      }
    }
    if (type === 'Unit') {
      addEditEventModel.unit = row.target.value
      addEditEventModel.selectedUnit = uintDropDown.filter((x) => x.value === row.target.value)[0]
    }
    if (type === 'FixedDistance') {
      var d = row.target.value;
      addEditEventModel.selectedFixedDistance = distanceDropDown.filter((x) => x.value == d)[0]
      if (d === "other") {
        addEditEventModel.isFixed = false
        addEditEventModel.totalDistance = 0
        addEditEventModel.unit = uintDropDown[0].value
        addEditEventModel.selectedUnit = uintDropDown[0]
      }
      else {
        addEditEventModel.isFixed = true
        addEditEventModel.totalDistance = d
        addEditEventModel.unit = uintDropDown[1].value
      }
    }
    setAddEditEventModel(addEditEventModel)
  }

  //input action
  const handleInput = (row: any, type: string) => {
    if (type === 'Participants') {
      addEditEventModel.maxParticipants = parseInt(row.target.value)
    }
    if (type === 'EventName') {
      addEditEventModel.name = row.target.value
    }
    if (type === 'Description') {
      addEditEventModel.description = row.target.value
    }
    if (type === 'StartDate') {
      addEditEventModel.startDate = row._d
    }
    if (type === 'EndDate') {
      addEditEventModel.endDate = row._d
    }
    if (type === 'RegistrationClosingDate') {
      addEditEventModel.registrationClosingDate = row._d
    }
    if (type === 'Distance') {
      addEditEventModel.totalDistance = parseInt(row.target.value)
    }
    if (type === 'Price') {
      addEditEventModel.joiningFees = parseInt(row.target.value)
    }
    if (type === 'Rewards') {
      addEditEventModel.rewards = row.target.value
    }
    setAddEditEventModel(addEditEventModel)
  }

  const HandleButton = (row: any, type: string, index: 0) => {
    if (type === 'Cancel') {
      setAddEditEventModel(initAddEditEventModel)
      navigate('/events')
    } else if (type === 'AddEventRule') {
      setEventRulesRow([...eventRulesRow, { id: 0, eventID: 0, rule: '', isError: false }])
    } else if (type === 'RemoveEventRule') {
      if (eventRulesRow.length > 1) {
        let f = eventRulesRow.filter((x, index1) => index1 !== index)
        setEventRulesRow(f)
      } else {
        return
      }
    }
  }
  const ruleValidtion = () => {
    let isValidRule = true
    if (eventRulesRow) {
      eventRulesRow?.map((x) => {
        if (!x.rule) {
          x.isError = true
          isValidRule = false
        }
      })
      setEventRulesRow([...eventRulesRow])
    }
    return isValidRule
  }

  const previousTab = (currentpage) => {
    if (currentpage === 1) {
      setCurrentPage(0)
      return
    } else if (currentpage === 2) {
      if (ruleValidtion()) {
        setCurrentPage(1)
        if (additionalData?.currentPage != null) {
          additionalData.currentPage = null
        }
        return
      } else {
        return
      }
    }
  }

  const onSubmit = (values: any) => {
    if (currentPage === 0) {
      if (
        !values.webBannerImage ||
        values.webBannerImage === undefined ||
        values.webBannerImage === '' ||
        !values.mobileBannerImage ||
        values.mobileBannerImage === undefined ||
        values.mobileBannerImage === ''
      ) {
        if (
          !values.webBannerImage ||
          values.webBannerImage === undefined ||
          values.webBannerImage === ''
        )
          setIsWebBannerImageRequired(true)

        if (
          !values.mobileBannerImage ||
          values.mobileBannerImage === undefined ||
          values.mobileBannerImage === ''
        )
          setIsMobileBannerImageRequired(true)

        return
      }
      setCurrentPage(1)
      return
    } else if (currentPage === 1) {
      if (
        values.description === null ||
        values.description === '' ||
        values.description === undefined
      ) {
        setDescriptionValidationMessage(true)
        return
      }

      if (ruleValidtion()) {
        setCurrentPage(2)
        return
      } else {
        return
      }
    }

    if (ruleValidtion()) {
      addEditEventModel.id = values.id
      addEditEventModel.description = values.description
      addEditEventModel.startDate = values.startDate
      addEditEventModel.endDate = values.endDate
      addEditEventModel.image = values.image
      addEditEventModel.joiningFees = values.isFree ? 0.0 : Number(values.joiningFees)
      addEditEventModel.maxParticipants = Number(values.maxParticipants)
      addEditEventModel.name = values.name
      addEditEventModel.rewards = values.rewards
      addEditEventModel.isChallenge = values.isChallenge
      addEditEventModel.totalDistance = values.isChallenge ? 0 : Number(values.totalDistance)
      addEditEventModel.duration = values.duration
      addEditEventModel.isActive = values.isActive
      addEditEventModel.isGift = values.isGift
      addEditEventModel.isPrivate = values.isPrivate
      addEditEventModel.location = values.location
      addEditEventModel.eventRules = eventRulesRow
      addEditEventModel.eventImageList = eventImageList
      addEditEventModel.eventTypeID = Number(values.eventTypeID)
      addEditEventModel.registrationClosingDate = values.registrationClosingDate != "" ? values.registrationClosingDate : null
      if (
        addEditEventModel.id > 0 &&
        values.webBannerImage != null &&
        values.webBannerImage !== undefined &&
        values.webBannerImage !== ''
      ) {
        const isOldImage = values.webBannerImage.includes(API_URL)
        if (!isOldImage) {
          addEditEventModel.webBannerImage = values.webBannerImage
        } else {
          addEditEventModel.webBannerImage = getEventQuery.data.result.webBannerImage
        }
      }
      if (
        addEditEventModel.id > 0 &&
        values.mobileBannerImage != null &&
        values.mobileBannerImage !== undefined &&
        values.mobileBannerImage !== ''
      ) {
        const isOldImage = values.mobileBannerImage.includes(API_URL)
        if (!isOldImage) {
          addEditEventModel.mobileBannerImage = values.mobileBannerImage
        } else {
          addEditEventModel.mobileBannerImage = getEventQuery.data.result.mobileBannerImage
        }
      } else {
        addEditEventModel.webBannerImage = values.webBannerImage
        addEditEventModel.mobileBannerImage = values.mobileBannerImage
      }

      if (addEditEventModel.id > 0) {
        if (
          (selectImage === EVENT_DEFAULT_IMAGE_PATH ||
            selectImage === EVENT_CYCLING_IMAGE_PATH ||
            selectImage === EVENT_WALKING_IMAGE_PATH ||
            selectImage === EVENT_RUNNING_IMAGE_PATH) &&
          getEventQuery.data.result?.image != null
        ) {
          addEditEventModel.fileBase64Data = null
        }
        if (
          selectImage !== EVENT_CYCLING_IMAGE_PATH &&
          selectImage !== EVENT_RUNNING_IMAGE_PATH &&
          selectImage !== EVENT_WALKING_IMAGE_PATH &&
          selectImage !== EVENT_DEFAULT_IMAGE_PATH &&
          getEventQuery.data.result?.image != null
        ) {
          addEditEventModel.image = getEventQuery.data.result?.image
        }
        addEditEventModel.image = addEditEventModel.image === '' ? null : addEditEventModel.image
        if (
          selectImage === EVENT_DEFAULT_IMAGE_PATH ||
          selectImage === EVENT_CYCLING_IMAGE_PATH ||
          selectImage === EVENT_WALKING_IMAGE_PATH ||
          selectImage === EVENT_RUNNING_IMAGE_PATH
        ) {
          addEditEventModel.image = null
        }
      }
      setAddEditEventModel({ ...addEditEventModel })
      addEditEventQuery.mutate(addEditEventModel)
    }
  }

  useEffect(() => {
    if (addEditEventQuery?.data?.statusCode === 200) {
      setEventRulesRow([])

      toastNotification('success', 'Event has been created successfully')
      navigate('/events')
    }
  }, [
    addEditEventQuery?.isError,
    addEditEventQuery?.isSuccess,
    editEventQuery?.isError,
    editEventQuery?.isSuccess,
  ])

  useEffect(() => {
    if (addEditEventQuery?.data?.statusCode === 200) {
      if (addEditEventModel.id > 0) toastNotification('success', 'Event has been updated successfully')
      else toastNotification('success', 'Event has been created successfully')
      setEventRulesRow([])
      navigate('/events')
    }
  }, [addEditEventQuery?.isError, addEditEventQuery?.isSuccess])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addEditEventModel}
        validationSchema={eventSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          submitForm,
        }: any) => {

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setFieldValue('id', addEditEventModel.id)
            setFieldValue('description', addEditEventModel.description)
            setFieldValue('startDate', addEditEventModel.startDate)
            setFieldValue('endDate', addEditEventModel.endDate)
            setFieldValue('image', addEditEventModel.image)
            setFieldValue('joiningFees', addEditEventModel.joiningFees)
            setFieldValue('maxParticipants', addEditEventModel.maxParticipants)
            setFieldValue('name', addEditEventModel.name)
            setFieldValue('registrationClosingDate', addEditEventModel.registrationClosingDate)
            setFieldValue('rewards', addEditEventModel.rewards)
            setFieldValue('totalDistance', addEditEventModel.totalDistance)
            setFieldValue('duration', addEditEventModel.duration)
            setFieldValue('eventRules', addEditEventModel.eventRules)
            setFieldValue('fileBase64Data', addEditEventModel.fileBase64Data)
            setFieldValue('eventTypeID', addEditEventModel.eventTypeID)
            setFieldValue('isActive', addEditEventModel.isActive)
            setFieldValue('webBannerImage', addEditEventModel.webBannerImage)
            setFieldValue('mobileBannerImage', addEditEventModel.mobileBannerImage)
            setFieldValue('isFixedParticpated', addEditEventModel.isFixedParticpated)
            setFieldValue('isFree', addEditEventModel.isFree)
            setFieldValue('isPrivate', addEditEventModel.isPrivate)
            setFieldValue('isChallenge', addEditEventModel.isChallenge)
          }, [setFieldValue])

          return (
            <div id='kt_app_content' className='app-content flex-column-fluid'>
              <div id='kt_app_content_container' className='app-container'>
                <Form
                  id='kt_ecommerce_add_event_form'
                  className='form d-flex flex-column flex-lg-row'
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
                    <div className='card tab-content'>
                      <div className='form-wizard'>
                        <div className='form-wizard-header pt-0'>
                          <ul className='list-unstyled d-flex justify-content-center form-wizard-steps clearfix'>
                            <li
                              className={
                                (currentPage === 0 ? 'active' : '') ||
                                (currentPage === 1 || currentPage === 2 ? 'activated' : '')
                              }
                            >

                              {(currentPage === 0 ? 'active' : '') ||
                                (currentPage === 1 || currentPage === 2 ? 'activated' : '') ?

                                <button type="button" id="kt_ecommerce_add_event_cancel" className="btn btn-primary" onClick={(e) => {
                                  if (!errors || Object.keys(errors).length == 0) {
                                    previousTab(1)
                                  }
                                }} style={{ padding: 0 }}>

                                  1  </button> :
                                <button type="submit" id="kt_ecommerce_add_event_submit" className="btn btn-primary" onClick={(e) => {
                                  ruleValidtion()
                                }} style={{ padding: 0 }}>  1 </button>
                              }
                            </li>
                            <li
                              className={
                                (currentPage === 1 ? 'active' : '') ||
                                (currentPage === 2 ? 'activated' : '')
                              }
                            >
                              {currentPage !== 0 ? <button
                                type='button'
                                id='kt_ecommerce_add_event_cancel'
                                className='btn btn-light me-5' style={{ padding: 0 }}
                                onClick={(e) => {
                                  if (!errors || Object.keys(errors).length == 0)
                                    previousTab(currentPage)
                                }}
                              >2
                              </button> :
                                <button type="submit" id="kt_ecommerce_add_event_submit" className="btn btn-primary" style={{ padding: 0 }}
                                  onClick={(e) => {
                                    if (!errors || Object.keys(errors).length == 0) {
                                      previousTab(currentPage)
                                    }

                                  }}>  2    </button>
                              }
                            </li>
                            <li className={currentPage === 2 ? 'active' : ''}>

                              {currentPage === 3 ? <button
                                type='button'
                                id='kt_ecommerce_add_event_cancel'
                                className='btn btn-light me-5' style={{ padding: 0 }}
                                onClick={(e) => {
                                  if (!errors || Object.keys(errors).length == 0) {
                                    previousTab(3)
                                  }
                                }}
                              >2
                              </button> :
                                <button type={addEditEventModel.id > 0 ? "button" : "submit"} id="kt_ecommerce_add_event_submit" className="btn btn-primary" style={{ padding: 0 }}
                                  onClick={((e) => {
                                    if (!errors || Object.keys(errors).length == 0) {
                                      previousTab(3);
                                      if (addEditEventModel.id > 0) {
                                        setCurrentPage(2)
                                      }
                                    }
                                  }
                                  )
                                  }
                                >  3  </button>}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {currentPage === 0 && (
                        <fieldset
                          // className={currentPage === 0 ? 'wizard-fieldset show' : 'wizard-fieldset'}
                          className='wizard-fieldset show'
                        >
                          <EventGeneralPage
                            uintDropDown={uintDropDown}
                            distanceTypeDropDpopwn={distanceTypeDropDown}
                            distanceDropDown={distanceDropDown}
                            handleDropDown={handleDropDown}
                            handleInput={handleInput}
                            addEditEventModel={addEditEventModel}
                            setAddEditEventModel={setAddEditEventModel}
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            isWebBannerImageRequired={isWebBannerImageRequired}
                            isMobileBannerImageRequired={isMobileBannerImageRequired}
                          />
                        </fieldset>
                      )}

                      {currentPage === 1 && (
                        <fieldset
                          className={currentPage === 1 ? 'wizard-fieldset show' : 'wizard-fieldset'}
                        >
                          <EventDescriptionPage
                            handleInput={handleInput}
                            addEditEventModel={addEditEventModel}
                            setAddEditEventModel={setAddEditEventModel}
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            descriptionValidationMessage={descriptionValidationMessage}
                          />
                        </fieldset>
                      )}
                      {currentPage === 2 && (
                        <fieldset
                          className={currentPage === 2 ? 'wizard-fieldset show' : 'wizard-fieldset'}
                        >
                          <EventRulesPage
                            HandleButton={HandleButton}
                            eventRulesRow={eventRulesRow}
                            setEventRulesRow={setEventRulesRow}
                          />
                        </fieldset>
                      )}
                    </div>
                    <div className='row'>
                      <div className='d-flex justify-content-end'>
                        {currentPage !== 0 && (
                          <button
                            type='button'
                            id='kt_ecommerce_add_event_cancel'
                            className='btn btn-light me-5'
                            onClick={(e) => previousTab(currentPage)}
                          >
                            <KTIcon iconName='arrow-left' className='fs-4' /> Previous
                          </button>
                        )}
                        <button
                          type='button'
                          id='kt_ecommerce_add_event_cancel'
                          className='btn btn-light me-5'
                          onClick={(e) => HandleButton(e, 'Cancel', 0)}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          id='kt_ecommerce_add_event_submit'
                          className='btn btn-primary'
                          onClick={(e) => {
                            ruleValidtion()
                          }}
                        >
                          {addEditEventQuery.isLoading ? (
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            <>
                              {' '}
                              {currentPage !== 2 && (
                                <span className='indicator-label'>
                                  Next <KTIcon iconName='arrow-right' className='fs-4' />
                                </span>
                              )}
                              {currentPage === 2 && (

                                <span className='indicator-label'>{addEditEventModel.id > 0 ? 'Save changes' : 'Submit'} </span>
                              )}
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
                <FocusError />
              </div>
            </div >
          )
        }}
      </Formik >
    </>
  )
}

export default AddEditEventPage
