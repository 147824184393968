/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState, Fragment } from 'react'
import { GetUserTransaction } from '../../../../services/User/User.type';
import { usePostQuery } from '../../../../hooks/reactQueryHelper';
import useTableState from '../../../components/Table/useTableState';
import { CellProps, Column } from 'react-table';
import Table from "../../../components/Table";
import { TableGlobalSearch } from '../../../components/Table/TableGlobalSearch';
import { KTCardBody } from "../../../../_metronic/helpers"
import TableInner from "../../../components/Table/TableInner"
import clsx from 'clsx';
import moment from 'moment';
import { TablePagination } from "../../../components/Table/TablePagination"
import { GridRequestModel, GridResponseModel, SortModel } from '../../../../services/Common/Common.type';
import { addcommasRupeesAmount, applyUserSpecificDateTimeFormat } from '../../../../helper/utility';
import { TableLoader } from '../../../components/Table/TableLoader';
import { useNavigate } from 'react-router-dom';



const UserTransactionPage = (props: any) => {
  const { userDetail, showUsername } = props;
  const getUserTransactionQuery: any = usePostQuery(
    'getUserTransaction',
    GetUserTransaction, {}
  );
  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({
      pageIndex: 0,
    });

  const { sort, page, limit, search } = tableState;
  const initSortModel: SortModel = {
    sort: '',
    fields: ''

  }
  const initGridRequestModel: GridRequestModel = {
    page: page ?? 0,
    limit: limit ?? 0,
    search: search ? search : '',
    sort: sort ? sort : initSortModel,
    id: 0,
    eventId: 0,
    eventTypeID: 0,
    paymentDate: null,
    isInvited: false,
    isJoined: false
  }

  const [transactionList, setTransactionList] = useState<any>();
  const [selectUserId, setSelectUserId] = useState<any>('');
  const [selectEventId, setSelectEventId] = useState<any>('');
  const [selectPaymentDate, setSelectPaymentDate] = useState<any>('');
  const navigate = useNavigate()

  useEffect(() => {
    if (getUserTransactionQuery.data) {
      setTransactionList(getUserTransactionQuery?.data?.result);
    }
  }, [getUserTransactionQuery.data])


  useEffect(() => {
    initGridRequestModel.page = page ?? 0
    initGridRequestModel.limit = limit ?? 0
    if (sort)
      initGridRequestModel.sort = sort
    if (search)
      initGridRequestModel.search = search
    if (userDetail) {
      initGridRequestModel.id = userDetail.id;
    }
    if (selectUserId > 0) {

      initGridRequestModel.id = selectUserId === "" ? 0 : selectUserId;
      initGridRequestModel.eventId = selectEventId === "" ? 0 : selectEventId
    }
    if (selectEventId > 0) {
      initGridRequestModel.id = selectUserId === "" ? 0 : selectUserId;
      initGridRequestModel.eventId = selectEventId === "" ? 0 : selectEventId
    }

    if (selectPaymentDate !== null) {
      if (selectUserId === "" && userDetail == undefined) {
        initGridRequestModel.id = selectUserId === "" ? 0 : selectUserId;
      }
      else {
        initGridRequestModel.id = selectUserId === "" ? userDetail.id : selectUserId;
      }
      //initGridRequestModel.id = selectUserId === "" ? 0 : selectUserId;
      initGridRequestModel.eventId = selectEventId === "" ? 0 : selectEventId
      initGridRequestModel.paymentDate = selectPaymentDate === "" ? null : selectPaymentDate
    }
    getUserTransactionQuery.mutate(initGridRequestModel);
  }, [page, limit, sort, search, selectEventId, selectUserId, selectPaymentDate]);


  const onChangePaymentDate = (selectPaymentDate: any) => {
    initGridRequestModel.page = 1;
    if (selectPaymentDate != null) {
      setSelectPaymentDate(selectPaymentDate);
      initGridRequestModel.id = selectUserId === "" ? 0 : selectUserId;
      initGridRequestModel.eventId = selectEventId === "" ? 0 : selectEventId
      initGridRequestModel.paymentDate = selectPaymentDate === "" ? null : selectPaymentDate
    }
  }

  const onSelectUserChange = (selectUserId) => {
    initGridRequestModel.page = 1;
    if (selectUserId != null) {
      setSelectUserId(selectUserId);
      initGridRequestModel.id = selectUserId === "" ? 0 : selectUserId;
      initGridRequestModel.eventId = selectEventId === "" ? 0 : selectEventId
      initGridRequestModel.paymentDate = selectPaymentDate === "" ? null : selectPaymentDate
      //getUserTransactionQuery.mutate(initGridRequestModel);
    }
  }

  const onSelectEventChange = (selectEventId) => {
    initGridRequestModel.page = 1;
    if (selectEventId != null) {
      setSelectEventId(selectEventId);
      initGridRequestModel.page = 1;
      initGridRequestModel.eventId = selectEventId === "" ? 0 : selectEventId;
      initGridRequestModel.id = selectUserId === "" ? 0 : selectUserId
      initGridRequestModel.paymentDate = selectPaymentDate === "" ? null : selectPaymentDate
    }
  }

  const columns = useMemo<Column<any>[]>(() => {
    const baseColumns = [
      {
        Header: `Event`,
        accessor: 'eventName',
        disableSortBy: false,
        maxWidth: 400,
        minWidth: 140,
        width: 200,
        Cell: (props: CellProps<any>) => {
          const getEventName = props?.row?.original?.eventName ? props?.row?.original?.eventName : '-'
          const eventName = `${getEventName.substring(0, 30)}${getEventName.length > 30 ? '...' : ''
            }`
          return <span>  <a className='text-gray-800 text-hover-primary mb-1' href="javascript:void(0)" onClick={() => {
            const additionalData = {
              tabName: 'UserParticipated', // Additional data you want to pass
            }
            navigate(`/events/detail/` + props?.row?.original?.eventID, { state: additionalData })
          }}>
            {eventName ? eventName : '-'}
          </a> </span>
        }
      },
      {
        Header: `Transaction ID`,
        accessor: 'transactionID',
        disableSortBy: false,
        Cell: (props: CellProps<any>) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex flex-column'>
                <span className='text-gray-800 mb-1'>
                  {props?.row?.original?.transactionID ? props?.row?.original?.transactionID : '-'}
                </span>
              </div>
            </div>
          )
        }
      },
      {
        Header: `Payment Date`,
        accessor: 'paymentDate',
        disableSortBy: false,
        Cell: (props: CellProps<any>) => {
          return <span> {props?.row?.original?.paymentDate ? moment(props?.row?.original?.paymentDate).format(applyUserSpecificDateTimeFormat()) : moment(props?.row?.original?.createdDate).format(applyUserSpecificDateTimeFormat())}</span>
        }
      },
      {
        Header: <div style={{ textAlign: 'right' }}>Amount</div>,
        accessor: 'joiningFees',
        disableSortBy: false,
        maxWidth: 50,
        minWidth: 20,
        width: 30,
        style: { textAlign: 'right' },
        Cell: (props: CellProps<any>) => {
          if (props?.row?.original?.joiningFees > 0) {
            return <div style={{ textAlign: 'right' }}><span className="fw-semibold fs-6 text-gray-800"> {addcommasRupeesAmount(props?.row?.original?.joiningFees)}</span></div>
          } else {
            return <div style={{ textAlign: 'right' }}><span className="fw-semibold fs-6 text-gray-800">Free</span></div>
          }
        }
      },
      {
        Header: `Payment Status`,
        accessor: 'paymentStatus',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const className = (props?.row?.original?.paymentStatus != 1) ? (props?.row?.original?.paymentStatus == 2 ? 'success' : 'danger') : 'warning';
          return (
            <div
              className={clsx(
                'badge ',
                `badge-light-${className}`,
                `fw-bolder`
              )}>{className === 'success' ? 'Completed' : className === 'warning' ? 'Pending' : 'Failed'}</div>
          )
        }
      },
    ];

    if (showUsername) {
      // Add the 'User Name' column if showUsername is true
      baseColumns.unshift({
        Header: `User Name`,
        accessor: 'userName',
        disableSortBy: false,
        Cell: (props: CellProps<any>) => {
          const fullName = `${props?.row?.original?.firstName} ${props?.row?.original?.surName}`;
          return (
            <span>
              <a
                className='text-gray-800 text-hover-primary mb-1'
                href='javascript:void(0)'
                onClick={() => {
                  const additionalData = {
                    tabName: 'Event', // Additional data you want to pass
                  }
                  navigate(`/users/detail/` + props?.row?.original?.userID, { state: additionalData })
                }}
              >
                {fullName ? fullName : '-'}
              </a>
            </span>
          );
        }
      });
    }

    return baseColumns;
  }, [showUsername]);

  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <Table
        columns={columns}
        data={transactionList?.result ?? []}
        initialState={tableInitialState}
        pageCount={transactionList?.totalPages ?? []}
        onTableStateChange={handleTableStateChange}
      >
        <Fragment>
          <TableGlobalSearch serachtext={"Search"} isAddbtnVisible={false} isDropDownVisible={userDetail != null && userDetail.id ? false : true} dropDownVisibility={['userDropDown', 'eventDropDown', 'paymentDate']} onChangePaymentDate={onChangePaymentDate} onSelectUserChange={onSelectUserChange} onSelectEventChange={onSelectEventChange} selectUserId={selectUserId} selectEventId={selectEventId} selectPaymentDate={selectPaymentDate} />
          <KTCardBody className='py-4'>
            <div className='table-responsive'>
              <TableInner loading={false} entityLabel='Transaction'></TableInner>
            </div>
            <TablePagination />
            <TableLoader isLoading={getUserTransactionQuery?.isLoading} />
          </KTCardBody>
        </Fragment>
      </Table>
    </div>
  )
}

export default UserTransactionPage
