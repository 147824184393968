export interface ApplicationVersion{
    id: number,
    platform : string | null,
    version : string | null,
    message : string | null
    latestVersion : string | null
    isForcefullyUpdate : boolean
    isShowUpdateDialog : boolean
};

export const getAllApplicationVersion = '/adminapi/get-all-application-version';
export const deleteApplicationVersion = '/adminapi/delete-application-version';
export const upsertApplicationVersion = '/adminapi/upsert-application-version';
export const getApplicationVersion = '/adminapi/get-application-version';