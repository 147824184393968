import { Modal } from "react-bootstrap";
import Table from '../../components/Table';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { KTCardBody, KTIcon } from '../../../_metronic/helpers'
import { TableLoader } from '../../components/Table/TableLoader'
import { TablePagination } from '../../components/Table/TablePagination'
import TableInner from '../../components/Table/TableInner'
import useTableState from "../../components/Table/useTableState";
import { CellProps, Column } from "react-table";
import { CSVLink } from 'react-csv';
import moment from "moment";
import { DemoInviteUserModel, initDemoInviteUserModel } from "../../../services/User/User.type";
import clsx from "clsx";
import { ITEMS_PER_PAGE } from "../../../config/constants";
import { GridResponseModel } from "../../../services/Common/Common.type";
import { applyUserSpecificDateFormat, applyUserSpecificDateTimeFormat, isValidDateFormat } from "../../../helper/utility";

const FailedRecordsModal = (props: any) => {
    const initGridResponseModel: GridResponseModel = {
        count: 0,
        currentPage: 0,
        result: [],
        totalPages: 0,
    }


    const { show, onHide, showToast, recordsList } = props;
    const [downloadRecord, setDownloadRecord] = useState<DemoInviteUserModel[]>([initDemoInviteUserModel]);
    const [userList, setUserList] = useState<GridResponseModel>(initGridResponseModel)
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(ITEMS_PER_PAGE);
    const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
        pageIndex: 0,
    });
    const [isLoading, setIsLoading] = useState(false); // Track loading state

    const fetchData = useCallback(async (page: number) => {
        setIsLoading(true);

        // Simulate data fetch
        const updatedRecords = recordsList.map((record: any) => ({
            FirstName: record?.firstName,
            Surname: record?.surname,
            Mobile: record?.mobile,
            DOB: record?.dobString?.toString(),
            Weight: record?.weight,
            Height: record?.height,
            Gender: record?.gender,
            Email: record.email,
        }));

        const totalRecords = updatedRecords.length;
        const startIndex = page * ITEMS_PER_PAGE;
        const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, totalRecords);
        const paginatedRecords = updatedRecords.slice(startIndex, endIndex);

        setUserList(prevState => ({
            result: [...prevState.result, ...paginatedRecords], // Append new records
            count: totalRecords,
            currentPage: page,
            totalPages: Math.ceil(totalRecords / ITEMS_PER_PAGE),
        }));

        setIsLoading(false);
    }, [recordsList]);

    useEffect(() => {

        const totalRecords = recordsList.map((record: any) => ({
            FirstName: record?.firstName,
            Surname: record?.surname,
            Mobile: record?.mobile,
            DOB: record?.dobString.toString(),
            Weight: record?.weight,
            Height: record?.height,
            Gender: record?.gender,
            Email: record.email
        }));
        setDownloadRecord(totalRecords);
        if (recordsList && recordsList.length > 0) {
            fetchData(0); // Initially load the first page of records
        }
    }, [recordsList]);

    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: `first Name`,
                accessor: 'FirstName',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    const gridRecord = props;

                    return (<>
                        <span>
                            {gridRecord?.row?.original?.FirstName == undefined || gridRecord?.row?.original?.FirstName == null || gridRecord?.row?.original?.FirstName == '' ? (<>
                                <i className="bi bi-exclamation-triangle-fill text-danger fs-3" data-tooltip-id="my-tooltip"
                                    data-tooltip-content="FirstName is required"></i>
                            </>) : (gridRecord?.row?.original?.FirstName)}
                        </span >
                    </>)
                }
            },
            {
                Header: `Surname`,
                accessor: 'Surname',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    const gridRecord = props;

                    return (<>
                        <span>
                            {gridRecord?.row?.original?.Surname == undefined || gridRecord?.row?.original?.Surname == null || gridRecord?.row?.original?.Surname == '' ? (<>
                                <i className="bi bi-exclamation-triangle-fill text-danger fs-3" data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Surname is required"></i>
                            </>) : (gridRecord?.row?.original?.Surname)}
                        </span >
                    </>)
                }
            },
            {
                Header: `mobile`,
                accessor: 'Mobile',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    // var mobilePattern = /^(?:\+91[-]?)?\d{10}$/;
                    var mobilePattern = /^(?:\+91[-]?)?0?\d{10}$/;
                    const gridRecord = props;
                    const mobileValue = gridRecord?.row?.original?.Mobile?.toString()?.replace(/ /g, "");

                    // Check if the mobile value is empty
                    const isEmpty = mobileValue === undefined || mobileValue === null || mobileValue === "";

                    // Validate the mobile number format
                    const isValidMobile = mobilePattern.test(mobileValue);
                    const count = gridRecord?.data.filter(entry => entry.Mobile === mobileValue).length;

                    return (
                        <>
                            <span
                                className={clsx(
                                    isEmpty || isValidMobile ? (count > 1 ? 'text-danger' : '') : 'text-danger'
                                )}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={isEmpty ? 'Mobile is required' : (!isValidMobile ? 'Mobile is not valid' : (count > 1 ? 'Mobile is duplicate' : ''))}
                            >
                                {isEmpty ? (
                                    <i className="bi bi-exclamation-triangle-fill text-danger fs-3"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Mobile is required"></i>
                                ) : (
                                    !isValidMobile ? (
                                        mobileValue
                                    ) : (
                                        mobileValue
                                    )
                                )}
                            </span>
                        </>
                    );
                }
            },
            {
                Header: `email`,
                accessor: 'Email',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
                    const gridRecord = props;
                    const emailValue = gridRecord?.row?.original?.Email?.toString()?.replace(/ /g, "");
                    const isValidEmail = emailPattern.test(emailValue);
                    const isEmpty = emailValue === undefined || emailValue === null || emailValue === "";

                    return (<>
                        <span className={clsx(
                            isEmpty || isValidEmail ? '' : 'text-danger'
                        )}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={isEmpty ? 'Email is required' : (!isValidEmail ? 'Email is not valid' : '')}
                        >
                            {isEmpty ? (
                                <i className="bi bi-exclamation-triangle-fill text-danger fs-3"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Email is required"></i>
                            ) : (
                                !isValidEmail ? (
                                    emailValue
                                ) : (
                                    emailValue
                                )
                            )}

                        </span >
                    </>)
                }
            },
            {
                Header: `weight`,
                accessor: 'Weight',
                disableSortBy: true,
                width: 100,
            },
            {
                Header: `Date of birth`,
                accessor: 'DOB',
                disableSortBy: true,
                width: 290,
                Cell: (props: CellProps<any>) => {
                    return (<>
                        <span className={clsx(isValidDateFormat(props?.row?.original?.DOB) ? '' : 'text-danger')}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={isValidDateFormat(props?.row?.original?.DOB) ? ('') : ('Date of Birth is not valid')}
                        >
                            {`${props?.row?.original?.DOB}`}
                        </span>
                    </>)
                }
            }
        ],
        []
    );
    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        const target = e.target as HTMLElement; // Type cast the target to HTMLElement
        const bottom = target.scrollHeight === target.scrollTop + target.clientHeight;
        if (bottom && userList.currentPage < userList.totalPages && !isLoading) {
            fetchData(userList.currentPage + 1); // Load more data when the bottom is reached
        }
    };
    return (<>
        <Modal show={show}
            onHide={() => {
                onHide()
            }}
            backdrop="static" keyboard={false} centered
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="bi bi-exclamation-triangle-fill text-danger pe-3 fs-1 "></i>
                    <span className="h3 pe-3">Data Processing Errors -</span>
                    <span className="h5">
                        Some records failed to upload.
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <ReactTooltip id="my-tooltip" content="" />
                <ReactTooltip id="my-tooltip-html" data-html={true} />
                <div className="row">
                    <div className="col-md-4 border-end pe-4">
                        <div className="pt-5">
                            <p className="mb-3">
                                <strong className="h">Important:</strong>
                                <ul>
                                    <li>
                                        <span className="pe-1">
                                            The column structure must be in this format:
                                        </span>
                                        <span>
                                            <b>#FirstName</b>
                                            , <b>#Surname</b>
                                            , <b>#Mobile</b>
                                            , <b>#DOB</b>
                                            , <b>#Weight</b>
                                            , <b>#Height</b>
                                            , <b>#Gender</b>
                                            , <b>#Email</b>
                                        </span>
                                    </li>
                                    <li>
                                        <p className="text-start">
                                            <span><b>#FirstName</b>
                                                , <b>#Surname</b>
                                                ,<b>#Mobile</b> and <b>#Email</b> are <b>required</b>.</span>
                                        </p>
                                    </li>
                                    <li>
                                        <span className="pe-1">
                                            The Mobile number must :
                                        </span>
                                        <span>
                                            <ul>
                                                <li>
                                                    <span>
                                                        Must be unique
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Accepts either a 10-digit mobile number or a mobile number starting with "+91-" followed by 10 digits, without any spaces or special characters.
                                                    </span>
                                                    <span data-tooltip-id='my-tooltip-html' data-html={true} data-tooltip-html={`<ul class="mb-1 ps-3"><li>Accepted Mobile number
                                                        </li>
                                                        <ul class="ps-4">
                                                        <li>+91-1234567890</li>
                                                        <li>+911234567890</li>
                                                        <li>1234567890</li>
                                                        </ul></ul>`}>
                                                        <i className='bi bi-info-circle-fill'></i>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Exclude spaces, special characters.
                                                    </span>
                                                </li>
                                            </ul>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="pe-1">
                                            The Email address must:
                                        </span>
                                        <span>
                                            <ul>
                                                <li>
                                                    <>
                                                        {
                                                            "Start with alphanumeric characters or these special characters: .!#$%&'*+-/=?^_`{|}~."
                                                        }
                                                    </>
                                                </li>
                                                <li>Contain an `@` symbol followed by a valid domain name.</li>
                                                <li>Have a valid domain extension (e.g., .com, .org, etc.).</li>
                                            </ul>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="pe-1">
                                            The Date of birth must:
                                        </span>
                                        <span>
                                            <ul>
                                                <li>
                                                    Be in the format DD-MM-YYYY.
                                                </li>
                                                <li>Have a valid day (1-31) and month (1-12).</li>
                                                <li>Not contain invalid dates (e.g., February 30th, April 31st, etc.).</li>
                                            </ul>
                                        </span>
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <p>
                                <strong>You can download these records and upload them corrected</strong>
                            </p>
                            <CSVLink
                                data={downloadRecord}
                                filename={`Users_${moment(new Date()).format(
                                    "DD_MM_YYYY_HH_mm_ss"
                                )}`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Download failed records"
                                className='btn btn-primary btn-sm'
                            >
                                <KTIcon iconName='file' className='fs-3' />
                                Download
                            </CSVLink>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="overflow-auto" onScroll={handleScroll} style={{ height: '50vh' }}>
                            <Table
                                columns={columns}
                                data={userList?.result ?? []}
                                initialState={tableInitialState}
                                pageCount={userList?.totalPages}
                                onTableStateChange={handleTableStateChange}
                            >
                                <Fragment>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="d-flex align-items-center flex-end flex-grow-1 px-3">
                                            <div className="border-0 pt-6">
                                                <div className="row">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <KTCardBody className='py-4'>
                                        <div className='table-responsive'>
                                            <TableInner loading={false} entityLabel='Events'></TableInner>
                                        </div>
                                    </KTCardBody>
                                </Fragment>
                            </Table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>)
};
export default FailedRecordsModal;