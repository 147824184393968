export interface NotificationHistoryModel{
    id: number,
    message: string | null,
    subject: string | null,
    notificationType: number | null,
    isSpecificUser: boolean,
    userList: string | null,
    isSuccess: boolean,
    createdDate: any
}

export const GetAllNotificationHistory = '/adminapi/getnotificationhistory'
export const GetSpecificUser = '/adminapi/getspecificuserlist'