import {
    ListsWidget3,
    ListsWidget5,
    MixedWidget10,
    MixedWidget11,
    MixedWidget2,
    TablesWidget11
} from '../../../_metronic/partials/widgets'
import { usePostQuery } from '../../../hooks/reactQueryHelper';
import { useEffect, useState } from 'react';
import { ChartWidgetModel, GetLatestSignUpUser, GetLatestUserActivityHistory, GetMonthWiseAmount, GetMonthwiseEventAndParticipantCount, GetStaticsWidgetDetails, GetUserTransactionWidget, TransactionFilter, UserTransactionCompleteWidgetModel, WidgetModel } from '../../../services/Dashboard/Dashboard.type';
import { UserModel, userActivitySessionModel } from '../../../services/User/User.type';
import Skeleton from '@material-ui/lab/Skeleton';
import NoDataMessage from '../../components/widgets/emptyData';

const DashboardPage = () => {
    const [widgetDetails, setWidgetDetails] = useState<WidgetModel[]>([])
    const [latestUserActivity, setLatestUserActivity] = useState<userActivitySessionModel[]>([])
    const [latestSignupUsers, setLatestSignupUser] = useState<UserModel[]>([])
    const [monthWiseAmount, setMonthWiseAmount] = useState<ChartWidgetModel[]>([])
    const [monthwiseEventAndParticipantCount, setMonthwiseEventAndParticipantCount] = useState<ChartWidgetModel[]>([])
    const [isLoading, setIsLoading] = useState(true);
    const [userTransactionWidget, setUserTransactionWidget] = useState<UserTransactionCompleteWidgetModel[]>([])
    const [selectUserId, setSelectUserId] = useState<any>(0);
    const [selectEventId, setSelectEventId] = useState<any>(0);
    const [selectPaymentDate, setSelectPaymentDate] = useState<any>('');
    const [selectPaymentEndDate, setSelectPaymentEndDate] = useState<any>('');
    const getWidgetcountQuery = usePostQuery('widgetcount', GetStaticsWidgetDetails, {})
    const getLatestUserActivityQuery = usePostQuery('latestuseractivity', GetLatestUserActivityHistory, {})
    const getLatestSignupUserQuery = usePostQuery('latestuserbysignup', GetLatestSignUpUser, {})
    const getMonthWiseAmountQuery = usePostQuery('GetMonthWiseAmount', GetMonthWiseAmount, {})
    const getMonthwiseEventAndParticipantCountQuery = usePostQuery('GetMonthwiseEventAndParticipantCount', GetMonthwiseEventAndParticipantCount, {})
    const getUserTransactionWidget = usePostQuery('GetUserTransactionWidget', GetUserTransactionWidget, {})
    const inputModel: TransactionFilter = {
        userID: selectUserId,
        eventID: selectEventId,
        fromDate: selectPaymentDate,
        endDate: selectPaymentEndDate
    }

    useEffect(() => {
        Promise.all([
            getWidgetcountQuery.mutate({}),
            getLatestUserActivityQuery.mutate({}),
            getLatestSignupUserQuery.mutate({}),
            getMonthWiseAmountQuery.mutate({}),
            getMonthwiseEventAndParticipantCountQuery.mutate({}),

        ])
    }, []);
    useEffect(() => {

        Promise.all([
            getUserTransactionWidget.mutate(inputModel)
        ])
    }, [selectUserId, selectEventId, selectPaymentDate, selectPaymentEndDate]);
    
    useEffect(() => {
        if (getWidgetcountQuery.isSuccess) {
            setWidgetDetails(getWidgetcountQuery.data.result);
            setIsLoading(false);
        }
        if (getLatestUserActivityQuery.isSuccess) {
            setLatestUserActivity(getLatestUserActivityQuery.data.result);
            setIsLoading(false);
        }
        if (getLatestSignupUserQuery.isSuccess) {
            setLatestSignupUser(getLatestSignupUserQuery.data.result);
            setIsLoading(false);
        }
        if (getMonthWiseAmountQuery.isSuccess) {
            setMonthWiseAmount(getMonthWiseAmountQuery.data.result);
            setIsLoading(false);
        }
        if (getMonthwiseEventAndParticipantCountQuery.isSuccess) {
            setMonthwiseEventAndParticipantCount(getMonthwiseEventAndParticipantCountQuery.data.result);
            setIsLoading(false);
        }
        if (getUserTransactionWidget.isSuccess) {
            setUserTransactionWidget(getUserTransactionWidget.data.result);
            setIsLoading(false);
        }
    }, [getWidgetcountQuery.isSuccess, getLatestUserActivityQuery.isSuccess, getLatestSignupUserQuery.isSuccess])

    const handleFilterSubmit = (filters: { userFilter: any; eventFilter: any; paymentdateFilter: any; paymentEndDateFilter: any }) => {
        setSelectUserId(filters.userFilter);
        setSelectEventId(filters.eventFilter);
        setSelectPaymentDate(filters.paymentdateFilter);
        setSelectPaymentEndDate(filters.paymentEndDateFilter);
    };

    return (
        <>
            <div className='row g-5 g-xl-8'>
                <div className='col-xxl-4'>
                    {isLoading? ( <Skeleton variant="rect" animation="wave" width={"100%"} height={"96%"} />):
                        (<MixedWidget2
                            className='card-xl-stretch mb-xl-8 shadow-sm'
                            chartColor='danger'
                            chartHeight='200px'
                            strokeColor='#cb1e46'
                            widgetDetails={widgetDetails}
                        />)
                    }
                </div>
                <div className='col-xxl-4'>
                    {isLoading ? (
                        <Skeleton variant="rect" animation="wave" width={"100%"} height={"96%"} />
                    ) : latestSignupUsers.length > 0 ? (
                        <ListsWidget3 className='card-xxl-stretch mb-xl-3 shadow-sm' latestSignupUsers={latestSignupUsers} />
                    ) : (
                        <NoDataMessage
                            header="New Users"
                            description="No users found."
                        />
                    )}
                </div>
                <div className='col-xxl-4'>
                    {getMonthWiseAmountQuery.isSuccess || getMonthwiseEventAndParticipantCountQuery.isSuccess ?
                        <>
                            {monthWiseAmount.length > 0 && <MixedWidget10
                                className='card-xxl-stretch-50 mb-5 mb-xl-8 shadow-sm'
                                chartColor='primary'
                                chartHeight='150px'
                                monthWiseAmount={monthWiseAmount}
                            />}
                            {monthwiseEventAndParticipantCount.length > 0 && <MixedWidget11
                                className='card-xxl-stretch-50 mb-5 mb-xl-8 shadow-sm'
                                chartColor='primary'
                                chartHeight='175px'
                                monthwiseEventAndParticipantCount={monthwiseEventAndParticipantCount}
                            />}
                        </> :
                        <Skeleton variant="rect" animation="wave" width={"100%"} height={"96%"} />
                    }
                </div>
            </div>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-4'>
                    {isLoading ? (
                        <Skeleton variant="rect" animation="wave" width={"100%"} height={"96%"} />
                    ) : latestUserActivity.length > 0 ? (
                        <ListsWidget5 className='card-xxl-stretch shadow-sm' latestUserActivity={latestUserActivity} />
                    ) : (
                        <NoDataMessage
                            header="User Activities"
                            description="No activities found."
                        />
                    )}
                </div>
                <div className='col-xl-8'>
                    <TablesWidget11
                        className='card-xxl-stretch mb-5 mb-xl-8 shadow-sm'
                        userTransactionWidget={getUserTransactionWidget?.data?.result || []}
                        onFilterSubmit={handleFilterSubmit}
                        getUserTransactionWidget={getUserTransactionWidget}
                    />
                </div>
            </div>
        </>
    )
}

export { DashboardPage }