/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import { KTCard } from "../../../_metronic/helpers";
import { useEffect, useState } from "react";
import clsx from "clsx";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Formik } from 'formik'
import { sendEmailAPI, sendMessageAPI, getAllMobileNumberAPI, sendnotificationAPI, getAllUsermail } from "../../../services/User/MessageModel.type";
import { getAllEventParticipantDropdownOption, getAllEventsEventDropdownOption } from "../../../services/Event/Event.type";
import { usePostQuery } from "../../../hooks/reactQueryHelper";
import * as Yup from 'yup'
import toastNotification from "../../../helper/toastUtils";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown'
import moment from "moment";

const PushNotificationPage = () => {
  const [smsShow, setSMSShow] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  const [emailShow, setEmailShow] = useState(false);
  const [formType, setFormType] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidMail, setIsValidMail] = useState(true);
  const navigate = useNavigate()
  const [isAllParticipant, setIsAllParticipant] = useState(false);
  const [participantList, setParticipantList] = useState<any>([]);
  const [eventList, setEventList] = useState<any>([])

  const messageSchema = Yup.object().shape({

    maintenanceStart: Yup.date()
      .required('Please select event start date')
      .test(
        'maintenanceStart',
        'Start date should not be the same as or after event end date',
        function (maintenanceStart) {
          const endDate = this.parent.maintenanceEnd
          return !endDate || maintenanceStart < endDate
        }
      )
    ,
    maintenanceEnd: Yup.date()
      .required('Please select event end date')
      .test(
        'maintenanceEnd',
        'End date should not be the same as or before event start date',
        function (maintenanceEnd) {
          const maintenanceStart = this.parent.maintenanceStart
          return !maintenanceStart || maintenanceEnd > maintenanceStart
        }
      ),
  });

  const emailSchema = Yup.object().shape({
    subject: Yup.string().trim().required('Please enter subject'),
    message: Yup.string().trim().required('Please enter email description'),
    isCustomSend: Yup.boolean(),
    emaillist: Yup.string().when('isCustomSend', (isCustomSend, schema) => {
      if (isCustomSend.includes(true) && email.length <= 0) {
        return schema.required("Please enter email address")
      }
      return schema
    })
  });
  const notificationSchema = Yup.object().shape({
    message: Yup.string().trim().required('Please enter message'),
    isPushNotificationSend: Yup.boolean(),
    isAllParticipant: Yup.boolean(),
    eventID: Yup.string()
      .test("not-default", "Please select a valid event.", function (value) {
        const { isPushNotificationSend } = this.parent;
        var evalue = (value === undefined);
        var pushvalue = (isPushNotificationSend === true);

        if (pushvalue == true && evalue == true) {
          return false;
        }
        else {
          return true;
        }
      }),
    userNameList: Yup.string().when('isAllParticipant', (isAllParticipant, schema) => {
      if (isAllParticipant.includes(true) && userName.length <= 0)
        return schema.required("Please enter UserName")
      return schema
    })
  });

  const validMailBody = (text) => {
    const re = /^(?!^(?:<p><br><\/p>|<p>\s*<\/p>)+$)<p>.*<\/p>$/gm;

    const isValid = re.test(text);
    return isValid;
  }

  const handleShowModal = (type) => {
    setFormType(type);  // Set the form type (e.g., "sms" or "email")
    if (type === "email") {
      setEmailShow(true);
    }
    else if (type === "sms") {
      setSMSShow(true);
    }
    else if (type === "notification") {
      setNotificationShow(true);
    }
  };
  const getAllMobileNumberQuery: any = usePostQuery(
    'getAllMobileNumberAPI',
    getAllMobileNumberAPI
  );

  const getAllEMailQuery: any = usePostQuery(
    'getAllUsermail',
    getAllUsermail
  );

  // const getAllFCMTokenUserNameQuery: any = usePostQuery(
  //   'getAllFCMtokenUserName',
  //   getAllFCMtokenUserName
  // );

  const getAllEventsEventDropdownOptionQuery: any = usePostQuery(
    'getAllEventsEventDropdownOption'
    , getAllEventsEventDropdownOption
  )

  const getAllEventParticipantDropdownOptionQuery: any = usePostQuery(
    'getAllEventParticipantDropdownOption'
    , getAllEventParticipantDropdownOption
  )
  const [email, setEmail] = useState([])
  var handleChangemail = (e: any) => {
    setEmail(e.map((item: any) => (item.value)))
  }

  const [mobile, setMobile] = useState([])
  var handleChangeMobileNumber = (e: any) => {
    setMobile(e.map((item: any) => (item.value)))
  }
  const [userName, setUserName] = useState([]);
  const [isdisabled, setDisabled] = useState(false);
  var handleChangeUserName = (e: any) => {
    if (!e) {
      setUserName([]);
    } else {
      setUserName(e.map((item: any) => (item.value)))
    }
    setDisabled((e.map((item: any) => (item.value)) || false) && (isAllParticipant))

  }


  const handleRemove = (valueToRemove: any) => {

    setUserName(valueToRemove);
    setDisabled((valueToRemove?.length == 0 ? false : true) && (isAllParticipant));
  };



  const handleClose = () => {
    setSMSShow(false);
    setEmailShow(false);
    setNotificationShow(false);
    setFormType("");
    setParticipantList([]);
    setUserName([]);
    setDisabled(false)
  };
  const sendMessageQuery: any = usePostQuery(
    'sendMessageAPI',
    sendMessageAPI,
    {}
  );

  const sendEmailQuery: any = usePostQuery(
    'sendEmailAPI',
    sendEmailAPI,
    {}
  );

  const sendnotificationQuery: any = usePostQuery(
    'sendnotificationAPI',
    sendnotificationAPI,
    {}
  );
  const handleChangeCustomerSMSSend = (e) => {

    if (formType === "sms") {
      getAllMobileNumberQuery.mutate({

        onSuccess: (data) => {
          // The mutation was successful, perform any action you want here
          toastNotification(
            'success',
            sendMessageQuery?.success?.response?.data?.responseException?.exceptionMessage ??
            'message sent successfully.'
          )
        },
        onError: (error) => {
          toastNotification(
            'error',
            sendMessageQuery?.error?.response?.data?.responseException?.exceptionMessage ??
            'Something went wrong.'
          )
        },
        onSettled: () => {
          setLoading(false);
          // setSubmitting(false); // Reset Formik submitting state to false
          handleClose(); // Close the modal after sending the message
        },
      });


    }
  }

  const checkHandlePushNotificationSend = (e) => {
    //setisPushNotificationSend(!isPushNotificationSend)
    if (formType === "notification") {
      // getAllFCMTokenUserNameQuery.mutate({
      //   onSuccess: (data) => {

      //     // The mutation was successful, perform any action you want here
      //     toastNotification(
      //       'success',
      //       getAllFCMTokenUserNameQuery?.success?.response?.data?.responseException?.exceptionMessage ??
      //       'message sent successfully.'
      //     )
      //   },
      //   onError: (error) => {
      //     toastNotification(
      //       'error',
      //       getAllFCMTokenUserNameQuery?.error?.response?.data?.responseException?.exceptionMessage ??
      //       'Something went wrong.'
      //     )
      //   },
      //   onSettled: () => {
      //     setLoading(false);
      //     // setSubmitting(false); // Reset Formik submitting state to false
      //     handleClose(); // Close the modal after sending the message
      //   },
      // });

      getAllEventsEventDropdownOptionQuery.mutate();
    }
  }

  useEffect(() => {
    if (
      getAllEventsEventDropdownOptionQuery?.isSuccess
    ) {
      setEventList(getAllEventsEventDropdownOptionQuery?.data?.result);
    }
  }, [getAllEventsEventDropdownOptionQuery?.isSuccess])

  const fetchUserList = (id: any) => {
    setUserName([]);
    if (id === "") {
      id = 0;
    }
    id = parseInt(id);
    getAllEventParticipantDropdownOptionQuery.mutate({ id: id });
  };

  useEffect(() => {
    if (
      getAllEventParticipantDropdownOptionQuery?.isSuccess
    ) {
      setParticipantList(getAllEventParticipantDropdownOptionQuery?.data?.result);
    }
  }, [getAllEventParticipantDropdownOptionQuery?.isSuccess])

  const checkHandleMailSend = (e) => {

    // setisCustomSend(!isCustomSend)
    if (formType === "email") {
      getAllEMailQuery.mutate({
        onSuccess: (data) => {

          // The mutation was successful, perform any action you want here
        },
        onError: (error) => {
          toastNotification(
            'error',
            sendMessageQuery?.error?.response?.data?.responseException?.exceptionMessage ??
            'Something went wrong.'
          )
        },
        onSettled: () => {
          setLoading(false);
          // setSubmitting(false); // Reset Formik submitting state to false
          handleClose(); // Close the modal after sending the message
        },
      });
    }
  }

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true); // Set Formik submitting state to true
    setLoading(true);

    const formData = {
      IsCustomSMSSend: values.isCustomSMSSend,
      MobileList: mobile,
      message: values.message,
      maintenanceStart: values.maintenanceStart,
      maintenanceEnd: values.maintenanceEnd
    };

    const formDataEmail = {
      IsCustomSend: values.isCustomSend,
      EmailList: email,
      EmailBody: values.message,
      EmailSubject: values.subject
    };
    const formDatanotification = {
      IsPushNotificationSend: values.isPushNotificationSend,
      userNameList: userName,
      message: values.message,
      isAllParticipant: values.isAllParticipant,
      eventID: values.eventID == "" ? 0 : values.eventID
    };
    if (formType === "sms") {

      sendMessageQuery.mutateAsync(formData, {
        onSuccess: (data) => {
          // The mutation was successful, perform any action you want here
          toastNotification(
            'success',
            sendMessageQuery?.success?.response?.data?.responseException?.exceptionMessage ??
            'message sent to all Users'
          )
          navigate(`/notifications`);
        },
        onError: (error) => {
          toastNotification(
            'error',
            sendMessageQuery?.error?.response?.data?.responseException?.exceptionMessage ??
            'Something went wrong.'
          )
        },
        onSettled: () => {
          setLoading(false);
          setSubmitting(false); // Reset Formik submitting state to false
          handleClose(); // Close the modal after sending the message
        },
      });
    }
    else if (formType === "email") {

      if (validMailBody(formDataEmail.EmailBody)) {
        setIsValidMail(true)
        sendEmailQuery.mutateAsync(formDataEmail, {
          onSuccess: (data) => {
            // The mutation was successful, perform any action you want here
            toastNotification(
              'success',
              sendEmailQuery?.success?.response?.data?.responseException?.exceptionMessage ??
              'message sent successfully.'
            )
            navigate(`/notifications`);
          },
          onError: (error) => {
            toastNotification(
              'error',
              sendEmailQuery?.error?.response?.data?.responseException?.exceptionMessage ??
              'Something went wrong.'
            )
          },
          onSettled: () => {
            setLoading(false);
            setSubmitting(false); // Reset Formik submitting state to false
            handleClose(); // Close the modal after sending the message
          },
        });
      }
      else {
        // toastNotification(
        //   'error',
        //   'Mail content cannot be empty'
        // );
        setIsValidMail(false)
        setLoading(false);
        setSubmitting(false);
      }
    }
    else if (formType === "notification") {
      sendnotificationQuery.mutate(formDatanotification, {
        onSuccess: (data) => {
          // The mutation was successful, perform any action you want here
          toastNotification(
            'success',
            sendnotificationQuery?.success?.response?.data?.responseException?.exceptionMessage ??
            'push notification sent successfully.'
          )
          navigate(`/notifications`);
        },
        onError: (error) => {
          toastNotification(
            'error',
            sendnotificationQuery?.error?.response?.data?.responseException?.exceptionMessage ??
            'Something went wrong.'
          )
        },
        onSettled: () => {
          setLoading(false);
          setSubmitting(false); // Reset Formik submitting state to false
          handleClose(); // Close the modal after sending the message
        },
      });
    }
  };


  return (
    <>
      <KTCard>
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div id="kt_app_content_container" className="app-container container-xxl">
                <div className="card">
                  <div className="card-body">
                    <div className="card-px text-center pt-15 pb-15">
                      <h2 className="fs-2x fw-bold mb-0">Push Notification Option</h2>
                      <p className="text-gray-400 fs-4 fw-semibold py-7">Click on the below buttons to Send
                        <br />a Message to Users.</p>
                      <a href="javascript:void(0)" className="btn btn-primary er fs-6 px-8 py-4 m-1" onClick={() => handleShowModal('sms')}>SMS Notification</a>
                      <a href="javascript:void(0)" className="btn btn-primary er fs-6 px-8 py-4 m-1" onClick={() => handleShowModal('email')}>Email Notification</a>
                      <a href="javascript:void(0)" className="btn btn-primary er fs-6 px-8 py-4 m-1" onClick={() => handleShowModal('notification')}>Push Notification</a>
                    </div>
                    <div className="text-center px-5">
                      <img src="media/logos/push_notification.png" alt="" className="mw-100 h-200px h-sm-325px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </KTCard>

      <Modal show={smsShow} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>{formType == 'sms' ? "Notify Maintenance" : "Message"}</h2>
          </Modal.Title>
        </Modal.Header>
        <Formik initialValues={{ message: '', isCustomSMSSend: false, maintenanceStart: moment(new Date()).format('YYYY-MM-DDTHH:mm'), maintenanceEnd: moment(new Date()).add(24, 'hours').add(1, 'minute').format('YYYY-MM-DDTHH:mm'), enableReinitialize: true }} validationSchema={messageSchema} onSubmit={onSubmit}  >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="rounded">
                  <div className="modal-body scroll-y py-0">
                    <div className="col-12 pt-1">
                    </div>
                    <Row>

                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <Form.Group className='fv-row' controlId='maintenanceStart'>
                          <Form.Label className='required form-label'>Maintenance Start from</Form.Label>
                          <Form.Control
                            type='datetime-local'
                            name='maintenanceStart'
                            placeholder='maintenanceStart'
                            onChange={handleChange}
                            onBlur={(e) => {
                              setFieldValue('maintenanceStart', e.target.value)
                            }}
                            min={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
                            value={values.maintenanceStart}
                            className={clsx(
                              'form-control bg-transparent',
                              { 'is-invalid': touched.maintenanceStart && errors.maintenanceStart },
                              {
                                'is-valid': touched.maintenanceStart && !errors.maintenanceStart,
                              }
                            )}
                          />
                          {errors.maintenanceStart && touched.maintenanceStart ? (
                            <div className='text-danger'>{errors.maintenanceStart}</div>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <Form.Group className='fv-row' controlId='maintenanceEnd'>
                          <Form.Label className='required form-label'>Event End Date</Form.Label>
                          <Form.Control
                            type='datetime-local'
                            name='maintenanceEnd'
                            placeholder='maintenanceEnd'
                            onChange={handleChange}
                            onBlur={(e) => {
                              setFieldValue('maintenanceEnd', e.target.value)
                            }}
                            min={values.maintenanceStart}
                            value={values.maintenanceEnd}
                            className={clsx(
                              'form-control bg-transparent',
                              { 'is-invalid': touched.maintenanceEnd && errors.maintenanceEnd },
                              {
                                'is-valid': touched.maintenanceEnd && !errors.maintenanceEnd,
                              }
                            )}
                          />
                          {errors.maintenanceEnd && touched.maintenanceEnd ? (
                            <div className='text-danger'>{errors.maintenanceEnd}</div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Row>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" className="btn btn-primary" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Sending...
                    </>
                  ) : (
                    <span><span className="px-2" style={{ fontSize: '15px' }}>Send</span><span><Icon icon="ic:outline-message" style={{ fontSize: '22px' }} /></span></span>
                  )}
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>

      <Modal show={emailShow} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Email</h2>
          </Modal.Title>
        </Modal.Header>
        <Formik initialValues={{ message: '', subject: '', emaillist: '', isCustomSend: false }} validationSchema={emailSchema} onSubmit={onSubmit}>
          {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, setFieldValue, errors, touched }) => {
            if ((touched.message && errors.message))
              setIsValidMail(false)
            else
              setIsValidMail(true)
            return (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="rounded">
                    <div className="modal-body scroll-y px-4 px-lg-4 pt-0 pb-7">
                      <div className="row">
                        <div className="col-12 pt-1">
                          <Form.Group className='mb-1 fv-row form-check-inline'>
                            <Form.Label className='form-label' htmlFor='chkIsCustomSend'>Want to send email to specific user?</Form.Label>
                            <Form.Control
                              className='form-check-input mx-3  mt-0 d-inline-block'
                              id='chkIsCustomSend'
                              name='isCustomSend'
                              type='checkbox'
                              checked={values.isCustomSend}
                              style={{ borderRadius: '33%' }}
                              onChange={(e) => {

                                checkHandleMailSend(e);
                                setFieldValue('isCustomSend', !values.isCustomSend);
                              }}
                            />
                          </Form.Group>
                          {values.isCustomSend &&
                            <Form.Group className='mb-1' >
                              <Form.Label className='required form-label'>User's Email</Form.Label>
                              <Multiselect
                                avoidHighlightFirstOption
                                onRemove={(event) => { }}
                                onSelect={handleChangemail}
                                displayValue="label"
                                options={getAllEMailQuery?.data?.result.map(
                                  (option: any, index: any) => {
                                    return {
                                      value: option.value,
                                      label: `${option.label}`,
                                    };
                                  }
                                )}

                                className={clsx('basic-multi-select border rounded-1', {
                                  'border-danger': touched.emaillist && errors.emaillist,
                                  '': touched.emaillist,
                                })}
                                showCheckbox
                              />
                              {values.isCustomSend && email.length <= 0 && touched.emaillist && errors.emaillist && (
                                <div className="text-danger">{errors.emaillist}</div>
                              )}
                            </Form.Group>
                          }

                        </div>
                        <div className="col-12 mb-2">
                          <Form.Group>
                            <Form.Label className="required form-label">Subject</Form.Label>
                            <Form.Control
                              type="text"
                              name="subject"
                              value={values.subject}
                              placeholder="Subject"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={clsx('form-control', {
                                'is-invalid': touched.subject && errors.subject,
                              })}
                            >
                            </Form.Control>
                            {touched.subject && errors.subject && (
                              <div className="text-danger">{errors.subject}</div>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-12 mb-2">
                          <Form.Group className='mb-10 fv-row'>
                            <Form.Label className='required form-label'>Email</Form.Label>
                            <div className="react-quill-wrapper" style={{ height: "300px" }}>
                              <ReactQuill
                                theme="snow"
                                style={{ height: "256px" }}
                                className={(isValidMail) ? '' : "is-invalid"}
                                value={values.message.trim()}
                                placeholder="Description"
                                onChange={(html) => {
                                  setFieldValue("message", html)
                                }}
                                onBlur={() => setFieldValue('message', values.message.trim())}
                              />
                            </div>
                            {touched.message && errors.message && (
                              <div className="text-danger">{errors.message}</div>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" className="btn btn-primary" variant="primary" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <>
                        <Spinner animation="border" size="sm" className="me-2" />
                        Sending...
                      </>
                    ) : (
                      <span><span className="px-2" style={{ fontSize: '15px' }}>Send</span><span><Icon icon="ic:outline-mail" style={{ fontSize: '22px' }} /></span></span>
                    )}
                  </Button>
                </Modal.Footer>
              </form>
            )
          }}
        </Formik>
      </Modal>

      <Modal show={notificationShow} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Push Notification </h2>
          </Modal.Title>
        </Modal.Header>
        <Formik initialValues={{ message: '', isPushNotificationSend: false, userNameList: '', eventID: '', isAllParticipant: false }} validationSchema={notificationSchema} onSubmit={onSubmit}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="rounded">
                  <div className="modal-body scroll-y py-0">
                    <div className="col-12 pt-1">
                      <Form.Group className='mb-1 fv-row'>
                        <Form.Label className='form-label' htmlFor='chkIsPushNotificationSend'>Notify selected users?</Form.Label>
                        <Form.Control
                          className='form-check-input mx-3  mt-0 d-inline-block'
                          id='chkIsPushNotificationSend'
                          name='isPushNotificationSend'
                          type='checkbox'
                          checked={values.isPushNotificationSend}
                          disabled={values.isAllParticipant}
                          onChange={(e) => {
                            checkHandlePushNotificationSend(e);
                            setFieldValue('isPushNotificationSend', !values.isPushNotificationSend);

                            if (values.isPushNotificationSend) {
                              setUserName([]);
                              setFieldValue('isAllParticipant', false);
                            }
                          }} />
                      </Form.Group>
                      {!values.isPushNotificationSend ? "" :
                        <>
                          <div className="row">
                            <Form.Group className='mb-1 col-md-6'  >
                              <Form.Label className='required form-label'>Event</Form.Label>
                              <select
                                className='form-select mb-2 '
                                data-control='select2'
                                data-hide-search='true'
                                data-placeholder='Select an option'
                                id='kt_ecommerce_add_event_status_select'
                                name='eventId'
                                value={values.eventID}
                                disabled={
                                  isdisabled
                                }
                                onChange={(e) => {
                                  if (e.target.value != null || e.target.value != "" || e.target.value != undefined) {
                                    fetchUserList(e.target.value);
                                    setFieldValue('eventID', e.target.value)
                                  }

                                }
                                }
                              >
                                <option key='' value=''>
                                  Select Event
                                </option>
                                {eventList?.map((x) => (
                                  <option key={x.value} value={x.value} title={x.label}>
                                    {x.label.length > 20 ? x.label.substring(0, 20) + '...' : x.label}
                                  </option>
                                ))}
                              </select>
                              {touched.eventID && errors.eventID && (
                                <div className="text-danger">{errors.eventID}</div>
                              )}
                            </Form.Group>
                            <Form.Group className='mb-1 col-md-6'>
                              <Form.Label className='form-label' htmlFor='chkIsAllParticipant'>Notify selected participants?</Form.Label>
                              <Form.Control
                                className='form-check-input mx-3  mt-0 d-inline-block d-flex items-align-bottom'
                                id='chkIsAllParticipant'
                                name='isAllParticipant'
                                type='checkbox'
                                checked={values.isAllParticipant}
                                onChange={(e) => {
                                  setFieldValue('isAllParticipant', !values.isAllParticipant);
                                  setIsAllParticipant(!values.isAllParticipant);
                                  setDisabled(!values.isAllParticipant)
                                }} />
                            </Form.Group>
                          </div>
                          {values.isAllParticipant && (<>
                            <Form.Group className='mb-1'>
                              <Form.Label className='required form-label'>User's Mobile Number</Form.Label>
                              <Multiselect
                                avoidHighlightFirstOption
                                onRemove={(event) => {
                                  handleRemove(event)
                                }}
                                onSelect={handleChangeUserName}
                                displayValue="label"
                                options={participantList.map(
                                  (option: any, index: any) => {
                                    return {

                                      value: option.value,
                                      label: `${option.label}`,
                                    };
                                  }
                                )}


                                className={clsx('basic-multi-select border rounded-1', {
                                  'border-danger': touched.userNameList && errors.userNameList,
                                  '': touched.userNameList,
                                })}
                                showCheckbox
                              />
                              {/* <Multiselect
                            avoidHighlightFirstOption
                            //name="userName"
                            onSelect={handleChangeUserName}
                         

                            options={getAllFCMTokenUserNameQuery?.data?.result.map(
                              (option: any, index: any) => {
                                return {
                                  value: option.value,
                                  label: `${option.label}  (${option.value})`,
                                };
                              }
                            )}
                            className={clsx('basic-multi-select border rounded-1', {
                              'border-danger': touched.userNameList && errors.userNameList,
                              '': touched.userNameList,
                            })}
                            showCheckbox
                            // classNamePrefix="select"
                          /> */}
                              {values.isPushNotificationSend && userName.length <= 0 && touched.userNameList && errors.userNameList && (
                                <div className="text-danger">{errors.userNameList}</div>
                              )}
                            </Form.Group>
                          </>)}
                        </>

                      }
                    </div>

                    <div>
                      <Form.Group className='mb-5 fv-row'>
                        <Form.Label className='required form-label'>Message</Form.Label>
                        <Form.Control
                          as='textarea'
                          type='textarea'
                          name='message'
                          rows={5}
                          className={clsx('form-control', {
                            'is-invalid': touched.message && errors.message,
                          })}
                          placeholder='Type Your message here...'
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></Form.Control>
                        {touched.message && errors.message && (
                          <div className="text-danger">{errors.message}</div>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" className="btn btn-primary" variant="primary" disabled={isSubmitting}>
                  {loading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Sending...
                    </>
                  ) : (
                    <span><span className="px-2" style={{ fontSize: '15px' }}>Send</span><span><Icon icon="ic:outline-message" style={{ fontSize: '22px' }} /></span></span>
                  )}
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal >


    </>
  );
}

export { PushNotificationPage }