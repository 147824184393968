import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import ViewUserPage from "./ViewUserPage";

const ViewUserPageWrapper = (props : any) => {
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Users',
            path: '/users',
            isSeparator: true,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: true,
        }
    ];
    return(
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>User Detail</PageTitle>
            <ViewUserPage />
        </>
    )
}

export default ViewUserPageWrapper;