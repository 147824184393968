import { cx } from "../../../../utils/classnames";

const EventRulePagination = (Props: any) => {
    const { eventRuleReult, gridRequestModel, setGridRequestModel, getEventRules } = Props

    const scrollToTop = () => {
        window.scrollTo({
            top: 200,
            left: 0,
            behavior: "smooth"
        });
    };

    const onClickPagination = (index: number) => {
        scrollToTop()
        gridRequestModel.page = index
        setGridRequestModel(gridRequestModel)
        getEventRules.mutate(gridRequestModel)
    }

    const renderList = () => {
        const paginationItems: any = [];
        for (let index = 1; index <= eventRuleReult?.totalPages; index++) {
            paginationItems.push(
                <li className={`page-item ${index === eventRuleReult.currentPage ? 'active' : ''}`} key={index}>
                    <button onClick={() => {
                        onClickPagination(index)
                    }} className="page-link">{index}</button>
                </li>
            );
        }
        return paginationItems;
    };

    return (
        <ul className="pagination">
            <li className={cx('page-item previous', {
                disabled: eventRuleReult.currentPage === 1,
            })} onClick={() => {
                if (eventRuleReult.currentPage - 1 > 0)
                    onClickPagination(eventRuleReult.currentPage - 1)
            }}>
                <button className="page-link">
                    <i className="previous"></i>
                </button>
            </li>


            {renderList()}
            <li className={cx('page-item next', { disabled: eventRuleReult.totalPages === eventRuleReult.currentPage })}>
                <button className="page-link" onClick={() => {
                    onClickPagination(eventRuleReult.currentPage + 1)
                }}>
                    <i className="next"></i>
                </button>
            </li>
        </ul>

    )
}

export default EventRulePagination