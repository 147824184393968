import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import HomeSliderPage from "./HomeSliderPage";

const HomeSliderWrapper: React.FC = (props : any) => {
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator : true,
            isActive: false,
        }
    ]
    return (
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Home Slider</PageTitle>
            <HomeSliderPage />
        </>
    );
}
export default HomeSliderWrapper;