import { useEffect, useState } from "react";
import { usePostQuery } from "../../../../hooks/reactQueryHelper";
import { EventRuleModel, GetEventRules } from "../../../../services/Event/Event.type";
import { GridRequestModel, GridResponseModel } from "../../../../services/Common/Common.type";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import EventRulePagination from "./EventRulePagination";
import { Skeleton } from "@material-ui/lab";
import SetInnerHTML from "../../../components/SetInnerHTML/SetInnerHTML";

const EventRulesPage = (Props: any) => {
    const { eventDetail } = Props;
    const navigate = useNavigate();

    const getEventRules: any = usePostQuery("GetEventParticipent", GetEventRules, {});
    const [eventRules, setEventRules] = useState<EventRuleModel[]>([]);

    const initGridRequestModel: GridRequestModel = {
        page: 1,
        limit: 8,
        search: null,
        sort: null,
        id: null,
        eventId: 0,
        eventTypeID: 0,
        paymentDate: null,
        isInvited: false,
        isJoined: false
    }

    const [gridRequestModel, setGridRequestModel] = useState<GridRequestModel>(initGridRequestModel);
    const [eventRuleReult, setEventRuleResult] = useState<GridResponseModel>();

    useEffect(() => {
        if (eventDetail != null && eventDetail?.id && eventDetail?.id > 0) {
            gridRequestModel.eventId = eventDetail?.id;
            setGridRequestModel(gridRequestModel)
            getEventRules.mutate(gridRequestModel);
        }
    }, [eventDetail?.id, gridRequestModel?.page])

    useEffect(() => {
        if (getEventRules.isSuccess) {
            setEventRules(getEventRules.data.result.result)
            setEventRuleResult(getEventRules.data.result)
        }
    }, [getEventRules.isSuccess, getEventRules.isLoading, eventRuleReult])

    useEffect(() => {
    }, [eventRules])

    useEffect(() => {
    }, [eventRuleReult])

    return (
        <>
            <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                <div className="card-body">
                    <div className="d-flex flex-column flex-xl-row">
                        <div className="flex-lg-row-fluid me-xl-15 mb-20 mb-xl-0">
                            <div className="mb-0">
                                <div className="mb-0">
                                    <div className="d-flex justify-content-between">
                                        <div className="text-dark mb-10"><h1 className="">Rules</h1></div>
                                        <div className="mb-10">
                                            <button title='edit'
                                                onClick={() => {
                                                    const additionalData = {
                                                        currentPage: 2, // Additional data you want to pass
                                                    };
                                                    navigate('/events/manage/' + eventDetail?.id, { state: additionalData });
                                                }}
                                                className="btn btn-icon btn-bg-primary btn-color-white btn-active-color-white btn-sm me-1 ">
                                                <span className="svg-icon svg-icon-3">
                                                    <Icon icon="ic:baseline-edit" />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-10">
                                    {getEventRules.isLoading && eventRules.length === 0 ?
                                        (<>
                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <div className="d-flex mb-10 svg-icon svg-icon-3x">
                                                    <Skeleton className="svg-icon svg-icon-3x ms-n1 svg-icon-success" variant="circle" width={50} height={50} />
                                                    <div className="d-flex flex-column">
                                                        <Skeleton className="text-muted fw-semibold fs-6 ms-5" variant="rect" width={1000} height={75} />
                                                    </div>
                                                </div>
                                            ))}
                                        </>)
                                        : eventRules.length === 0 ? (
                                            <div className="">
                                                <div className="text-center">
                                                    <span className="text-muted fw-semibold fs-6" > No Rules exists</span>
                                                </div>
                                            </div>
                                        ) : eventRules.map((rule, index) => {
                                            return (
                                                <div className="d-flex mb-10">
                                                    <span className="svg-icon svg-icon-3x me-5 ms-n1 mt-2 svg-icon-success">
                                                        <Icon icon="fluent:list-16-filled" hFlip={true} vFlip={true} />
                                                    </span>
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex align-items-center mb-2">
                                                        </div>
                                                        <SetInnerHTML inneHtml={rule.rule} className="text-muted fw-semibold fs-6" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                                {eventRuleReult?.currentPage && eventRuleReult?.totalPages > 1 && <EventRulePagination eventRuleReult={eventRuleReult} gridRequestModel={gridRequestModel} setGridRequestModel={setGridRequestModel} getEventRules={getEventRules} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventRulesPage