import React from 'react';

const SetInnerHTML: React.FC<{ inneHtml: string, className? : string }> = ({ inneHtml,className }) => {

    return (
        <span className={className} dangerouslySetInnerHTML={{ __html: inneHtml }} />
    )
};

export default SetInnerHTML;
