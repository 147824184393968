import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useGetQuery } from '../../../../hooks/reactQueryHelper';
import { GetEvent, EventModel } from "../../../../services/Event/Event.type";
import EventOverviewPage from "./EventOverviewPage";
import EventRulesPage from "./EventRulesPage";
import UserParticipatedPage from "./UserParticipatedPage";
import EventWinnerDetailPage from "./EventWinnerDetailPage";
import { useLocation } from "react-router-dom";
import { EVENT_CYCLING_IMAGE_PATH, EVENT_RUNNING_IMAGE_PATH, EVENT_WALKING_IMAGE_PATH, EVENT_DEFAULT_IMAGE_PATH, EVENT_IMAGE_UPLOAD_PATH } from "../../../../shared/constants";
import { BASE_ENDPOINT } from '../../../../shared/apiConfig';
import { currencyPrefix } from "../../../../helper/utility";
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from "clsx";
import SetInnerHTML from "../../../components/SetInnerHTML/SetInnerHTML";
import EventImagePage from "./EventImagePage";
const API_URL = BASE_ENDPOINT;

const ViewEventPage = (props: any) => {

    const getEventQuery: any = useGetQuery(
        'getEvent',
        GetEvent
    );

    const location = useLocation();
    const additionalData: any = location.state;

    const [eventDetail, setEventDetail] = useState<EventModel>();
    const [tabType, setTabType] = useState<any>(additionalData?.tabName ?? 'Overview');
    const [eventImageUrl, setEventImageUrl] = useState<any>(EVENT_DEFAULT_IMAGE_PATH);


    useEffect(() => {
        if (getEventQuery.isSuccess) {
            const getEvent: EventModel = getEventQuery.data.result;
            setEventDetail(getEvent);
            if (getEvent != null && getEvent.image != null && getEvent.image !== undefined && getEvent.image !== '') {
                var eventImageUrl = API_URL + EVENT_IMAGE_UPLOAD_PATH + getEvent.image;
                setEventImageUrl(eventImageUrl);
            } else {
                setEventImageUrl(EVENT_DEFAULT_IMAGE_PATH);
            }
        }
    }, [getEventQuery.isSuccess])

    useEffect(() => {
        const pathname = window.location.pathname.split("/");
        const id = Number(pathname[pathname.length - 1])
        if (id) {
            getEventQuery.mutate(id)
        }
    }, [tabType])

    return (
        <>
            <div id="kt_app_content_container" className="app-container">
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-9 pb-0">
                        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                            <div className="me-7 mb-4">
                                {eventDetail ? (
                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                        {
                                            eventDetail?.image !== null ? (
                                                <img src={eventImageUrl} alt={props?.row?.original?.name} onError={(ele) => {
                                                    ele.currentTarget.src = toAbsoluteUrl(EVENT_CYCLING_IMAGE_PATH)
                                                }} />
                                            ) : (
                                                <>
                                                    {
                                                        eventDetail?.eventTypeID === 1 ? (
                                                            <img src={toAbsoluteUrl(EVENT_CYCLING_IMAGE_PATH)} alt={props?.row?.original?.name} />
                                                        ) : eventDetail?.eventTypeID === 2 ? (
                                                            <img src={toAbsoluteUrl(EVENT_WALKING_IMAGE_PATH)} alt={props?.row?.original?.name} />
                                                        ) : (
                                                            <img src={toAbsoluteUrl(EVENT_RUNNING_IMAGE_PATH)} alt={props?.row?.original?.name} />
                                                        )}

                                                    {eventDetail?.isActive === true ? <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div> : ""}
                                                </>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <Skeleton variant="rect" animation="wave" width={150} height={150} />
                                )}
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-2">
                                            <span className="text-gray-900 fs-2 fw-bold me-1">{eventDetail ? eventDetail?.name : <Skeleton width={200} />}</span>
                                        </div>
                                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                            <div className="d-flex align-items-center text-gray-400 mb-2">
                                                {eventDetail && eventDetail.isPublish && <span style={{ marginLeft: '5px' }} className="badge badge-success"> Published</span>}
                                            </div>
                                            <div className="d-flex align-items-center text-gray-400 mb-2">
                                                {eventDetail && eventDetail.isChallenge ?
                                                    <span style={{ marginLeft: '5px' }} className="badge badge-info">Challenge</span>
                                                    :
                                                    (
                                                        <> {eventDetail?.isPrivate === true ? (
                                                            <span style={{ marginLeft: '5px' }} className='badge badge-danger'>Private Event</span>)
                                                            : (
                                                                <span style={{ marginLeft: '5px' }} className='badge badge-warning'>Public Event</span>)}
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap flex-stack">
                                    <div className="d-flex flex-column flex-grow-1 pe-8">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">
                                                    {eventDetail ? (
                                                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">
                                                            {eventDetail?.maxParticipants ? eventDetail?.maxParticipants : '-'}
                                                        </div>
                                                    ) : (
                                                        <Skeleton width={60} />
                                                    )}
                                                </div>

                                                <div className="fw-semibold fs-6 text-gray-400">Max Participants</div>

                                            </div>

                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">

                                                    {eventDetail ? (
                                                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="75">
                                                            {eventDetail?.eventParticipentCount}
                                                        </div>
                                                    ) : (
                                                        <Skeleton width={60} />
                                                    )}
                                                </div>

                                                <div className="fw-semibold fs-6 text-gray-400">Total Participated</div>

                                            </div>
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">
                                                    {eventDetail ? (
                                                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">
                                                            {eventDetail?.joiningFees > 0 ? <SetInnerHTML inneHtml={currencyPrefix() + ' ' + `${eventDetail?.joiningFees}`} /> : 'Free'}
                                                        </div>
                                                    ) : (
                                                        <Skeleton width={60} />
                                                    )}
                                                </div>
                                                <div className="fw-semibold fs-6 text-gray-400">Joining Fee</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'Overview' ? 'active' : '')} data-bs-toggle="tab" onClick={() => setTabType('Overview')}>Overview</button>
                            </li>
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'Images' ? 'active' : '')} data-bs-toggle="tab" onClick={() => setTabType('Images')}>Images</button>
                            </li>
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'Rules' ? 'active' : '')} data-bs-toggle="tab" onClick={() => setTabType('Rules')}>Rules</button>
                            </li>
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'UserParticipated' ? 'active' : '')} data-bs-toggle="tab" onClick={() => setTabType('UserParticipated')}>  Participated Users  </button>
                            </li>
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'EventWinnerInformation' ? 'active' : '')} data-bs-toggle="tab" onClick={() => setTabType('EventWinnerInformation')}>  Winner Information  </button>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="tab-content">
                    {tabType === 'Overview' && <EventOverviewPage eventDetail={eventDetail} getEventQuery={getEventQuery} />}
                    {tabType === 'Images' && <EventImagePage eventDetail={eventDetail} />}
                    {tabType === 'Rules' && <EventRulesPage eventDetail={eventDetail} />}
                    {tabType === 'UserParticipated' && <UserParticipatedPage eventDetail={eventDetail} />}
                    {tabType === 'EventWinnerInformation' && <EventWinnerDetailPage eventDetail={eventDetail} />}
                </div>
            </div >
        </>
    )
}

export default ViewEventPage;