
export interface MessageModel {
  messagecontent: string
}

export const sendMessageAPI = '/adminapi/sendsms';
export const sendEmailAPI = '/adminapi/sendemail';
export const getAllMobileNumberAPI = '/adminapi/getallusermobilenumber';
export const getAllUsermail = '/adminapi/getallusermail';
export const sendnotificationAPI = '/adminapi/sendpushNotification';
export const getAllFCMtokenUserName = '/adminapi/getfcmtokenUserName';