/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import {
  HeaderUserMenu,
  ThemeModeSwitcher,
} from '../../../partials'
import { useAuth } from '../../../../app/modules/auth'
import { BASE_ENDPOINT } from '../../../../shared/apiConfig';
import { USER_DEFAULT_IMAGE_PATH, USER_IMAGE_UPLOAD_PATH } from '../../../../shared/constants'
const API_URL = BASE_ENDPOINT;

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1'

const Topbar: FC = () => {
  const { auth } = useAuth();
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
        </div>
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src={auth?.result?.image ? API_URL + USER_IMAGE_UPLOAD_PATH + auth?.result?.image : toAbsoluteUrl(USER_DEFAULT_IMAGE_PATH)}
              alt='user'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export { Topbar }
