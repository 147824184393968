import { Fragment, useEffect, useMemo, useState } from "react"
import { KTCardBody } from "../../../_metronic/helpers"
import Table from "../../components/Table"
import { TableGlobalSearch } from "../../components/Table/TableGlobalSearch"
import { TableLoader } from "../../components/Table/TableLoader"
import { TablePagination } from "../../components/Table/TablePagination"
import TableInner from "../../components/Table/TableInner"
import { usePostQuery } from "../../../hooks/reactQueryHelper"
import { GridRequestModel, GridResponseModel, SortModel } from "../../../services/Common/Common.type"
import { GetAllNotificationHistory } from "../../../services/Notification/Notification.type"
import { useNavigate } from "react-router-dom"
import useTableState from "../../components/Table/useTableState"
import { CellProps, Column } from "react-table"
import { Icon } from "@iconify/react"
import clsx from "clsx"
import moment from "moment"
import { applyUserSpecificDateTimeFormat } from "../../../helper/utility"
import { Tooltip } from "react-tooltip"
import { Button, Modal } from "react-bootstrap"
import { UserTable } from "./UserTable"
import SetInnerHTML from "../../components/SetInnerHTML/SetInnerHTML"

const NotificationTable = (props: any) => {
    const getNotificationHistory: any = usePostQuery("getNotificationHistory", GetAllNotificationHistory, {})
    const navigate = useNavigate()
    const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
        pageIndex: 0,
    })
    const initGridResponseModel: GridResponseModel = {
        count: 0,
        currentPage: 0,
        result: [],
        totalPages: 0,
    }

    const [notificationList, setNotificationList] = useState<GridResponseModel>(initGridResponseModel)
    const { sort, page, limit, search } = tableState

    const [smsShow, setSMSShow] = useState(false);
    const [notificationShow, setNotificationShow] = useState(false);
    const [emailShow, setEmailShow] = useState(false);
    const [userShow, setUserShow] = useState(false);
    const [message, setMessage] = useState()
    const [subject, setSubject] = useState()
    const [notificationId, setNotificationId] = useState<any>()

    const initSortModel: SortModel = {
        sort: 'desc',
        fields: 'createdDate'
    }

    const initGridRequestModel: GridRequestModel = {
        page: page ?? 0,
        limit: limit ?? 0,
        search: search ? search : '',
        sort: sort ? sort : initSortModel,
        id: 0,
        eventId: 0,
        eventTypeID: 0,
        paymentDate: null,
        isInvited: false,
        isJoined: false
    }

    useEffect(() => {
        initGridRequestModel.page = page ?? 0
        initGridRequestModel.limit = limit ?? 0
        if (sort) initGridRequestModel.sort = sort
        if (search) initGridRequestModel.search = search
        getNotificationHistory.mutate(initGridRequestModel)
    }, [page, limit, sort, search])

    useEffect(() => {
        if (getNotificationHistory.data)
            setNotificationList(getNotificationHistory?.data?.result)
    }, [getNotificationHistory.data])

    const actionButtonHandler = (row: any, type: string) => {
        if (type === 'Add') {
            navigate(`/notifications/manage`)
        }
    }

    const handleShowModal = (data) => {
        if (data.notificationType === 2) {
            setMessage(data.message)
            setSubject(data.subject)
            setEmailShow(true);
        }
        else if (data.notificationType === 1) {
            setMessage(data.message)
            setSMSShow(true);
        }
        else {
            setMessage(data.message)
            setNotificationShow(true);
        }
    };

    const handleShow = (data: any) => {
        setNotificationId(data.id)
        setUserShow(true)
    }
    const handleClose = () => {
        setSMSShow(false);
        setEmailShow(false);
        setNotificationShow(false);
        setUserShow(false);
    };

    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: `Notification Type`,
                accessor: 'notificationType',
                Cell: (props: CellProps<any>) => {
                    const Type = props?.row?.original?.notificationType
                    const icon = Type == 1 ? <Icon icon="fa-solid:sms" /> : (Type == 2 ? <Icon icon="line-md:email" /> : <Icon icon="mi:notification" />)
                    return <span data-tooltip-id="my-tooltip" data-tooltip-content={Type === 1 ? 'SMS' : (Type == 2 ? 'Email' : 'Notification')} className="display-6 ms-5">{icon}</span>
                },
            },
            {
                Header: `Send to`,
                accessor: 'isSpecificUser',
                Cell: (props: CellProps<any>) => {
                    const SpecificUser = props?.row?.original?.isSpecificUser ? 'Specific' : 'All'
                    return (
                        <div className="text-start">
                            <span className='badge badge-light-success cursor-pointer' data-tooltip-id="my-tooltip" data-tooltip-content="View Users" onClick={() => handleShow(props?.row?.original)}>
                                {SpecificUser}   {SpecificUser != 'All' ? <Icon icon="heroicons:user-16-solid" style={{ height: '24px', width: '28px' }}></Icon> :
                                    <Icon style={{ height: '30px', width: '40px' }} icon="clarity:users-solid" />}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: `Status`,
                accessor: 'isSuccess',
                disableSortBy: true,
                tooltip: 'isSuccess',
                Cell: (props: CellProps<any>) => {
                    const className = props?.row?.original?.isSuccess ? 'success' : 'danger'
                    return (
                        <>
                            <span
                                data-tooltip-id="my-tooltip" data-tooltip-content={props?.row?.original?.isSuccess === true ? 'Success' : 'Failed'}
                                className={clsx(
                                    'badge badge-circle fs-1',
                                    `badge-light-${className}`,
                                    `fw-bolder`
                                )}
                            >
                                {props?.row?.original?.isSuccess ? (
                                    <Icon icon='simple-line-icons:check' />
                                ) : (
                                    <Icon icon='radix-icons:cross-circled' />
                                )}
                            </span>
                        </>
                    )
                },
            },
            {
                Header: `Send Date`,
                accessor: 'createdDate',
                Cell: (props: CellProps<any>) => {
                    return (
                        <span>
                            {' '}
                            {props?.row?.original?.createdDate ? moment(props?.row?.original?.createdDate).format(applyUserSpecificDateTimeFormat()) : '-'}
                        </span>
                    )
                },
            },
            {
                Header: `Message`,
                accessor: 'message',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    return (
                        <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column'>
                                <a href="javascript:void(0)" className="btn btn-sm btn-primary" data-tooltip-id="my-tooltip" data-tooltip-content="View Message" onClick={() => handleShowModal(props?.row?.original)}>View</a>
                            </div>
                        </div>
                    )
                },
            },
        ],
        []
    )

    return (
        <>
            <Tooltip id="my-tooltip" content="" />
            <Table
                columns={columns}
                data={notificationList?.result ?? []}
                initialState={tableInitialState}
                pageCount={notificationList?.totalPages}
                onTableStateChange={handleTableStateChange}
            >
                <Fragment>
                    <TableGlobalSearch
                        actionButtonHandler={actionButtonHandler}
                        serachtext={'Search'}
                        addBtnText={'Notification Option'}
                        isAddbtnVisible={true}
                    />
                    <KTCardBody className='py-4'>
                        <div className='table-responsive'>
                            <TableInner loading={false} entityLabel='Notification History'></TableInner>
                        </div>
                        <TablePagination pageOptions={[5, 10, 20, 50]} />
                        <TableLoader isLoading={getNotificationHistory?.isLoading} />
                    </KTCardBody>
                </Fragment>
            </Table>

            <Modal show={smsShow} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Message</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="rounded">
                        <div className="modal-body scroll-y py-0">
                            <div className="row" >
                                <div className="col-md-12 mb-2">
                                    <div className="row">
                                        <label className="col-md-12 form-label">Message :</label>
                                        <div className="border col-md-12 py-3">
                                            <SetInnerHTML inneHtml={message ?? '-'} className='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={emailShow} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Mail</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="rounded">
                        <div className="modal-body scroll-y px-4 px-lg-4 pt-0 pb-7">
                            <div className="row" >
                                <div className="col-md-12 mb-2">
                                    <div className="row">
                                        <label className="col-md-12 form-label">Subject :</label>
                                        <div className="border col-md-12 py-3">{subject}</div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <div className="row">
                                        <label className="col-md-12 form-label">Mail :</label>
                                        <div className="border col-md-12 py-3">
                                            <SetInnerHTML inneHtml={message ?? '-'} className='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={notificationShow} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Push Notification </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="rounded">
                        <div className="modal-body scroll-y py-0">
                            <div className="row" >
                                <div className="col-md-12 mb-2">
                                    <div className="row">
                                        <label className="col-md-12 form-label">Notification Content :</label>
                                        <div className="border col-md-12 py-3">
                                            <SetInnerHTML inneHtml={message ?? '-'} className='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={userShow} onHide={handleClose} size="xl" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>User List</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '400px', width: '100%', overflowY: 'scroll' }}>
                    <UserTable id={notificationId} />
                </Modal.Body>
                <Modal.Footer>
                    <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export { NotificationTable }