import { Fragment, useEffect, useMemo, useState } from "react";
import { usePostQuery } from "../../../hooks/reactQueryHelper"
import { deleteApplicationVersion, getAllApplicationVersion } from "../../../services/ApplicationVersion/ApplicationVersion.type"
import { GridRequestModel, GridResponseModel, SortModel } from "../../../services/Common/Common.type";
import useTableState from "../../components/Table/useTableState";
import showConfirmationDialog from "../../../helper/showConfirmationDialogUtility";
import toastNotification from "../../../helper/toastUtils";
import { CellProps, Column } from "react-table";
import { Icon } from "@iconify/react";
import Table from "../../components/Table";
import { TableGlobalSearch } from "../../components/Table/TableGlobalSearch";
import { KTCardBody } from "../../../_metronic/helpers";
import TableInner from "../../components/Table/TableInner";
import { TablePagination } from "../../components/Table/TablePagination";
import { TableLoader } from "../../components/Table/TableLoader";
import { Tooltip } from "react-tooltip";
import { AddEditOption } from "../../../services/HomeSlider/HomeSlider.type";
import AddEditApplicationVersionWrapper from "./AddEditApplicationVersionWrapper";
import moment from "moment";
import { applyUserSpecificDateTimeFormat } from "../../../helper/utility";

const ApplicationVersionTable = () => {

    const initGridResponseModel: GridResponseModel = {
        count: 0,
        currentPage: 0,
        result: [],
        totalPages: 0,
    }

    const getAllApplicationVersionQuery = usePostQuery("getAllApplicationVersion", getAllApplicationVersion);
    const deleteApplicationVersionQuery = usePostQuery("deleteApplicationVersion", deleteApplicationVersion);
    const [dataList, setDataList] = useState<GridResponseModel>(initGridResponseModel)
    const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
        pageIndex: 0,
    })
    const [addEditOption, setAddEditOption] = useState<AddEditOption>({
        isOpen: false,
        id: 0,
        isEdit: false
    });
    const { sort, page, limit, search } = tableState

    const initSortModel: SortModel = {
        sort: 'desc',
        fields: 'id',
    }

    const initGridRequestModel: GridRequestModel = {
        page: page ?? 0,
        limit: limit ?? 0,
        search: search ? search : '',
        sort: sort ? sort : initSortModel,
        id: 0,
        eventId: 0,
        eventTypeID: 0,
        paymentDate: null,
        isInvited: false,
        isJoined: false
    }

    const fetchList = () => {
        getAllApplicationVersionQuery.mutate(initGridRequestModel)
    };

    useEffect(() => {
        initGridRequestModel.page = page ?? 0
        initGridRequestModel.limit = limit ?? 0
        if (sort) initGridRequestModel.sort = sort
        if (search) initGridRequestModel.search = search

        fetchList();
    }, [page, limit, sort, search])

    const openConfirmationDialog = async (row) => {
        const result = await showConfirmationDialog(
            'Confirmation',
            'Are you sure you want to delete application?',
            'delete'
        )
        if (result) deleteApplicationVersionQuery.mutate({ id: row.id })
    }

    useEffect(() => {
        if (getAllApplicationVersionQuery?.isSuccess) {
            setDataList(getAllApplicationVersionQuery?.data?.result)
        }
    }, [getAllApplicationVersionQuery?.isSuccess]);

    // useEffect(() => {
    //     if (!addEditOption.isOpen)
    //         getAllApplicationVersionQuery.mutate(initGridRequestModel)
    // }, [initGridRequestModel, addEditOption, deleteApplicationVersionQuery.isSuccess])

    useEffect(() => {
        if (deleteApplicationVersionQuery?.isError) {
            toastNotification(
                'error',
                deleteApplicationVersionQuery?.error?.response?.data?.responseException?.exceptionMessage
            )
        } else if (deleteApplicationVersionQuery?.data?.statusCode === 200) {
            toastNotification('success', 'Application version deleted sucessfully')
            fetchList();
        }
    }, [deleteApplicationVersionQuery.isError, deleteApplicationVersionQuery.isSuccess]);

    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: `version`,
                accessor: 'version',
                disableSortBy: true,
            },
            {
                Header: `platform`,
                accessor: 'platform',
                disableSortBy: true,
            },
            {
                Header: `isForcefullyUpdate`,
                accessor: 'isForcefullyUpdate',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    const result = props.row.original
                    return (
                        <>
                            {props?.row?.original?.isForcefullyUpdate === true && (
                                <span className='badge badge-light-success'>Yes</span>
                            )}
                            {props?.row?.original?.isForcefullyUpdate === false && (
                                <span className='badge badge-light-success'>No</span>
                            )}
                        </>
                    )
                }
            },
            {
                Header: `createdDate`,
                accessor: 'createdDate',
                disableSortBy: false,
                Cell: (props: CellProps<any>) => {
                    return (
                        <span>
                            {props?.row?.original?.createdDate
                                ? moment(props?.row?.original?.createdDate).format(applyUserSpecificDateTimeFormat())
                                : '-'}
                        </span>)
                },
            },
            {
                Header: <span className='d-block text-center rounded-end'>Actions</span>,
                accessor: 'id',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    const result = props.row.original
                    return (
                        <div className='d-flex justify-content-center flex-shrink-0'>
                            <button
                                data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                onClick={() => {
                                    actionButtonHandler(result, 'Edit')
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                            >
                                <span className='svg-icon svg-icon-3'>
                                    <Icon icon='ic:baseline-edit' />
                                </span>
                            </button>
                            <button
                                data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                onClick={async () => openConfirmationDialog(result)}
                            >
                                <span className='svg-icon svg-icon-3'>
                                    <Icon icon='ic:baseline-delete' />
                                </span>
                            </button>
                        </div>
                    )
                },
            },
        ]
        , []
    )

    const actionButtonHandler = (row: any, type: string) => {
        if (type === 'Add') {
            setAddEditOption({
                id: 0,
                isEdit: false,
                isOpen: true
            })
        } else if (type === 'Edit') {
            setAddEditOption({
                id: row?.id,
                isEdit: true,
                isOpen: true
            })
        }
    }

    const OnHide = () => {
        setAddEditOption({
            id: 0,
            isEdit: false,
            isOpen: false
        })
    }

    return (<>
        <Tooltip id='tooltip' content="" />
        <Table
            columns={columns}
            data={dataList?.result ?? []}
            initialState={tableInitialState}
            pageCount={dataList?.totalPages ?? []}
            onTableStateChange={handleTableStateChange}
        >
            <Fragment>
                <TableGlobalSearch
                    serachtext={'Search Application Version'}
                    addBtnText={'Create Application Version'}
                    actionButtonHandler={actionButtonHandler}
                    isAddbtnVisible={true}
                />
                <KTCardBody className='py-4'>
                    <div className='table-responsive'>
                        <TableInner loading={false} entityLabel='Application Version'></TableInner>
                    </div>
                    <TablePagination />
                    <TableLoader isLoading={deleteApplicationVersionQuery.isLoading || deleteApplicationVersionQuery.isLoading} />
                </KTCardBody>
            </Fragment>
        </Table>
        {addEditOption.isOpen && <AddEditApplicationVersionWrapper addEditOption={addEditOption} fetchListQuery={fetchList} OnHide={OnHide} />}
    </>)
}

export default ApplicationVersionTable