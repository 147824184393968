const EventImagePage = (props : any) =>{
    const { eventDetail } = props
    return (
        <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-body p-9'>
                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <label className='form-label form-label'>
                            Event web banner image
                            </label>
                            <div className='row mt-2'>
                            <div className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'>
                                {eventDetail.webBannerImage && (
                                <img
                                    alt='not found'
                                    src={eventDetail.webBannerImage}
                                    className='img-fluid image-input-wrapper w-350px h-200px'
                                />
                                )}
                            </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <label className='form-label form-label'>
                            Event mobile banner image
                            </label>
                            <div className='row mt-2'>
                            <div className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'>
                                {eventDetail.mobileBannerImage && (
                                <img
                                    alt='not found'
                                    width={'300px'}
                                    src={eventDetail.mobileBannerImage}
                                    className='img-fluid image-input-wrapper w-350px h-200px'
                                />
                                )}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventImagePage