import { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import clsx from "clsx";
import { usePostQuery } from "../../../hooks/reactQueryHelper";
import { DemoInviteUserModel, UploadUsers, UserModel } from "../../../services/User/User.type";
import { ButtonComponent, UploadFileComponent } from "../../components/buttonComponent/UploadFileComponent";
import { Modal, Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { debug } from "console";
import toastNotification from "../../../helper/toastUtils";
import FailedRecordsModal from "./FailedRecordsModal";

interface Invitation {
  firstName: string;
  surname: string;
  mobile: string;
  dobString: string;
  dob: Date | null;
  weight: number | null;
  height: number | null;
  gender: string | null;
  email: string;
}
const initialInvitation: Invitation[] = [
  {
    firstName: "Ayush",
    surname: "Ayush",
    mobile: "",
    dobString: "",
    dob: null,
    weight: null,
    height: null,
    gender: null,
    email: "",
  },
];
const UploadUserModal = (props: any) => {
  const [filename, setFilename] = useState("");
  const [userFile, setUserFile]: any = useState<File | null>(null);
  const [userDisabled, setUserDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [failedInvitationList, SetFailedInvitationList] = useState<Invitation[]>([])
  const [invalidRecordModalShow, SetInvalidRecordModalShow] = useState(false);
  const { show, onHide, fetchUserList, showToast, eventID } = props;

  const uploadUsersQuery = usePostQuery("uploadUsers", UploadUsers, {
    headers: { "Content-Type": "multipart/form-data" }
  });

  const handleFileUpload = (e: any) => {
    var allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
    const selectedFile = e.target.files[0];
    if (!allowedExtensions.exec(e.target.value)) {
      showToast(
        "warning",
        "The file format is not supported. Please upload a valid file."
      );
      e.target.value = "";
      setUserDisabled(false);
      return false;
    }
    const url = URL.createObjectURL(e.target.files[0]);
    setUserFile(selectedFile);
    setUserDisabled(true);
    setFilename(url);
    e.target.value = "";
  };
  const DataSubmit = async (file) => {
    if (userFile) {
      const formData = new FormData();
      formData.append("FormFile", userFile);
      formData.append("eventID", eventID);
      uploadUsersQuery.mutate(formData);
    } else {
      setIsError(true);
      setUserDisabled(true);
    }
  };
  const RemoveButton = () => {
    setIsError(false);
    setUserDisabled(false);
    setUserFile(null);
    fetchUserList();
    setFilename("");
  };

  useEffect(() => {
    if (uploadUsersQuery.isSuccess) {
      const receivedInvitation = uploadUsersQuery?.data?.result?.receivedInvitation;

      if (receivedInvitation?.length > 0) {
        SetFailedInvitationList(receivedInvitation);
        SetInvalidRecordModalShow(true);
      } else {
        onHide();
      }
      fetchUserList();
      setIsError(false);
      setUserDisabled(false);
      setUserFile(null);
      setFilename("");
      showToast("success", uploadUsersQuery.data.result.message);
    } else if (uploadUsersQuery.isError) {
      showToast(
        "error",
        uploadUsersQuery?.error?.response?.data?.responseException?.exceptionMessage
      );
    }
  }, [uploadUsersQuery.isSuccess, uploadUsersQuery.isError]);


  const userdata = [
    {
      FirstName: 'admin',
      Surname: 'admin',
      Mobile: '1234987654',
      DOB: '19-03-1999',
      Weight: '56',
      Height: '151',
      Gender: 'M',
      Email: 'admin@fitevent.com',
    }
  ]

  return (
    <>
      <Modal show={show}
        onHide={() => {
          onHide()
        }}
        backdrop="static" keyboard={false} centered

      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Upload Users</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <ReactTooltip id="my-tooltip" content="" />
          <ReactTooltip id="my-tooltip-html" data-html={true} />
          <div className="text-primary-60 font-normal py-2 mt-2 text-sm me-3">
            <p className="mb-1">
              <span className="mb-3">
                <i className="bi bi-exclamation-triangle-fill text-warning pe-3 fs-4"></i><strong className="h5">Important:</strong>
              </span>
              <ul>
                <li>
                  <p className="mb-1">
                    For bulk entry, upload a  <strong>.csv</strong> file.
                  </p>
                </li>
                <li>
                  <span className="pe-1">
                    The column
                    structure must be in this format:
                  </span>
                  <span>
                    <b>#FirstName</b>
                    , <b>#Surname</b>
                    , <b>#Mobile</b>
                    , <b>#DOB</b>
                    , <b>#Weight</b>
                    , <b>#Height</b>
                    , <b>#Gender</b>
                    , <b>#Email</b>
                  </span>
                  <br />
                </li>
                <li>
                  <p className="text-start mb-1">
                    <span><b>#FirstName</b>
                      , <b>#Surname</b>
                      ,<b>#Mobile</b> and <b>#Email</b> are <b>required</b>.</span>
                  </p>
                </li>
                <li>
                  <span className="pe-1">
                    The Mobile number must :
                  </span>
                  <span>
                    <ul>
                      <li>
                        <span>
                          Must be unique
                        </span>
                      </li>
                      <li>
                        <span>
                          Accepts either a 10-digit mobile number or a mobile number starting with "+91-" followed by 10 digits, without any spaces or special characters.
                        </span>
                        <span data-tooltip-id='my-tooltip-html' data-html={true} data-tooltip-html={`<ul class="mb-1 ps-3"><li>Accepted Mobile number
                                                        </li>
                                                        <ul class="ps-4">
                                                        <li>+91-1234567890</li>
                                                        <li>+911234567890</li>
                                                        <li>1234567890</li>
                                                        </ul></ul>`}>
                          <i className='bi bi-info-circle-fill'></i>
                        </span>
                      </li>
                      <li>
                        <span>
                          Exclude spaces, special characters.
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
                <li>
                  <span className="pe-1">
                    The Email address must:
                  </span>
                  <span>
                    <ul>
                      <li>
                        <>
                          {
                            "Start with alphanumeric characters or these special characters: .!#$%&'*+-/=?^_`{|}~."
                          }
                        </>
                      </li>
                      <li>Contain an `@` symbol followed by a valid domain name.</li>
                      <li>Have a valid domain extension (e.g., .com, .org, etc.).</li>
                    </ul>
                  </span>
                </li>
                <li>
                  <span className="pe-1">
                    The Date of birth must:
                  </span>
                  <span>
                    <ul>
                      <li>
                        Be in the format DD-MM-YYYY.
                      </li>
                      <li>Have a valid day (1-31) and month (1-12).</li>
                      <li>Not contain invalid dates (e.g., February 30th, April 31st, etc.).</li>
                    </ul>
                  </span>
                </li>
              </ul>
            </p>
            <p className="mb-1 text-justify">Don’t have the CSV (Comma delimited)?
              <span className="ps-2">
                <CSVLink
                  data={userdata}
                  filename={`Users_${moment(new Date()).format(
                    "DD_MM_YYYY_HH_mm_ss"
                  )}`}
                  className=""
                >
                  <span className="cursor-pointer text-decoration-underline ps-2 text-dark fw-bold">
                    Download CSV template
                  </span>
                </CSVLink>
              </span>
            </p>
          </div>

          <div className="p-2 p-md-3 pt-md-2">
            <div className="row">
              <div className="col-md-12">
                <div>

                  <UploadFileComponent
                    name={filename ? "Replace file" : "Attach file"}
                    isIcon={true}
                    onChange={(e: any) => {
                      handleFileUpload(e);
                    }}
                    accept=".csv"
                    className={clsx(
                      "",
                      isError
                        ? "border-danger bg-danger text-danger"
                        : "border-primary bg-light text-secondary hover:bg-primary active:outline-none active:ring active:ring-blue-900"
                    )}
                  />
                </div>
                <div className="text-sm text-black my-3">{userFile?.name}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 d-flex align-items-start">
                <ButtonComponent
                  name="Upload"
                  istrue={true}
                  className={"btn btn-sm btn-primary text-white"}
                  onClick={DataSubmit}
                  loading={uploadUsersQuery.isLoading}
                  isDisabled={!userDisabled}
                />
              </div>

              {userFile != null && (<>
                <div className="col-md-6 d-flex align-items-start">
                  <ButtonComponent
                    name="Delete"
                    istrue={true}
                    className={"btn btn-sm btn-danger text-white"}
                    onClick={RemoveButton}
                    loading={false}
                    isDisabled={!userDisabled}
                  />
                </div>
              </>)}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {
        invalidRecordModalShow &&
        <FailedRecordsModal
          show={invalidRecordModalShow}
          onHide={() => SetInvalidRecordModalShow(false)}
          recordsList={failedInvitationList}
          showToast={toastNotification}
        // recordsList={failedInvitationList}
        />
      }
    </>

  );
};

export default UploadUserModal;