/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { GetUserTransactionWidget, TransactionFilter, UserTransactionCompleteWidgetModel } from '../../../../services/Dashboard/Dashboard.type';
import { TableGlobalSearch } from '../../../../app/components/Table/TableGlobalSearch';
import { addcommasRupeesAmount, applyUserSpecificDateTimeFormat } from '../../../../helper/utility';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Dropdown1 } from '../../content/dropdown/Dropdown1';
import { usePostQuery } from '../../../../hooks/reactQueryHelper';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';

type Props = {
  className: string
  userTransactionWidget: UserTransactionCompleteWidgetModel[],
  onFilterSubmit: (filters: { userFilter: any; eventFilter: any; paymentdateFilter: any; paymentEndDateFilter: any }) => void;
  getUserTransactionWidget: any
}

const TablesWidget11: React.FC<Props> = ({ className, userTransactionWidget, onFilterSubmit, getUserTransactionWidget }) => {
  const navigate = useNavigate();

  const OnClickhandler = (id: number, redirectTo: string) => {
    const additionalData = {
      tabName: '', // Additional data you want to pass
    }
    if (redirectTo === 'Event') {
      additionalData.tabName = 'UserParticipated'
      navigate(`/events/detail/${id}`,{state: additionalData})
    }
    if (redirectTo === 'User') {
      additionalData.tabName = 'Transaction'
      navigate(`/users/detail/` + id,{state: additionalData})
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>

        <h3 className='card-title fw-bold text-dark'>
          <span className='card-label fw-bold fs-3 mb-1'>User Transaction</span>
        </h3>

        <div className='card-toolbar'>
          <button
            data-tooltip-id="my-tooltip" data-tooltip-content="Transaction"
            title='transaction'
            onClick={() => {
              navigate('/transaction')
            }}
            className='btn btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <span className='svg-icon svg-icon-4'>
              <Icon icon='carbon:view-filled' />
            </span>
            <span className='ps-2'>
              All transaction
            </span>
          </button>
          <div className='dropdown'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='top-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown1 onFilterSubmit={onFilterSubmit} />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>User</th>
                <th className='min-w-140px'>Event</th>
                <th className='min-w-120px'>Transaction ID</th>
                <th className='min-w-100px '>Payment Date</th>
                <th className='min-w-100px '>Amount</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            {getUserTransactionWidget.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={5} className="text-center">
                    <div className="py-5">
                      <Skeleton animation="wave" variant="rect" width="100%" height={40} />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : userTransactionWidget.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={5} className="text-center">
                    <div className="fw-bold text-center h6">No transactions yet.</div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {
                  userTransactionWidget.map((x, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='javascript:void(0)' onClick={(e) => OnClickhandler(x.userID, 'User')} className='text-dark fw-bold text-hover-primary fs-6'>
                                  {x.firstName} {x.surName}
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href='javascript:void(0)' onClick={(e) => OnClickhandler(x.eventID, 'Event')} className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {x.eventName}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x.eventTypeName}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x.transactionID}
                            </span>
                          </td>
                          <td className='text-end'>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-muted me-2 fs-7 fw-semibold'>{moment(x.paymentDate).format(applyUserSpecificDateTimeFormat())}</span>
                              </div>
                            </div>
                          </td>
                          <td className='text-end'>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-muted me-2 fs-7 fw-semibold'>{addcommasRupeesAmount(x.joiningFees)}</span>
                              </div>
                            </div>
                          </td>
                        </tr >
                      </>
                    )
                  })
                }
              </tbody>
            )}
            {/* end::Table body */}
          </table>
          {getUserTransactionWidget.isLoading && (
            <div className='text-center'>
              <Skeleton animation="wave" variant="rect" width="100%" height={40} />
            </div>
          )}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget11 }
