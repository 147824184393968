import React, { useEffect, useRef, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import * as Yup from 'yup'
import { Form as BForm, Button, Spinner } from 'react-bootstrap'
import clsx from 'clsx'
import { Formik, Form } from 'formik'
import { usePostQuery, useGetQuery } from '../../../hooks/reactQueryHelper'
import {
  AddEditUserModel,
  EditUserProfile,
  GetUser,
  UserModel,
} from '../../../services/User/User.type'
import { useNavigate } from 'react-router'
import { DropdownModel } from '../../../services/Common/Common.type'
import { useAuth } from '../../modules/auth'
import { BASE_ENDPOINT } from '../../../shared/apiConfig'
import { USER_DARK_IMAGE_PATH, USER_DEFAULT_IMAGE_PATH, USER_IMAGE_UPLOAD_PATH } from '../../../shared/constants'
import toastNotification from '../../../helper/toastUtils'
import ImageCrop from '../../components/ImageCrop'
import { Icon } from '@iconify/react'
import { themeModeSwitchHelper, useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { FocusError } from '../../../helper/utility'
const API_URL = BASE_ENDPOINT

const validationSchema = Yup.object({
  firstName: Yup.string().required('Please enter first name'),
  surname: Yup.string().required('Please enter last name'),
  mobile: Yup.string()
    .required('Please enter mobile')
    .min(10, 'Mobile must be at least 10 characters')
    .matches(/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/, 'Please enter valid mobile number'),
  email: Yup.string().nullable().email('Please enter vaild email'),
})

const UserProfilePage: React.FC<any> = (props: any) => {
  const { auth, saveAuth } = useAuth()
  const [id, setUserId] = useState<any>()
  const [isSubmitVisible, SetIsSubmitVisible] = useState<any>(false)
  const [isHovering, setIsHovering] = useState(false);
  const [areaPixels, setareaPixels] = useState<any>(null)
  const fileInputprofileRef = useRef<any>(null)
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const { getLoading, upsertLoading } = props
  const navigate = useNavigate()
  const editUserProfileQuery: any = usePostQuery('editUser', EditUserProfile, {})
  const getUserQuery: any = useGetQuery('getUser', GetUser)

  const { mode } = useThemeMode()

  const statusDropDown: DropdownModel[] = [
    {
      value: 1,
      label: 'Active',
    },
    {
      value: 0,
      label: 'Inactive',
    },
  ]

  const initAddEditUserModel: AddEditUserModel = {
    id: 0,
    firstName: '',
    surname: '',
    password: '',
    dob: new Date('2000-01-01'),
    weight: null,
    height: null,
    mobile: '',
    address: '',
    landmark: '',
    pincode: '',
    city: '',
    image: '',
    state: '',
    email: '',
    isActive: true,
    createdDate: null,
    modifiedDate: null,
    modifiedBy: 0,
    createdBy: 0,
    selectedStatus: statusDropDown[0],
    gender: null,
    fileBase64Data: null,
    participentEventCount: 0,
    createTimeDuration: null,
    isVerified: true,
    platForm: '',
    isInvited: false,
    deletedDate: null
  }
  const [addEditUserModel, setAddEditUserModel] = useState<AddEditUserModel>(initAddEditUserModel)
  const [selectImage, setSelectedImage] = useState<any>()
  const [selectFileBase64, setSelectFileBase64] = useState<any>(null)
  const [visible, setVisible] = useState<any>(false)
  const [imagesrc, setImageSrc] = useState<any>()
  const [isImageNotFound, setIsImageNotFound] = useState<any>(false)

  useEffect(() => {
    themeModeSwitchHelper(mode)
    if (
      selectImage !== USER_DEFAULT_IMAGE_PATH &&
      selectImage !== USER_DARK_IMAGE_PATH
    ) {
      setSelectedImage(selectImage)
    } else {
      setSelectedImage(mode === 'light' ? USER_DEFAULT_IMAGE_PATH : USER_DARK_IMAGE_PATH)
    }
  }, [mode])

  useEffect(() => {
    if (auth != null) {
      setUserId(auth.result.id)
    }
  }, [])

  useEffect(() => {
    if (id) {
      getUserQuery.mutate(id)
    }
  }, [id])

  useEffect(() => {
    if (getUserQuery.isSuccess) {
      const getUser: UserModel = getUserQuery.data.result
      addEditUserModel.email = getUser.email
      addEditUserModel.firstName = getUser.firstName
      addEditUserModel.id = getUser.id
      addEditUserModel.isActive = getUser.isActive
      addEditUserModel.mobile = getUser.mobile
      addEditUserModel.surname = getUser.surname
      addEditUserModel.image = getUser.image
      if (
        addEditUserModel.image != null &&
        addEditUserModel.image !== undefined &&
        addEditUserModel.image !== ''
      ) {
        var selectImage = addEditUserModel.image
        setSelectedImage(selectImage)
      } else {
        setSelectedImage(mode === 'light' ? USER_DEFAULT_IMAGE_PATH : USER_DARK_IMAGE_PATH)
      }
      setAddEditUserModel({ ...addEditUserModel })
    }
  }, [getUserQuery?.isSuccess])

  const HandleButtonAction = (row: any, type: string) => {
    if (type === 'Cancel') {
      setAddEditUserModel({ ...initAddEditUserModel })
      navigate('/dashboard')
      SetIsSubmitVisible(false)
    }
  }

  const onSubmit = (values: any) => {
    addEditUserModel.firstName = values.firstName
    addEditUserModel.surname = values.surname
    addEditUserModel.email = values.email
    addEditUserModel.mobile = values.mobile
    addEditUserModel.id = parseInt(values.id)
    addEditUserModel.fileBase64Data = selectFileBase64 != null ? selectFileBase64 : null
    // addEditUserModel.fileBase64Data = selectFileBase64
    if (addEditUserModel.id > 0) {
      if ((selectImage === USER_DEFAULT_IMAGE_PATH || selectImage === USER_DARK_IMAGE_PATH) && getUserQuery.data.result?.image != null) {
        addEditUserModel.fileBase64Data = null
      }
      if ((selectImage === USER_DEFAULT_IMAGE_PATH || selectImage === USER_DARK_IMAGE_PATH) && getUserQuery.data.result?.image != null) {
        addEditUserModel.image = getUserQuery.data.result?.image
        setSelectedImage(addEditUserModel.image)
      }
      addEditUserModel.image = selectImage
      if (selectImage === USER_DEFAULT_IMAGE_PATH || selectImage === USER_DARK_IMAGE_PATH) {
        addEditUserModel.image = null
      }
    }
    setAddEditUserModel({ ...addEditUserModel })
    if (values.id) {
      editUserProfileQuery.mutate(addEditUserModel)
    }
    SetIsSubmitVisible(false)
  }

  const cancelProfile = async (e) => {
    setSelectedImage(mode === 'light' ? USER_DEFAULT_IMAGE_PATH : USER_DARK_IMAGE_PATH)
  }

  const profileChange = async (e) => {
    setIsImageNotFound(false)
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader()
        reader.onload = (e) => {
          setImageSrc(e.target?.result)
        }
        reader.readAsDataURL(e.target.files[0])
        setVisible(true)
      } else {
        toastNotification(
          'warning',
          'Please select only image jpg, jpeg, png, gif'
        )
        return
      }
    }
    fileInputprofileRef.current.value = null
  }

  useEffect(() => {
    if (editUserProfileQuery?.data?.statusCode === 200) {
      toastNotification('success', 'User profile has been updated successfully')
      if (editUserProfileQuery?.data?.result) {
        if (
          editUserProfileQuery?.data?.result?.image != null &&
          editUserProfileQuery?.data?.result?.image !== undefined &&
          editUserProfileQuery?.data?.result?.image !== ''
        ) {
          var selectImage =
            API_URL + USER_IMAGE_UPLOAD_PATH + editUserProfileQuery?.data?.result?.image
          setSelectedImage(selectImage)
        } else {
          setSelectedImage(mode === 'light' ? USER_DEFAULT_IMAGE_PATH : USER_DARK_IMAGE_PATH)
        }

        const authData: any = {
          ...auth,
          result: {
            ...auth?.result,
            firstName: editUserProfileQuery?.data?.result?.firstName,
            surname: editUserProfileQuery?.data?.result?.surname,
            email: editUserProfileQuery?.data?.result?.email,
            mobile: editUserProfileQuery?.data?.result?.mobile,
            image: editUserProfileQuery?.data?.result?.image,
          },
        }
        saveAuth(authData)
      }
    }
  }, [editUserProfileQuery?.isError, editUserProfileQuery?.isSuccess])

  const toggleVisible = React.useCallback(() => {
    setVisible((p) => !p)
  }, [visible])

  const editProfile = (e) => {
    SetIsSubmitVisible(true)
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addEditUserModel}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          getFieldProps,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (isSubmitVisible === false) {
              setFieldValue('firstName', addEditUserModel.firstName)
              setFieldValue('surname', addEditUserModel.surname)
              setFieldValue('mobile', addEditUserModel.mobile)
              setFieldValue('email', addEditUserModel.email)
              setFieldValue('id', addEditUserModel.id)
              setFieldValue('isActive', addEditUserModel.isActive)
            }
          }, [addEditUserModel])

          return (
            <div id='kt_app_content' className='app-content flex-column-fluid'>
              <div id='kt_app_content_container' className='app-container'>
                <ImageCrop
                  visible={visible}
                  imagesrc={imagesrc}
                  onCancel={toggleVisible}
                  setareaPixels = {setareaPixels}
                  onComplete={(imagePromisse) => {
                    imagePromisse.then((image) => {
                      setSelectFileBase64(image)
                      setSelectedImage(image)
                      setVisible(false)
                    })
                  }}
                  containerStyle={{
                    position: 'relative',
                    width: '100%',
                    height: 300,
                    background: '#333',
                  }}
                  imageModule="UserModule"
                />
                <fieldset disabled={upsertLoading || getLoading}>
                  <Form
                    onSubmit={handleSubmit}
                    noValidate
                    className='form d-flex flex-column flex-lg-row'
                    id='kt_sign_in_form'
                  >
                    <div className='col-md-3 d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
                      <div className='card card-flush py-4'>
                        <div className='card-header'>
                          <div className='card-title' style={{ position: 'relative', left: '38%' }}>
                            <h2>Image</h2>
                          </div>
                        </div>
                        <div className='card-body text-center pt-0'>
                          <div className='img-content image-input image-input-empty image-input-outline image-input-placeholder mb-3'>
                            <a href="javascript:void(0)">

                              {selectImage && (
                                <>
                                  <img
                                    alt='not found'
                                    width={'250px'}
                                    src={selectImage}
                                    className='image-input-wrapper w-150px h-150px'
                                    onError={function (ele) {
                                      setIsImageNotFound(true)
                                      ele.currentTarget.src = toAbsoluteUrl(USER_DEFAULT_IMAGE_PATH)
                                    }}
                                  />
                                </>
                              )}
                              {isSubmitVisible && (
                                <>
                                  <div className="img-content-overlay"></div>
                                  <div className="img-content-details fadeIn-bottom fadeIn-left">
                                  <label
                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px shadow myProfile'
                                    data-kt-image-input-action='change'
                                    data-bs-toggle='tooltip'
                                    title='Change avatar'
                                  >
                                    <span className='text-muted'>
                                      <Icon icon="uil:image-upload" width="50" height="50" />
                                    </span>
                                    <input
                                      type='file'
                                      name='avatar'
                                      accept='.png, .jpg,.jpeg'
                                      ref={fileInputprofileRef}
                                      onChange={profileChange}
                                    />
                                    <input type='hidden' name='avatar_remove' />
                                  </label>
                                  </div>
                                </>
                              )}
                            </a>
                            {selectImage !== USER_DEFAULT_IMAGE_PATH && selectImage !== USER_DARK_IMAGE_PATH && isSubmitVisible && (
                              <span
                                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow profileDelete'
                                onClick={cancelProfile}
                                data-bs-toggle='tooltip'
                                title='Cancel avatar'
                              >
                                <Icon icon='ion:close' />
                              </span>
                            )}
                            <span
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow '
                              data-kt-image-input-action='remove'
                              data-bs-toggle='tooltip'
                              title='Remove avatar'
                            >
                              <Icon icon='ion:close' />
                            </span>
                          </div>
                          <div className='text-muted fs-7'>
                            Set the user image. Only *.png, *.jpg and *.jpeg image files are
                            accepted
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12 d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='kt_ecommerce_add_event_general'
                          role='tab-panel'
                        >
                          <div className='d-flex flex-column gap-7 gap-lg-10'>
                            <div className='card card-flush py-4'>
                              <div className='card-header'>
                                <div className='card-title'>
                                  {!isSubmitVisible && (
                                    <h2>User Profile</h2>
                                  )}
                                  {isSubmitVisible && (
                                    <h2>Edit User Profile</h2>
                                  )}

                                </div>
                                {!isSubmitVisible && (
                                  <Button
                                    onClick={editProfile}
                                    className='btn btn-sm btn-primary align-self-center'
                                  >
                                    Edit
                                  </Button>
                                )}
                              </div>
                              <div className='card-body row pt-0'>
                                <BForm.Group
                                  className='fv-row col-sm-6 col-md-6 mb-8'
                                  controlId='firstName'
                                >
                                  <BForm.Label>
                                    First Name <span style={{ color: 'red' }}>*</span>
                                  </BForm.Label>
                                  <BForm.Control
                                    type='text'
                                    name='firstName'
                                    placeholder='First Name'
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                      setFieldValue('firstName', e.target.value)
                                    }}
                                    defaultValue={values.firstName ?? ''}
                                    disabled={!isSubmitVisible}
                                    className={clsx(
                                      'form-control bg-transparent',
                                      { 'is-invalid': touched.firstName && errors.firstName },
                                      {
                                        'is-valid': touched.firstName && !errors.firstName,
                                      }
                                    )}
                                  />
                                  {errors.firstName && touched.firstName ? (
                                    <div className='text-danger'>{errors.firstName}</div>
                                  ) : null}
                                </BForm.Group>

                                <BForm.Group
                                  className='fv-row col-sm-6 col-md-6 mb-8'
                                  controlId='surname'
                                >
                                  <BForm.Label>
                                    Last Name <span style={{ color: 'red' }}>*</span>
                                  </BForm.Label>
                                  <BForm.Control
                                    type='text'
                                    name='surname'
                                    placeholder='Last Name'
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                      setFieldValue('surname', e.target.value)
                                    }}
                                    defaultValue={values.surname ?? ''}
                                    disabled={!isSubmitVisible}
                                    className={clsx(
                                      'form-control bg-transparent',
                                      { 'is-invalid': touched.surname && errors.surname },
                                      {
                                        'is-valid': touched.surname && !errors.surname,
                                      }
                                    )}
                                  />
                                  {errors.surname && touched.surname ? (
                                    <div className='text-danger'>{errors.surname}</div>
                                  ) : null}
                                </BForm.Group>
                                <BForm.Group
                                  className='fv-row col-sm-6 col-md-6 mb-8'
                                  controlId='mobile'
                                >
                                  <BForm.Label>
                                    Mobile Number <span style={{ color: 'red' }}>*</span>
                                  </BForm.Label>
                                  <BForm.Control
                                    type='text'
                                    name='mobile'
                                    minLength={10}
                                    maxLength={15}
                                    placeholder='Mobile'
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                      setFieldValue('mobile', e.target.value)
                                    }}
                                    defaultValue={values.mobile ?? ''}
                                    disabled={!isSubmitVisible}
                                    className={clsx(
                                      'form-control bg-transparent',
                                      { 'is-invalid': touched.mobile && errors.mobile },
                                      {
                                        'is-valid': touched.mobile && !errors.mobile,
                                      }
                                    )}
                                  />
                                  {errors.mobile && touched.mobile ? (
                                    <div className='text-danger'>{errors.mobile}</div>
                                  ) : null}
                                </BForm.Group>
                                <BForm.Group
                                  className='fv-row col-sm-6 col-md-6 mb-8'
                                  controlId='email'
                                >
                                  <BForm.Label>Email</BForm.Label>
                                  <BForm.Control
                                    type='email'
                                    name='email'
                                    placeholder='Email'
                                    onChange={handleChange}
                                    disabled={true}
                                    defaultValue={values.email ?? ''}
                                    className={clsx(
                                      'form-control bg-transparent',
                                      { 'is-invalid': touched.email && errors.email },
                                      {
                                        'is-valid': touched.email && !errors.email,
                                      }
                                    )}
                                  />
                                  {errors.email && touched.email ? (
                                    <div className='text-danger'>{errors.email}</div>
                                  ) : null}
                                </BForm.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {isSubmitVisible && (
                        <div className='d-flex justify-content-end'>
                          <button
                            type='button'
                            id='kt_ecommerce_add_event_cancel'
                            className='btn btn-light me-5'
                            onClick={(e) => HandleButtonAction(e, 'Cancel')}
                          >
                            Cancel
                          </button>
                          <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
                            {editUserProfileQuery.isLoading ? (
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='false'
                              />
                            ) : (
                              <span className='indicator-label'>Save Changes</span>
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </Form>
                  <FocusError />
                </fieldset>
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}

export default UserProfilePage
