import { DropdownModel } from "../services/Common/Common.type";


export const statusDropDown: DropdownModel[] = [
    {
        value: 1,
        label: "Active"
    },
    {
        value: 0,
        label: "Inactive"
    }
];

export const GetGoogleAPIKey = "AIzaSyBUYCW7YCuNHNb6D-cr6B9tQWQfd22wX3Q"

export const ITEMS_PER_PAGE = 10