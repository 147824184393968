import { KTCard } from "../../../_metronic/helpers"
import ApplicationVersionTable from "./ApplicationVersionTable"


const ApplicationVersionPage = () => {
    return (
        <>
            <KTCard>
                <ApplicationVersionTable />
            </KTCard>
        </>
    )
}

export default ApplicationVersionPage