import React from 'react';

interface NoDataMessageProps {
  header: string;
  description: string;
}
const NoDataMessage: React.FC<NoDataMessageProps> = ({header, description}) => {
  return (
    <div className="card card-xl-stretch mb-xl-8 shadow-sm">
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">{header}</h3>
      </div>
      <div className='card-body pt-0'>
        <div className='d-flex align-items-center bg-secondary border-bottom border-1 rounded p-5 mb-7'>
          <span className="text-dark me-5">
            <i className="ki-duotone ki-abstract-26 text-dark fs-1 me-5">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </span>
          <div className='flex-grow-1 fs-6 fw-bold text-gray-800'>
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDataMessage;
