import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import ApplicationVersionPage from "./ApplicationVersionPage";

const ApplicationVersionWrapper: React.FC = (props : any) => {
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator : true,
            isActive: false,
        }
    ]
    return (
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Application Version</PageTitle>
            <ApplicationVersionPage />
        </>
    );
}
export default ApplicationVersionWrapper;