import { useFormikContext } from "formik";
import moment from "moment";
import { useEffect } from "react";

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

const alphaBatesClass1: string[] = []
const alphaBatesClass2: string[] = []
const alphaBatesClass3: string[] = []
const alphaBatesClass4: string[] = []

alphabet.forEach((element, index) => {
    if (index % 4 === 0) {
        alphaBatesClass1.push(element)
    }
    else if (index % 4 === 1) {
        alphaBatesClass2.push(element)
    }
    else if (index % 4 === 2) {
        alphaBatesClass3.push(element)
    }
    else if (index % 4 === 3) {
        alphaBatesClass4.push(element)
    }
});

export const isValidDateFormat = (dob: any) => {
    if (!dob) return false; // Accept null or empty DOB
    const format = "DD-MM-YYYY"; // Define your desired format
    return moment(dob, format, true).isValid();
}

export const applyUserSpecificDateFormat = () => {
    return "DD/MM/yyyy";
};

export const applyUserSesstionFormat = () => {
    return "MMM DD YYYY"
}

export const applyUserSpecificTimeFormat = () => {
    return "hh:mm a";
};

export const applyUserSpecificTimeWithoutMeridiemFormat = () => {
    return "kk:mm";
}

export const applyUserSpecificDateTimeFormat = () => {
    return applyUserSpecificDateFormat() + ' ' + applyUserSpecificTimeFormat();
};

export const convertNumbersWithCommas = (
    x: number | string,
    isDecimalConversionNotNeeded?: boolean,
) => {
    let number: number | string = Number(x)
    if (!isDecimalConversionNotNeeded) number = Number(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addcommasRupeesAmount = (amount) => {
    const formattedAmount = amount.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR'
    });
    return formattedAmount;
}


export const currencyPrefix = () => {
    return '&#8377';
}

export const GetMonthWithFormart = (month: number, format: string) => {
    var toDay = new Date()
    toDay.setMonth(month)
    return moment(toDay).format(format)
}

export const GetInitailCharaterClass = (charter: string) => {
    if (alphaBatesClass1.filter(x => { return x === charter }).length > 0) {
        return 'text-success'
    }
    else if (alphaBatesClass2.filter(x => { return x === charter }).length > 0) {
        return 'text-warning'
    }
    else if (alphaBatesClass3.filter(x => { return x === charter }).length > 0) {
        return 'text-danger'
    }
    else if (alphaBatesClass4.filter(x => { return x === charter }).length > 0) {
        return 'text-info'
    }
}

export function checkImageExists(url, callback) {
    var img = new Image();
    img.src = url
    img.onload = function () {
        callback(true)
    };
    img.onerror = function () {
        callback(false)
    };
}

export function ConvertDistance(value: any, from: string, to: string) {
    if (from === 'm' && to === 'km') {
        return (value / 1000).toFixed(3).replace(/\.?0+$/, '');
    }
}

export const ConvertTime = (value) => {
    let hours = Math.floor(value / 3600)
    let minutes = Math.floor((value - hours * 3600) / 60)
    let seconds = value - hours * 3600 - (minutes * 60)
    let result = ""
    if (hours > 0)
        result += hours + "h "
    if (minutes > 0)
        result += minutes + "min "
    if (seconds > 0)
        result += seconds + "sec "
    return result;
}

export const FocusError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            let keys = Object.keys(errors);
            if (keys.length > 0) {
                const selector = `[name=${keys[0]}]`;
                const errorElement = document.querySelector(selector) as HTMLElement;
                if (errorElement) {
                    errorElement.focus()
                    errorElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }
        }
    }, [errors, isSubmitting, isValidating]);

    return null;
};