import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import useJwt from '../hooks/useJwt'

const App = () => {
  const { isTokenValid } = useJwt();
  const { logout } = useAuth();

  useEffect(() => {
    if (!isTokenValid()) {
      logout();
    }
  });
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
