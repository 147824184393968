import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { Form as BForm, Spinner } from 'react-bootstrap';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import { usePostQuery } from '../../../hooks/reactQueryHelper';
import { useAuth } from "../../modules/auth";
import { ChangePassword,ChangePasswordAPI } from "../../../services/User/ChangePassword.type";
import { useNavigate } from "react-router-dom";
import toastNotification from "../../../helper/toastUtils";

//initialValues
const initialValues: ChangePassword = {
    userId: 0,
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: ''
};

//Validation Schema 
const validationSchema = Yup.object({
    currentPassword: Yup.string()
        .required('Please enter current password')
        .min(8, 'Password must be at least 8 character')
        .max(8, 'Password should not exceed 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])/,
            'Must contain one uppercase, one lowercase'
        )
        .matches(/^(?=.*[!@#\$%\^&\*])/, 'Must contain one special case character')
        .matches(/^(?=.{6,20}$)\D*\d/, 'Must contain one number')
    , newPassword: Yup.string()
        .required('Please enter new password')
        .min(8, 'Password must be at least 8 character')
        .max(8, 'Password should not exceed 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])/,
            'Must contain one uppercase, one lowercase'
        )
        .matches(/^(?=.*[!@#\$%\^&\*])/, 'Must contain one special case character')
        .matches(/^(?=.{6,20}$)\D*\d/, 'Must contain one number')
        .notOneOf([Yup.ref('currentPassword')], 'New password cannot be the same as the current password')
    , passwordConfirmation: Yup.string()
        .required('Please enter confirm password')
        .min(8, 'Password must be at least 8 character')
        .max(8, 'Password should not exceed 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])/,
            'Must contain one uppercase, one lowercase'
        )
        .matches(/^(?=.*[!@#\$%\^&\*])/, 'Must contain one special case character')
        .matches(/^(?=.{6,20}$)\D*\d/, 'Must contain one number')
        .oneOf([Yup.ref('newPassword')], 'Password and confirm password does not match'),
});

const ChangePasswordPage: React.FC<any> = (props: any) => {
    const { auth } = useAuth();
    const { getLoading, upsertLoading } = props;
    const changePasswordQuery: any = usePostQuery('changePassword', ChangePasswordAPI, {})
    const navigate = useNavigate();

    const [changePasswordModel, setChangePasswordModel] = useState<ChangePassword>(initialValues);
    const [passwordShown, setPasswordShown] = useState(false);
    const [newpasswordShown, setNewPasswordShown] = useState(false);
    const [confirmnewpasswordShown, setConfirmNewPasswordShown] = useState(false);

    //submit Change Password Data
    const onSubmit = (values: any) => {
        changePasswordModel.currentPassword = values.currentPassword
        changePasswordModel.newPassword = values.newPassword
        changePasswordModel.passwordConfirmation = values.passwordConfirmation
        changePasswordModel.userId = parseInt(auth?.result?.id);
        setChangePasswordModel({ ...changePasswordModel });
        if (changePasswordModel.userId) {
            var data = { OldPassword: '', newPassword: '', id: 0 };
            data.OldPassword = changePasswordModel.currentPassword;
            data.newPassword = changePasswordModel.newPassword;
            data.id = changePasswordModel.userId;
            changePasswordQuery.mutate(data)
        }
    };
    //change password api Reponse
    useEffect(() => {
        if (changePasswordQuery?.data?.statusCode === 200) {
            setChangePasswordModel({ ...initialValues })
            toastNotification('success', changePasswordQuery?.data?.result)
        }
    }, [changePasswordQuery?.isError, changePasswordQuery?.isSuccess])

    useEffect(() => {
        if (parseInt(auth?.result?.id) != null) {
            setChangePasswordModel((prevState: ChangePassword) => ({ ...prevState, userId: parseInt(auth?.result?.id) }))
        }
    }, [])

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={changePasswordModel}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={true}
                validateOnBlur={false}
            >
                {({
                    values,
                    touched,
                    errors,
                    resetForm,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                }) => {

                    function HandleCancelAction(e: any, arg1: string): void {
                        navigate('/dashboard')
                    }

                    return (
                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container">
                                <fieldset disabled={upsertLoading || getLoading}>
                                    <Form onSubmit={handleSubmit} noValidate className="form d-flex flex-column flex-lg-row" id="kt_sign_in_form">
                                        <div className="col-md-3 d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                                            <div className="card card-flush py-4">
                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2>Rules</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body mb-6 pt-0">
                                                    <div className="d-flex flex-column text-gray-600">
                                                        <div className="d-flex align-items-center py-2">
                                                            <span className="bullet bg-primary me-3"></span>Password must be at least 8 character</div>
                                                        <div className="d-flex align-items-center py-2">
                                                            <span className="bullet bg-primary me-3"></span>Must contain one uppercase, one lowercase</div>
                                                        <div className="d-flex align-items-center py-2">
                                                            <span className="bullet bg-primary me-3"></span>Must contain one special case character</div>
                                                        <div className="d-flex align-items-center py-2">
                                                            <span className="bullet bg-primary me-3"></span>Must contain one number</div>
                                                        <div className="d-flex align-items-center py-2">
                                                            <span className="bullet bg-primary me-3"></span>New password and current password are not same</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-9 d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="kt_ecommerce_add_event_general" role="tab-panel">
                                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                                        <div className="card card-flush py-4">
                                                            <div className="card-body pt-4 row">
                                                                <BForm.Group className='fv-row col-sm-6 col-md-12 mb-8' controlId='oldPassword'>
                                                                    <BForm.Label>
                                                                        Current Password <span style={{ color: 'red' }}>*</span>
                                                                    </BForm.Label>
                                                                    <BForm.Control
                                                                        type={passwordShown ? "text" : "password"}
                                                                        name='currentPassword'
                                                                        placeholder='Current Password'
                                                                        onChange={handleChange}
                                                                        value={values.currentPassword}
                                                                        className={
                                                                            clsx(
                                                                                'form-control bg-transparent',
                                                                                { 'is-invalid': touched.currentPassword && errors.currentPassword },
                                                                                {
                                                                                    'is-valid': touched.currentPassword && !errors.currentPassword,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <i
                                                                            className={passwordShown ? 'fa fa-eye-slash eyeicon' : "fa fa-eye eyeicon"}
                                                                            aria-hidden="true"
                                                                            onClick={() => {
                                                                                if (passwordShown) { setPasswordShown(false) }
                                                                                else {
                                                                                    setPasswordShown(true)
                                                                                }
                                                                            }}></i>
                                                                    </div>
                                                                    {errors.currentPassword && touched.currentPassword ? (
                                                                        <div className='text-danger'>
                                                                            {errors.currentPassword}
                                                                        </div>
                                                                    ) : null}
                                                                </BForm.Group>

                                                                <BForm.Group className='fv-row col-sm-6 col-md-12 mb-8' controlId='newPassword'>
                                                                    <BForm.Label>
                                                                        New Password <span style={{ color: 'red' }}>*</span>
                                                                    </BForm.Label>
                                                                    <BForm.Control
                                                                        type={newpasswordShown ? "text" : "password"}
                                                                        name='newPassword'
                                                                        placeholder='New Password'
                                                                        onChange={handleChange}
                                                                        value={values.newPassword}
                                                                        className={
                                                                            clsx(
                                                                                'form-control bg-transparent',
                                                                                { 'is-invalid': touched.newPassword && errors.newPassword },
                                                                                {
                                                                                    'is-valid': touched.newPassword && !errors.newPassword,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <i
                                                                            className={newpasswordShown ? 'fa fa-eye-slash eyeicon' : "fa fa-eye eyeicon"}
                                                                            aria-hidden="true"
                                                                            onClick={() => {
                                                                                if (newpasswordShown) { setNewPasswordShown(false) }
                                                                                else {
                                                                                    setNewPasswordShown(true)
                                                                                }
                                                                            }}></i>
                                                                    </div>
                                                                    {errors.newPassword && touched.newPassword ? (
                                                                        <div className='text-danger'>
                                                                            {errors.newPassword}
                                                                        </div>
                                                                    ) : null}
                                                                </BForm.Group>
                                                                <BForm.Group className='fv-row col-sm-6 col-md-12 mb-8' controlId='passwordConfirmation'>
                                                                    <BForm.Label>
                                                                        Confirm New Password <span style={{ color: 'red' }}>*</span>
                                                                    </BForm.Label>
                                                                    <BForm.Control
                                                                        type={confirmnewpasswordShown ? "text" : "password"}
                                                                        name='passwordConfirmation'
                                                                        placeholder='Confirmation Password'
                                                                        onChange={handleChange}
                                                                        value={values.passwordConfirmation}
                                                                        className={
                                                                            clsx(
                                                                                'form-control bg-transparent',
                                                                                { 'is-invalid': touched.passwordConfirmation && errors.passwordConfirmation },
                                                                                {
                                                                                    'is-valid': touched.passwordConfirmation && !errors.passwordConfirmation,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <i
                                                                            className={confirmnewpasswordShown ? 'fa fa-eye-slash eyeicon' : "fa fa-eye eyeicon"}
                                                                            aria-hidden="true"
                                                                            onClick={() => {
                                                                                if (confirmnewpasswordShown) { setConfirmNewPasswordShown(false) }
                                                                                else {
                                                                                    setConfirmNewPasswordShown(true)
                                                                                }
                                                                            }}></i>
                                                                    </div>
                                                                    {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                                        <div className='text-danger'>
                                                                            {errors.passwordConfirmation}
                                                                        </div>
                                                                    ) : null}
                                                                </BForm.Group>

                                                                <div className="d-flex justify-content-end">
                                                                    <button type="button" id="kt_ecommerce_add_event_cancel" className="btn btn-light me-5" onClick={(e) => HandleCancelAction(e, 'Cancel')}>Cancel</button>
                                                                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
                                                                        {changePasswordQuery.isLoading ? (
                                                                            <Spinner
                                                                                as='span'
                                                                                animation='border'
                                                                                size='sm'
                                                                                role='status'
                                                                                aria-hidden='false'
                                                                            />
                                                                        ) :
                                                                            (<span className="indicator-label">Change Password</span>)}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </fieldset>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </>
    )
}

export default ChangePasswordPage;