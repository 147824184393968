import { KTCard } from "../../../_metronic/helpers"
import React from "react";
import { EventsTable } from "./EventsTable";

const EventPage: React.FC = (props: any) => {
    return (
        <>
          <KTCard>
            <EventsTable />
          </KTCard>
        </>
      )
}

export default EventPage