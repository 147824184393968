import { Form as BForm, Modal, Spinner } from "react-bootstrap"
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { FocusError } from "../../../helper/utility"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import * as Yup from 'yup';
import { DropdownModel } from "../../../services/Common/Common.type"
import { AddEditUser, AddEditUserModel, UserModel } from "../../../services/User/User.type"
import clsx from "clsx"
import moment from "moment"
import { Icon } from "@iconify/react"
import { usePostQuery } from "../../../hooks/reactQueryHelper"
import toastNotification from "../../../helper/toastUtils"
const validationSchema = Yup.object({
    firstName: Yup.string()
        .required('Please enter first name')
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    surname: Yup.string()
        .required('Please enter last name')
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    mobile: Yup.string()
        .required('Please enter mobile number')
        .min(10, 'Mobile must be at least 10 characters')
        .matches(/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/, 'Please enter valid mobile number'),
    email: Yup.string().trim().email('Please enter vaild email').required('Please enter email'),
    height: Yup.number()
        .nullable(),
    dob: Yup.date()
        .required('Please select date of birth'),
});

const statusDropDown: DropdownModel[] = [
    {
        value: 1,
        label: "Active"
    },
    {
        value: 0,
        label: "Inactive"
    }
];

let initialValues: AddEditUserModel = {
    id: 0,
    firstName: '',
    surname: '',
    password: '',
    dob: null,
    weight: 0,
    height: 0,
    mobile: '',
    address: '',
    landmark: '',
    pincode: '',
    city: '',
    gender: '',
    image: '',
    state: '',
    email: null,
    isActive: true,
    createdDate: Date,
    modifiedDate: Date,
    modifiedBy: null,
    createdBy: null,
    selectedStatus: statusDropDown[0],
    fileBase64Data: null,
    participentEventCount: 0,
    createTimeDuration: null,
    isVerified: true,
    platForm: '',
    isInvited: false,
    deletedDate: null
};


const AddUserModal = (props: any) => {
    const {
        show,
        onHide,
        fetchUserList
    } = props
    const [ismobile, setMobile] = useState<any>([]);
    
    const [addEditUserModel, setAddEditUserModel] = useState<AddEditUserModel>(initialValues);
    const [modalShow, setModalShow] = useState(false)
    const addEditUserQuery: any = usePostQuery('addUser', AddEditUser, {});

    const onSubmit = (values: any) => {
        addEditUserModel.dob = values.dob != "" ? values.dob : null
        addEditUserModel.email = values.email
        addEditUserModel.firstName = values.firstName
        addEditUserModel.surname = values.surname
        addEditUserModel.gender = values.gender
        addEditUserModel.height = parseFloat(values.height)
        addEditUserModel.weight = parseFloat(values.weight)
        addEditUserModel.id = parseInt(values.id)
        addEditUserModel.mobile = values.mobile

        setAddEditUserModel({ ...addEditUserModel })
        addEditUserQuery.mutate(addEditUserModel)
    }

    useEffect(() => {
        if (addEditUserQuery?.data?.statusCode === 200) {
          toastNotification('success', 'User Added successfully')
          
        fetchUserList() 
        setAddEditUserModel(initialValues);
        onHide();
        }
      }, [addEditUserQuery.isError, addEditUserQuery.isSuccess])

    return (<>

        <Modal show={show}
            onHide={() => onHide()}
            size="lg" backdrop="static" keyboard={false} centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Add User</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <ReactTooltip id="my-tooltip" content="" />
                <Formik
                    enableReinitialize={true}
                    initialValues={addEditUserModel}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnChange={true}
                    validateOnBlur={false}
                >
                    {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }: any) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        return (
                            <div id='kt_app_content' className='app-content flex-column-fluid'>
                                <div id='kt_app_content_container' className='app-container'>
                                    <fieldset>
                                        <BForm
                                            onSubmit={handleSubmit}
                                            noValidate
                                            className='form d-flex flex-column flex-lg-row'
                                            id='kt_sign_in_form'
                                        >
                                            <div className='col-md-12 d-flex flex-column flex-row-fluid'>
                                                <div className='tab-content'>
                                                    <div
                                                        className='tab-pane fade show active'
                                                        id='kt_ecommerce_add_event_general'
                                                        // eslint-disable-next-line jsx-a11y/aria-role
                                                        role='tab-panel'
                                                    >
                                                        <div className='d-flex flex-column'>
                                                            <div className='card card-flush py-4'>
                                                                <div className='card-body row pt-0'>
                                                                    <div className='row'>

                                                                        <div className='row'>
                                                                            <div className='col-sm-12 col-md-6'>
                                                                                <BForm.Group
                                                                                    className='fv-row mb-2'
                                                                                    controlId='firstName'
                                                                                >
                                                                                    <BForm.Label>
                                                                                        First Name <span style={{ color: 'red' }}>*</span>
                                                                                    </BForm.Label>
                                                                                    <BForm.Control
                                                                                        type='text'
                                                                                        name='firstName'
                                                                                        placeholder='First Name'
                                                                                        maxLength={50}
                                                                                        onChange={(e) => {
                                                                                            setFieldValue('firstName', e.target.value)
                                                                                        }}
                                                                                        defaultValue={values.firstName ?? ''}
                                                                                        className={clsx(
                                                                                            'form-control bg-transparent',
                                                                                            { 'is-invalid': touched.firstName && errors.firstName },
                                                                                            {
                                                                                                'is-valid': touched.firstName && !errors.firstName,
                                                                                            }
                                                                                        )}
                                                                                        onBlur={(e) => {
                                                                                            setFieldValue('firstName', e.target.value)
                                                                                        }}
                                                                                    />
                                                                                    {errors.firstName && touched.firstName ? (
                                                                                        <span className='text-danger'>{errors.firstName}</span>
                                                                                    ) : null}
                                                                                </BForm.Group>
                                                                            </div>
                                                                            <div className='col-sm-12 col-md-6'>
                                                                                <BForm.Group
                                                                                    className='fv-row mb-2'
                                                                                    controlId='surname'
                                                                                >
                                                                                    <BForm.Label>
                                                                                        Last Name <span style={{ color: 'red' }}>*</span>
                                                                                    </BForm.Label>
                                                                                    <BForm.Control
                                                                                        type='text'
                                                                                        name='surame'
                                                                                        placeholder='Last Name'
                                                                                        maxLength={50}
                                                                                        onChange={handleChange}
                                                                                        defaultValue={values.surname ?? ''}
                                                                                        onBlur={(e) => {
                                                                                            setFieldValue('surname', e.target.value)
                                                                                        }}
                                                                                        className={clsx(
                                                                                            'form-control bg-transparent',
                                                                                            { 'is-invalid': touched.surname && errors.surname },
                                                                                            {
                                                                                                'is-valid': touched.surname && !errors.surname,
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    {errors.surname && touched.surname ? (
                                                                                        <span className='text-danger'>{errors.surname}</span>
                                                                                    ) : null}
                                                                                </BForm.Group>
                                                                            </div>
                                                                            <div className='col-sm-12 col-md-6'>
                                                                                <BForm.Group
                                                                                    className='fv-row mb-2'
                                                                                    controlId='mobile'
                                                                                >
                                                                                    <BForm.Label>
                                                                                        Mobile Number<span style={{ color: 'red' }}>*</span>
                                                                                    </BForm.Label>

                                                                                    <BForm.Control
                                                                                        type='text'
                                                                                        name='mobile'
                                                                                        minLength={10}
                                                                                        maxLength={15}
                                                                                        placeholder='Mobile'
                                                                                        defaultValue={values.mobile ?? ''}
                                                                                        onChange={handleChange}
                                                                                        onBlur={(e) => {
                                                                                            setFieldValue('mobile', e.target.value)
                                                                                        }}
                                                                                        className={clsx(
                                                                                            'form-control bg-transparent',
                                                                                            { 'is-invalid': touched.mobile && errors.mobile },
                                                                                            {
                                                                                                'is-valid': touched.mobile && !errors.mobile,
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    {errors.mobile && touched.mobile ? (
                                                                                        <span className='text-danger'>{errors.mobile}</span>
                                                                                    ) : null}
                                                                                </BForm.Group>
                                                                            </div>

                                                                            <div className='col-sm-12 col-md-6'>
                                                                                <BForm.Group
                                                                                    className='fv-row mb-2'
                                                                                    controlId='email'
                                                                                >
                                                                                    <BForm.Label>Email<span style={{ color: 'red' }}>*</span></BForm.Label>
                                                                                    <BForm.Control
                                                                                        type='email'
                                                                                        name='email'
                                                                                        placeholder='Email'
                                                                                        onChange={handleChange}
                                                                                        onBlur={(e) => {
                                                                                            setFieldValue('email', e.target.value)
                                                                                        }}
                                                                                        maxLength={50}
                                                                                        defaultValue={values.email ?? ''}
                                                                                        className={clsx(
                                                                                            'form-control bg-transparent',
                                                                                            { 'is-invalid': touched.email && errors.email },
                                                                                            {
                                                                                                'is-valid': touched.email && !errors.email,
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    {errors.email && touched.email ? (
                                                                                        <span className='text-danger'>{errors.email}</span>
                                                                                    ) : null}
                                                                                </BForm.Group>
                                                                            </div>
                                                                            <div className='col-sm-12 col-md-6'>
                                                                                <BForm.Group
                                                                                    className='fv-row mb-2 col-sm-12 col-md-12'
                                                                                    controlId='weight'
                                                                                >
                                                                                    <BForm.Label>Weight</BForm.Label>
                                                                                    <BForm.Control
                                                                                        type='number'
                                                                                        name='weight'
                                                                                        placeholder='weight'
                                                                                        onChange={handleChange}
                                                                                        value={values.weight || ''}
                                                                                        min={0}
                                                                                        step='0.01'
                                                                                    />
                                                                                    <div className="text-muted fs-7"><span>Weight in Kilogram</span></div>
                                                                                </BForm.Group>


                                                                            </div>
                                                                            <div className='col-sm-12 col-md-6'>
                                                                                <BForm.Group
                                                                                    className='fv-row mb-2'
                                                                                    controlId='height'
                                                                                >
                                                                                    <BForm.Label>Height</BForm.Label>
                                                                                    <BForm.Control
                                                                                        type='number'
                                                                                        name='height'
                                                                                        placeholder='Height'
                                                                                        onChange={handleChange}
                                                                                        onBlur={(e) => {
                                                                                            setFieldValue('height', e.target.value)
                                                                                        }}
                                                                                        value={values.height || ''}
                                                                                        min={140}
                                                                                        step='0.01'
                                                                                        className={clsx(
                                                                                            'form-control bg-transparent',
                                                                                            {
                                                                                                'is-invalid':
                                                                                                    values.height != null &&
                                                                                                    values.height !== 0 &&
                                                                                                    touched.height &&
                                                                                                    errors.height,
                                                                                            },
                                                                                            {
                                                                                                'is-valid':
                                                                                                    values.height != null &&
                                                                                                    values.height !== 0 &&
                                                                                                    values.height > 0 &&
                                                                                                    touched.height &&
                                                                                                    !errors.height,
                                                                                            }
                                                                                        )}
                                                                                    />{' '}
                                                                                    {errors.height && touched.height ? (
                                                                                        <span className='text-danger'>{errors.height}</span>
                                                                                    ) : <span className="text-muted fs-7">Height in centimeter</span>}
                                                                                </BForm.Group>
                                                                            </div>

                                                                            <div className='col-sm-12 col-md-6'>
                                                                                <BForm.Group
                                                                                    className='fv-row mb-2'
                                                                                    controlId='dob'
                                                                                >
                                                                                    <BForm.Label>Date Of Birth  <span style={{ color: 'red' }}>*</span></BForm.Label>
                                                                                    <BForm.Control
                                                                                        type='date'
                                                                                        name='dob'
                                                                                        placeholder='Date Of Brith'
                                                                                        onChange={(e) => {
                                                                                            setFieldValue('dob', e.target.value)
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            setFieldValue('dob', e.target.value)
                                                                                        }}
                                                                                        value={moment(values.dob).format('YYYY-MM-DD')}
                                                                                        max={moment().subtract(8, 'years').format('YYYY-MM-DD')}
                                                                                        className={clsx(
                                                                                            'form-control bg-transparent',
                                                                                            { 'is-invalid': touched.dob && errors.dob },
                                                                                            {
                                                                                                'is-valid': touched.dob && !errors.dob,
                                                                                            }
                                                                                        )} />
                                                                                    {errors.dob && touched.dob ? (
                                                                                        <span className='text-danger'>{errors.dob}</span>
                                                                                    ) : null}
                                                                                </BForm.Group>
                                                                            </div>
                                                                            <div className='col-sm-12 col-md-6'>
                                                                                <BForm.Group
                                                                                    className='mb-5 mt-12'
                                                                                    key='GenderRadio'
                                                                                >
                                                                                    <BForm.Label>Gender <span style={{ color: 'red' }}>*</span> &nbsp; </BForm.Label>
                                                                                    <BForm.Check
                                                                                        type='radio'
                                                                                        label='Male'
                                                                                        name='gender'
                                                                                        id='maleGender'
                                                                                        inline
                                                                                        value='M'
                                                                                        checked={values.gender === 'M'}
                                                                                        onChange={() => setFieldValue('gender', 'M')}
                                                                                    />
                                                                                    <BForm.Check
                                                                                        type='radio'
                                                                                        label='Female'
                                                                                        name='gender'
                                                                                        id='femaleGender'
                                                                                        inline
                                                                                        value='F'
                                                                                        defaultChecked={true}
                                                                                        checked={values.gender === 'F'}
                                                                                        onChange={() => setFieldValue('gender', 'F')}
                                                                                    />
                                                                                </BForm.Group>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-end'>
                                                    <button
                                                        type='button'
                                                        id='kt_ecommerce_add_event_cancel'
                                                        className='btn btn-light me-5'
                                                        onClick={(e) => onHide()}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
                                                        {addEditUserQuery.isLoading ? (
                                                            <Spinner
                                                                as='span'
                                                                animation='border'
                                                                size='sm'
                                                                role='status'
                                                                aria-hidden='false'
                                                            />
                                                        ) : (
                                                            <span className='indicator-label'>Save Changes</span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </BForm>
                                        <FocusError />
                                    </fieldset>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    </>)
}
export default AddUserModal