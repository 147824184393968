/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState, Fragment } from 'react'
import { GetEventParticipatedByUserID } from '../../../../services/User/User.type'
import { usePostQuery } from '../../../../hooks/reactQueryHelper'
import useTableState from '../../../components/Table/useTableState'
import { CellProps, Column } from 'react-table'
import Table from '../../../components/Table'
import TableInner from '../../../components/Table/TableInner'
import { TableGlobalSearch } from '../../../components/Table/TableGlobalSearch'
import { KTCardBody } from '../../../../_metronic/helpers'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { TablePagination } from '../../../components/Table/TablePagination'
import { GridRequestModel, SortModel } from '../../../../services/Common/Common.type'
import { TableLoader } from '../../../components/Table/TableLoader'
import { useParams } from 'react-router'
import { applyUserSpecificDateTimeFormat } from '../../../../helper/utility'

const UserEventPage = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { userDetail } = props
  const getEventParticipatedByUserIDQuery: any = usePostQuery(
    'getEventParticipatedByUserID',
    GetEventParticipatedByUserID,
    {}
  )
  const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
    pageIndex: 0,
  })

  const { sort, page, limit, search } = tableState
  const initSortModel: SortModel = {
    sort: '',
    fields: '',
  }

  const initGridRequestModel: GridRequestModel = {
    page: page ?? 0,
    limit: limit ?? 0,
    search: search ? search : '',
    sort: sort ? sort : initSortModel,
    id: 0,
    eventId: 0,
    eventTypeID: 0,
    paymentDate: null,
    isInvited: false,
    isJoined: false
  }
  const [eventList, setEventList] = useState<any>()

  useEffect(() => {
    if (getEventParticipatedByUserIDQuery.data) {
      setEventList(getEventParticipatedByUserIDQuery?.data?.result)
    }
  }, [getEventParticipatedByUserIDQuery.data])

  useEffect(() => {
    initGridRequestModel.page = page ?? 0
    initGridRequestModel.limit = limit ?? 0
    initGridRequestModel.id = Number(id)
    if (sort) initGridRequestModel.sort = sort
    if (search) initGridRequestModel.search = search
    if (userDetail != null && userDetail.id != null && userDetail.id !== undefined) {
      initGridRequestModel.id = userDetail.id
    }
    getEventParticipatedByUserIDQuery.mutate(initGridRequestModel)
  }, [page, limit, sort, search])

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: `Event Name`,
        accessor: 'eventName',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex flex-column'>
                <a
                  className='text-gray-800 text-hover-primary mb-1'
                  href='javascript:void(0)'
                  onClick={() => {
                    const additionalData = {
                      tabName: 'EventWinnerInformation', // Additional data you want to pass
                    }
                    navigate(`/events/detail/` + props?.row?.original?.eventID, {
                      state: additionalData,
                    })
                  }}
                >
                  {props?.row?.original?.eventName ? props?.row?.original?.eventName : '-'}
                </a>
              </div>
            </div>
          )
        },
      },
      {
        Header: `start Date`,
        accessor: 'startDate',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span>
              {' '}
              {moment(props?.row?.original?.startDate).format(applyUserSpecificDateTimeFormat())}
            </span>
          )
        },
      },
      {
        Header: `end Date`,
        accessor: 'endDate',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span>
              {' '}
              {moment(props?.row?.original?.endDate).format(applyUserSpecificDateTimeFormat())}
            </span>
          )
        },
      },
      {
        Header: `Event Type`,
        accessor: 'eventTypeName',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span>
              {' '}
              {props?.row?.original?.eventTypeName ? props?.row?.original?.eventTypeName : '-'}
            </span>
          )
        },
      },
      {
        Header: `Challenge?`,
        accessor: 'isChallenge',
        disableSortBy: true,
        maxWidth: 150,
        width: 120,
        minwidth: 120,
        Cell: (props: CellProps<any>) => {
          return (
            <>
              {props?.row?.original?.isChallenge === true ? (
                <span className='badge badge-light-info'>Challenge</span>
              ) : (
                <span className='badge badge-light-warning'>Event</span>
              )}
            </>
          )
        },
      },
      {
        Header: `Duration`,
        accessor: 'duration',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span> {props?.row?.original?.duration ? props?.row?.original?.duration : '-'}</span>
          )
        },
      },
      {
        Header: `Total Distance`,
        accessor: 'totalDistance',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span>
              {' '}
              {props?.row?.original?.totalDistance
                ? `${props.row.original.totalDistance} ${props.row.original.unit || ''}`.trim()
                : '-'}{' '}
            </span>
          )
        },
      },
    ],
    []
  )

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <Table
        columns={columns}
        data={eventList?.result ?? []}
        initialState={tableInitialState}
        pageCount={eventList?.totalPages ?? []}
        onTableStateChange={handleTableStateChange}
      >
        <Fragment>
          <TableGlobalSearch serachtext={'Search'} isAddbtnVisible={false} />
          <KTCardBody className='py-4'>
            <div className='table-responsive'>
              <TableInner loading={false} entityLabel='Events'></TableInner>
            </div>
            <TablePagination />
            <TableLoader isLoading={getEventParticipatedByUserIDQuery?.isLoading} />
          </KTCardBody>
        </Fragment>
      </Table>
    </div>
  )
}

export default UserEventPage
