/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { usePostQuery } from "../../../../hooks/reactQueryHelper";
import { GetEventParticipent } from "../../../../services/Event/Event.type";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { GridRequestModel, SortModel } from "../../../../services/Common/Common.type";
import { GetInitailCharaterClass } from "../../../../helper/utility";
import { USER_DEFAULT_IMAGE_PATH } from "../../../../shared/constants";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import { useNavigate } from "react-router"

const UserParticipatedPage = (props: any) => {
  const { eventDetail } = props;
  const getEventParticipent: any = usePostQuery("GetEventParticipent", GetEventParticipent, {});
  const [userList, setUserList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const loadMore = () => {
    // Show loader on the button when loading more data
    setIsLoading(true);

    const page = Math.ceil(userList.length / 12) + 1;
    const initSortModel: SortModel = {
      sort: "",
      fields: ""
    };

    const initGridRequestModel: GridRequestModel = {
      page: page,
      limit: 12,
      search: "",
      sort: initSortModel,
      id: 0,
      eventId: 0,
      eventTypeID: 0,
      paymentDate: null,
      isInvited: false,
      isJoined: false
    };

    if (eventDetail != null && eventDetail.id != null && eventDetail.id != undefined) {
      initGridRequestModel.id = eventDetail.id;
    }

    getEventParticipent.mutate(initGridRequestModel, {
      onSuccess: (response) => {
        // Update the userList and check for the end of records
        if (response?.result) {
          setUserList([...userList, ...response?.result]);
          if (response.result.length < 12) {
            // No more records to load
            setHasMore(false);
          }
        }
      },
      onError: (error) => {
        console.error("Error loading more records", error);
      },
    });

    // Hide the loader on the button when loading is complete
    setIsLoading(false);
  };

  useEffect(() => {
    if (eventDetail != null && eventDetail.id != null && eventDetail.id != undefined)
      loadMore(); // Load initial records when the component mounts.
  }, [eventDetail?.id]);

  return (
    <>
      <div id="kt_project_users_table_pane" className="tab-pane fade show active">
        {getEventParticipent.isLoading && userList.length === 0 ? (
          <div className="row g-6 g-xl-9">
            {Array.from({ length: 6 }).map((_, index) => (
              <div className="col-md-6 col-xxl-4" key={index}>
                <div className="card">
                  <div className="card-body d-flex flex-center flex-column pt-12 p-9">
                    <div className="symbol symbol-65px symbol-circle mb-5 d-flex flex-center flex-column">
                      <Skeleton variant="circle" width={65} height={65} />
                      <Skeleton variant="text" width={80} />
                      <Skeleton variant="text" width={80} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : userList.length === 0 ? (
          <div className="card mb-5 mb-xl-10">
            <div className="card-body p-9">
              <div className="fw-bold text-center text-muted h6">Participants user not found.</div>
            </div>
          </div>
        ) : (
          <>
            <div className="row g-6 g-xl-9">
              {userList.map((x: any, index: any) => {
                return (
                  <div className="col-md-6 col-xxl-4" key={index}>
                    <div className="card">
                      <div className="card-body d-flex flex-center flex-column pt-12 p-9 shadow-sm">
                        <div className="symbol symbol-65px symbol-circle mb-5">
                          {x.participentImage && (
                            <img
                              src={
                                x.participentImage ? x.participentImage
                                  : toAbsoluteUrl(USER_DEFAULT_IMAGE_PATH)
                              }
                              alt=""
                              onError={(ele) => {
                                ele.currentTarget.src = toAbsoluteUrl(USER_DEFAULT_IMAGE_PATH);
                              }}
                            />
                          )}
                          {!x.participentImage && (
                            <span
                              className={clsx(`symbol-label`, "fs-2x", `fw-semibold`, "bg-light-info", GetInitailCharaterClass(x?.firstName ? x?.firstName.charAt(0).toUpperCase() : ""))}
                            >
                              {x.firstName.charAt(0).toUpperCase()}
                            </span>
                          )}

                          {x.userIsActive ? (
                            <div className="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                          ) : (
                            <div className="bg-danger position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                          )}
                        </div>
                        <a href="javascript:void(0)" onClick={() => {
                          const additionalData = {
                            tabName: 'Activity', // Additional data you want to pass
                          };
                          navigate(`/users/detail/` + x.userID, { state: additionalData })
                        }} className="fs-4 text-gray-800 text-hover-primary fw-bold mb-6">
                          {x.firstName} {x.lastName}
                        </a>
                        <div className="d-flex flex-center flex-wrap">
                          <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                            <div className="fs-6 fw-bold text-gray-700">
                              {x?.height === null || x?.height === undefined || x?.height === '' ? '-' : x?.height + ' Cm'}
                            </div>
                            <div className="fw-semibold text-gray-400">Height</div>
                          </div>
                          <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                            <div className="fs-6 fw-bold text-gray-700">
                              {x?.weight === null || x?.weight === undefined || x?.weight === '' ? '-' : x?.weight + ' Kg'}
                            </div>
                            <div className="fw-semibold text-gray-400">Weight</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {hasMore ? (
              <div className="row py-5">
                <div className="col-xl-12">
                  <div className="d-grid gap-2 col-6 mx-auto">
                    <button onClick={loadMore} type="button" className="btn btn-primary">
                      {getEventParticipent.isLoading ? (<span><span className="pe-3 fs-4">Loading</span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>) : (<span><span className="pe-3 fs-4">Show more</span></span>)}
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default UserParticipatedPage;