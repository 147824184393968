import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import ChangePasswordPage from "./ChangePasswordPage";

const ChangePasswordPageWrapper = (props : any) => {
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: true,
        }
    ];
    return(
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Change Password</PageTitle>
            <ChangePasswordPage />
        </>
    )
}

export default ChangePasswordPageWrapper;