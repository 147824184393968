import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import EventPageWrapper from '../pages/event/EventPageWrapper'
import AddEditEventPageWrapper from '../pages/event/AddEditEvent/AddEditEventPageWrapper'
import UserPageWrapper from '../pages/user/UserPageWrapper'
import AddEditUserPageWrapper from '../pages/user/AdEditUser/AddEditUserPageWrapper'
import ViewUserPageWrapper from '../pages/user/ViewUser/ViewUserPageWrapper'
import ViewEventPageWrapper from '../pages/event/ViewEvent/ViewEventPageWrapper'
import UserProfilePageWrapper from '../pages/userProfile/UserProfilePageWrapper'
import ChangePasswordPageWrapper from '../pages/changePassword/ChangePasswordPageWrapper'
import OtpRequestPageWrapper from '../pages/otpRequest/OtpRequestPageWrapper'
import TransactionPageWrapper from '../pages/transaction/TransactionPageWrapper'
import ContactInquiryWrapper from '../pages/contactInquiry/ContactInquiryWrapper'
import NotificationPageWrapper from '../pages/pushNotifications/NotificationPageWrapper'
import PushNotificationPageWrapper from '../pages/pushNotifications/PushNotificationPageWrapper'
import HomeSliderWrapper from '../pages/homeSlider/HomeSliderWrapper'
import ApplicationVersionWrapper from '../pages/applicationVersion/ApplicationVersionWrapper'
const PrivateRoutes = () => {

  return (
   
        <Routes>
          <Route element={<MasterLayout />}>
            {/* Redirect to Dashboard after success login/registartion */}
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />
            {/* Pages */}
            <Route path='dashboard' element={<DashboardWrapper />} />
            <Route path='events' element={<EventPageWrapper />} />
            <Route path='events/manage' element={<AddEditEventPageWrapper />} />
            <Route path='events/manage/:id' element={<AddEditEventPageWrapper />} />
            <Route path='users' element={<UserPageWrapper />} />
            <Route path='users/manage' element={<AddEditUserPageWrapper />} />
            <Route path='users/manage/:id' element={<AddEditUserPageWrapper />} />
            <Route path='users/detail/:id' element={<ViewUserPageWrapper />} />
            <Route path='events/detail/:id' element={<ViewEventPageWrapper />} />
            <Route path='profile' element={<UserProfilePageWrapper />} />
            <Route path='changePassword' element={<ChangePasswordPageWrapper />} />
            <Route path='otp-request' element={<OtpRequestPageWrapper />} />
            <Route path='transaction' element={<TransactionPageWrapper />} />
            <Route path='notifications' element={<NotificationPageWrapper />} />
            <Route path='notifications/manage' element={<PushNotificationPageWrapper />} />
            <Route path='contactinquiry' element={<ContactInquiryWrapper />} />
            <Route path='homeslider' element={<HomeSliderWrapper />} />
            <Route path='applicationversion' element={<ApplicationVersionWrapper />} />
            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
          </Route>
        </Routes>
  )
}

export { PrivateRoutes }
