import React, { FC } from 'react';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { NotificationTable } from './NotificationTable';
import { KTCard } from '../../../_metronic/helpers';

const PushNotificationPageWrapper: React.FC = (props: any) => {
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator : true,
            isActive: false,
        }
    ];

    return(
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}> Notifications </PageTitle>
            <KTCard>  
                <NotificationTable/>
            </KTCard>
        </>
    )
}

export default PushNotificationPageWrapper