import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import ViewEventPage from "./ViewEventPage";

const ViewEventPageWrapper = (props : any) => {
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Events',
            path: '/events',
            isSeparator: true,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: true,
        }
    ];
    return(
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Event Detail</PageTitle>
            <ViewEventPage />
        </>
    )
}

export default ViewEventPageWrapper;