import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { DashboardPage } from './DashboardPage';

const DashboardWrapper = () => {
  const widgetsBreadCrumbs: Array<PageLink> = [
    {
        title: '',
        path: '',
        isSeparator: false,
        isActive: false,
    }
];
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={widgetsBreadCrumbs}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
