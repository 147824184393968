import { Modal, Spinner } from "react-bootstrap";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Table from "../../../components/Table";
import { Fragment, useEffect, useState } from "react";
import { KTCardBody, KTIcon } from "../../../../_metronic/helpers";
import TableInner from "../../../components/Table/TableInner";
import { TablePagination } from "../../../components/Table/TablePagination";
import { TableLoader } from "../../../components/Table/TableLoader";
import useTableState from "../../../components/Table/useTableState";
import React from "react";
import { CellProps, Column, Row } from "react-table";
import { usePostQuery } from "../../../../hooks/reactQueryHelper";
import { GridRequestModel, SortModel } from "../../../../services/Common/Common.type";
import { EventInvitationModel, EventInvitationRequestModel, GetInviteUserList } from "../../../../services/User/User.type";
import toastNotification from "../../../../helper/toastUtils";
import { TableGlobalSearch } from "../../../components/Table/TableGlobalSearch";
import { EventInviteUser, EventUninviteUser } from "../../../../services/Event/Event.type";
import AddUserModal from "../../user/AddUserModal";
import UploadUserModal from "../../user/UploadUserModal";

const EventUserInviteModal = (props: any) => {
  const {
    show,
    onHide,
    eventID
  } = props

  const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
    pageIndex: 0,
  })
  const initEventInvitationRequestModel: EventInvitationRequestModel = {
    eventID: 0,
    invitedUsers: []
  };
  const initEventInvitationModel: EventInvitationModel = {
    id: 0,
    eventID: 0,
    userID: 0,
    name: null,
    deletedBy: 0,
    deletedDate: null,
    emailAddress: null,
    isInvited: false
  }
  const [eventInviteList, setEventInviteList] = useState<EventInvitationRequestModel>(initEventInvitationRequestModel);

  const getUserList = usePostQuery("getUserList", GetInviteUserList);
  const { sort, page, limit, search } = tableState;
  const [selectedUserID, setSelectedUserID] = useState<string>("");

  const [inviteUserList, setInviteUserList] = useState<EventInvitationModel[]>([]);
  const [addUserModalShow, setAddUserModalShow] = useState(false);
  const [modalUploadShow, setModalUploadShow] = useState(false);
  const initSortModel: SortModel = {
    sort: '',
    fields: '',
  }

  const initGridRequestModel: GridRequestModel = {
    page: page ?? 0,
    limit: limit ?? 0,
    search: search ? search : '',
    sort: sort ? sort : initSortModel,
    id: 0,
    eventId: eventID ? eventID : 0,
    eventTypeID: 0,
    paymentDate: null,
    isInvited: false,
    isJoined: false
  }
  const [userList, setUserList] = useState<any[]>([]);
  const [gridSearch, setGridSearch] = useState(initGridRequestModel);
  const [totalInvited, setTotalInvited] = useState<number>(0);
  const [totalParticipants, setTotalParticipants] = useState<number>(0);

  const fetchUserList = () => {
    console.log('gridSearch', gridSearch)
    getUserList.mutate(gridSearch);
  };

  const [totalRecords, setTotalRecords] = useState(0);

  type OptionType = {
    value: string;
    label: string;
  };

  const [options, setOptions] = useState<OptionType[]>([
    {
      value: `All Users(${totalRecords})`,
      label: `All Users(${totalRecords})`,
    },
    { value: `Invited(${totalInvited})`, label: `Invited(${totalInvited})` },
    { value: `Joined Users(${totalParticipants})`, label: `Joined Users(${totalParticipants})` },
    { value: `Not yet Join(${totalInvited - totalParticipants})`, label: `Not yet Join(${totalInvited - totalParticipants})` },
  ]);


  const [value, setValue] = useState<any>();

  useEffect(() => {
    const isInvited = false;
    const isJoined = false;
    setGridSearch({
      id: 0,
      page: page ?? 0,
      limit: limit ?? 0,
      search: search ? search : "",
      sort: sort ? sort : initSortModel,
      eventId: eventID ? eventID : 0,
      eventTypeID: 0,
      paymentDate: null,
      isInvited: isInvited,
      isJoined: isJoined
    });
  }, [page, limit, sort, search, value]);

  useEffect(() => {
    fetchUserList();
  }, [gridSearch]);

  useEffect(() => {
    if (getUserList.isSuccess) {

      const response: any = getUserList?.data?.result;
      if (response) {
        const invitedUsers: [] = response.result.filter(
          (user) => user.isInvited
        );
        const participants: [] = response.result.filter(
          (user) => user.isJoined
        );
        const notJoined: [] = response.result.filter(
          (user) => (!user.isJoined && user.isInvited)
        );
        setTotalRecords(response.count);
        setTotalInvited(response.totalInvited);

        setTotalParticipants(response?.totalParticipants);
        if (value && value.startsWith("Invited")) {
          setUserList([...invitedUsers]);
        } else if (value && value.startsWith("Joined")) {
          setUserList([...participants]);
        } else if (value && value.startsWith("Not")) {
          setUserList([...notJoined]);
        }
        else {
          setUserList([...response?.result]);
        }
      }

    }
  }, [getUserList.isSuccess, value]);

  useEffect(() => {
    // Dynamically update options based on the latest totals
    setOptions([
      {
        value: `All Users (${totalRecords})`,
        label: `All Users (${totalRecords})`,
      },
      {
        value: `Invited (${totalInvited})`,
        label: `Invited (${totalInvited})`,
      },
      {
        value: `Joined Users (${totalParticipants})`,
        label: `Joined Users (${totalParticipants})`,
      },
      {
        value: `Not yet Join (${totalInvited - totalParticipants})`,
        label: `Not yet Join (${totalInvited - totalParticipants})`,
      },
    ]);
  }, [totalInvited, totalRecords, totalParticipants]);
  const toggleAll = (e: any, type: string, id: number | null) => {
    if (type === "All") {
      debugger
      setInviteUserList([]);
      if (e.target.checked) {
        const getAllInvited = userList.map((e: any) => {
          return {
            userID: e.id,
            name: e.firstName + " " + e.surname,
            emailAddress: e.email,
            eventID: eventID,
            isInvited: e.isInvited,
            id: 0,
            deletedDate: null,
            deletedBy: 0
          };
        });
        setInviteUserList([...getAllInvited]);
      }
    } else {
      if (e.target.checked) {

        const getInviteUser: any = userList.filter(
          (x: any) => x.id.toString() == id?.toString()
        )[0];

        setSelectedUserID(getInviteUser.id)
        inviteUserList.push({
          userID: getInviteUser.id,
          name: getInviteUser.firstName + " " + getInviteUser.surname,
          emailAddress: getInviteUser.email,
          eventID: eventID,
          id: 0,
          deletedDate: null,
          deletedBy: 0,
          isInvited: getInviteUser.isInvited
        });
        setInviteUserList([...inviteUserList]);


      } else {
        const removeInvitedUser = inviteUserList.filter(
          (x: any) => x.userID != id
        );
        setInviteUserList([...removeInvitedUser]);
      }
    }
  };



  useEffect(() => {
    initEventInvitationRequestModel.eventID = eventID;
    initEventInvitationRequestModel.invitedUsers = inviteUserList;
    setEventInviteList(initEventInvitationRequestModel);

  }, [inviteUserList]);

  const eventInvite = usePostQuery("eventInvite", EventInviteUser);

  const InviteUserHandler = (person: any) => {

    setSelectedUserID(person.id);
    const fullName = `${person.firstName} ${person.surName}`;

    const inviteUser: EventInvitationModel = {
      id: 0,
      eventID: 0,
      userID: person.id,
      name: `${person?.firstName} ${person?.surname}`,
      emailAddress: person.email ? person.email : "",
      deletedDate: null,
      deletedBy: 0,
      isInvited: false
    };
    const eventInviteModel: EventInvitationRequestModel = {
      eventID: eventID,
      invitedUsers: [inviteUser],
    };
    eventInvite.mutate(eventInviteModel);
  };
  const [isModalClose, setIsModelClose] = useState(false);

  const handleOnclick = () => {

    setIsModelClose(true)
    if (inviteUserList.length > 0) {
      eventInviteList.eventID = eventID;
      eventInvite.mutate(eventInviteList);
      const updatedInviteUserList = inviteUserList.map((user: any) => ({
        ...user,
        isInvited: !user.isInvited,
      }));
      setInviteUserList(updatedInviteUserList);
    }
    setTimeout(() => {
      onHide();
    }, 1000)
  };
  useEffect(() => {
    if (eventInvite.isSuccess) {
      fetchUserList();
      setSelectedUserID("");
      if (isModalClose) {
        setIsModelClose(false)
        onHide();
      }
      toastNotification("success", "Invitation sent!");
    }
  }, [eventInvite.isSuccess]);

  useEffect(() => {
    if (eventInvite.isError) {
      toastNotification(
        "error",
        eventInvite?.error?.response?.data?.responseException?.exceptionMessage
      );
    }
  }, [eventInvite?.isError])

  const eventUserUnInvite = usePostQuery("eventUserUnInvite", EventUninviteUser);

  const handleUnInviteUser = (user: any) => {
    setSelectedUserID(user.id);
    const UnInviteUser: EventInvitationModel = {
      id: 0,
      eventID: eventID,
      userID: user.id,
      name: `${user?.firstName} ${user?.surname}`,
      emailAddress: user.email ? user.email : "",
      deletedDate: null,
      deletedBy: 0,
      isInvited: false
    };

    eventUserUnInvite.mutate(UnInviteUser);
  };

  useEffect(() => {
    if (eventUserUnInvite.isSuccess) {
      toastNotification("success", "Invitation canceled");
      // if (userList !== undefined) setUserList(!userList);
      fetchUserList();
      setSelectedUserID("");
    }
  }, [eventUserUnInvite.isSuccess]);

  const someChecked =
    inviteUserList.length > 0 && inviteUserList.length < userList.length;

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <>
            <input
              className="h-4 w-4 cursor-pointer"
              type="checkbox"
              defaultChecked={
                userList.length > 0 && inviteUserList.length === userList.length
              }
              onChange={(e) => toggleAll(e, "All", null)}
              ref={(input) => {
                if (input) {
                  input.indeterminate = someChecked;
                }
              }}
              disabled={userList.length == 0}
            />
          </>
        ),
        id: "id",
        // accessor: "id",
        className: "text-primary-60 pt-2.5 inline-flex w-8",
        Cell: ({ row }: { row: Row<any> }) => {
          const isChecked =
            inviteUserList.filter((x: any) => x.userID == row?.original?.id)
              .length > 0;
          return (
            <input
              className="h-4 w-4 cursor-pointer"
              type="checkbox"
              defaultChecked={isChecked}
              onChange={(e: any) => {
                toggleAll(e, "Manually", row?.original?.id);
              }}
            />
          );
        },
      },
      {
        disableSortBy: true,
        Header: "Name",
        accessor: "name",
        className:
          "inline-flex w-[40%] pt-2.5 inline-flex md:w-2/5 max-sm:w-2/5 font-semibold text-xs leading-3  text-primary",
        Cell: ({ row }: { row: Row<any> }) => {
          return (
            <div className="flex flex-col gap-px mt-1 mb-1">
              <div className="font-normal text-base mb-1.5 leading-[17.6px]">
                {row?.original?.firstName} {row?.original?.surname}
              </div>
            </div>
          );
        },
      },

      {
        Header: "Email",
        accessor: "email",
        disableSortBy: true,
        className:
          "inline-flex w-[40%] pt-2.5 pl-8 inline-flex md:w-1/5 max-sm:w-1/5 font-semibold text-xs leading-3  text-primary",
        Cell: ({ row }: { row: Row<any> }) => {
          return (
            <div
              className="text-primary-70 md:w-2/5 max-sm:w-2/5 max-lg:truncate font-normal text-sm leading-[16.1px]"
              data-tooltip-id="tag-tooltip"
              data-tooltip-html={row?.original?.emailAddress}
            >
              {row?.original?.email}
            </div>
          );
        },
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        disableSortBy: true,
        className:
          "inline-flex w-[40%] pt-2.5 pl-8 inline-flex md:w-1/5 max-sm:w-1/5 font-semibold text-xs leading-3  text-primary",
        Cell: ({ row }: { row: Row<any> }) => {
          return (
            <div
              className="text-primary-70 md:w-2/5 max-sm:w-2/5 max-lg:truncate font-normal text-sm leading-[16.1px]"
              data-tooltip-id="tag-tooltip"
              data-tooltip-html={row?.original?.mobile}
            >
              {row?.original?.mobile}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "isInvited",
        Cell: ({ row }: CellProps<any>) => (
          <div className="w-full flex flex-row-reverse">
            {row.original.isInvited ? (
              <button
                type="button"
                className={`btn btn-link text-primary hover:text-indigo-900 underline ${eventUserUnInvite.isLoading || row?.original?.isJoined ? "disabled disabled-button" : ""}`}

                onClick={() => {
                  handleUnInviteUser(row.original);
                }}
                disabled={eventUserUnInvite.isLoading || row?.original?.isJoined}
              >
                {eventUserUnInvite.isLoading &&
                  selectedUserID == row.original.id ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <span className="text-danger underline">Cancel Invite</span>
                    <span className="sr-only">, {row.original.id}</span>
                  </>
                )}
              </button>
            ) : (
              <button
                type="button"
                className={`btn btn-link text-primary hover:text-indigo-900 underline ${eventUserUnInvite.isLoading || row?.original?.isJoined ? "disabled disabled-button" : ""}`}
                onClick={() => {
                  InviteUserHandler(row.original);
                }}
                disabled={eventInvite.isLoading || row?.original?.isJoined}
              >
                {eventInvite.isLoading &&
                  selectedUserID == row.original.id ? (
                  <div role="status">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    Invite
                    <span className="sr-only">, {row.original.id}</span>
                  </>
                )}
              </button>
            )}
          </div>
        ),
        className: "text-primary-60 mr-3 md:w-1/4 max-sm:w-1/5",
        disableSortBy: true,
      },
      {
        disableSortBy: true,
        Header: "joining status",
        accessor: "isJoined",
        className:
          "inline-flex w-[40%] pt-2.5 inline-flex md:w-2/5 max-sm:w-2/5 font-semibold text-xs leading-3  text-primary",
        Cell: ({ row }: { row: Row<any> }) => {
          return (
            <div className="flex flex-col gap-px mt-1 mb-1">
              <div className="font-normal text-base mb-1.5 leading-[17.6px]">
                {row?.original?.isJoined ? (
                  <><span>
                    Joined
                  </span></>
                ) : (<>
                  <span>
                    Not Joined
                  </span>
                </>)}
              </div>
            </div>
          );
        },
      },
    ],
    [userList, eventInvite, selectedUserID]
  );

  const pageCount =
    totalRecords % gridSearch.limit === 0
      ? totalRecords / gridSearch.limit
      : Math.floor(totalRecords / gridSearch.limit + 1);

  const OpenAddUsermodal = () => {
    setAddUserModalShow(true);
  }

  const OpenUploadUsermodal = () => {
    setModalUploadShow(true);
  }

  return (
    <>
      <Modal show={show}
        onHide={() => {
          setValue("")
          onHide()
        }}
        className={addUserModalShow || modalUploadShow ? "d-none" : ""}
        size="xl" backdrop="static" keyboard={false} centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Invite Users</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <ReactTooltip id="my-tooltip" content="" />
          <Table
            columns={columns}
            data={userList ?? []}
            initialState={tableInitialState}
            pageCount={pageCount}
            onTableStateChange={handleTableStateChange}
          >
            <Fragment>
              <div className="d-flex align-items-center flex-grow-1 px-3">
                <div className="flex-grow-1">
                  <TableGlobalSearch serachtext="Search" pagename="invitemodal" />
                </div>
                <div className="border-0 pt-6">
                  <div className="row">
                    <div className="col-auto">
                      <select
                        className='form-select'
                        data-control='select2'
                        data-hide-search='true'
                        data-placeholder='Select an option'
                        value={value}
                        name='isActive'
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                      >
                        <option key='' value='' disabled>
                          Select
                        </option>
                        {options.map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-auto">
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={(e) => {
                          OpenAddUsermodal();
                        }}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                        Add User
                      </button>
                    </div>
                    <div className="col-auto">
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={(e) => {
                          OpenUploadUsermodal();
                        }}
                      >
                        <KTIcon iconName='file' className='fs-2' />
                        Upload Users
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <KTCardBody className='py-4'>
                <div className='table-responsive'>
                  <TableInner loading={false} entityLabel='Invite User'></TableInner>
                </div>
                <TablePagination pageOptions={[5, 10, 20, 50]} />
                <TableLoader isLoading={false} />
              </KTCardBody>
            </Fragment>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" id="kt_modal_add_customer_cancel" className="btn btn-light me-3"
            onClick={() => {
              setValue("")
              onHide()
            }}
          >
            Cancel
          </button>
          <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary"
            onClick={() => {
              handleOnclick()
            }}
          >
            {eventInvite.isLoading ?
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='false'
              />
              : <span className="indicator-label">Send Invitation</span>
            }
          </button>
        </Modal.Footer>
      </Modal>
      {addUserModalShow &&
        <AddUserModal
          show={addUserModalShow}
          fetchUserList={fetchUserList}
          onHide={() => setAddUserModalShow(false)}
        />
      }
      {
        modalUploadShow &&
        <UploadUserModal
          show={modalUploadShow}
          onHide={() => setModalUploadShow(false)}
          fetchUserList={fetchUserList}
          showToast={toastNotification}
          eventID={eventID}
        />
      }
    </>
  )
}

export default EventUserInviteModal