/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Form as BForm, Spinner } from 'react-bootstrap';
import * as Yup from 'yup'
import clsx from 'clsx'
import { Formik } from 'formik'
import { useAuth } from '../core/Auth'
import { usePostQuery } from '../../../../hooks/reactQueryHelper'
import { Form } from 'react-bootstrap'
import { loginAPI, LoginModel } from '../../../../services/Login/Login.type';
import { useCookies } from 'react-cookie';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email')
    .required('Please enter email'),
  password: Yup.string().required('Please enter password'),
});

const Login: React.FC<{}> = (props: any) => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [isSuccess, setIsSucessLogin] = useState(true);
  const [cookieData, setCookieData] = useCookies();
  const [passwordShown, setPasswordShown] = useState(false);

  const logInContentQuery: any = usePostQuery(
    'login',
    loginAPI,
    {}
  );
  const initialValues: LoginModel = {
    email: cookieData.email || '',
    password: cookieData.password || '',
    rememberPassword: cookieData.rememberPassword || false
  };

  useEffect(() => {
    initialValues.email = cookieData.email ? cookieData.email?.toString() : '';
    initialValues.password = cookieData.password ? cookieData.password?.toString() : '';
    initialValues.rememberPassword = cookieData.rememberPassword == true ? true : false;
  }, []);



  const onSubmit = (values) => {
    setLoading(true);
    const formData = {
      email: values.email,
      password: values.password,
      rememberPassword: values.rememberPassword
    }
    logInContentQuery.mutate(formData)
    setLoading(false);
    if (values.rememberPassword === true) {
      setCookieData('email', values.email);
      setCookieData('password', values.password);
      setCookieData('rememberPassword', 'true');
    } else {
      setCookieData('email', '');
      setCookieData('password', '');
      setCookieData('rememberPassword', 'false');
    }
  }

  useEffect(() => {
    (document.getElementById('email') as HTMLInputElement).value = cookieData.email ? cookieData.email : '';
    (document.getElementById('password') as HTMLInputElement).value = cookieData.password ? cookieData.password : '';
    (document.getElementById('rememberPassword') as HTMLInputElement).checked = cookieData.rememberPassword?.toString() == 'true' ? true : false;
  }, []);

  useEffect(() => {
    setLoading(false);
    setIsSucessLogin(true)
    if (logInContentQuery?.isError) {
      saveAuth(undefined);
      setLoading(false);
      setIsSucessLogin(false);
    }
    else if (logInContentQuery?.data?.statusCode === 200) {
      saveAuth(logInContentQuery?.data?.result);
      setCurrentUser(logInContentQuery?.data?.result?.token);
    }
  }, [logInContentQuery])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }: any) => {
        return (
          <Form
            className="form w-100" noValidate id="kt_sign_in_form" onSubmit={handleSubmit}>
            <div className="text-center mb-11">
              <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
              <div className="text-gray-500 fw-semibold fs-6"></div>
            </div>
            {!isSuccess && (
              <>
                <div className="card card-xl-stretch mb-5 mb-xl-6">
                  <div className="d-flex align-items-center mb-6 bg-light-danger">
                    <span className="bullet bullet-vertical h-40px bg-danger"></span>
                    <div className="flex-grow-1">
                      <div className="text-danger fw-bold fs-6  mx-5">Incorrect email or password.</div>
                    </div>
                  </div>

                </div>
              </>
            )}
            <BForm.Group className='fv-row mb-8' controlId='email'>
              <BForm.Control
                type='email'
                name='email'
                placeholder='Email'
                maxLength={50}
                onChange={handleChange}
                value={values.email}
                className={
                  clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': touched.email && errors.email },
                    {
                      'is-valid': touched.email && !errors.email,
                    }
                  )
                }
              />
              {errors.email && touched.email ? (
                <div className='text-danger'>
                  {errors.email}
                </div>
              ) : null}
            </BForm.Group>
            <BForm.Group className='fv-row mb-7' controlId='password'>
              <BForm.Control
                type={passwordShown ? "text" : "password"}
                name='password'
                placeholder='Password'
                maxLength={10}
                onChange={handleChange}
                value={values.password}
                className={
                  clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': touched.password && errors.password },
                    {
                      'is-valid': touched.password && !errors.password,
                    }
                  )
                }
              />
              <div className="input-group-append">
                <i
                  className={passwordShown ? 'fa fa-eye-slash eyeicon' : "fa fa-eye eyeicon"}
                  aria-hidden="true"
                  onClick={() => {
                    if (passwordShown) { setPasswordShown(false) }
                    else {
                      setPasswordShown(true)
                    }
                  }}></i>
              </div>
              {errors.password && touched.password ? (
                <div className='text-danger'>
                  {errors.password}
                </div>
              ) : null}
            </BForm.Group>
            <BForm.Group className='fv-row mb-3' controlId='rememberPassword'>
              <BForm.Check.Input
                type='checkbox'
                name='rememberPassword'
                onChange={handleChange}
                value={values.rememberPassword}
              />
              <BForm.Check.Label className='ms-2'>
                Remember me
              </BForm.Check.Label>
            </BForm.Group>
            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-6">
              <div></div>
            </div>
            <div className="d-grid mb-10">
              <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
                {logInContentQuery.isLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) :
                  (<span className="indicator-label">Sign In</span>)}
              </button>
            </div>
          </Form>
        )
      }}
    </Formik >

  )
}

export default Login
