import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import AddEditEventPage from "./AddEditEventPage";
import { useParams } from "react-router-dom";

const AddEditEventPageWrapper = (props: any) => {
    const { id } = useParams()
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Events',
            path: '/events',
            isSeparator: true,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: true,
        }
    ];
    return (
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>{id ? 'Edit Event' : 'Add Event'}</PageTitle>
            <AddEditEventPage id={id} />
        </>
    )
}

export default AddEditEventPageWrapper;