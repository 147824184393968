/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useMemo, useState } from "react"
import { TableGlobalSearch } from "../../components/Table/TableGlobalSearch"
import { KTCardBody } from "../../../_metronic/helpers"
import TableInner from "../../components/Table/TableInner"
import { TablePagination } from "../../components/Table/TablePagination"
import { TableLoader } from "../../components/Table/TableLoader"
import { usePostQuery } from "../../../hooks/reactQueryHelper"
import { GetSpecificUser } from "../../../services/Notification/Notification.type"
import { useNavigate } from "react-router-dom"
import useTableState from "../../components/Table/useTableState"
import { GridRequestModel, GridResponseModel, SortModel } from "../../../services/Common/Common.type"
import { CellProps, Column } from "react-table"
import Table from "../../components/Table"

const UserTable = (props) => {
  const { id } = props
  const getUserList: any = usePostQuery("getUserList", GetSpecificUser, {})
  const navigate = useNavigate()
  const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
    pageIndex: 0,
  })
  const initGridResponseModel: GridResponseModel = {
    count: 0,
    currentPage: 0,
    result: [],
    totalPages: 0,
  }

  const [userList, setUserList] = useState<GridResponseModel>(initGridResponseModel)
  const { sort, page, limit, search } = tableState

  const initSortModel: SortModel = {
    sort: 'desc',
    fields: 'createdDate'
  }

  const initGridRequestModel: GridRequestModel = {
    page: page ?? 0,
    limit: limit ?? 0,
    search: search ? search : '',
    sort: sort ? sort : initSortModel,
    id: 0,
    eventId: 0,
    eventTypeID: 0,
    paymentDate: null,
    isInvited: false,
    isJoined: false
  }

  useEffect(() => {
    initGridRequestModel.page = page ?? 0
    initGridRequestModel.limit = limit ?? 0
    initGridRequestModel.id = id
    if (sort) initGridRequestModel.sort = sort
    if (search) initGridRequestModel.search = search
    getUserList.mutate(initGridRequestModel)
  }, [page, limit, sort, search])

  useEffect(() => {
    if (getUserList.data)
      setUserList(getUserList?.data?.result)
  }, [getUserList.data])

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: `Name`,
        accessor: 'userName',
        Cell: (props: CellProps<any>) => {
          const fullName = `${props?.row?.original?.firstName} ${props?.row?.original?.surname}`
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex flex-column'>
                <a
                  className='text-gray-800 text-hover-primary mb-1'
                  href='javascript:void(0)'
                  onClick={() => {
                    const additionalData = {
                      tabName: 'Activity', // Additional data you want to pass
                    }
                    navigate(`/users/detail/` + props?.row?.original?.id, { state: additionalData })
                  }}
                >
                  {fullName ? fullName : '-'}
                </a>
              </div>
            </div>
          )
        },
      },
      {
        Header: `Mobile`,
        accessor: 'mobile',
        Cell: (props: CellProps<any>) => {
          return (
            <span>{`${props?.row?.original?.mobile ? props?.row?.original?.mobile : '-'}`}</span>
          )
        },
      },
      {
        Header: `Email`,
        accessor: 'email',
        Cell: (props: CellProps<any>) => {
          return <span>{props?.row?.original?.email}</span>
        },
      }
    ],
    []
  )

  return (
    <>
      <Table
        columns={columns}
        data={userList?.result ?? []}
        initialState={tableInitialState}
        pageCount={userList?.totalPages}
        onTableStateChange={handleTableStateChange}
      >
        <Fragment>
          <TableGlobalSearch serachtext={'Search'} />
          <KTCardBody className='py-4'>
            <div className='table-responsive'>
              <TableInner loading={false} entityLabel='Notification History'></TableInner>
            </div>
            <TablePagination pageOptions={[5, 10, 20, 50]} />
            <TableLoader isLoading={getUserList?.isLoading} />
          </KTCardBody>
        </Fragment>
      </Table>
    </>
  )
}
export { UserTable }