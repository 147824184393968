/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form as BForm } from 'react-bootstrap'
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { DropdownModel } from '../../../../services/Event/Event.type'
import { statusDropDown } from '../../../../config/constants'
import { useRef } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { EVENT_DEFAULT_IMAGE_PATH, EVENT_DARK_IMAGE_PATH } from '../../../../shared/constants'
import ImageCrop from '../../../components/ImageCrop'
import toastNotification from '../../../../helper/toastUtils'

import { Icon } from '@iconify/react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { themeModeSwitchHelper, useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import moment from 'moment'

const EventGeneralPage = (props: any) => {
  const fileInputWebRef = useRef<any>(null)
  const fileInputMobileRef = useRef<any>(null)
  const {
    uintDropDown,
    handleDropDown,
    distanceDropDown,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    addEditEventModel
  } = props
  const [eventWebBannerImage, setEventWebBannerImage] = useState<any>()
  const [eventMobileBannerImage, setMobileBannerImage] = useState<any>()
  const [areaPixels, setareaPixels] = useState<any>(null)
  const [visible, setVisible] = useState<any>(false)
  const [mobileBannerVisible, setMobileBannerVisible] = useState<any>(false)
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']
  const { mode } = useThemeMode()
  const [selectImage, setSelectedImage] = useState<any>(mode === 'light' ? EVENT_DEFAULT_IMAGE_PATH : EVENT_DARK_IMAGE_PATH)
  const eventTypeDropdown: DropdownModel[] = [
    {
      value: 1,
      label: 'Cycling',
    },
    {
      value: 2,
      label: 'Walking',
    },
    {
      value: 3,
      label: 'Running',
    },
  ]

  const onEventTypeChange = (eventType) => {
    setFieldValue('eventTypeID', eventType)
  }

  const onlyNumberValidate = (evt: any) => {
    var theEvent = evt || window.event
    var maxparticipantslength = evt.target.value.length;
    if (maxparticipantslength > 6)
      theEvent.preventDefault()
  }

  const handlekeypresschanges = (event) => {
    let keySign = event.key;
    if (keySign === "<" || keySign === ">")
      event.preventDefault();
  };

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const fileName = file.name
      const fileExtension = fileName.split('.').pop().toLowerCase()
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader()
        reader.onload = (e) => {
          setEventWebBannerImage(e.target?.result)
        }
        reader.readAsDataURL(e.target.files[0])
        setVisible(true)
      } else {
        toastNotification('warning', 'Please select only image jpg, jpeg, png, gif')
        return
      }
    }
    fileInputWebRef.current.value = null
  }

  const handleMobileBannerImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const fileName = file.name
      const fileExtension = fileName.split('.').pop().toLowerCase()
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader()
        reader.onload = (e) => {
          setMobileBannerImage(e.target?.result)
        }
        reader.readAsDataURL(e.target.files[0])
        setMobileBannerVisible(true)
      } else {
        toastNotification('warning', 'Please select only image jpg, jpeg, png, gif')
        return
      }
    }
    fileInputMobileRef.current.value = null
  }

  const cancelEventWebImage = () => {
    setFieldValue('webBannerImage', null)
    setEventWebBannerImage(null)
  }

  const cancelEventMobileImage = () => {
    setFieldValue('mobileBannerImage', null)
    setMobileBannerImage(null)
  }

  const toggleVisible = React.useCallback(() => {
    setVisible((p) => !p)
  }, [visible])

  const toggleVisible1 = React.useCallback(() => {
    setMobileBannerVisible((p) => !p)
  }, [mobileBannerVisible])


  useEffect(() => {
    themeModeSwitchHelper(mode)
    setSelectedImage(mode === 'light' ? EVENT_DEFAULT_IMAGE_PATH : EVENT_DARK_IMAGE_PATH)
  }, [mode])
  return (
    <>
      <div
        className='tab-pane fade show active'
        id='kt_ecommerce_add_event_general'
        role='tab-panel'
      >
        <div className='row'>
          <h2 style={{ paddingLeft: '40px' }}>{addEditEventModel.id > 0 ? 'Edit Event Information' : 'Add Event Information'}</h2>
        </div>

        <div className='row'>
          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
            <div className='card card-flush'>
              <div className='card-body'>
                <ImageCrop
                  visible={visible}
                  imagesrc={eventWebBannerImage}
                  onCancel={toggleVisible}
                  setareaPixels={setareaPixels}
                  onComplete={(imagePromisse) => {
                    imagePromisse.then((image) => {
                      setFieldValue('webBannerImage', image)
                      setEventWebBannerImage(image)
                      setVisible(false)
                    })
                  }}
                  containerStyle={{
                    position: 'relative',
                    width: '100%',
                    height: 300,
                    background: '#333',
                  }}
                  imageModule='webBannerImage'
                />
                <ImageCrop
                  visible={mobileBannerVisible}
                  imagesrc={eventMobileBannerImage}
                  onCancel={toggleVisible1}
                  setareaPixels={setareaPixels}
                  onComplete={(imagePromisse) => {
                    imagePromisse.then((image) => {
                      setFieldValue('mobileBannerImage', image)
                      setMobileBannerImage(image)
                      setMobileBannerVisible(false)
                    })
                  }}
                  containerStyle={{
                    position: 'relative',
                    width: '100%',
                    height: 300,
                    background: '#333',
                  }}
                  imageModule='mobileBanner'
                />
                <form>
                  <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <BForm.Group className='fv-row'>
                        <BForm.Label className='required form-label'>Event Name</BForm.Label>
                        <BForm.Control
                          type='text'
                          name='name'
                          className={clsx(
                            'form-control',
                            { 'is-invalid': touched.name && errors.name },
                            {
                              'is-valid': touched.name && !errors.name,
                            }
                          )}
                          placeholder='Event name'
                          onChange={handleChange}
                          onBlur={(e) => {
                            setFieldValue('name', e.target.value)
                          }}
                          defaultValue={values.name}
                          maxLength={50}
                        ></BForm.Control>
                        {errors.name && touched.name ? (
                          <div className='text-danger fs-7'>{errors.name}</div>
                        ) : null}
                      </BForm.Group>
                    </div>
                    <div className='col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'>
                      <BForm.Group className='fv-row'>
                        <BForm.Label className='form-label'>
                          Is fixed Participant &nbsp;
                          <span data-tooltip-id='my-tooltip' data-tooltip-content='Event is free'>
                            <i className='bi bi-info-circle-fill'></i>
                          </span>
                        </BForm.Label>
                        <BForm.Group className='form-check form-check-custom form-check-solid mb-2'>
                          <BForm.Control
                            className='form-check-input'
                            id='isFixedParticpated'
                            name='isFixedParticpated'
                            type='checkbox'
                            checked={values.isFixedParticpated}
                            onChange={(e) => {
                              setFieldValue('isFixedParticpated', e.target['checked']);
                              setFieldValue('maxParticipants', 0)
                            }}
                          />
                          <BForm.Label className='form-check-label' htmlFor='isFixedParticpated'>
                            Yes
                          </BForm.Label>
                        </BForm.Group>
                        <div className='text-muted fs-7'></div>
                      </BForm.Group>
                    </div>
                    {values.isFixedParticpated && <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                      <BForm.Group className='fv-row'>
                        <BForm.Label
                          className='form-label required'
                          htmlFor='kt_ecommerce_add_event_store_template'
                        >
                          Total Participants
                        </BForm.Label>
                        <BForm.Control
                          type='number'
                          name='maxParticipants'
                          className={clsx(
                            'form-control',
                            { 'is-invalid': touched.maxParticipants && errors.maxParticipants },
                            {
                              'is-valid': touched.maxParticipants && !errors.maxParticipants,
                            }
                          )}
                          placeholder='Total Participants'
                          onChange={handleChange}
                          onKeyPress={onlyNumberValidate}
                          onBlur={(e) => {
                            setFieldValue('maxParticipants', e.target.value)
                          }}
                          value={values.maxParticipants}
                        ></BForm.Control>
                        {touched.maxParticipants && errors.maxParticipants ? (
                          <div className='text-danger fs-7'>{errors.maxParticipants}</div>
                        ) : null}
                      </BForm.Group>
                    </div>}

                  </div>
                  <div className='row mt-2'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <div className='row mt-2'>
                        <BForm.Group className='fv-row col-sm-6 col-md-6'>
                          <BForm.Label className='form-label'>
                            Is Event Private &nbsp;
                            <span data-tooltip-id='my-tooltip' data-tooltip-content='Only invited user will participate in this private event'>
                              <i className='bi bi-info-circle-fill'></i>
                            </span>
                          </BForm.Label>
                          <BForm.Group className='form-check form-check-custom form-check-solid'>
                            <BForm.Control
                              className='form-check-input'
                              id='chkIsPrivate'
                              name='isPrivate'
                              type='checkbox'
                              checked={values.isPrivate}
                              onChange={handleChange}
                            />
                            <BForm.Label className='form-check-label' htmlFor='chkIsPrivate'>
                              Yes
                            </BForm.Label>
                          </BForm.Group>
                          <div className='text-muted fs-7'></div>
                        </BForm.Group>
                        <BForm.Group className='fv-row col-sm-6 col-md-6'>
                          <BForm.Label className='required form-label'>Event Type</BForm.Label>
                          <select
                            className='form-select mb-2'
                            data-control='select2'
                            data-hide-search='true'
                            data-placeholder='Select an option'
                            value={values.eventTypeID ?? ''}
                            name='eventTypeID'
                            onChange={(e) => onEventTypeChange(e.target.value)}
                          >
                            <option key='' value='' disabled>
                              Select
                            </option>
                            {eventTypeDropdown.map((x) => (
                              <option key={x.value} value={x.value}>
                                {x.label}
                              </option>
                            ))}
                          </select>
                        </BForm.Group>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <BForm.Group className='fv-row mt-2' controlId='isActive'>
                        <BForm.Label>Status</BForm.Label>
                        <select
                          className='form-select mb-2'
                          data-control='select2'
                          data-hide-search='true'
                          data-placeholder='Select an option'
                          value={values.selectedStatus.value}
                          name='isActive'
                          onChange={(e) => {
                            values.selectedStatus.value = e.target.value
                            if (values.selectedStatus.value === '1') {
                              setFieldValue('isActive', true)
                            } else {
                              setFieldValue('isActive', false)
                            }
                          }}
                        >
                          <option key='' value='' disabled>
                            Select
                          </option>
                          {statusDropDown.map((x) => (
                            <option key={x.value} value={x.value}>
                              {x.label}
                            </option>
                          ))}
                        </select>
                      </BForm.Group>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <BForm.Group className='fv-row' controlId='startDate'>
                        <BForm.Label className='required form-label'>Event Start Date</BForm.Label>
                        <BForm.Control
                          type='datetime-local'
                          name='startDate'
                          placeholder='startDate'
                          onChange={handleChange}
                          onBlur={(e) => {
                            setFieldValue('startDate', e.target.value)
                          }}
                          min={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
                          value={values.startDate}
                          className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': touched.startDate && errors.startDate },
                            {
                              'is-valid': touched.startDate && !errors.startDate,
                            }
                          )}
                        />
                        {errors.startDate && touched.startDate ? (
                          <div className='text-danger'>{errors.startDate}</div>
                        ) : null}
                      </BForm.Group>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <BForm.Group className='fv-row' controlId='endDate'>
                        <BForm.Label className='required form-label'>Event End Date</BForm.Label>
                        <BForm.Control
                          type='datetime-local'
                          name='endDate'
                          placeholder='endDate'
                          onChange={handleChange}
                          onBlur={(e) => {
                            setFieldValue('endDate', e.target.value)
                          }}
                          min={values.startDate}
                          value={values.endDate}
                          className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': touched.endDate && errors.endDate },
                            {
                              'is-valid': touched.endDate && !errors.endDate,
                            }
                          )}
                        />
                        {errors.endDate && touched.endDate ? (
                          <div className='text-danger'>{errors.endDate}</div>
                        ) : null}
                      </BForm.Group>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <ReactTooltip id='my-tooltip' content='' />
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-2 col-12'>
                      <BForm.Group className='fv-row' controlId='registrationClosingDate'>
                        <BForm.Label className='form-label'>
                          Registration Closing Date
                          <span
                            data-tooltip-id='my-tooltip'
                            data-tooltip-content='Select the date when registration for this event closes'
                          >
                            <i className='bi bi-info-circle-fill ms-2 '></i>
                          </span>
                        </BForm.Label>
                        <BForm.Control
                          type='date'
                          name='registrationClosingDate'
                          placeholder='RegistrationClosingDate'
                          onChange={handleChange}
                          onBlur={(e) => {
                            setFieldValue('registrationClosingDate', e.target.value)
                          }}
                          max={values.endDate}
                          value={values.registrationClosingDate}
                          className={clsx(
                            'form-control bg-transparent mb-2',
                            { 'is-invalid': touched.registrationClosingDate && errors.registrationClosingDate },
                            {
                              'is-valid': touched.registrationClosingDate && !errors.registrationClosingDate,
                            }
                          )}
                        />
                        {errors.registrationClosingDate && touched.registrationClosingDate ? (
                          <div className='text-danger'>{errors.registrationClosingDate}</div>
                        ) : null}
                      </BForm.Group>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-2 col-12'>
                      <BForm.Group className='fv-row'>
                        <BForm.Label className='form-label'>Location</BForm.Label>
                        <BForm.Control
                          type='text'
                          name='location'
                          placeholder='Location'
                          min={0}
                          className='mb-2'
                          value={values.location}
                          onChange={handleChange}
                          onBlur={(e) => {
                            setFieldValue('location', e.target.value)
                          }}
                        />
                      </BForm.Group>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-2 col-12'>
                      <div className='row pt-3'>
                        <BForm.Group className='fv-row col-sm-5 col-md-5'>
                          <BForm.Label className='form-label'>
                            Is goodies & Gift included &nbsp;
                            <span data-tooltip-id='my-tooltip' data-tooltip-content='The inclusion of goodies and gifts will be displayed to users upon their enrollment in this event.'>
                              <i className='bi bi-info-circle-fill'></i>
                            </span>
                          </BForm.Label>
                          <BForm.Group className='form-check form-check-custom form-check-solid mb-2'>
                            <BForm.Control
                              className='form-check-input'
                              id='chkIsGift'
                              name='isGift'
                              type='checkbox'
                              checked={values.isGift}
                              onChange={handleChange}
                            />
                            <BForm.Label className='form-check-label' htmlFor='chkIsGift'>
                              Yes
                            </BForm.Label>
                          </BForm.Group>
                          <div className='text-muted fs-7'></div>
                        </BForm.Group>
                        <BForm.Group className='fv-row col-sm-4 col-md-4'>
                          <BForm.Label className='form-label'>
                            Is event free &nbsp;
                            <span data-tooltip-id='my-tooltip' data-tooltip-content='Event is free'>
                              <i className='bi bi-info-circle-fill'></i>
                            </span>
                          </BForm.Label>
                          <BForm.Group className='form-check form-check-custom form-check-solid mb-2'>
                            <BForm.Control
                              className='form-check-input'
                              id='chkIsFree'
                              name='isFree'
                              type='checkbox'
                              checked={values.isFree}
                              onChange={(e) => {
                                setFieldValue('isFree', e.target['checked']);
                              }}
                            />
                            <BForm.Label className='form-check-label' htmlFor='chkIsFree'>
                              Yes
                            </BForm.Label>
                          </BForm.Group>
                          <div className='text-muted fs-7'></div>
                        </BForm.Group>
                        <BForm.Group className='fv-row col-sm-3 col-md-3'>
                          <BForm.Label className='form-label'>
                            Is challenge &nbsp;
                            <span data-tooltip-id='my-tooltip' data-tooltip-content='Multi-day event'>
                              <i className='bi bi-info-circle-fill'></i>
                            </span>
                          </BForm.Label>
                          <BForm.Group className='form-check form-check-custom form-check-solid mb-2'>
                            <BForm.Control
                              className='form-check-input'
                              id='chkIsChallenge'
                              name='isChallenge'
                              type='checkbox'
                              checked={values.isChallenge}
                              onChange={(e) => {
                                setFieldValue('isChallenge', e.target['checked']);

                                if (e.target['checked']) {
                                  setFieldValue('totalDistance', 0);
                                  setFieldValue('unit', 'km');
                                  setFieldValue('isFixed', false);
                                }
                                else {
                                  setFieldValue('totalDistance', distanceDropDown[0].value);
                                  setFieldValue('unit', 'km');
                                }
                              }}
                            />
                            <BForm.Label className='form-check-label' htmlFor='chkIsChallenge'>
                              Yes
                            </BForm.Label>
                          </BForm.Group>
                          <div className='text-muted fs-7'></div>
                        </BForm.Group>
                      </div>
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-2 col-12'>
                      {!values.isFree && (
                        <BForm.Group className='fv-row'>
                          <BForm.Label className='required form-label'>Joining Fee</BForm.Label>
                          <BForm.Control
                            type='number'
                            name='joiningFees'
                            placeholder='Joining fee'
                            min={1}
                            value={values.joiningFees}
                            onKeyPress={onlyNumberValidate}
                            onChange={handleChange}
                            onBlur={(e) => {
                              setFieldValue('joiningFees', e.target.value)
                            }}
                            className={clsx(
                              'form-control',
                              { 'is-invalid': touched.joiningFees && errors.joiningFees },
                              {
                                'is-valid': touched.joiningFees && !errors.joiningFees,
                              }
                            )}
                          />
                          {errors.joiningFees && touched.joiningFees ? (
                            <div className='text-danger fs-7'>{errors.joiningFees}</div>
                          ) : null}
                        </BForm.Group>
                      )}
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12' >
                      {!values.isChallenge && (
                        <BForm.Group className='fv-row'>
                          <BForm.Label className='required form-label'>Distance</BForm.Label>
                          <BForm.Select
                            className='form-select mb-2'
                            data-control='select2'
                            data-hide-search='true'
                            data-placeholder='Select an option'
                            onChange={(e) => {
                              handleDropDown(e, 'FixedDistance')
                              const selectedValue = e.target.value
                              setFieldValue('selectedFixedDistance', distanceDropDown.filter((x) => x.value == selectedValue)[0])
                              if (selectedValue === 'other') {
                                setFieldValue('totalDistance', 0)
                                setFieldValue('unit', 'm')
                                setFieldValue('isFixed', false)
                              }
                              else {
                                setFieldValue('totalDistance', e.target.value)
                                setFieldValue('unit', 'km')
                                setFieldValue('isFixed', true)
                              }
                            }}
                            id='selectedFixedDistance'
                            name='selectedFixedDistance'
                          >
                            {distanceDropDown.map((x) => (
                              <option
                                value={x.value}
                                selected={x.value === values.selectedFixedDistance?.value}
                              >
                                {x.label}
                              </option>
                            ))}
                          </BForm.Select>
                        </BForm.Group>
                      )}
                    </div>
                    {!addEditEventModel.isFixed && !values.isChallenge && (<>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6' >
                        <BForm.Group className='fv-row'>
                          <BForm.Label className='required form-label'>Unit</BForm.Label>
                          <BForm.Select
                            className='form-select mb-2'
                            data-control='select2'
                            data-hide-search='true'
                            data-placeholder='Select an option'
                            onChange={(e) => {
                              handleDropDown(e, 'Unit')
                              handleChange(e)
                            }}
                            id='kt_ecommerce_add_event_store_template'
                          >
                            {uintDropDown.map((x) => (
                              <option
                                value={x.value}
                                selected={x.value === values.selectedUnit?.value}
                              >
                                {x.label}
                              </option>
                            ))}
                          </BForm.Select>
                        </BForm.Group>
                      </div>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                        <BForm.Group className='fv-row'>
                          <BForm.Label className='required form-label'>Distance</BForm.Label>
                          <BForm.Control
                            type='number'
                            name='totalDistance'
                            className={clsx(
                              'form-control',
                              { 'is-invalid': touched.totalDistance && errors.totalDistance },
                              {
                                'is-valid': touched.totalDistance && !errors.totalDistance,
                              }
                            )}
                            placeholder='Distance'
                            min={1}
                            value={values.totalDistance}
                            onChange={handleChange}
                            onBlur={(e) => {
                              setFieldValue('totalDistance', values.isChallenge == true ? 0 : e.target.value)
                            }}
                          />
                          {errors.totalDistance && touched.totalDistance ? (
                            <div className='text-danger fs-7'>{errors.totalDistance}</div>
                          ) : null}
                        </BForm.Group>
                      </div>
                    </>)}
                  </div>
                  <div className='row mt-2'>
                    <div className='mb-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                      <BForm.Group>
                        <BForm.Label className='form-label'>Rewards</BForm.Label>
                        <BForm.Control
                          as='textarea'
                          className='form-control'
                          name='rewards'
                          placeholder='Rewards'
                          defaultValue={values.rewards}
                          onChange={handleChange}
                          onBlur={(e) => {
                            setFieldValue('rewards', e.target.value)
                          }}
                          onKeyPress={handlekeypresschanges}
                        />
                        <div className='text-muted fs-7'></div>
                      </BForm.Group>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col-md-6'>
                      <div>
                        <label className='form-label form-label required'>
                          Event web banner image
                        </label>
                        <span data-tooltip-id='my-tooltip' data-tooltip-content='Recommended to upload an image in a 3:2 aspect ratio (e.g., 450 x 300, 900 x 600 pixels)' className='ps-2'>
                          <i className='bi bi-info-circle-fill'></i>
                        </span>
                      </div>
                      <div className='img-content image-input image-input-empty image-input-outline image-input-placeholder border border-gray-300'>
                        <a href="javascript:void(0)">
                          <div className="img-content-overlay" onClick={() => fileInputWebRef.current?.click()}></div>
                          <div className="img-content-details fadeIn-bottom fadeIn-left">
                            <label
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px shadow webBannerEditIcon'
                              data-kt-image-input-action='change'
                              data-bs-toggle='tooltip'
                              title='Change avatar'
                            >
                              <span className='text-muted'>
                                <Icon icon="uil:image-upload" width="50" height="50" />
                              </span>
                              <input
                                type='file'
                                ref={fileInputWebRef}
                                style={{ display: 'none' }}
                                accept='image/png, image/jpeg, image/jpg'
                                onChange={handleImageChange}
                              />
                              <input type='hidden' name='avatar_remove' />


                            </label>
                          </div>
                          {values.webBannerImage && (
                            <span
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow webBannerRemoveIcon'
                              onClick={cancelEventWebImage}
                              data-bs-toggle='tooltip'
                              title='Cancel avatar'
                            >
                              <span className='svg-icon svg-icon-3'>
                                <Icon icon='ion:close' />
                              </span>
                            </span>
                          )}
                          {values.webBannerImage && (
                            <img
                              alt='not found'
                              src={values.webBannerImage}
                              className='image-input-wrapper w-auto h-200px img-fluid'
                            />
                          )}
                          {(values.webBannerImage === null ||
                            values.webBannerImage === '' ||
                            values.webBannerImage === undefined) && (

                              <img
                                alt='not found'
                                src={toAbsoluteUrl(mode === 'light' ? EVENT_DEFAULT_IMAGE_PATH : EVENT_DARK_IMAGE_PATH)}
                                className='img-content-image image-input-wrapper w-auto h-200px is-valid bannerImage1 img-fluid'
                                style={{
                                  border:
                                    errors.webBannerImage && touched.webBannerImage
                                      ? '1px solid #f1416c'
                                      : '',
                                }}
                              />
                            )}
                        </a>
                      </div>
                      {errors.webBannerImage && touched.webBannerImage ? (
                        <div className='text-danger fs-7'>{errors.webBannerImage}</div>
                      ) : null}
                    </div>
                    <div className='col-md-6'>
                      <div>
                        <label className='form-label form-label required'>
                          Event mobile banner image
                        </label>
                        <span data-tooltip-id='my-tooltip' data-tooltip-content='Recommended to upload an image in a 16:9 aspect ratio (e.g., 1024 x 576, 1280 x 720 pixels)' className='ps-2'>
                          <i className='bi bi-info-circle-fill'></i>
                        </span>
                      </div>
                      <div className='img-content image-input image-input-empty image-input-outline image-input-placeholder  border border-gray-300'>
                        <a href="javascript:void(0)">
                          <div className="img-content-overlay" onClick={() => fileInputMobileRef.current?.click()} ></div>
                          <div className="img-content-details fadeIn-bottom fadeIn-left">
                            <label
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px shadow mobileBannerEditIcon'
                              data-kt-image-input-action='change'
                              data-bs-toggle='tooltip'
                              title='Change avatar'
                            >
                              <span className='text-muted'>
                                <Icon icon="uil:image-upload" width="50" height="50" />
                              </span>
                              <input
                                type='file'
                                ref={fileInputMobileRef}
                                style={{ display: 'none' }}
                                accept='image/png, image/jpeg, image/jpg'
                                onChange={handleMobileBannerImageChange}
                              />
                              <input type='hidden' name='avatar_remove' />
                            </label>
                          </div>
                          {values.mobileBannerImage && (
                            <span
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow mobileBannerRemoveIcon'
                              onClick={cancelEventMobileImage}
                              data-bs-toggle='tooltip'
                              title='Cancel avatar'
                            >
                              <span className='svg-icon svg-icon-3'>
                                <Icon icon='ion:close' />
                              </span>
                            </span>
                          )}
                          {values.mobileBannerImage && (
                            <img
                              alt='not found'
                              width={'300px'}
                              src={values.mobileBannerImage}
                              className='image-input-wrapper w-auto h-200px img-fluid'
                            />
                          )}
                          {(values.mobileBannerImage === null ||
                            values.mobileBannerImage === '' ||
                            values.mobileBannerImage === undefined) && (
                              <img
                                alt='not found'
                                width={'300px'}
                                src={toAbsoluteUrl(mode === 'light' ? EVENT_DEFAULT_IMAGE_PATH : EVENT_DARK_IMAGE_PATH)}
                                className='image-input-wrapper w-auto h-200px img-fluid'
                                style={{
                                  border:
                                    errors.mobileBannerImage && touched.mobileBannerImage
                                      ? '1px solid #f1416c'
                                      : '',
                                }}
                              />
                            )}
                        </a>
                      </div>
                      {errors.mobileBannerImage && touched.mobileBannerImage ? (
                        <div className='text-danger fs-7'>{errors.mobileBannerImage}</div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default EventGeneralPage
