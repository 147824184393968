import { useEffect, useMemo, useState, Fragment } from 'react'
import useTableState from '../../components/Table/useTableState'
import { GridRequestModel, GridResponseModel, SortModel } from '../../../services/Common/Common.type';
import { usePostQuery } from '../../../hooks/reactQueryHelper';
import { GetContactInquiryList } from '../../../services/User/User.type';
import { CellProps, Column } from 'react-table';
import Table from '../../components/Table';
import { TableGlobalSearch } from '../../components/Table/TableGlobalSearch';
import { KTCardBody } from '../../../_metronic/helpers';
import TableInner from '../../components/Table/TableInner';
import { TablePagination } from '../../components/Table/TablePagination';
import { TableLoader } from '../../components/Table/TableLoader';
import { applyUserSpecificDateTimeFormat } from '../../../helper/utility';
import moment from 'moment';
import { Button, Form, Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import SetInnerHTML from '../../components/SetInnerHTML/SetInnerHTML';

const ContactInquiryTable = (props: any) => {
    const getContactInquiryQuery: any = usePostQuery('getContactInquiryList', GetContactInquiryList);
    const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
        pageIndex: 0,
    })
    const [discriptionShow, setDiscriptionShow] = useState(false);
    const [discription, setDiscription] = useState()

    const initGridResponseModel: GridResponseModel = {
        count: 0,
        currentPage: 0,
        result: [],
        totalPages: 0
    }

    const [contactInquiryList, setContactInquiryList] = useState<GridResponseModel>(initGridResponseModel)
    const { sort, page, limit, search } = tableState
    const initSortModel: SortModel = {
        sort: 'desc',
        fields: 'createdDate',
    }

    const initGridRequestModel: GridRequestModel = {
        page: page ?? 0,
        limit: limit ?? 0,
        search: search ? search : '',
        sort: sort ? sort : initSortModel,
        id: 0,
        eventId: 0,
        eventTypeID: 0,
        paymentDate: null,
        isInvited: false,
        isJoined: false
    }

    useEffect(() => {
        initGridRequestModel.page = page ?? 0
        initGridRequestModel.limit = limit ?? 0
        if (sort) initGridRequestModel.sort = sort
        if (search) initGridRequestModel.search = search
        getContactInquiryQuery.mutate(initGridRequestModel)
    }, [page, limit, search, sort])

    useEffect(() => {
        if (getContactInquiryQuery.data)
            setContactInquiryList(getContactInquiryQuery?.data?.result)
    }, [getContactInquiryQuery.data, getContactInquiryQuery.iserror, getContactInquiryQuery.isSuccess])

    const handleShowModal = (data) => {
        setDiscription(data.discription)
        setDiscriptionShow(true);
    };

    const handleClose = () => setDiscriptionShow(false);

    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: `Name`,
                accessor: 'name',
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (props: CellProps<any>) => {
                    return <span>{props?.row?.original?.name ? props?.row?.original?.name : '-'}</span>
                },
            },
            {
                Header: `Email`,
                accessor: 'email',
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (props: CellProps<any>) => {
                    return <span>{props?.row?.original?.email ? props?.row?.original?.email : '-'}</span>
                },
            },
            {
                Header: `Message`,
                accessor: 'discription',
                disableSortBy: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (props: CellProps<any>) => {
                    return (
                        <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column'>
                                <a href="javascript:void(0)" className="btn btn-sm btn-primary" data-tooltip-id="tooltip" data-tooltip-content="View Discription" onClick={() => handleShowModal(props?.row?.original)}>View</a>
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: `Created Date`,
                accessor: 'createdDate',
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (props: CellProps<any>) => {
                    return (
                        <span>
                            {props?.row?.original?.createdDate
                                ? moment(props?.row?.original?.createdDate).format(applyUserSpecificDateTimeFormat())
                                : '-'}
                        </span>)
                },
            }
        ]
        , [])

    return (
        <>
            <Tooltip id='tooltip' content="" />
            <Table
                columns={columns}
                data={contactInquiryList?.result ?? []}
                initialState={tableInitialState}
                pageCount={contactInquiryList?.totalPages ?? []}
                onTableStateChange={handleTableStateChange}
            >
                <Fragment>
                    <TableGlobalSearch />
                    <KTCardBody className='py-4'>
                        <div className='table-responsive'>
                            <TableInner loading={false} entityLabel='Contact Inquiry'></TableInner>
                        </div>
                        <TablePagination />
                        <TableLoader />
                    </KTCardBody>
                </Fragment>
            </Table>

            <Modal show={discriptionShow} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Message</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="rounded">
                        <div className="modal-body scroll-y py-0">
                            <div className="row" >
                                <div className="col-md-12 mb-2">
                                    <div className="row">
                                        <label className="col-md-12 form-label">Message :</label>
                                        <div className="border col-md-12 py-3">
                                            <SetInnerHTML inneHtml={discription ?? '-'} className='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ContactInquiryTable
