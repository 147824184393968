import { jwtDecode }  from 'jwt-decode';

function useJwt() {
  const isTokenValid = () => {
    try {
      const localStorageData: any = localStorage.getItem('fitevent-auth');
      const token: string = localStorageData
        ? JSON.parse(localStorageData).token
        : null;
      if (token) {
        const jwtDecodedToken:any =  jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds

        return jwtDecodedToken.exp > currentTime;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return { isTokenValid };
}

export default useJwt;