import moment from 'moment';
import { applyUserSpecificDateFormat } from '../../../../helper/utility';
import Skeleton from '@material-ui/lab/Skeleton';
const UserOverviewPage = (Props: any) => {
    const { userDetail, getUserQuery } = Props;
    return (
        <>
            <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                <div className="card-body p-9">
                    <div className="row mb-7">
                        <label className="col-lg-2 fw-semibold text-muted">Full Name</label>
                        <div className="col-lg-10">
                            {getUserQuery.isLoading ? (
                                // Render a loading Skeleton for the full name
                                <Skeleton variant="text" width={150} />
                            ) : (
                                // Render the actual full name when not loading
                                <div>
                                    <span className="fw-bold fs-6 text-gray-800">{userDetail?.firstName ? userDetail?.firstName : '-'} {userDetail?.surname ? userDetail?.surname : '-'}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-2 fw-semibold text-muted">Email</label>
                        <div className="col-lg-10 fv-row">
                            {getUserQuery.isLoading ? (
                                // Render a loading Skeleton for the Email
                                <Skeleton variant="text" width={150} />
                            ) : (
                                // Render the actual Email when not loading
                                <span className="fw-semibold text-gray-800 fs-6">{userDetail?.email ? userDetail?.email : '-'} </span>
                            )}
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-2 fw-semibold text-muted">
                            Mobile
                        </label>
                        <div className="col-lg-10 d-flex align-items-center">
                            {getUserQuery.isLoading ? (
                                // Render a loading Skeleton for the mobile
                                <Skeleton variant="text" width={150} />
                            ) : (
                                // Render the actual mobile when not loading
                                <div>
                                    <span className="fw-bold fs-6 text-gray-800 me-2">{userDetail?.mobile ? userDetail?.mobile : '-'}</span>
                                </div>

                            )}
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-2 fw-semibold text-muted">Date Of Birth</label>
                        <div className="col-lg-10">
                            {getUserQuery.isLoading ? (
                                // Render a loading Skeleton for the Date Of Birth
                                <Skeleton variant="text" width={150} />
                            ) : (
                                // Render the actual Date Of Birth when not loading
                                <span className="fw-bold fs-6 text-gray-800 "> {userDetail?.dob ? moment(userDetail?.dob).format(applyUserSpecificDateFormat()) : '-'} </span>
                            )}
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-2 fw-semibold text-muted">
                            Height
                        </label>
                        <div className="col-lg-10">
                            {getUserQuery.isLoading ? (
                                // Render a loading Skeleton for the height
                                <Skeleton variant="text" width={150} />
                            ) : (
                                // Render the actual height when not loading
                                <span className="fw-bold fs-6 text-gray-800">{userDetail?.height === null || userDetail?.height === undefined || userDetail?.height === '' ? '-' : userDetail?.height + ' Cm'}</span>
                            )}
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-2 fw-semibold text-muted">Weight</label>
                        <div className="col-lg-10">
                            {getUserQuery.isLoading ? (
                                // Render a loading Skeleton for the Weight
                                <Skeleton variant="text" width={150} />
                            ) : (
                                // Render the actual Weight when not loading
                                <span className="fw-bold fs-6 text-gray-800">{userDetail?.weight === null || userDetail?.weight === undefined || userDetail?.weight === '' ? '-' : userDetail?.weight + ' Kg'}</span>
                            )}
                        </div>
                    </div>
                    <div className="row mb-10">
                        <label className="col-lg-2 fw-semibold text-muted">Gender</label>
                        <div className="col-lg-10">
                            {getUserQuery.isLoading ? (
                                // Render a loading Skeleton for the Gender
                                <Skeleton variant="text" width={150} />
                            ) : (
                                // Render the actual Gender when not loading
                                <span className="fw-semibold fs-6 text-gray-800">{userDetail?.gender ? userDetail?.gender : '-'}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserOverviewPage