import React from 'react';

import { cx } from '../../../utils/classnames';

import {
  getPageNumbers,
  PAGE_SIZE_OPTIONS,
  PAGINATION_DOTS,
} from './Table.utils';

import { useTableInstance } from './index';

export const TablePaginationSelectOption: React.FC<{
  pageOptions?: number[];
}> = ({ pageOptions }) => {
  const tableInstance = useTableInstance();

  const { state, setPageSize } = tableInstance;

  const { pageSize } = state;

  return (
    <select
      value={pageSize}
      onChange={(e) => {
        setPageSize(Number(e.target.value));
      }}
      className={'form-select rt_pagination_select'}
    >
      {(pageOptions || PAGE_SIZE_OPTIONS).map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
  );
};

export const TablePagination: React.FC<{
  pageOptions?: number[];
}> = ({ pageOptions }) => {
  const tableInstance = useTableInstance();

  const {
    state,
    pageCount,
    gotoPage,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
  } = tableInstance;

  const { pageIndex } = state;

  const formattedPages = React.useMemo(
    () =>
      getPageNumbers({
        currentPage: pageIndex + 1,
        lastPageNumber: pageCount,
      }),
    [pageCount, pageIndex]
  );

  const renderPagination = (formattedPages: any[]) => {
    const paginationItems: any = [];
    for (let index = 0; index < formattedPages?.length; index++) {
      paginationItems.push(
        <li className="page-item" key={index}>
          {formattedPages[index] === PAGINATION_DOTS ? (
            <span className='page-link'>{formattedPages[index]}</span>
          ) : (
            <button
              className={cx('page-link', {
                active: pageIndex === (formattedPages[index] as number) - 1,
              })}
              onClick={() => gotoPage((formattedPages[index] as number) - 1)}
            >{formattedPages[index]}</button>
          )}

        </li>
      );
    }
    return paginationItems;
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5">
        <div className="dataTables_length" id="kt_ecommerce_products_table_length">
          <label>
            <TablePaginationSelectOption pageOptions={pageOptions} />
          </label>
        </div>
      </div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          {formattedPages.length > 0 &&
            <ul className="pagination">
              <li className={cx('page-item', {
                disabled: pageIndex === 0,
              })}>
                <button className="page-link page-text me-5" onClick={() => gotoPage(0)}>
                  &#9664;&#9664; {/* Double Left Arrow */}
                </button>
              </li>
              <li className={cx('page-item', 'previous', {
                disabled: !canPreviousPage,
              })}>
                <button className="page-link page-text me-5" onClick={previousPage}>
                  &#9664;
                </button>
              </li>
              {renderPagination(formattedPages)}
              <li className={cx('page-item', 'next', {
                disabled: !canNextPage,
              })}>
                <button className="page-link page-text me-5" onClick={nextPage}>
                  &#9658;
                </button>
              </li>
              <li className={cx('page-item', {
                disabled: pageIndex === (pageCount - 1),
              })}>
                <button className="page-link page-text me-5" onClick={() => gotoPage(pageCount - 1)}>
                  &#9658;&#9658;
                </button>
              </li>
            </ul>
          }
        </div>
      </div>
    </div>
  );
};
