import React from 'react';

//import { PageTitle } from '../../../theme/layout/core';

import EventPage from './EventPage';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

const EventPageWrapper: React.FC = (props: any) => {
  const widgetsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: true,
    }
  ];
  return (
    <>
      <PageTitle breadcrumbs={widgetsBreadCrumbs}>Events</PageTitle>
      <EventPage />
    </>
  );
};

export default EventPageWrapper;
