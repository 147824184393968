import { Fragment, useEffect, useMemo, useState } from "react"
import { useDeleteQuery, usePostQuery } from "../../../hooks/reactQueryHelper"
import { AddEditOption, DeleteHomeSlider, GetAllHomeSlider } from "../../../services/HomeSlider/HomeSlider.type"
import { GridRequestModel, GridResponseModel, SortModel } from "../../../services/Common/Common.type"
import useTableState from "../../components/Table/useTableState"
import { Tooltip } from "react-tooltip"
import { TableGlobalSearch } from "../../components/Table/TableGlobalSearch"
import { KTCardBody } from "../../../_metronic/helpers"
import TableInner from "../../components/Table/TableInner"
import { TablePagination } from "../../components/Table/TablePagination"
import { TableLoader } from "../../components/Table/TableLoader"
import Table from "../../components/Table"
import { CellProps, Column } from "react-table"
import { Button, Modal } from "react-bootstrap"
import SetInnerHTML from "../../components/SetInnerHTML/SetInnerHTML"
import moment from "moment"
import { applyUserSpecificDateTimeFormat } from "../../../helper/utility"
import { Icon } from "@iconify/react"
import clsx from "clsx"
import { useNavigate } from "react-router-dom"
import AddEditHomeSliderWrapper from "./AddEditHomeSliderWrapper"
import { number } from "yup"
import showConfirmationDialog from "../../../helper/showConfirmationDialogUtility"
import toastNotification from "../../../helper/toastUtils"

const HomeSliderTable = () => {

    const getAllHomeSlider = usePostQuery("GetAllHomeSlider", GetAllHomeSlider)
    const deleteHomeSlider = useDeleteQuery("DeleteHomeSlider", DeleteHomeSlider, 0)

    const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
        pageIndex: 0,
    })
    const navigate = useNavigate()
    const { sort, page, limit, search } = tableState
    const initSortModel: SortModel = {
        sort: 'desc',
        fields: 'id',
    }

    const [gridSearch, setGridSearch] = useState<GridRequestModel>({
        page: page ?? 0,
        limit: limit ?? 0,
        search: search ? search : '',
        sort: sort ? sort : initSortModel,
        id: 0,
        eventId: 0,
        eventTypeID: 0,
        paymentDate: null,
        isInvited: false,
        isJoined: false
    })

    const [gridResponseModel, setGridResponseModal] = useState<GridResponseModel>({
        count: 0,
        currentPage: 0,
        result: [],
        totalPages: 0
    })

    const [discription, setDiscription] = useState();
    const [discriptionShow, setDiscriptionShow] = useState(false);
    const [addEditOption, setAddEditOption] = useState<AddEditOption>({
        isOpen: false,
        id: 0,
        isEdit: false
    });


    useEffect(() => {
        const updatedGridSearch = { ...gridSearch };
        updatedGridSearch.page = page ?? 0
        updatedGridSearch.limit = limit ?? 0
        if (sort) updatedGridSearch.sort = sort
        if (search) updatedGridSearch.search = search
        setGridSearch(updatedGridSearch);
    }, [page, limit, search, sort])

    useEffect(() => {
        if (!addEditOption.isOpen)
            getAllHomeSlider.mutate(gridSearch)
    }, [gridSearch, addEditOption, deleteHomeSlider.isSuccess])

    useEffect(() => {
        if (getAllHomeSlider.isSuccess) {
            setGridResponseModal(getAllHomeSlider.data.result)
        }
    }, [getAllHomeSlider.isSuccess])

    const handleShowModal = (data) => {
        setDiscription(data.description)
        setDiscriptionShow(true);
    };
    const handleClose = () => setDiscriptionShow(false);

    const openConfirmationDialog = async (row) => {
        const result = await showConfirmationDialog(
            'Confirmation',
            'Are you sure you want to delete this home silder?',
            'Delete'
        )
        if (result) deleteHomeSlider.mutate(row.id)
    }

    useEffect(() => {
        if (deleteHomeSlider?.data?.statusCode === 200) {
            toastNotification('success', 'Home slider deleted successfully')
        }
    }, [deleteHomeSlider.isError, deleteHomeSlider.isSuccess, deleteHomeSlider.isLoading])

    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: `Title`,
                accessor: 'title',
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (props: CellProps<any>) => {
                    return <span>{props?.row?.original?.title ? props?.row?.original?.title : '-'}</span>
                },
            },
            {
                Header: `Description`,
                accessor: 'description',
                disableSortBy: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (props: CellProps<any>) => {
                    return (
                        <div className='d-flex align-items-center'>
                            {props?.row?.original.description ? <div className='d-flex flex-column'>
                                <a href="javascript:void(0)" className="btn btn-sm btn-primary" data-tooltip-id="tooltip" data-tooltip-content="View Discription" onClick={() => handleShowModal(props?.row?.original)}>View</a>
                            </div> : '-'}

                        </div>
                    )
                },
            },
            {
                Header: `Created Date`,
                accessor: 'createdDate',
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (props: CellProps<any>) => {
                    return (
                        <span>
                            {props?.row?.original?.createdDate
                                ? moment(props?.row?.original?.createdDate).format(applyUserSpecificDateTimeFormat())
                                : '-'}
                        </span>)
                },
            },
            {
                Header: `Status`,
                accessor: 'isActive',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    const className = props?.row?.original?.isActive ? 'success' : 'danger'
                    return (
                        <>
                            {' '}
                            <span
                                data-tooltip-id="my-tooltip" data-tooltip-content={props?.row?.original?.isActive === true ? 'Active' : 'In Active'}
                                className={clsx(
                                    'badge badge-circle fs-1',
                                    `badge-light-${className}`,
                                    `fw-bolder`,
                                    `cursor-pointer`
                                )}
                            >
                                {props?.row?.original?.isActive ? (
                                    <Icon icon='simple-line-icons:check' />
                                ) : (
                                    <Icon icon='radix-icons:cross-circled' />
                                )}
                            </span>
                        </>
                    )
                },
            },
            {
                Header: <span className='d-block text-center rounded-end'>Actions</span>,
                accessor: 'id',
                disableSortBy: true,
                Cell: (props: CellProps<any>) => {
                    const result = props.row.original
                    return (
                        <>
                            <div className='d-flex justify-content-center flex-shrink-0'>
                                <button
                                    data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                    onClick={() => {
                                        actionButtonHandler(result, 'View')
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    <span className='svg-icon svg-icon-3'>
                                        <Icon icon='carbon:view-filled' />
                                    </span>
                                </button>
                                <button
                                    data-tooltip-id="my-tooltip" data-tooltip-content={`Edit`}
                                    title='Edit'
                                    onClick={() => {
                                        actionButtonHandler(result, 'Edit')
                                    }}
                                    className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1`}
                                >
                                    <span className='svg-icon svg-icon-3'>
                                        <Icon icon='ic:baseline-edit' />
                                    </span>
                                </button>
                                <button
                                    data-tooltip-id='my-tooltip' data-tooltip-content="Delete"
                                    title='Delete'
                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                    onClick={async () => openConfirmationDialog(result)}
                                >
                                    <span className='svg-icon svg-icon-3'>
                                        <Icon icon='ic:baseline-delete' />
                                    </span>
                                </button>
                            </div>
                        </>
                    )
                },
            },
        ]
        , [])



    const actionButtonHandler = (row: any, type: string) => {
        if (type === 'Add') {
            setAddEditOption({
                id: 0,
                isEdit: false,
                isOpen: true
            })
        } else if (type === 'Edit') {
            setAddEditOption({
                id: row?.id,
                isEdit: true,
                isOpen: true
            })
        } else if (type === 'View') {
            //navigate(`/events/detail/${row?.id}`)
        } else if (type === 'Close') {
            //setVisible(false)
        } else if (type === 'PageURL') {

        }
    }

    const OnHide = () => {
        setAddEditOption({
            id: 0,
            isEdit: false,
            isOpen: false
        })
    }

    return (<>
        <Tooltip id='tooltip' content="" />
        <Table
            columns={columns}
            data={gridResponseModel?.result ?? []}
            initialState={tableInitialState}
            pageCount={gridResponseModel?.totalPages ?? []}
            onTableStateChange={handleTableStateChange}
        >
            <Fragment>
                <TableGlobalSearch
                    serachtext={'Serach Home Slider'}
                    addBtnText={'Create Home Slider'}
                    actionButtonHandler={actionButtonHandler}
                    isAddbtnVisible={true}
                />
                <KTCardBody className='py-4'>
                    <div className='table-responsive'>
                        <TableInner loading={false} entityLabel='Home Slider'></TableInner>
                    </div>
                    <TablePagination />
                    <TableLoader isLoading={deleteHomeSlider.isLoading || getAllHomeSlider.isLoading} />
                </KTCardBody>
            </Fragment>
        </Table>
        <Modal show={discriptionShow} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Message</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="rounded">
                    <div className="modal-body scroll-y py-0">
                        <div className="row" >
                            <div className="col-md-12 mb-2">
                                <div className="row">
                                    <label className="col-md-12 form-label">Message :</label>
                                    <div className="border col-md-12 py-3">
                                        <SetInnerHTML inneHtml={discription ?? '-'} className='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="reset" className="btn btn-light me-3" variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>

        {addEditOption.isOpen && <AddEditHomeSliderWrapper addEditOption={addEditOption} OnHide={OnHide} />}
    </>)
}

export default HomeSliderTable