/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { CellProps, Column } from 'react-table'
import { KTCardBody } from '../../../_metronic/helpers'
import { Fragment, useEffect, useMemo, useState } from 'react'
import Table from '../../components/Table'
import useTableState from '../../components/Table/useTableState'
import TableInner from '../../components/Table/TableInner'
import { TablePagination } from '../../components/Table/TablePagination'
import clsx from 'clsx'
import { TableGlobalSearch } from '../../components/Table/TableGlobalSearch'
import { DeleteEvent, EventInviteUser, EventPublishRequsetModel, GetAllEvents, PublishEvent } from '../../../services/Event/Event.type'
import { useDeleteQuery, usePostQuery } from '../../../hooks/reactQueryHelper'
import { useNavigate } from 'react-router'
import { GridRequestModel, GridResponseModel, SortModel } from '../../../services/Common/Common.type'
import { TableLoader } from '../../components/Table/TableLoader'
import toastNotification from '../../../helper/toastUtils'
import showConfirmationDialog from '../../../helper/showConfirmationDialogUtility'
import { Icon } from '@iconify/react'
import EventRule from './EventRule'
import { Modal } from 'react-bootstrap'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { applyUserSpecificDateFormat, applyUserSpecificDateTimeFormat } from '../../../helper/utility'
import moment from 'moment'
import { WEB_ENDPOINT } from '../../../shared/apiConfig'
import EventUserInviteModal from './AddEditEvent/EventUserInviteModal'

// eslint-disable-next-line react-hooks/rules-of-hooks

const EventsTable = (props: any) => {
  const getAllEventsQuery: any = usePostQuery('getAllEventsQuery', GetAllEvents, {})
  const deleteEventQuery: any = useDeleteQuery('DeleteEvent', DeleteEvent, 0)
  const eventPublishQuery: any = usePostQuery('PublishEvent', PublishEvent, {})
  const eventInvite = usePostQuery("eventInvite", EventInviteUser);
  const [modalShow, setModalShow] = useState(false)
  const [id, setID] = useState(0);
  const navigate = useNavigate()
  const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
    pageIndex: 0,
  })
  const initGridResponseModel: GridResponseModel = {
    count: 0,
    currentPage: 0,
    result: [],
    totalPages: 0,
  }

  const [eventList, setEventList] = useState<GridResponseModel>(initGridResponseModel)
  const { sort, page, limit, search } = tableState
  const initSortModel: SortModel = {
    sort: '',
    fields: '',
  }

  const initGridRequestModel: GridRequestModel = {
    page: page ?? 0,
    limit: limit ?? 0,
    search: search ? search : '',
    sort: sort ? sort : initSortModel,
    id: 0,
    eventId: 0,
    eventTypeID: 0,
    paymentDate: null,
    isInvited: false,
    isJoined: false
  }

  const initEventPublishRequsetModel: EventPublishRequsetModel = {
    id: 0,
    isPublish: false
  }

  const [visible, setVisible] = useState<any>(false);
  const [currentEventId, setCurrentEventId] = useState<any>();
  const [eventName, setEventName] = useState<any>();
  const [selectEventType, setSelectEventType] = useState<any>('');
  const [lastEventTypeID, setLastEventTypeID] = useState<any>('');
  useEffect(() => {
    initGridRequestModel.page = page ?? 0
    initGridRequestModel.limit = limit ?? 0
    if (sort) initGridRequestModel.sort = sort
    if (search) initGridRequestModel.search = search
    if (selectEventType > 0) {
      if (lastEventTypeID !== selectEventType) {
        initGridRequestModel.page = 1;
      }
      initGridRequestModel.eventTypeID = selectEventType === "" ? 0 : selectEventType;
      setLastEventTypeID(initGridRequestModel.eventTypeID)
    }
    getAllEventsQuery.mutate(initGridRequestModel)
  }, [page, limit, sort, search, selectEventType])

  const actionButtonHandler = (row: any, type: string) => {
    if (type === 'Add') {
      navigate('/events/manage')
    } else if (type === 'Edit') {
      navigate('/events/manage/' + row?.id)
    } else if (type === 'View') {
      navigate(`/events/detail/${row?.id}`)
    } else if (type === 'Close') {
      setVisible(false)
    } else if (type === 'PageURL') {

    }
  }

  const onSelectEventTypeChange = (selectEventTypeId) => {
    if (selectEventTypeId != null) {
      setSelectEventType(selectEventTypeId);
      initGridRequestModel.eventTypeID = selectEventTypeId === "" ? 0 : selectEventTypeId;
    }
  }

  const openConfirmationDialog = async (row) => {
    const result = await showConfirmationDialog(
      'Confirmation',
      'Are you sure you want to delete this event?',
      'Delete'
    )
    if (result) deleteEventQuery.mutate(row.id)
  }
  const OpenInvitationmodal = (row) => {
    if (row) {
      setID(row.id)
      setModalShow(true);
    }
  }

  const openIsPublishEventDialog = async (row) => {
    const result = await showConfirmationDialog(
      'Confirmation',
      'Are you sure you want to publish this event?',
      'Publish'
    )
    if (result === true) {
      initEventPublishRequsetModel.id = row.id
      initEventPublishRequsetModel.isPublish = true;
      eventPublishQuery.mutate(initEventPublishRequsetModel)
    }
  }

  useEffect(() => {
    if (getAllEventsQuery.data) {
      setEventList(getAllEventsQuery?.data?.result)
    }
  }, [getAllEventsQuery.data, getAllEventsQuery.isError, getAllEventsQuery.isSuccess])

  useEffect(() => {
    if (deleteEventQuery?.data?.statusCode === 200) {
      toastNotification('success', 'Event deleted successfully')
      getAllEventsQuery.mutate(initGridRequestModel)
    }
  }, [deleteEventQuery.isError, deleteEventQuery.isSuccess])

  useEffect(() => {
    if (eventPublishQuery?.data?.statusCode === 200) {
      toastNotification('success', eventPublishQuery?.data?.result)
      getAllEventsQuery.mutate(initGridRequestModel)
    }
  }, [eventPublishQuery.isError, eventPublishQuery.isSuccess, eventPublishQuery.isLoading])

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: `Name`,
        accessor: 'name',
        maxWidth: 500,
        minWidth: 140,
        width: 500,
        Cell: (props: CellProps<any>) => {
          const getEventName = props?.row?.original?.name ? props?.row?.original?.name : '-'
          const eventName = `${getEventName.substring(0, 30)}${getEventName.length > 30 ? '...' : ''
            }`
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex flex-column'>
                <a className='text-gray-800 text-hover-primary mb-1' href="javascript:void(0)" onClick={() => {
                  navigate(`/events/detail/` + props?.row?.original?.id)
                }}>
                  {eventName ? eventName : '-'}
                </a>
                {props?.row?.original?.isPublish && props?.row?.original?.isActive
                  ? <a href={`${WEB_ENDPOINT}/eventdetail/${props?.row?.original?.pageURL}`} target="_blank" className='text-primary p-0' onClick={(e) => actionButtonHandler(props.row.original, 'PageURL')} rel="noreferrer">click here</a>
                  : ''}

              </div>
            </div>
          )
        },
      },
      {
        Header: `Event Type`,
        accessor: 'eventTypeID',
        maxWidth: 50,
        minWidth: 20,
        width: 50,
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const eventType =
            props?.row?.original?.eventTypeID === 1
              ? 'Cycling'
              : props?.row?.original?.eventTypeID === 2
                ? 'Walking'
                : 'Running'
          const className =
            props?.row?.original?.eventTypeID === 1
              ? 'success'
              : props?.row?.original?.eventTypeID === 2
                ? 'primary'
                : 'danger'
          return (
            <div className={clsx('badge ', `badge-light-${className}`, `fw-bolder`)}>
              {eventType}
            </div>
          )
        },
      },

      {
        Header: `Start Date`,
        accessor: 'startDate',
        maxWidth: 600,
        minWidth: 140,
        width: 600,
        Cell: (props: CellProps<any>) => {
          return <span>{`${props?.row?.original?.startDate ? moment(props?.row?.original?.startDate).format(applyUserSpecificDateTimeFormat()) : '-'}`}</span>
        },
      },
      {
        Header: `Duration`,
        accessor: 'duration',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <span>{`${props?.row?.original?.duration ? props?.row?.original?.duration : '-'
              }`}</span>
          )
        },
      },
      {
        Header: `Status`,
        accessor: 'isActive',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const className = props?.row?.original?.isActive ? 'success' : 'danger'
          return (
            <>
              {' '}
              <span
                data-tooltip-id="my-tooltip" data-tooltip-content={props?.row?.original?.isActive === true ? 'Active' : 'In Active'}
                className={clsx(
                  'badge badge-circle fs-1',
                  `badge-light-${className}`,
                  `fw-bolder`,
                  `cursor-pointer`
                )}
              >
                {props?.row?.original?.isActive ? (
                  <Icon icon='simple-line-icons:check' />
                ) : (
                  <Icon icon='radix-icons:cross-circled' />
                )}
              </span>
            </>
          )
        },
      },
      {
        Header: `Rule`,
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          return (
            <> <a
              href='javascript:void(0)'
              className={clsx(
                'badge badge-circle fs-1',
                'badge-light-primary text-hover-primary',
                `fw-bolder`
              )}
            >
              {' '}
              <span
                data-tooltip-id="my-tooltip" data-tooltip-content="Rule"
                onClick={() => {
                  const additionalData = {
                    tabName: 'Rules',
                  }
                  navigate(`/events/detail/` + props?.row?.original?.id, { state: additionalData })
                }}
              >

                <Icon icon='flat-color-icons:rules' />{' '}
              </span>

            </a>
            </>
          )
        },
      },
      {
        Header: `Participant`,
        accessor: 'eventParticipentCount',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <div style={{ textAlign: 'center' }} data-tooltip-id="my-tooltip" data-tooltip-content="Event participent Count">
              <button
                type='button'
                className='btn btn-link text-primary btn-sm'
                onClick={() => {
                  const additionalData = {
                    tabName: 'UserParticipated',
                  }
                  navigate(`/events/detail/` + props?.row?.original?.id, { state: additionalData })
                }}
              >
                <span className='badge badge-circle badge-outline badge-primary btn-sm'>
                  {' '}
                  {props?.row?.original?.eventParticipentCount}
                </span>
              </button>
            </div>
          )
        },
      },
      {
        Header: `Published? `,
        accessor: 'isPublish',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original
          return (
            <>

              {(props?.row?.original?.isPublish === false ||
                props?.row?.original?.isPublish === null) &&
                (props?.row?.original?.isActive === false ||
                  props?.row?.original?.startDate < new Date().toISOString().slice(0, -8)) && (
                  <span data-tooltip-id="my-tooltip" data-tooltip-content={`${props?.row?.original?.startDate < new Date().toISOString().slice(0, -8) ? "Please set start date as future date of the event" : 'Please activate the event before publishing'}`}
                    className='badge badge-light-primary text-muted'
                  >{`${props?.row?.original?.startDate > new Date().toISOString().slice(0, -8)
                    ? 'Publish event'
                    : '-'
                    }`}</span>
                )}
              {props?.row?.original?.startDate > new Date().toISOString().slice(0, -8) &&
                (props?.row?.original?.isPublish === false ||
                  props?.row?.original?.isPublish === null) &&
                props?.row?.original?.isActive === true && (
                  <span data-tooltip-id="my-tooltip" data-tooltip-content={`${props?.row?.original?.startDate > new Date().toISOString().slice(0, -8) ? "" : ''}`}
                    className='badge badge-light-primary cursor-pointer'
                    onClick={async () => openIsPublishEventDialog(result)}
                  >{`${props?.row?.original?.startDate > new Date().toISOString().slice(0, -8)
                    ? 'Publish event'
                    : '-'
                    }`}</span>
                )}
              {props?.row?.original?.isPublish === true && (
                <span className='badge badge-light-success'>Published</span>
              )}
            </>
          )
        },
      },
      {
        Header: `Challenge?`,
        accessor: 'isChallenge',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <>
              {props?.row?.original?.isChallenge === true ? (
                <span className='badge badge-light-info'>Challenge</span>
              ) :
                (
                  <> {props?.row?.original?.isPrivate === true ? (
                    <span className='badge badge-light-danger'>Private Event</span>) : (
                    <span className='badge badge-light-warning'>Public Event</span>)}
                  </>
                )
              }
            </>
          )
        }
      },
      {
        Header: <span className='d-block text-center rounded-end'>Actions</span>,
        accessor: 'id',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original
          return (
            <>
              <div className='d-flex justify-content-center flex-shrink-0'>
                <button
                  data-tooltip-id="my-tooltip" data-tooltip-content="View"
                  onClick={() => {
                    actionButtonHandler(result, 'View')
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  <span className='svg-icon svg-icon-3'>
                    <Icon icon='carbon:view-filled' />
                  </span>
                </button>
                <button
                  data-tooltip-id="my-tooltip" data-tooltip-content={`Edit`}
                  title='Edit'
                  onClick={() => {
                    actionButtonHandler(result, 'Edit')
                  }}
                  // className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ${(props?.row?.original?.isPublish != null ? ((props?.row?.original?.isPublish) ? 'disabled disabled-button' : '') : '')}`}
                  className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1`}
                >
                  <span className='svg-icon svg-icon-3'>
                    <Icon icon='ic:baseline-edit' />
                  </span>
                </button>
                <button
                  data-tooltip-id='my-tooltip' data-tooltip-content="Delete"
                  title='Delete'
                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                  onClick={async () => openConfirmationDialog(result)}
                >
                  <span className='svg-icon svg-icon-3'>
                    <Icon icon='ic:baseline-delete' />
                  </span>
                </button>
                <button
                  data-tooltip-id='my-tooltip' data-tooltip-content={`${props?.row?.original?.isPublish != null
                    ? (props?.row?.original?.isPublish
                      ? (props?.row?.original?.isPrivate === true ? 'Invite User' : 'Event must be private')
                      : 'Event must be published')
                    : 'Event must be published'}`}
                  title='Invite User'
                  className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm  ${props?.row?.original?.isPublish != null
                    ? (props?.row?.original?.isPublish
                      ? (props?.row?.original?.isPrivate === true ? '' : 'disabled disabled-button')
                      : 'disabled disabled-button')
                    : 'disabled disabled-button'}`}
                  disabled={!(
                    props?.row?.original?.isPublish === true &&
                    props?.row?.original?.isPrivate === true
                  )}
                  onClick={() => OpenInvitationmodal(result)}
                >
                  <span className='svg-icon svg-icon-3'>
                    <Icon icon="mdi:people-add" style={{ color: 'grey' }} />
                  </span>
                </button>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <ReactTooltip id="my-tooltip" content="" />
      <Table
        columns={columns}
        data={eventList?.result ?? []}
        initialState={tableInitialState}
        pageCount={eventList?.totalPages}
        onTableStateChange={handleTableStateChange}
      >
        <Fragment>
          <TableGlobalSearch
            actionButtonHandler={actionButtonHandler}
            serachtext={'Serach Event'}
            addBtnText={'Create Event'}
            isAddbtnVisible={true}
            onSelectEventTypeChange={onSelectEventTypeChange}
            isDropDownVisible={true}
            dropDownVisibility={['eventTypeDropDown']}
            selectEventType={selectEventType}
          />
          <KTCardBody className='py-4'>
            <div className='table-responsive'>
              <TableInner loading={false} entityLabel='Events'></TableInner>
            </div>
            <TablePagination pageOptions={[5, 10, 20, 50]} />
            <TableLoader isLoading={getAllEventsQuery?.isLoading || eventPublishQuery.isLoading} />
          </KTCardBody>
        </Fragment>
      </Table>
      <Modal
        show={visible}
        onHide={() => actionButtonHandler(null, 'Close')}
        backdrop='static'
        size='lg'
        keyboard={false}
      >
        <div className='modal-content'>
          <Modal.Header closeButton className='modal-header'>
            <h2 className='fw-bold' title={eventName}>
              {eventName != null && eventName.length > 40
                ? eventName.substring(0, 40) + '...'
                : eventName}{' '}
              Event Rule
            </h2>
          </Modal.Header>
          <Modal.Body className='modal-body scroll-y scroll-x'>
            <EventRule eventId={currentEventId} />
            <div className='text-end'>
              <button
                type='reset'
                className='btn btn-primary me-3'
                data-kt-users-modal-action='cancel'
                onClick={() => actionButtonHandler(null, 'Close')}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {modalShow &&
        <EventUserInviteModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          eventID={id}
        />
      }
    </>
  )
}

export { EventsTable }
