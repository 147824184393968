export interface EventModel {
  id: number
  name: string | null
  eventTypeID: number
  description: string | null
  startDate: any
  endDate: any
  unit: string | null
  totalDistance: number
  joiningFees: number
  maxParticipants: number | null
  rewards: string | null
  image: string | null
  isActive: boolean
  eventTypeName: string | null
  duration: string
  createdDate: any | null
  modifiedDate: any | null
  modifiedBy: number | null
  createdBy: number | null
  eventRules: EventRuleModel[] | null
  fileBase64Data: string | null
  eventParticipentCount: number
  eventImageList: EventImageModel[] | null
  isGift: boolean
  location: string | null
  mobileBannerImage: string | null
  webBannerImage: string | null
  pageURL: string | null
  filebase64WebBannerImage: string | null
  filebase64mobileBannerImage: string | null
  isPublish: boolean
  isFixed: boolean
  registrationClosingDate: any | null
  isFree: boolean
  isPrivate: boolean
  isChallenge: boolean
}

export interface EventRuleModel {
  id: number
  eventID: number
  rule: string
  isError: boolean
}

export interface EventImageModel {
  id: number
  eventID: number
  image: string
  filebase64: string
}

export interface AddEditEventModel extends EventModel {
  selectedStatus: DropdownModel
  selectedUnit: DropdownModel
  selectedEventType: DropdownModel
  selectedFixedDistance: DropdownModel
  selectedDistanceType: boolean
  isFixedParticpated: boolean
}

export interface DropdownModel {
  value: any
  label: string
}

export interface EventPublishRequsetModel {
  id: number
  isPublish: boolean
}

export interface EventRankModel {
  rank: number
  userID: number
  eventID: number
  totalDuration: number
  totalCalories: number
  totalDistance: number
  unit: string
  timeUnit: string
  participantName: string
  age: number
  gender: string | null
  image: string | null
  // totalSteps: number
  winnerRank: number | null
}

export const GetAllEvents = '/adminapi/getallevents'
export const AddEditEvent = '/adminapi/upsertevent'
export const GetEvent = '/adminapi/getevent'
export const EditEvent = '/adminapi/editevent'
export const DeleteEvent = '/adminapi/deleteEvent'
export const GetEventParticipent = '/adminapi/geteventparticipent'
export const GetEventRules = '/adminapi/geteventrules'
export const PublishEvent = '/adminapi/eventpublish'
export const GetEventForEdit = '/adminapi/geteventforedit'
export const GetAllEventWinners = '/adminapi/getleaderboardbyid'
export const EventInviteUser = '/adminapi/invite-user'
export const EventUninviteUser = '/adminapi/eventuninviteuser'
export const getAllEventsEventDropdownOption = '/adminapi/get-all-event-dropdown'
export const getAllEventParticipantDropdownOption = '/adminapi/get-participantFcmToken'