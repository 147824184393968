import { DropdownModel } from "../Common/Common.type"

export interface UserModel {
    id: number,
    firstName: string | '',
    surname: string | null,
    password: string | null,
    dob: any,
    weight: number | null,
    height: number | null,
    mobile: string | null,
    address: string | null,
    landmark: string | null,
    pincode: string | null,
    city: string | null,
    gender: any | null,
    image: string | null,
    state: string | null,
    email: string | null,
    isActive: boolean,
    createdDate: any | null,
    modifiedDate: any | null,
    modifiedBy: number | null,
    createdBy: number | null,
    fileBase64Data: string | null
    participentEventCount: number
    createTimeDuration: string | null
    isVerified: boolean,
    platForm: string | null,
    isInvited: boolean,
    deletedDate: any
}
export interface AddEditUserModel extends UserModel {
    selectedStatus: DropdownModel,
}

export const initDemoInviteUserModel: DemoInviteUserModel = {
    FirstName: '',
    Surname: '',
    Mobile: '',
    DOB: null,
    Weight: '',
    Height: '',
    Gender: '',
    Email: ''
}

export interface journalModel {
    todayActivity: todayActiviy | null,
    journal: JournalData[] | null

}

export interface todayActiviy {
    steps: number | null,
    calories: number | null,
    distance: number | null,
    unit: string | null

}

export interface userActivitySessionModel {
    id: number,
    userID: number | null,
    eventTypeID: number | null,
    startDate: any | null,
    endDate: any | null,
    unit: string | null,
    distance: number | null,
    eventTypeName: string | null,
    duration: string | null,
    eventID: number | null,
    startLongitude: number,
    endLongitude: number,
    startLatitude: number,
    endLatitude: number,
    calories: number
    eventName: string | null,
    eventStartDate: any | null
    eventEndDate: any | null,
    userName: string | null,
    timeDuration: string | null,
    activityID: number,
    startatitude: number,
    longitude: number,
    startTime: any
    endTime: any
}

export interface JournalData {
    date: any | null,
    steps: number | null,
    activity: userActivitySessionModel[] | null

}

export interface UserActivitySession {
    duration: number | null,
    calories: number | null,
    totalSteps: number | null,
    unit: string | null,
    timeUnit: string | null,
    participantName: string | null,
    age: number | null,
    distance: number | null,
    id: number,
    eventTypeID: number,
    userActivityHistories: UserActivityHistory[] | null
}

export interface UserActivityHistory {
    id: number,
    latitude: number,
    longitude: number,
    steps: number,
    time: any,
    activityID: number
}

export interface EventInvitationModel {
    id: number | 0,
    eventID: number | 0,
    userID: number | 0,
    name: string | null,
    deletedDate: Date | null,
    deletedBy: number | 0,
    emailAddress: string | null
    isInvited: boolean | null
}
export interface EventInvitationRequestModel {
    eventID: number,
    invitedUsers: EventInvitationModel[] | null
}

export interface DemoInviteUserModel {
    FirstName: any,
    Surname: any,
    Mobile: any,
    DOB: any,
    Weight: any,
    Height: any,
    Gender: any,
    Email: any
}
export const GetAllUsers = '/adminapi/getallusers'
export const AddEditUser = '/adminapi/upsertuser'
export const DeleteUser = '/adminapi/deleteuser'
export const GetUser = '/adminapi/getuser'
export const EditUser = '/addminapi/edituser'
export const GetUserActivity = '/adminapi/getuseractivity'
export const GetEventParticipatedByUserID = '/adminapi/geteventparticipatedbyuserid'
export const GetUserTransaction = '/adminapi/getusertransaction'
export const EditUserProfile = '/adminapi/edituserprofile'
export const GetAllStatesById = '/adminapi/getstates'
export const GetAllCitysById = '/adminapi/getcities'
export const GetUserActivityHistory = '/adminapi/getuseractivityjournal'
export const GetTransactionUserList = '/adminapi/gettransactionuserlist'
export const GetTransactionEventList = '/adminapi/gettransactioneventlist'
export const GetContactInquiryList = '/adminapi/getcontactinquirylist'
export const GetUserActivityHistories = '/adminapi/getuseractivityhistory'
export const GetInviteUserList = '/adminapi/getinviteuserlist'
export const UploadUsers = '/adminapi/upload-users'