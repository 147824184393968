/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Form, Modal, Spinner } from "react-bootstrap"
import { AddEditHomeSlideModel, AddEditOption, GetHomeSlider, UpsertHomeSlider } from "../../../services/HomeSlider/HomeSlider.type"
import { Form as BForm } from 'react-bootstrap'
import { Icon } from "@iconify/react"
import { useCallback, useEffect, useRef, useState } from "react"
import { Formik, FormikHelpers, FormikValues } from "formik"
import * as Yup from 'yup'
import clsx from "clsx"
import { useGetQuery, usePostQuery } from "../../../hooks/reactQueryHelper"
import toastNotification from "../../../helper/toastUtils"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { useThemeMode } from "../../../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import { EVENT_DARK_IMAGE_PATH, EVENT_DEFAULT_IMAGE_PATH } from "../../../shared/constants"
import ImageCrop from "../../components/ImageCrop"

interface AddEditHomeSilterProps {
    addEditOption: AddEditOption,
    OnHide: any
}

const validationSchema = Yup.object({
    title: Yup.string()
        .required('Please enter title')
        .trim()
        .matches(/^(?!.*<.*>)[\w\s!@#$%^&*(),.?":;'"{}|<>[\]\\/`~_\-+=]*$/, 'Only alphabets, numbers, spaces, and special characters are allowed, but no HTML tags'),


    description: Yup.string()
        .required('Please enter description')
        .trim(),
    image: Yup.string().trim().required('Please upload mobile banner image'),
    isActive: Yup.boolean(),
})

const AddEditHomeSliderWrapper = (props: AddEditHomeSilterProps) => {

    const { addEditOption, OnHide } = props
    const initAddEditHomeSliderModel: AddEditHomeSlideModel = {
        id: 0,
        title: null,
        description: null,
        image: null,
        externalLink: null,
        isActive: true,
        createdDate: undefined,
        modifiedDate: undefined,
        modifiedBy: null,
        createdBy: null,
        imageBase64: null
    }
    const [addEditHomeSlideModel, setAddEditHomeSlideModel] = useState<AddEditHomeSlideModel>()
    const [areaPixels, setareaPixels] = useState<any>(null)
    const upsertHomeSlider = usePostQuery("UpsertHomeSlider", UpsertHomeSlider)
    const getHomeSlider = useGetQuery("GetHomeSlider", GetHomeSlider)

    useEffect(() => {
        if (!addEditOption.isEdit) {
            setAddEditHomeSlideModel(initAddEditHomeSliderModel)
        }
        else if (addEditOption.id > 0) {
            getHomeSlider.mutate(addEditOption.id)
        }
    }, [])

    useEffect(() => {
        if (getHomeSlider.isSuccess) {
            setAddEditHomeSlideModel(getHomeSlider?.data?.result)
        }
    }, [getHomeSlider.isLoading, getHomeSlider.isLoading, getHomeSlider.isSuccess])

    const onSubmit = (values: AddEditHomeSlideModel) => {
        upsertHomeSlider.mutate(values)
    }

    useEffect(() => {
        if (upsertHomeSlider.isSuccess) {
            toastNotification('success', 'Home slider has been updated successfully')
            OnHide()
        }
    }, [upsertHomeSlider.isError, upsertHomeSlider.isSuccess, upsertHomeSlider.isLoading])


    //image settings
    const fileInputMobileRef = useRef<any>(null)
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']
    const [eventMobileBannerImage, setMobileBannerImage] = useState<any>()
    const [mobileBannerVisible, setMobileBannerVisible] = useState<any>(false)

    const handleMobileBannerImageChange = async (e, setFieldValue) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const fileName = file.name
            const fileExtension = fileName.split('.').pop().toLowerCase()
            if (allowedExtensions.includes(fileExtension)) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    setMobileBannerImage(e.target?.result)
                }
                reader.readAsDataURL(e.target.files[0])
                setMobileBannerVisible(true)
            } else {
                toastNotification('warning', 'Please select only image jpg, jpeg, png, gif')
                return
            }
        }
        fileInputMobileRef.current.value = null
    }
    const { mode } = useThemeMode()

    const toggleVisible1 = useCallback(() => {
        setMobileBannerVisible((p) => !p)
    }, [mobileBannerVisible])

    return (<>
        <Modal show={addEditOption.isOpen} onHide={OnHide} size="lg" backdrop="static" keyboard={false} centered id="kt_modal_add_customer">
            {addEditHomeSlideModel &&
                <Formik
                    enableReinitialize={true}
                    initialValues={addEditHomeSlideModel}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={false}
                >{({ values, touched, errors, handleChange, handleSubmit, setFieldValue }: any) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        setFieldValue('title', addEditHomeSlideModel?.title)
                        setFieldValue('description', addEditHomeSlideModel?.description)
                        setFieldValue('image', addEditHomeSlideModel?.image)
                        setFieldValue('externalLink', addEditHomeSlideModel?.externalLink)
                        setFieldValue('isActive', addEditHomeSlideModel?.isActive)
                        setFieldValue('id', addEditHomeSlideModel?.id)
                    }, [setFieldValue])
                    return (<>
                        <fieldset>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header closeButton id="kt_modal_add_customer_header">
                                    <Modal.Title>
                                        <h2>{addEditHomeSlideModel?.id ? 'Edit' : 'Add'} a Home Slider</h2>
                                    </Modal.Title>
                                </Modal.Header>
                                <ImageCrop
                                    visible={mobileBannerVisible}
                                    imagesrc={eventMobileBannerImage}
                                    onCancel={toggleVisible1}
                                    setareaPixels={setareaPixels}
                                    onComplete={(imagePromisse) => {
                                        imagePromisse.then((image) => {
                                            setFieldValue('imageBase64', image)
                                            setFieldValue('image', image)
                                            setMobileBannerImage(image)
                                            setMobileBannerVisible(false)
                                        })
                                    }}
                                    containerStyle={{
                                        position: 'relative',
                                        width: '100%',
                                        height: 300,
                                        background: '#333',
                                    }}
                                    imageModule='mobileBanner'
                                />
                                <Modal.Body>
                                    <div className="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                                        <BForm.Group className="fv-row mb-7" controlId="title">
                                            <BForm.Label className="required fs-6 fw-semibold mb-2">Title</BForm.Label>
                                            <BForm.Control
                                                type="text"
                                                placeholder="Title"
                                                name="title"
                                                id="title"
                                                maxLength={100}
                                                onChange={handleChange}
                                                defaultValue={values.title}
                                                className={clsx("form-control bg-transparent",
                                                    { 'is-invalid': touched.title && errors.title },
                                                    {
                                                        'is-valid': touched.title && !errors.title,
                                                    }
                                                )}
                                                onBlur={(e) => {
                                                    setFieldValue('title', e.target.value)
                                                }}
                                            />
                                            {errors.title && touched.title && <span className='text-danger'>{errors.title}</span>}
                                        </BForm.Group >
                                        <BForm.Group className="fv-row mb-7" controlId="description">
                                            <BForm.Label className="required fs-6 fw-semibold mb-2">Description</BForm.Label>
                                            <BForm.Control
                                                as='textarea'
                                                name="description"
                                                type="textarea"
                                                id="description"
                                                className={clsx(
                                                    'form-control',
                                                    { 'is-invalid': touched.description && errors.description },
                                                    { 'is-valid': touched.description && !errors.description }
                                                )}
                                                style={{ height: '150px' }}
                                                placeholder='Description'
                                                onChange={handleChange}
                                                defaultValue={values.description ?? ''}
                                                onBlur={(e) => {
                                                    setFieldValue('description', e.target.value)
                                                }}
                                            >
                                            </BForm.Control>
                                            {errors.description && touched.description && <span className='text-danger'>{errors.description}</span>}
                                        </BForm.Group>
                                        <div className="row g-9 mb-7">


                                            <BForm.Group className="col-md-6 fv-row fv-plugins-icon-container">
                                                <BForm.Label className="form-label required">
                                                    Image
                                                </BForm.Label>
                                                <div className="img-content image-input image-input-empty image-input-placeholder border border-gray-300 form-control bg-transparent p-0 m-0 displayBlock" style={{ width: "fit-content" }}>
                                                    <a href="javascript:void(0)">
                                                        <div className="img-content-overlay"></div>
                                                        <div className="img-content-details fadeIn-bottom fadeIn-left">
                                                            <label
                                                                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px shadow mobileBannerEditIcon'
                                                                data-kt-image-input-action='change'
                                                                data-bs-toggle='tooltip'
                                                                title='Change avatar'
                                                            >
                                                                <span className='text-muted'>
                                                                    <Icon icon="uil:image-upload" width="50" height="50" />
                                                                </span>
                                                                <input
                                                                    type='file'
                                                                    ref={fileInputMobileRef}
                                                                    style={{ display: 'none' }}
                                                                    accept='image/png, image/jpeg, image/jpg'
                                                                    onChange={(e) => handleMobileBannerImageChange(e, setFieldValue)}
                                                                />
                                                                <input type='hidden' name='avatar_remove' />
                                                            </label>
                                                        </div>

                                                        {(values.image || values.imageBase64) && (
                                                            <span
                                                                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow mobileBannerRemoveIcon'
                                                                onClick={() => {
                                                                    setFieldValue('image', null)
                                                                    setFieldValue('imageBase64', null)
                                                                    setMobileBannerImage(null)
                                                                }}
                                                                data-bs-toggle='tooltip'
                                                                title='Cancel avatar'
                                                            >
                                                                <span className='svg-icon svg-icon-3'>
                                                                    <Icon icon='ion:close' />
                                                                </span>
                                                            </span>
                                                        )}
                                                        {(values.image || values.imageBase64) ? (
                                                            <img
                                                                alt='not found'
                                                                width={'300px'}
                                                                src={values.imageBase64 ? values.imageBase64 : values.image}
                                                                className='image-input-wrapper w-auto h-200px img-fluid'
                                                            />
                                                        ) : <img
                                                            alt='not found'
                                                            width={'300px'}
                                                            src={"https://placehold.co/403x268?text=N/A"}
                                                            className='image-input-wrapper w-auto h-200px img-fluid'
                                                            style={{
                                                                border:
                                                                    errors.image && touched.image
                                                                        ? '1px solid #f1416c'
                                                                        : '',
                                                            }}
                                                        />}
                                                    </a>
                                                </div>
                                                {errors.image && touched.image ? (
                                                    <div className='text-danger fs-7'>{errors.image}</div>
                                                ) : null}
                                            </BForm.Group>
                                            <div className="col-md-6 fv-row fv-plugins-icon-container" >
                                                <BForm.Group className="mb-7" controlId="externalLink">
                                                    <BForm.Label className="fs-6 fw-semibold mb-2">External Link</BForm.Label>
                                                    <BForm.Control
                                                        type="text"
                                                        placeholder="External Link"
                                                        name="externalLink"
                                                        id="externalLink"
                                                        maxLength={100}
                                                        onChange={(e) => {
                                                            setFieldValue('externalLink', e.target.value)
                                                        }}
                                                        defaultValue={values.externalLink}
                                                        className={clsx("form-control bg-transparent",
                                                            { 'is-invalid': touched.externalLink && errors.externalLink },
                                                            {
                                                                'is-valid': touched.externalLink && !errors.externalLink,
                                                            }
                                                        )}
                                                        onBlur={(e) => {
                                                            setFieldValue('externalLink', e.target.value)
                                                        }}
                                                    />
                                                    {errors.externalLink && touched.externalLink && <span className='text-danger'>{errors.externalLink}</span>}
                                                </BForm.Group>
                                                <BForm.Group controlId="chkIsActive">
                                                    <BForm.Label className='form-label'>
                                                        Active? &nbsp;
                                                    </BForm.Label>
                                                    <BForm.Group className='form-check form-check-custom form-check-solid mb-2'>
                                                        <BForm.Control
                                                            className='form-check-input'
                                                            id='chkIsActive'
                                                            name='isFree'
                                                            type='checkbox'
                                                            defaultChecked={values.isActive}
                                                            onChange={(e) => {
                                                                setFieldValue('isActive', (e.target as HTMLInputElement).checked);
                                                            }}
                                                        />
                                                        <BForm.Label className='form-check-label' htmlFor='chkIsActive'>
                                                            Active
                                                        </BForm.Label>
                                                    </BForm.Group>
                                                    {errors.isActive && touched.isActive && <span className='text-danger'>{errors.isActive}</span>}
                                                </BForm.Group>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" id="kt_modal_add_customer_cancel" className="btn btn-light me-3" onClick={OnHide}>Cancel</button>
                                    <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                                        {upsertHomeSlider.isLoading ? <Spinner
                                            as='span'
                                            animation='border'
                                            size='sm'
                                            role='status'
                                            aria-hidden='false'
                                        /> :
                                            <span className="indicator-label">Save Changes</span>}
                                    </button>
                                </Modal.Footer>

                            </Form >
                        </fieldset>
                    </>)
                }}

                </Formik >
            }
        </Modal >

    </>)
}

export default AddEditHomeSliderWrapper