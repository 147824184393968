import { AddEditOption } from "../../../services/HomeSlider/HomeSlider.type";
import * as Yup from 'yup'
import { ApplicationVersion, getApplicationVersion, upsertApplicationVersion } from "../../../services/ApplicationVersion/ApplicationVersion.type";
import { useEffect, useState } from "react";
import { useGetQuery, usePostQuery } from "../../../hooks/reactQueryHelper";
import toastNotification from "../../../helper/toastUtils";
import { Form as BForm, Form, Modal, ModalHeader, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import clsx from "clsx";
import { DropdownModel } from "../../../services/Common/Common.type";


const platformDropdown: DropdownModel[] = [
    {
        value: 'android',
        label: 'android',
    },
    {
        value: 'ios',
        label: 'ios',
    }
]
interface AddEditApplicationProps {
    addEditOption: AddEditOption,
    OnHide: any,
    fetchListQuery: any
}

const validationSchema = Yup.object({
    version: Yup.string()
        .required('Please enter version')
        .trim(),
    platform: Yup.string()
        .required('Please enter description')
        .trim(),
    isForcefullyUpdate: Yup.boolean(),
})

const AddEditApplicationVersionWrapper = (props: AddEditApplicationProps) => {

    const { addEditOption, OnHide, fetchListQuery } = props
    const initAddEditApplicationVersionModel: ApplicationVersion = {
        id: 0,
        platform: null,
        version: null,
        message: null,
        latestVersion: null,
        isForcefullyUpdate: false,
        isShowUpdateDialog: false
    }

    const [addEditApplicationVersionModel, setAddEditApplicationVersionModel] = useState<ApplicationVersion>()
    const upsertApplicationVersionQuery = usePostQuery('upsertApplicationVersion', upsertApplicationVersion);
    const getApplicationVersionQuery = useGetQuery("getApplicationVersion", getApplicationVersion);

    useEffect(() => {
        if (!addEditOption.isEdit) {
            setAddEditApplicationVersionModel(initAddEditApplicationVersionModel);
        }
        else if (addEditOption.id > 0) {
            getApplicationVersionQuery.mutate(addEditOption.id)
        }
    }, [])

    useEffect(() => {
        if (getApplicationVersionQuery.isSuccess) {
            setAddEditApplicationVersionModel(getApplicationVersionQuery.data.result)
        }
    }, [getApplicationVersionQuery.isLoading, getApplicationVersionQuery.isLoading, getApplicationVersionQuery.isSuccess])

    const onSubmit = (values: ApplicationVersion) => {
        upsertApplicationVersionQuery.mutate(values)
    }

    useEffect(() => {
        if (upsertApplicationVersionQuery.isSuccess) {
            toastNotification('success', 'Application version has been updated successfully')
            OnHide();

        } else if (upsertApplicationVersionQuery.isError) {
            toastNotification('error',
                upsertApplicationVersionQuery?.error?.response?.data?.responseException?.exceptionMessage
            );
        }

        fetchListQuery();
    }, [upsertApplicationVersionQuery.isError, upsertApplicationVersionQuery.isSuccess, upsertApplicationVersionQuery.isLoading])

    return (<>
        <Modal show={addEditOption.isOpen} onHide={OnHide} size="lg" backdrop="static" keyboard={false} centered id="kt_modal_add_customer">
            {
                addEditApplicationVersionModel && (<>
                    <Formik
                        enableReinitialize={true}
                        initialValues={addEditApplicationVersionModel}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={false}
                    >{({ values, touched, errors, handleChange, handleSubmit, setFieldValue }: any) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            setFieldValue('version', addEditApplicationVersionModel?.version)
                            setFieldValue('platform', addEditApplicationVersionModel?.platform)
                            setFieldValue('isForcefullyUpdate', addEditApplicationVersionModel?.isForcefullyUpdate)
                            setFieldValue('id', addEditApplicationVersionModel?.id)
                        }, [setFieldValue])

                        return (<>
                            <fieldset>
                                <Form onSubmit={handleSubmit}>
                                    <ModalHeader closeButton id="kt_modal_add_customer_header">
                                        <Modal.Title>
                                            <h2>{addEditApplicationVersionModel?.id ? 'Edit' : 'Add'} a Application Version</h2>
                                        </Modal.Title>
                                    </ModalHeader>
                                    <Modal.Body>
                                        <div className="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                                            <BForm.Group className="fv-row mb-7">
                                                <BForm.Label className="required fs-6 fw-semibold mb-2">Version</BForm.Label>
                                                <BForm.Control
                                                    type="text"
                                                    placeholder="Version"

                                                    name="version"
                                                    maxLength={100}
                                                    onChange={handleChange}
                                                    defaultValue={values.version ?? ''}
                                                    className={clsx("form-control bg-transparent",
                                                        { 'is-invalid': touched.version && errors.version },
                                                        {
                                                            'is-valid': touched.version && !errors.version,
                                                        }
                                                    )}
                                                    onBlur={(e) => {
                                                        setFieldValue('version', e.target.value)
                                                    }}
                                                />
                                                {errors.version && touched.version && <span className='text-danger'>{errors.version}</span>}
                                            </BForm.Group >
                                            <BForm.Group className='fv-row col-sm-6 col-md-6'>
                                                <BForm.Label className='required form-label'>Platform</BForm.Label>
                                                <select
                                                    className='form-select mb-2'
                                                    data-control='select2'
                                                    data-hide-search='true'
                                                    data-placeholder='Select an option'
                                                    value={values.platform ?? ''}
                                                    name='platform'
                                                    onChange={(e) => setFieldValue('platform', e.target.value)}
                                                >
                                                    <option key='' value='' disabled>
                                                        Select
                                                    </option>
                                                    {platformDropdown.map((x) => (
                                                        <option key={x.value} value={x.value}>
                                                            {x.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </BForm.Group>
                                            <BForm.Group>
                                                <BForm.Label className='form-label'>
                                                    Forcefully Update? &nbsp;
                                                </BForm.Label>
                                                <BForm.Group className='form-check form-check-custom form-check-solid mb-2'>
                                                    <BForm.Control
                                                        className='form-check-input'
                                                        id='chkIsForcefullyUpdate'
                                                        name='isForcefullyUpdate'
                                                        type='checkbox'
                                                        defaultChecked={values.isForcefullyUpdate}
                                                        onChange={(e) => {
                                                            setFieldValue('isForcefullyUpdate', (e.target as HTMLInputElement).checked);
                                                        }}
                                                    />
                                                    <BForm.Label className='form-check-label' htmlFor='chkIsForcefullyUpdate'>
                                                        IsForcefullyUpdate
                                                    </BForm.Label>
                                                </BForm.Group>
                                                {errors.isForcefullyUpdate && touched.isForcefullyUpdate && <span className='text-danger'>{errors.isForcefullyUpdate}</span>}
                                            </BForm.Group>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="button" id="kt_modal_add_customer_cancel" className="btn btn-light me-3" onClick={OnHide}>Cancel</button>
                                        <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                                            {upsertApplicationVersionQuery.isLoading ? <Spinner
                                                as='span'
                                                animation='border'
                                                size='sm'
                                                role='status'
                                                aria-hidden='false'
                                            /> :
                                                <span className="indicator-label">Save Changes</span>}
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            </fieldset>
                        </>)
                    }
                        }

                    </Formik>
                </>)
            }
        </Modal>
    </>);
}

export default AddEditApplicationVersionWrapper