import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { Form as BForm, Spinner } from 'react-bootstrap'
import clsx from 'clsx'
import { Formik, Form } from 'formik'
import { usePostQuery, useGetQuery } from '../../../../hooks/reactQueryHelper'
import {
  AddEditUserModel,
  AddEditUser,
  GetAllCitysById,
  GetAllStatesById,
  GetUser,
  UserModel,
} from '../../../../services/User/User.type'
import { useNavigate } from 'react-router'
import { DropdownModel } from '../../../../services/Common/Common.type'
import { BASE_ENDPOINT } from '../../../../shared/apiConfig'
import { USER_DARK_IMAGE_PATH, USER_DEFAULT_IMAGE_PATH } from '../../../../shared/constants'
import toastNotification from '../../../../helper/toastUtils'
import ImageCrop from '../../../components/ImageCrop'
import moment from 'moment'
import { Icon } from '@iconify/react'
import { themeModeSwitchHelper, useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { FocusError } from '../../../../helper/utility'


const API_URL = BASE_ENDPOINT

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required('Please enter first name')
    .trim()
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  surname: Yup.string()
    .required('Please enter last name')
    .trim()
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  mobile: Yup.string()
    .required('Please enter mobile number')
    .min(10, 'Mobile must be at least 10 characters')
    .matches(/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/, 'Please enter valid mobile number'),
  email: Yup.string().nullable().email('Please enter vaild email'),
  pincode: Yup.string()
    .nullable()
    .min(6, 'Please enter 6 Digit Pincode')
    .max(6, 'Please enter 6 Digit Pincode'),
  height: Yup.number()
    .nullable(),
  dob: Yup.date()
    .required('Please select date of birth'),
})

const AddEditUserPage: React.FC<any> = (props: any) => {
  const { id, getLoading, upsertLoading } = props
  const { mode } = useThemeMode()
  const navigate = useNavigate()
  const addEditUserQuery: any = usePostQuery('addUser', AddEditUser, {})
  const [selectImage, setSelectedImage] = useState<any>(mode === 'light' ? USER_DEFAULT_IMAGE_PATH : USER_DARK_IMAGE_PATH)
  const [selectFileBase64, setSelectFileBase64] = useState<any>(null)
  const [isImageNotFound, setIsImageNotFound] = useState<any>(false)
  const [stateList, setStateList] = useState<any>([])
  const [cityList, setCityList] = useState<any>([])
  const [visible, setVisible] = useState<any>(false)
  const [imagesrc, setImageSrc] = useState<any>()
  const [bgClass, setBgClass] = useState<string>('bg-success')
  const [ismobile, setMobile] = useState<any>([])
  const [isverified, setIsVerified] = useState<any>([])
  const fileInputprofileRef = useRef<any>(null)
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const [areaPixels, setareaPixels] = useState<any>(null);

  const getUserQuery: any = useGetQuery('getUser', GetUser)

  const getAllStatesByIdQuery: any = useGetQuery('getAllStatesById', GetAllStatesById)

  const getAllCityByIdQuery: any = useGetQuery(' getAllCityById', GetAllCitysById)

  const statusDropDown: DropdownModel[] = [
    {
      value: 1,
      label: 'Active',
    },
    {
      value: 0,
      label: 'Inactive',
    },
  ]

  const initAddEditUserModel: AddEditUserModel = {
    id: 0,
    firstName: '',
    surname: '',
    password: '',
    dob: null,
    weight: null,
    height: null,
    mobile: '',
    address: '',
    landmark: '',
    pincode: '',
    city: '',
    image: null,
    state: '',
    email: '',
    isActive: true,
    createdDate: null,
    modifiedDate: null,
    modifiedBy: 0,
    createdBy: 0,
    selectedStatus: statusDropDown[0],
    gender: 'F',
    fileBase64Data: null,
    participentEventCount: 0,
    createTimeDuration: '',
    isVerified: true,
    platForm: '',
    isInvited: false,
    deletedDate: null
  }

  const [addEditUserModel, setAddEditUserModel] = useState<AddEditUserModel>(initAddEditUserModel)
  useEffect(() => {
    if (id) getUserQuery.mutate(id)
  }, [id])


  useEffect(() => {
    getAllStatesByIdQuery.mutate(101)
  }, [])

  useEffect(() => {
    if (getAllStatesByIdQuery.data) {
      setStateList(getAllStatesByIdQuery?.data?.result)
    }
  }, [getAllStatesByIdQuery.data])

  useEffect(() => {
    if (getAllCityByIdQuery.data) {
      setCityList(getAllCityByIdQuery?.data?.result)
    }
  }, [getAllCityByIdQuery.data])

  useEffect(() => {
    if (getUserQuery.isSuccess) {
      const getUser: UserModel = getUserQuery.data.result
      addEditUserModel.address = getUser.address
      addEditUserModel.city = getUser.city
      if (getUser.image != null && getUser.image !== undefined && getUser.image !== '') {
        var selectImage = getUser.image
        setSelectedImage(selectImage)
      } else {
        setSelectedImage(mode === 'light' ? USER_DEFAULT_IMAGE_PATH : USER_DARK_IMAGE_PATH)
      }
      addEditUserModel.dob = getUser.dob
      addEditUserModel.email = getUser.email
      addEditUserModel.firstName = getUser.firstName
      addEditUserModel.gender = getUser.gender != null ? getUser.gender.toUpperCase() : getUser.gender
      addEditUserModel.height = getUser.height
      addEditUserModel.weight = getUser.weight
      addEditUserModel.id = getUser.id
      addEditUserModel.isActive = getUser.isActive
      addEditUserModel.landmark = getUser.landmark
      addEditUserModel.mobile = getUser.mobile
      addEditUserModel.pincode = getUser.pincode
      addEditUserModel.state = getUser.state
      addEditUserModel.surname = getUser.surname
      addEditUserModel.isVerified = getUser.isVerified
      if (getUser.isVerified === false && getUser.platForm === null) {
        setIsVerified(false)
        setMobile(false)
      }
      if (getUser.isVerified === true && getUser.platForm === null) {
        setIsVerified(false)
        setMobile(true)
      }
      if (getUser.isVerified === false && getUser.platForm != null) {
        setIsVerified(true)
        setMobile(false)
      }
      addEditUserModel.selectedStatus = statusDropDown[getUser.isActive === true ? 0 : 1]
      setBgClass(getUser.isActive === true ? 'bg-success' : 'bg-danger')
      if (
        addEditUserModel.state != null &&
        addEditUserModel.state !== '' &&
        stateList != null &&
        stateList.length > 0
      ) {
        var selectState = stateList.find((x: any) => {
          return x.name === addEditUserModel.state
        })
        if (selectState)
          getAllCityByIdQuery.mutate(selectState?.id)
      }
      setAddEditUserModel({ ...addEditUserModel })
    }
  }, [getUserQuery?.isSuccess])

  const HandleButtonAction = (row: any, type: string) => {
    if (type === 'Cancel') {
      setAddEditUserModel({ ...initAddEditUserModel })
      navigate('/users')
    }
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  const profileChange = async (e) => {
    setIsImageNotFound(false)
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader()
        reader.onload = (e) => {
          setImageSrc(e.target?.result)
        }
        reader.readAsDataURL(e.target.files[0])
        setVisible(true)
      }
      else {
        toastNotification(
          'warning',
          'Please select only image jpg, jpeg, png, gif'
        )
        return
      }
    }
    fileInputprofileRef.current.value = null
  }

  const cancelProfile = async (e) => {
    setSelectedImage(mode === 'light' ? USER_DEFAULT_IMAGE_PATH : USER_DARK_IMAGE_PATH)
  }

  const onlyNumberValidate = (evt: any) => {
    var theEvent = evt || window.event
    // Handle key press
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    var regex = /[0-9]|\./
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  }

  const onlyCharacterValidate = (evt: any) => {
    var theEvent = evt || window.event
    // Handle key press
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    var regex = /^[aA-zZ\s]+$/
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }

  }

  const onSubmit = (values: any) => {
    addEditUserModel.address = values.address
    addEditUserModel.city = values.city
    addEditUserModel.dob = values.dob != "" ? values.dob : null
    addEditUserModel.email = values.email
    addEditUserModel.firstName = values.firstName
    addEditUserModel.gender = values.gender
    addEditUserModel.height = parseFloat(values.height)
    addEditUserModel.weight = parseFloat(values.weight)
    addEditUserModel.id = parseInt(values.id)
    addEditUserModel.isActive = values.isActive
    addEditUserModel.landmark = values.landmark
    addEditUserModel.mobile = values.mobile
    addEditUserModel.pincode = values.pincode
    addEditUserModel.state = values.state
    addEditUserModel.surname = values.surname
    addEditUserModel.fileBase64Data = selectFileBase64
    if (addEditUserModel.id > 0) {
      if (selectImage === USER_DEFAULT_IMAGE_PATH && getUserQuery.data.result?.image != null) {
        addEditUserModel.fileBase64Data = null
      }
      if (selectImage !== USER_DEFAULT_IMAGE_PATH && getUserQuery.data.result?.image != null) {
        addEditUserModel.image = getUserQuery.data.result?.image
      }
      addEditUserModel.image = addEditUserModel.image === '' ? null : addEditUserModel.image
      if (selectImage === USER_DEFAULT_IMAGE_PATH) {
        addEditUserModel.image = null
      }
      addEditUserModel.image = addEditUserModel.image === '' ? null : addEditUserModel.image
    }
    setAddEditUserModel({ ...addEditUserModel })
    addEditUserQuery.mutate(addEditUserModel)
  }

  const toggleVisible = React.useCallback(() => {
    setVisible((p) => !p)
  }, [visible])

  useEffect(() => {
    if (addEditUserQuery?.data?.statusCode === 200) {
      if (addEditUserModel.id > 0) toastNotification('success', 'User has been updated successfully')
      else toastNotification('success', 'User has been created successfully')
      navigate('/users')
    }
  }, [addEditUserQuery?.isError, addEditUserQuery?.isSuccess])


  useEffect(() => {
    themeModeSwitchHelper(mode)
    setSelectedImage(mode === 'light' ? USER_DEFAULT_IMAGE_PATH : USER_DARK_IMAGE_PATH)
  }, [mode])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addEditUserModel}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }: any) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setFieldValue('address', addEditUserModel.address)
            setFieldValue('city', addEditUserModel.city)
            setFieldValue('dob', addEditUserModel.dob)
            setFieldValue('email', addEditUserModel.email)
            setFieldValue('firstName', addEditUserModel.firstName)
            setFieldValue('gender', addEditUserModel.gender)
            setFieldValue('height', addEditUserModel.height)
            setFieldValue('weight', addEditUserModel.weight)
            setFieldValue('id', addEditUserModel.id)
            setFieldValue('isActive', addEditUserModel.isActive)
            setFieldValue('landmark', addEditUserModel.landmark)
            setFieldValue('mobile', addEditUserModel.mobile)
            setMobile(null)
            setFieldValue('pincode', addEditUserModel.pincode)
            setFieldValue('state', addEditUserModel.state)
            setFieldValue('surname', addEditUserModel.surname)
            setFieldValue('selectedStatus', addEditUserModel.selectedStatus)
            setFieldValue('image', addEditUserModel.image)
            setIsVerified(false)
          }, [setFieldValue])

          const onStateChange = (stateName) => {
            setFieldValue('state', stateName)
            setFieldValue('city', '')
            var selectState = stateList.find((item) => item.name === stateName)
            getAllCityByIdQuery.mutate(selectState.id)
          }

          return (
            <div id='kt_app_content' className='app-content flex-column-fluid'>
              <div id='kt_app_content_container' className='app-container'>
                <ImageCrop
                  visible={visible}
                  imagesrc={imagesrc}
                  onCancel={toggleVisible}
                  setareaPixels = {setareaPixels}
                  onComplete={(imagePromisse) => {
                    imagePromisse.then((image) => {
                      setSelectFileBase64(image)
                      setSelectedImage(image)
                      setVisible(false)
                    })
                  }}
                  containerStyle={{
                    position: 'relative',
                    width: '100%',
                    height: 300,
                    background: '#333',
                  }}
                  imageModule="UserModule"
                />
                <fieldset disabled={upsertLoading || getLoading}>
                  <Form
                    onSubmit={handleSubmit}
                    noValidate
                    className='form d-flex flex-column flex-lg-row'
                    id='kt_sign_in_form'
                  >
                    <div className='col-md-12 d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='kt_ecommerce_add_event_general'
                          // eslint-disable-next-line jsx-a11y/aria-role
                          role='tab-panel'
                        >
                          <div className='d-flex flex-column gap-7 gap-lg-10'>
                            <div className='card card-flush py-4'>
                              <div className='card-header'>
                                <div className='card-title'>
                                  <h2>{id ? 'Edit User Profile' : 'User Profile'}</h2>
                                </div>
                              </div>
                              <div className='card-body row pt-0'>
                                <div className='row g-3'>
                                  <div className='col-sm-12 col-md-8'>
                                    <div className='row'>
                                      <div className='col-sm-12 col-md-4'>
                                        <BForm.Group
                                          className='fv-row mb-8'
                                          controlId='firstName'
                                        >
                                          <BForm.Label>
                                            First Name <span style={{ color: 'red' }}>*</span>
                                          </BForm.Label>
                                          <BForm.Control
                                            type='text'
                                            name='firstName'
                                            placeholder='First Name'
                                            onKeyPress={onlyCharacterValidate}
                                            maxLength={50}
                                            onChange={(e) => {
                                              setFieldValue('firstName', e.target.value)
                                            }}
                                            defaultValue={values.firstName ?? ''}
                                            className={clsx(
                                              'form-control bg-transparent',
                                              { 'is-invalid': touched.firstName && errors.firstName },
                                              {
                                                'is-valid': touched.firstName && !errors.firstName,
                                              }
                                            )}
                                            onBlur={(e) => {
                                              setFieldValue('firstName', e.target.value)
                                            }}
                                          />
                                          {errors.firstName && touched.firstName ? (
                                            <span className='text-danger'>{errors.firstName}</span>
                                          ) : null}
                                        </BForm.Group>
                                      </div>
                                      <div className='col-sm-12 col-md-4'>
                                        <BForm.Group
                                          className='fv-row mb-8'
                                          controlId='surname'
                                        >
                                          <BForm.Label>
                                            Last Name <span style={{ color: 'red' }}>*</span>
                                          </BForm.Label>
                                          <BForm.Control
                                            type='text'
                                            name='surname'
                                            placeholder='Last Name'
                                            maxLength={50}
                                            onChange={handleChange}
                                            onKeyPress={onlyCharacterValidate}
                                            defaultValue={values.surname ?? ''}
                                            onBlur={(e) => {
                                              setFieldValue('surname', e.target.value)
                                            }}
                                            className={clsx(
                                              'form-control bg-transparent',
                                              { 'is-invalid': touched.surname && errors.surname },
                                              {
                                                'is-valid': touched.surname && !errors.surname,
                                              }
                                            )}
                                          />
                                          {errors.surname && touched.surname ? (
                                            <span className='text-danger'>{errors.surname}</span>
                                          ) : null}
                                        </BForm.Group>
                                      </div>
                                      <div className='col-sm-12 col-md-4'>
                                        <BForm.Group
                                          className='fv-row mb-8'
                                          controlId='mobile'
                                        >
                                          <BForm.Label>
                                            Mobile Number<span style={{ color: 'red' }}>*</span>
                                          </BForm.Label>

                                          <BForm.Control
                                            type='text'
                                            name='mobile'
                                            minLength={10}
                                            maxLength={15}
                                            placeholder='Mobile'
                                            defaultValue={values.mobile ?? ''}
                                            disabled={ismobile}
                                            onChange={handleChange}
                                            onKeyPress={onlyNumberValidate}
                                            onBlur={(e) => {
                                              setFieldValue('mobile', e.target.value)
                                            }}
                                            className={clsx(
                                              'form-control bg-transparent',
                                              { 'is-invalid': touched.mobile && errors.mobile },
                                              {
                                                'is-valid': touched.mobile && !errors.mobile,
                                              }
                                            )}
                                          />
                                          {errors.mobile && touched.mobile ? (
                                            <span className='text-danger'>{errors.mobile}</span>
                                          ) : null}
                                        </BForm.Group>
                                      </div>
                                    </div>
                                    <div className='row'>
                                      <div className='col-sm-12 col-md-4'>
                                        <BForm.Group
                                          className='fv-row mb-8'
                                          controlId='email'
                                        >
                                          <BForm.Label>Email</BForm.Label>
                                          <BForm.Control
                                            type='email'
                                            name='email'
                                            disabled={isverified}
                                            placeholder='Email'
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                              setFieldValue('email', e.target.value)
                                            }}
                                            maxLength={50}
                                            defaultValue={values.email ?? ''}
                                            className={clsx(
                                              'form-control bg-transparent',
                                              {
                                                'is-invalid':
                                                  values.email !== null &&
                                                  values.email !== '' &&
                                                  touched.email &&
                                                  errors.email,
                                              },
                                              {
                                                'is-valid':
                                                  values.email !== null &&
                                                  values.email !== '' &&
                                                  touched.email &&
                                                  !errors.email,
                                              }
                                            )}
                                          />
                                          {errors.email && touched.email ? (
                                            <span className='text-danger'>{errors.email}</span>
                                          ) : null}
                                        </BForm.Group>
                                      </div>
                                      <div className='col-sm-12 col-md-4'>
                                        <BForm.Group
                                          className='fv-row mb-8 col-sm-12 col-md-12'
                                          controlId='weight'
                                        >
                                          <BForm.Label>Weight</BForm.Label>
                                          <BForm.Control
                                            type='number'
                                            name='weight'
                                            placeholder='weight'
                                            onChange={handleChange}
                                            value={values.weight || ''}
                                            onKeyPress={preventMinus}
                                            min={0}
                                            step='0.01'
                                          />
                                          <div className="text-muted fs-7"><span>Weight in Kilogram</span></div>
                                        </BForm.Group>


                                      </div>
                                      <div className='col-sm-12 col-md-4'>
                                        <BForm.Group
                                          className='fv-row mb-8'
                                          controlId='height'
                                        >
                                          <BForm.Label>Height</BForm.Label>
                                          <BForm.Control
                                            type='number'
                                            name='height'
                                            placeholder='Height'
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                              setFieldValue('height', e.target.value)
                                            }}
                                            value={values.height || ''}
                                            min={140}
                                            onKeyPress={preventMinus}
                                            step='0.01'
                                            className={clsx(
                                              'form-control bg-transparent',
                                              {
                                                'is-invalid':
                                                  values.height != null &&
                                                  values.height !== 0 &&
                                                  touched.height &&
                                                  errors.height,
                                              },
                                              {
                                                'is-valid':
                                                  values.height != null &&
                                                  values.height !== 0 &&
                                                  values.height > 0 &&
                                                  touched.height &&
                                                  !errors.height,
                                              }
                                            )}
                                          />{' '}
                                          {errors.height && touched.height ? (
                                            <span className='text-danger'>{errors.height}</span>
                                          ) : <span className="text-muted fs-7">Height in centimeter</span>}
                                        </BForm.Group>
                                      </div>
                                      <div className='row'>
                                        <div className='col-sm-12 col-md-4'>
                                          <BForm.Group
                                            className='fv-row mb-8'
                                            controlId='dob'
                                          >
                                            <BForm.Label>Date Of Birth  <span style={{ color: 'red' }}>*</span></BForm.Label>
                                            <BForm.Control
                                              type='date'
                                              name='dob'
                                              placeholder='Date Of Brith'
                                              onChange={(e) => {
                                                setFieldValue('dob', e.target.value)
                                              }}
                                              onBlur={(e) => {
                                                setFieldValue('dob', e.target.value)
                                              }}
                                              value={moment(values.dob).format('YYYY-MM-DD')}
                                              max={moment().subtract(8, 'years').format('YYYY-MM-DD')}
                                              className={clsx(
                                                'form-control bg-transparent',
                                                { 'is-invalid': touched.dob && errors.dob },
                                                {
                                                  'is-valid': touched.dob && !errors.dob,
                                                }
                                              )} />
                                            {errors.dob && touched.dob ? (
                                              <span className='text-danger'>{errors.dob}</span>
                                            ) : null}
                                          </BForm.Group>
                                        </div>
                                        <div className='col-sm-12 col-md-4'>
                                          <BForm.Group
                                            className='fv-row mb-8'
                                            controlId='city'
                                          >
                                            <BForm.Label>Status</BForm.Label>
                                            <select
                                              className='form-select mb-2'
                                              data-control='select2'
                                              data-hide-search='true'
                                              data-placeholder='Select an option'
                                              value={values.selectedStatus.value}
                                              name='status'
                                              onChange={(e) => {
                                                const isActive = e.target.value === '1'
                                                values.isActive = isActive
                                                values.selectedStatus = isActive
                                                  ? statusDropDown[0]
                                                  : statusDropDown[1] // Check the selected value
                                                setBgClass(isActive ? 'bg-success' : 'bg-danger')
                                              }}
                                            >
                                              <option key='' value='' disabled>
                                                Select
                                              </option>
                                              {statusDropDown.map((x) => (
                                                <option key={x.value} value={x.value}>
                                                  {x.label}
                                                </option>
                                              ))}
                                            </select>
                                          </BForm.Group>
                                        </div>
                                        <div className='col-sm-12 col-md-4'>
                                          <BForm.Group
                                            className='mb-5 mt-12'
                                            key='GenderRadio'
                                          >
                                            <BForm.Label>Gender <span style={{ color: 'red' }}>*</span> &nbsp; </BForm.Label>
                                            <BForm.Check
                                              type='radio'
                                              label='Male'
                                              name='gender'
                                              id='maleGender'
                                              inline
                                              value='M'
                                              checked={values.gender === 'M'}
                                              onChange={() => setFieldValue('gender', 'M')}
                                            />
                                            <BForm.Check
                                              type='radio'
                                              label='Female'
                                              name='gender'
                                              id='femaleGender'
                                              inline
                                              value='F'
                                              defaultChecked={true}
                                              checked={values.gender === 'F'}
                                              onChange={() => setFieldValue('gender', 'F')}
                                            />
                                          </BForm.Group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4'>
                                    <div className='text-center pt-0 image-input' style={{ marginLeft: '28%', marginTop: '5%' }}>
                                      <div className="img-content image-input image-input-empty image-input-outline image-input-placeholder mb-3" style={{ border: '3px solid var(--bs-body-bg)', boxShadow: 'var(--bs-box-shadow)' }}>
                                        <a href="javascript:void(0)">
                                          <div className="img-content-overlay"></div>
                                          <img width={'250px'}
                                            className="img-content-image image-input-wrapper w-200px h-200px userProfileImage"
                                            src={selectImage} alt="" />
                                          <div className="img-content-details fadeIn-bottom fadeIn-left">
                                            <h1><label
                                              className='btn btn-icon btn-circle btn-active-color-primary profileEdit'
                                              data-kt-image-input-action='change'
                                              data-bs-toggle='tooltip'
                                              title='Change avatar'

                                            >
                                              <span className='text-muted'>
                                                <Icon icon="uil:image-upload" width="50" height="50" />
                                              </span>
                                              <input
                                                type='file'
                                                name='avatar'
                                                accept='.png, .jpg,.jpeg'
                                                ref={fileInputprofileRef}
                                                onChange={profileChange}
                                              />
                                              <input type='hidden' name='avatar_remove' />
                                            </label>


                                            </h1>
                                          </div>
                                        </a>

                                      </div>
                                      {selectImage !== USER_DEFAULT_IMAGE_PATH &&
                                        !isImageNotFound && (
                                          <span
                                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow profileDelete'
                                            onClick={cancelProfile}
                                            data-bs-toggle='tooltip'
                                            title='Cancel avatar'
                                          >
                                            <span className='svg-icon svg-icon-3'>
                                              <Icon icon='ion:close' />
                                            </span>
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='card card-flush py-4'>
                              <div className='card-header'>
                                <div className='card-title'>
                                  <h2>Address</h2>
                                </div>
                              </div>
                              <div className='card-body row pt-0'>
                                <BForm.Group
                                  className='fv-row mb-8 col-sm-6 col-md-12'
                                  controlId='address'
                                >
                                  <BForm.Label>Address</BForm.Label>
                                  <BForm.Control
                                    as='textarea'
                                    name='address'
                                    placeholder='Address'
                                    onChange={handleChange}
                                    maxLength={200}
                                    onBlur={(e) => {
                                      setFieldValue('address', e.target.value)
                                    }}
                                    defaultValue={values.address ?? ''}
                                  />
                                  {errors.address && touched.address ? (
                                    <span className='text-danger'>{errors.address}</span>
                                  ) : null}
                                </BForm.Group>
                                <BForm.Group
                                  className='fv-row mb-8 col-sm-6 col-md-12'
                                  controlId='landmark'
                                >
                                  <BForm.Label>Landmark</BForm.Label>
                                  <BForm.Control
                                    type='text'
                                    name='landmark'
                                    placeholder='Landmark'
                                    maxLength={150}
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                      setFieldValue('landmark', e.target.value)
                                    }}
                                    defaultValue={values.landmark ?? ''}
                                  />
                                  {errors.landmark && touched.landmark ? (
                                    <span className='text-danger'>{errors.landmark}</span>
                                  ) : null}
                                </BForm.Group>
                                <BForm.Group
                                  className='fv-row mb-8 col-sm-6 col-md-4'
                                  controlId='city'
                                >
                                  <BForm.Label>City  </BForm.Label>
                                  <BForm.Control
                                    type='text'
                                    name='city'
                                    placeholder='City'
                                    maxLength={50}
                                    onChange={handleChange}
                                    defaultValue={values.city ?? ''}
                                    onBlur={(e) => {
                                      setFieldValue('city', e.target.value)
                                    }}

                                    className={clsx(
                                      'form-control bg-transparent',
                                      { 'is-invalid': touched.city && errors.city },
                                      {
                                        'is-valid': touched.city && !errors.city,
                                      }
                                    )}
                                  />
                                  {errors.city && touched.city ? (
                                    <span className='text-danger'>{errors.city}</span>
                                  ) : null}
                                </BForm.Group>

                                <BForm.Group
                                  className='fv-row mb-8 col-sm-6 col-md-4'
                                  controlId='state'
                                >
                                  <BForm.Label>State</BForm.Label>
                                  <select
                                    className='form-select mb-2'
                                    data-control='select2'
                                    data-hide-search='true'
                                    data-placeholder='Select an option'
                                    value={values.state ?? ''}
                                    name='state'
                                    onChange={(e) => onStateChange(e.target.value)}
                                  >
                                    <option key='' value='' disabled>
                                      Select
                                    </option>
                                    {stateList.map((x) => (
                                      <option key={x.id} value={x.name}>
                                        {x.name}
                                      </option>
                                    ))}
                                  </select>
                                </BForm.Group>


                                <BForm.Group
                                  className='fv-row mb-8 col-sm-6 col-md-4'
                                  controlId='pincode'
                                >
                                  <BForm.Label>Pincode</BForm.Label>
                                  <BForm.Control
                                    onKeyPress={onlyNumberValidate}
                                    type='text'
                                    name='pincode'
                                    placeholder='Pincode'
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                      setFieldValue('pincode', e.target.value)
                                    }}
                                    defaultValue={values.pincode ?? ''}
                                    maxLength={6}
                                    minLength={6}
                                    className={clsx(
                                      'form-control bg-transparent',
                                      {
                                        'is-invalid':
                                          values.pincode !== null &&
                                          values.pincode !== '' &&
                                          touched.pincode &&
                                          errors.pincode,
                                      },
                                      {
                                        'is-valid':
                                          values.pincode !== null &&
                                          values.pincode !== '' &&
                                          touched.pincode &&
                                          !errors.pincode,
                                      }
                                    )}
                                  />
                                  {errors.pincode && touched.pincode ? (
                                    <span className='text-danger'>{errors.pincode}</span>
                                  ) : null}
                                </BForm.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          id='kt_ecommerce_add_event_cancel'
                          className='btn btn-light me-5'
                          onClick={(e) => HandleButtonAction(e, 'Cancel')}
                        >
                          Cancel
                        </button>
                        <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
                          {addEditUserQuery.isLoading ? (
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='false'
                            />
                          ) : (
                            <span className='indicator-label'>Save Changes</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                  <FocusError />
                </fieldset>
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}

export default AddEditUserPage