import { Icon } from "@iconify/react";
import clsx from "clsx";
import CircularProgress from '@mui/material/CircularProgress';

interface FileUploadProps {
  name: any;
  onChange?: any;
  isIcon?: boolean;
  IconName?: string;
  accept?: string;
  className?: string;
}

interface ButtonProps {
  name: any;
  onClick?: any;
  istrue?: any;
  loading?: any;
  className?: any;
  isDisabled?: any;
  isICon?: boolean;
  IconName?: string;
  IconClass?: string;
  textClass?: string;
  isBloackButton?: boolean
}

export const UploadFileComponent: React.FC<FileUploadProps> = ({
  name,
  onChange,
  isIcon,
  IconName,
  accept,
  className,
}) => {
  return (
    <>
      <form>
        <label
          className={clsx(
            "",
            className ? className : ""
          )}

        >
          <span className="btn btn-primary btn d-flex align-items-center hover:btn-info hover:text-black">
            <span className="pe-2">
              {isIcon && (
                <Icon icon="mdi:file-upload" width={20} style={{ color: 'white' }} />
              )}
            </span>
            <span>
              {name}
            </span>
            <input
              id="uploadfile"
              type="file"
              className="d-none"
              onChange={onChange}
              accept={accept}
            />
          </span>
        </label>

      </form>
    </>
  );
};

export const ButtonComponent: React.FC<ButtonProps> = ({
  name,
  istrue,
  onClick,
  loading,
  className,
  isDisabled,
}) => {
  return (
    <div>
      <button
        type="submit"
        onClick={istrue ? onClick : null}
        className={clsx(
          "",
          isDisabled
            ? "cursor-none"
            : "cursor-pointer",
          className ? className : ""
        )}
        disabled={isDisabled ? isDisabled : loading}
      >
        {loading ? (
          <>
          <div className="px-5">
            <CircularProgress color="inherit" thickness={5} variant="indeterminate" size={18} />
          </div>
          </>
        ) : (
          <>{name}</>
        )}
      </button>
    </div>
  );
};