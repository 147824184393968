import { KTCard } from "../../../_metronic/helpers";
import React from "react";
import UserTransactionPage from "../user/ViewUser/UserTransactionPage";

const TransactionPage: React.FC = (Props: any) => {
    return (
        <>
            <UserTransactionPage showUsername={true} />
        </>
    )
}

export default TransactionPage