import {FC, useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {themeModeSwitchHelper, useThemeMode} from '../partials/layout/theme-mode/ThemeModeProvider'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import {WithChildren, toAbsoluteUrl} from '../helpers'

const MasterLayout: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const {mode} = useThemeMode()
  const location = useLocation()
  const pathname = window.location
  useEffect(() => {
    document.body.style.backgroundImage = `url(${toAbsoluteUrl(`/media/auth/header-bg.jpg`)})`
    const root = document.getElementById('root')
    if (root) {
      root.style.height = 'auto'
    }
    if (pathname?.pathname === '/dashboard') {
      document.body.style.backgroundPosition = ``
      document.body.style.backgroundSize = ``
    }
    return () => {
      document.body.style.backgroundImage = `url(${toAbsoluteUrl(`/media/auth/header-bg.jpg`)})`
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid' id='style-1'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <div className='scrollbar' id='style-1'>
            <div className='force-overflow'>
              <HeaderWrapper />

              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <Toolbar />
                <div
                  className={clsx(
                    'd-flex flex-column-fluid align-items-start',
                    classes.contentContainer.join(' ')
                  )}
                  id='kt_post'
                >
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
              
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
