import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import AddEditUserPage from "./AddEditUserPage";
import { useParams } from "react-router-dom";

const AddEditUserPageWrapper = (props: any) => {
    const { id } = useParams()
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Users',
            path: '/users',
            isSeparator: true,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: true,
        }
    ];
    
    return (
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>{id ? 'Edit User' : 'Add User'}</PageTitle>
            <AddEditUserPage id={id}/>
        </>
    )
}

export default AddEditUserPageWrapper;