import { KTCard} from "../../../_metronic/helpers"
import React from "react";
import { UsersTable } from "./UsersTable";

const UserPage: React.FC = (props: any) => {
    return (
        <>
          <KTCard>
            <UsersTable />
          </KTCard>
        </>
      )
}

export default UserPage