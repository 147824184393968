/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { GetTransactionEventList, GetTransactionUserList } from '../../../../services/User/User.type'
import { usePostQuery } from '../../../../hooks/reactQueryHelper'
import {
  GetUserTransactionWidget,
  TransactionFilter,
} from '../../../../services/Dashboard/Dashboard.type'
import { Form as BForm, Spinner } from 'react-bootstrap'
import { DateRangePicker } from 'react-date-range'
import { DateRange } from 'react-date-range'
import moment from 'moment'

type Props = {
  onFilterSubmit: (filters: { userFilter: any; eventFilter: any; paymentdateFilter: any; paymentEndDateFilter: any }) => void
}

export function Dropdown1({ onFilterSubmit }: Props) {
  const getTransactionUserListQuery: any = usePostQuery(
    'getTransactionUserList',
    GetTransactionUserList,
    {}
  )
  const getTransactionEventListQuery: any = usePostQuery(
    'getTransactionEventList',
    GetTransactionEventList,
    {}
  )
  const getUserTransactionWidget = usePostQuery(
    'GetUserTransactionWidget',
    GetUserTransactionWidget,
    {}
  )
  const { mode } = useThemeMode()
  const [userFilter, setUserFilter] = useState<any>(0)
  const [eventFilter, setEventFilter] = useState<any>(0)
  const [paymentdateFilter, setPaymentDateFilter] = useState<any>(null)
  const [paymentEndDateFilter, setPaymentEndDateFilter] = useState<any>(null)


  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ])
  const inputModel: TransactionFilter = {
    userID: userFilter,
    eventID: eventFilter,
    fromDate: dateRange.startDate,
    endDate: dateRange.EndDate
  }

  useEffect(() => {
    getTransactionUserListQuery.mutate()
    getTransactionEventListQuery.mutate()
  }, [])

  const onHandleEventChange = (e: any) => {
    setEventFilter(e.target.value)
  }
  const onHandleUserChange = (e: any) => {
    setUserFilter(e.target.value)
  }
  const onChangePaymentDate = (e: any) => {
    setPaymentDateFilter(moment(e.startDate).format('YYYY-MM-DD'))
    setPaymentEndDateFilter(moment(e.endDate).format('YYYY-MM-DD'))
  }

  const resetFilters = () => {
    dateRange.startDate = null
    dateRange.endDate = null
    setDateRange(dateRange)
    setUserFilter(0)
    setEventFilter(0)
    setPaymentDateFilter(null)
    setPaymentEndDateFilter(null)
  }
  const handleReset = () => {
    resetFilters()
    onSubmit()
  }
  const onSubmit = () => {
    onFilterSubmit({ userFilter, eventFilter, paymentdateFilter, paymentEndDateFilter })
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-700px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bold'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='mb-10'>
              <select
                className='form-select mb-2 col-md-4'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Select an option'
                id='kt_select2_3'
                title='Select an option'
                value={eventFilter}
                onChange={onHandleEventChange}
              >
                <option value='0'>Select Event</option>
                {getTransactionEventListQuery.data?.result?.map((x: any) => (
                  <option key={x.id} value={x.id} title={x.name}>
                    {x.name.length > 20 ? x.name.substring(0, 20) + '...' : x.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='mb-10'>
              <select
                className='form-select mb-2 col-md-4'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Select an option'
                id='kt_select2_3'
                title='Select an option'
                value={userFilter}
                onChange={onHandleUserChange}
              >
                <option value='0'>Select User</option>
                {getTransactionUserListQuery?.data?.result?.map((x) => (
                  <option key={x.id} value={x.id} title={x.id}>
                    {x.firstName} {x.surname}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mb-4'>
              <div className='mb-10'>
                <DateRange
                  editableDateInputs={false}
                  onChange={(item) => {
                    setDateRange([item.selection]);
                    onChangePaymentDate(item.selection);
                  }}
                  moveRangeOnFirstSelection={true}
                  ranges={dateRange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className={clsx('btn btn-sm btn-active-light-primary me-2', {
              'btn-light ': mode === 'light',
            })}
            onClick={handleReset}
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>
          <button
            className={clsx(
              'btn btn-primary btn-sm',
              'fw-bolder px-6',
              'me-2',
              'fa-white text-hover-white',
              'cursor-pointer'
            )}
            data-kt-menu-dismiss='true'
            onClick={onSubmit}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
