import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useGetQuery } from '../../../../hooks/reactQueryHelper';
import { GetUser, UserModel } from "../../../../services/User/User.type";
import UserOverviewPage from "./UserOverviewPage";
import UserEventPage from "./UserEventPage";
import UserActivityPage from "./UserActivityPage";
import UserTransactionPage from "./UserTransactionPage";
import { USER_DEFAULT_IMAGE_PATH } from "../../../../shared/constants";
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from "clsx";
import { GetInitailCharaterClass } from "../../../../helper/utility";
import { useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ViewUserPage = (props: any) => {
    const getUserQuery: any = useGetQuery(
        'getUser',
        GetUser
    );
    const location = useLocation();
    const additionalData: any = location.state;
    const [userDetail, setUserDetail] = useState<UserModel>();
    const [tabType, setTabType] = useState<any>(additionalData?.tabName ?? 'Activity');
    const [, setProfileImageUrl] = useState<any>(USER_DEFAULT_IMAGE_PATH);
    
    useEffect(() => {
        if (getUserQuery.isSuccess) {
            const getUser: UserModel = getUserQuery.data.result;
            if (getUser != null && getUser.gender != null) {
                if (getUser.gender === "M") {
                    getUser.gender = "Male"
                } else if (getUser.gender === "F") {
                    getUser.gender = "Female"
                } else {
                    getUser.gender = "-"
                }
            }
            setUserDetail(getUser);
            
            if (getUser != null && getUser.image != null && getUser.image !== undefined && getUser.image !== '') {
                var profileImageUrl = getUser.image;
                setProfileImageUrl(profileImageUrl);
            } else {
                setProfileImageUrl(USER_DEFAULT_IMAGE_PATH);
            }
        }
    }, [getUserQuery?.isSuccess])

    useEffect(() => {
        const pathname = window.location.pathname.split("/");
        const id = Number(pathname[pathname.length - 1])
        if (!isNaN(id)) {
            getUserQuery.mutate(id)
        }
    }, [tabType])

    const [dateRange, setDateRange] = useState<any>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    return (
        <>
        <ReactTooltip id="my-tooltip"/>
            <div id="kt_app_content_container" className="app-container">
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-9 pb-0">
                        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                            <div className="me-7 mb-4">
                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">

                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                        {userDetail ? (
                                            userDetail?.image ? (
                                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                    <img
                                                        src={userDetail?.image}
                                                        alt='Fie Event'
                                                        onError={(ele) => {
                                                            ele.currentTarget.src = toAbsoluteUrl(USER_DEFAULT_IMAGE_PATH);
                                                        }}
                                                    />
                                                    {(!userDetail?.isActive) || userDetail?.deletedDate != null ? (
                                                        <div data-tooltip-id="my-tooltip" data-tooltip-content={`${!userDetail?.isActive ? "account is inactive":(userDetail?.deletedDate != null ? "account is deleted by user" :"Active")}`} className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-danger rounded-circle border border-4 border-body h-20px w-20px"></div>
                                                    ) : (
                                                        <div data-tooltip-id="my-tooltip" data-tooltip-content={`${!userDetail?.isActive ? "account is inactive":(userDetail?.deletedDate != null ? "account is deleted by user" :"Active")}`} className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                                    )}
                                                </div>
                                            ) : (
                                                <span className={clsx(`symbol-label`, 'fs-2x', `fw-semibold`, 'bg-light-info', GetInitailCharaterClass(userDetail?.firstName ? userDetail?.firstName.charAt(0).toUpperCase() : ''))}>{userDetail?.firstName.charAt(0).toUpperCase()}</span>
                                            )
                                        ) : (
                                            <Skeleton variant="rect" width={160} height={160} />
                                        )}

                                    </div>


                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">

                                    <div className="d-flex flex-column">

                                        <div className="d-flex align-items-center mb-2">
                                            <span className="text-gray-900 fs-2 fw-bold me-1">
                                                {userDetail ? `${userDetail.firstName} ${userDetail.surname}` : <Skeleton width={150} height={24} variant="rect" />}
                                            </span>
                                        </div>
                                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                            <div className="d-flex align-items-center text-gray-400 mb-2">
                                          
                                            {userDetail?.isVerified===false?<span className="badge badge-success"> Verified By {userDetail?.platForm}</span>:<span className="badge badge-success">Verified By OTP</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap flex-stack">
                                    <div className="d-flex flex-column flex-grow-1 pe-8">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">

                                                    <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">
                                                        {userDetail ? (
                                                            userDetail?.height ? (
                                                                <span>{userDetail?.height} cm</span>
                                                            ) : (
                                                                <span>-</span>
                                                            )
                                                        ) : (
                                                            <Skeleton variant="rect" width={60} />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="fw-semibold fs-6 text-gray-400">Height</div>

                                            </div>
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">
                                                        {userDetail ? (
                                                            userDetail?.weight ? (
                                                                <span>{userDetail?.weight} kg</span>
                                                            ) : (
                                                                <span>-</span>
                                                            )
                                                        ) : (
                                                            <Skeleton variant="rect" width={60} />
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="fw-semibold fs-6 text-gray-400">Weight</div>

                                            </div>

                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="75">
                                                        {userDetail ? userDetail.participentEventCount : <Skeleton width={60} />}
                                                    </div>
                                                </div>

                                                <div className="fw-semibold fs-6 text-gray-400">Participated Events</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'Activity' ? 'active' : '')} data-bs-toggle="tab" onClick={(e) => { setTabType('Activity') }}>Activities</button>
                            </li>
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'Overview' ? 'active' : '')} data-bs-toggle="tab" onClick={(e) => { setTabType('Overview') }}>Overview</button>
                            </li>
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'Event' ? 'active' : '')} data-bs-toggle="tab" onClick={(e) => { setTabType('Event') }}>Participated Events</button>
                            </li>
                            <li className="nav-item">
                                <button className={clsx("nav-link text-active-primary pb-4", tabType === 'Transaction' ? 'active' : '')} data-bs-toggle="tab" onClick={(e) => { setTabType('Transaction') }}>Transactions</button>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="tab-content">
                    {tabType === 'Overview' && <UserOverviewPage userDetail={userDetail} getUserQuery={getUserQuery} />}
                    {tabType === 'Activity' && <UserActivityPage userDetail={userDetail} dateRange={dateRange} setDateRange={setDateRange}/>}
                    {tabType === 'Event' && <UserEventPage userDetail={userDetail} />}
                    { userDetail  && userDetail != null && tabType === 'Transaction' && <UserTransactionPage userDetail={userDetail} showUsername={false} getUserQuery={getUserQuery} />}
                </div>
            </div>
        </>
    )
}

export default ViewUserPage;