/* eslint-disable jsx-a11y/anchor-is-valid */
import { CellProps, Column } from 'react-table'
import { KTCardBody, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Fragment, useEffect, useMemo, useState } from 'react'
import Table from '../../components/Table'
import useTableState from '../../components/Table/useTableState'
import TableInner from '../../components/Table/TableInner'
import { TablePagination } from '../../components/Table/TablePagination'
import clsx from 'clsx'
import { TableGlobalSearch } from '../../components/Table/TableGlobalSearch'
import { DeleteUser, GetAllUsers, UserModel } from '../../../services/User/User.type'
import { useDeleteQuery, usePostQuery } from '../../../hooks/reactQueryHelper'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { GridRequestModel, GridResponseModel, SortModel } from '../../../services/Common/Common.type'
import {
  GetInitailCharaterClass,
  applyUserSpecificDateFormat,
  checkImageExists,
} from '../../../helper/utility'
import { TableLoader } from '../../components/Table/TableLoader'
import { BASE_ENDPOINT } from '../../../shared/apiConfig'
import { USER_DEFAULT_IMAGE_PATH, USER_IMAGE_UPLOAD_PATH } from '../../../shared/constants'
import toastNotification from '../../../helper/toastUtils'
import showConfirmationDialog from '../../../helper/showConfirmationDialogUtility'
import { Icon } from '@iconify/react'
import { Modal } from 'react-bootstrap'
import UserEventPage from './ViewUser/UserEventPage'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const API_URL = BASE_ENDPOINT

const UsersTable = (props: any) => {
  const getAllUsersQuery: any = usePostQuery('getAllUsersQuery', GetAllUsers, {})
  const deleteUserQuery: any = useDeleteQuery('DeleteUser', DeleteUser, 0)
  const navigate = useNavigate()
  const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
    pageIndex: 0,
  })

  const initGridResponseModel: GridResponseModel = {
    count: 0,
    currentPage: 0,
    result: [],
    totalPages: 0,
  }

  const [userList, setUserList] = useState<GridResponseModel>(initGridResponseModel)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const { sort, page, limit, search } = tableState
  const [userDetail, setUserDetail] = useState<UserModel>()
  const initSortModel: SortModel = {
    sort: 'desc',
    fields: 'createdDate'
  }

  const initGridRequestModel: GridRequestModel = {
    page: page ?? 0,
    limit: limit ?? 0,
    search: search ? search : '',
    sort: sort ? sort : initSortModel,
    id: 0,
    eventId: 0,
    eventTypeID: 0,
    paymentDate: null,
    isInvited: false,
    isJoined: false
  }

  useEffect(() => {
    initGridRequestModel.page = page ?? 0
    initGridRequestModel.limit = limit ?? 0
    if (sort) initGridRequestModel.sort = sort
    if (search) initGridRequestModel.search = search
    getAllUsersQuery.mutate(initGridRequestModel)
  }, [page, limit, sort, search])

  const actionButtonHandler = (row: any, type: string) => {
    if (type === 'Add') {
      navigate(`/users/manage`)
    } else if (type === 'Edit') {
      navigate(`/users/manage/` + row?.id)
    } else if (type === 'View') {
      const additionalData = {
        tabName: 'Activity', // Additional data you want to pass
      }
      navigate(`/users/detail/` + row?.id, { state: additionalData })
    } else if (type === 'Modal') {
      setUserDetail(row)
      setIsOpenModal(true)
    } else if (type === 'Close') {
      setIsOpenModal(false)
    }
  }

  const openConfirmationDialog = async (row) => {
    const result = await showConfirmationDialog(
      'Confirmation',
      'Are you sure you want to delete user?',
      'delete'
    )
    if (result) deleteUserQuery.mutate(row.id)
  }

  useEffect(() => {
    if (getAllUsersQuery.data) {
      setUserList(getAllUsersQuery?.data?.result)
    }
  }, [getAllUsersQuery.data])

  useEffect(() => {
    if (deleteUserQuery?.isError) {
      toastNotification(
        'error',
        deleteUserQuery?.error?.response?.data?.responseException?.exceptionMessage
      )
    } else if (deleteUserQuery?.data?.statusCode === 200) {
      toastNotification('success', 'User deleted sucessfully')
      getAllUsersQuery.mutate(initGridRequestModel)
    }
  }, [deleteUserQuery.isError, deleteUserQuery.isSuccess])

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: `Name`,
        accessor: 'userName',
        Cell: (props: CellProps<any>) => {
          const fullName = `${props?.row?.original?.firstName} ${props?.row?.original?.surname}`
          return (
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                {props?.row?.original?.image ? (
                  <img
                    src={props?.row?.original?.image}
                    alt=''
                    onError={(ele) => {
                      ele.currentTarget.src = toAbsoluteUrl(USER_DEFAULT_IMAGE_PATH)
                    }}
                  />
                ) : (
                  <span
                    className={clsx(
                      `symbol-label`,
                      'fs-2x',
                      `fw-semibold`,
                      'bg-light-info',
                      GetInitailCharaterClass(props.row.original.firstName.charAt(0).toUpperCase())
                    )}
                  >
                    {props.row.original.firstName.charAt(0).toUpperCase()}
                  </span>
                )}
              </div>
              <div className='d-flex flex-column'>
                <a
                  className='text-gray-800 text-hover-primary mb-1'
                  href='javascript:void(0)'
                  onClick={() => {
                    const additionalData = {
                      tabName: 'Activity', // Additional data you want to pass
                    }
                    navigate(`/users/detail/` + props?.row?.original?.id, { state: additionalData })
                  }}
                >
                  {fullName ? fullName : '-'}
                </a>

                <span>{props?.row?.original?.email}</span>
              </div>
            </div>
          )
        },
      },
      {
        Header: `Mobile`,
        accessor: 'mobile',
        Cell: (props: CellProps<any>) => {
          return (
            <span>{`${props?.row?.original?.mobile ? props?.row?.original?.mobile : '-'}`}</span>
          )
        },
      },
      {
        Header: `Gender`,
        accessor: 'gender',
        Cell: (props: CellProps<any>) => {
          let gender =
            props?.row?.original?.gender != null ? props?.row?.original?.gender.toUpperCase() : '-'
          return <span>{`${gender === 'M' ? 'Male' : gender === 'F' ? 'Female' : '-'}`}</span>
        },
      },
      {
        Header: `Date Of birth`,
        accessor: 'dob',
        Cell: (props: CellProps<any>) => {
          return (
            <span>
              {' '}
              {props?.row?.original?.dob
                ? moment(props?.row?.original?.dob).format(applyUserSpecificDateFormat())
                : '-'}
            </span>
          )
        },
      },
      {
        Header: `Status`,
        accessor: 'isActive',
        disableSortBy: true,
        tooltip: 'isActive',
        Cell: (props: CellProps<any>) => {
          const className = props?.row?.original?.isActive ? 'success' : 'danger'
          return (
            <>
              <span
                data-tooltip-id="my-tooltip" data-tooltip-content={props?.row?.original?.isActive === true ? 'Active' : 'In Active'}
                className={clsx(
                  'badge badge-circle fs-1',
                  `badge-light-${className}`,
                  `fw-bolder`,
                  `cursor-pointer`
                )}
              >
                {props?.row?.original?.isActive ? (
                  <Icon icon='simple-line-icons:check' />
                ) : (
                  <Icon icon='radix-icons:cross-circled' />
                )}
              </span>
            </>
          )
        },
      },
      {
        Header: `Active Events`,
        accessor: 'participentEventCount',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          return (
            <div style={{ textAlign: 'center' }} data-tooltip-id="my-tooltip" data-tooltip-content="Active event count">
              <button
                type='button'
                className='btn btn-link text-primary btn-sm'
                onClick={() => {
                  const additionalData = {
                    tabName: 'Event',
                  }
                  navigate(`/users/detail/` + props?.row?.original?.id, { state: additionalData })
                }}
              >
                <span className='badge badge-circle badge-outline badge-primary btn-sm'>
                  {' '}
                  {props?.row?.original?.participentEventCount}
                </span>
              </button>
            </div>
          )
        },
      },
      {
        Header: <span className='d-block text-center rounded-end'>Actions</span>,
        accessor: 'id',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props.row.original
          return (
            <div className='d-flex justify-content-center flex-shrink-0'>
              <button
                data-tooltip-id="my-tooltip" data-tooltip-content="View"
                onClick={() => {
                  actionButtonHandler(result, 'View')
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <span className='svg-icon svg-icon-3'>
                  <Icon icon='carbon:view-filled' />
                </span>
              </button>
              <button
                data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                onClick={() => {
                  actionButtonHandler(result, 'Edit')
                }}
                className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
              >
                <span className='svg-icon svg-icon-3'>
                  <Icon icon='ic:baseline-edit' />
                </span>
              </button>
              <button
                data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                onClick={async () => openConfirmationDialog(result)}
              >
                <span className='svg-icon svg-icon-3'>
                  <Icon icon='ic:baseline-delete' />
                </span>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <ReactTooltip id="my-tooltip" content="" />
      <Table
        columns={columns}
        data={userList?.result ?? []}
        initialState={tableInitialState}
        pageCount={userList?.totalPages}
        onTableStateChange={handleTableStateChange}
      >
        <Fragment>
          <TableGlobalSearch
            actionButtonHandler={actionButtonHandler}
            serachtext={'Search'}
            addBtnText={'Create User'}
            isAddbtnVisible={true}
            isDropDownVisible={false}
          />
          <KTCardBody className='py-4'>
            <div className='table-responsive'>
              <TableInner loading={false} entityLabel='Users'></TableInner>
            </div>
            <TablePagination pageOptions={[5, 10, 20, 50]} />
            <TableLoader isLoading={getAllUsersQuery?.isLoading} />
          </KTCardBody>
        </Fragment>
      </Table>

      <Modal
        show={isOpenModal}
        onHide={() => actionButtonHandler(null, 'Close')}
        backdrop='static'
        size='xl'
        keyboard={false}
      >
        <div className='modal-content'>
          <Modal.Header closeButton className='modal-header'>
            <h2 className='fw-bold'>Participated Event</h2>
          </Modal.Header>
          <Modal.Body className='modal-body scroll-y scroll-x'>
            <UserEventPage userDetail={userDetail} />
            <div className='text-end'>
              <button
                type='reset'
                className='btn btn-primary me-3'
                data-kt-users-modal-action='cancel'
                onClick={() => actionButtonHandler(null, 'Close')}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}

export { UsersTable }