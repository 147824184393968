import axios from 'axios';
// eslint-disable-next-line import/order
import { useMutation } from 'react-query';

// eslint-disable-next-line import/no-unresolved

import { BASE_ENDPOINT } from '../shared/apiConfig';
import { getToken } from '../app/modules/auth/core/AuthHelpers';
import toastNotification from '../helper/toastUtils';
import { validateJwtToken } from '../services/Login/Login.type';

const API_URL = BASE_ENDPOINT;

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosApi.interceptors.request.use((config: any) => {
  const token = getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

//validate response
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === "ERR_NETWORK" || error.code === "ERR_FAILED") {
      localStorage.clear();
      return (window.location.href = '/auth');
    }

    return Promise.reject(error);
  }
);

const ShowErrorToast = (res: any) => {
  if (!res.request.responseURL.includes(validateJwtToken)) {
    toastNotification('error', res.response?.data?.responseException?.exceptionMessage ?? 'Something went wrong.')
  }
}

const usePostQuery = (mutationKey = '', url: any, config = {}) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (formData: any = {}) => {
      return await axiosApi
        .post(url, formData, config)
        .then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: (res: any) => {
      ShowErrorToast(res);
      return res?.response
    }
  });
};

const usePutQuery = (mutationKey = '', url: any) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (formData: any = {}, config: any = {}) => {
      return await axiosApi
        .put(url, formData, config)
        .then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: (res: any) => {
      ShowErrorToast(res);
      return res?.response
    }
  });
};

const useDeleteQuery = (mutationKey = '', url: any, id: any) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (config: any) => {
      return await axiosApi
        .delete(url + "/" + config)
        .then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: (res: any) => {
      ShowErrorToast(res);
      return res?.response
    }
  });
};

const useTokenQuery = (mutationKey = '', url: any) => {

  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (value: any) => {
      return await axiosApi.post(url + '?token=' + value).then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: (res: any) => {
      ShowErrorToast(res);
      return res?.response
    }
  });
};

const useGetQuery = (mutationKey = '', url: any) => {

  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (value: any) => {
      return await axiosApi.post(url + "/" + value).then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: (res: any) => {
      ShowErrorToast(res);
      return res?.response
    }
  });
}

export { useGetQuery, usePostQuery, usePutQuery, useDeleteQuery, useTokenQuery };