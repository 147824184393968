export interface OtpRequestModel {
    id : number,
    mobile : string | null,
    otpCode : string | null,
    otpExpiry : any | null,
    otpRequestType : string | null,
    ipAddress : string | null,
    createdDate : any | null,
    isVerified : boolean,
    deviceType:string | null
}

export const GetAllOtpRequests = '/adminapi/getallotp'