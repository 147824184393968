import {useEffect, useState} from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import {useGetQuery} from '../../../hooks/reactQueryHelper'
import {EventModel, GetEvent} from '../../../services/Event/Event.type'

const EventRule = (props) => {
  const [eventRuleList, setEventRuleList] = useState<any[]>([])
  const getEventQuery: any = useGetQuery('getEvent', GetEvent)

  useEffect(() => {
    if (props.eventId) {
      getEventQuery.mutate(props.eventId)
    }
  }, [getEventQuery, props.eventId])

  useEffect(() => {
    if (getEventQuery.isSuccess) {
      const getEvent: EventModel = getEventQuery.data.result
      setEventRuleList(getEvent.eventRules ?? [])
    }
  }, [getEventQuery.isSuccess])

  return (
    <>
      <div className='table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-5'>
          <tbody>
            {eventRuleList != null &&
              eventRuleList.length > 0 &&
              eventRuleList.map((item, index) => (
                <tr>
                  <td className='min-w-400px'>
                    <span title={item.rule}>
                      {item.rule.length > 100 ? item.rule.substring(0, 100) + '...' : item.rule}
                    </span>
                  </td>
                </tr>
              ))}
              {eventRuleList.length === 0 &&   <tr><td className="fw-bold text-center h6"> No Rules exist.</td></tr> }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default EventRule
