export const BASE_LAYOUT_CONFIG_KEY = '';
export const USER_IMAGE_UPLOAD_PATH = '/upload/user/';
export const USER_DEFAULT_IMAGE_PATH = '/media/svg/avatars/blank.svg';
export const USER_DARK_IMAGE_PATH = '/media/svg/avatars/blank-dark.svg';
export const EVENT_IMAGE_UPLOAD_PATH = '/upload/event/';
export const EVENT_DEFAULT_IMAGE_PATH = '/media/svg/files/blank-image.svg';
export const EVENT_DARK_IMAGE_PATH='/media/svg/files/blank-image-dark.svg';
export const EVENT_CYCLING_IMAGE_PATH = '/media/events/directions_bike.svg';
export const EVENT_WALKING_IMAGE_PATH = '/media/events/directions_walk.svg';
export const EVENT_RUNNING_IMAGE_PATH = '/media/events/directions_run.svg';
export const MODE = { production: 'prod', development: 'dev', local: 'local',};


