import React, { FC } from 'react';
import UserPage from './UserPage';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

const UserPageWrapper: React.FC = (props: any) => {

  const widgetsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ];

  return (
    <>
      <PageTitle breadcrumbs={widgetsBreadCrumbs}> Users </PageTitle>
      <UserPage />
    </>
  );
};

export default UserPageWrapper;
