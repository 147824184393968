import { MenuItem } from './MenuItem'
import { useIntl } from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      <MenuItem title='Users' to='/users' />
      <MenuItem title='Events' to='/events' />
      <MenuItem title='OTP Requests' to='/otp-request' />
      <MenuItem title='Transactions' to='/transaction'/>
      <MenuItem title='Notifications' to='/notifications'/>
      <MenuItem title='Contact Enquiries' to='/contactinquiry'/>
      <MenuItem title='Home Slider' to='/homeslider'/>
      <MenuItem title='Application Version' to='/applicationversion'/>
    </>
  )
}