import { KTCard } from "../../../_metronic/helpers"
import HomeSliderTable from "./HomeSliderTable"

const HomeSliderPage = () => {
    return (
        <>
            <KTCard>
                <HomeSliderTable />
            </KTCard>
        </>
    )
}

export default HomeSliderPage