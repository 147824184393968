import Skeleton from '@material-ui/lab/Skeleton';
import { usePostQuery } from '../../../../hooks/reactQueryHelper';
import { useEffect, useState } from 'react'
import { EventRankModel, GetAllEventWinners } from '../../../../services/Event/Event.type';
import { GridEventWinnerModel } from '../../../../services/Common/Common.type';
import Table from 'react-bootstrap/esm/Table';
import { ConvertTime } from '../../../../helper/utility';
import { useParams } from 'react-router';
import { WinnerModel } from '../../../../services/User/EventWinnerModel.type';
import { MarkEventWinner } from '../../../../services/User/EventWinnerModel.type';
import { Icon } from '@iconify/react';
import showConfirmationDialog from '../../../../helper/showConfirmationDialogUtility';
import { useNavigate } from 'react-router-dom';

const EventWinnerDetailPage = (props: any) => {
	const { id } = useParams()
	const { eventDetail } = props;

	const getAllEventWinnersQuery: any = usePostQuery('getAllEventWinnersQuery', GetAllEventWinners, {})
	const markEventWinnerQuery: any = usePostQuery('markEventWinner', MarkEventWinner, {});
	const [eventWinnerList, setEventWinnerList] = useState<EventRankModel[]>([]);
	const [selectAgeGroupValue, setSelectAgeGroupValue] = useState<any>('');
	const [selectGenderValue, setSelectGenderValue] = useState<any>('');
	const [showWinningColumn, setShowWinningColumn] = useState(false);
	const [showButton, setShowButton] = useState(true);
	const [showButtontext, setButtonText] = useState<any>('');
	const navigate = useNavigate();
	const [buttonHoveredIndex, setButtonHoveredIndex] = useState<number | null>(null);
	const handleButtonHover = (index: number) => {
		const clickedCellData = eventWinnerList;
		if (clickedCellData?.find((x) => x.winnerRank === null)) {
			setButtonText("1st Winner")
		}
		if (clickedCellData?.find((x) => x.winnerRank === 1)) {
			setButtonText("2nd Winner")
		}
		if (clickedCellData?.find((x) => x.winnerRank === 2)) {
			setButtonText("3rd Winner")
		}
		setButtonHoveredIndex(index);
	};

	const eventWinnerModel: WinnerModel = {
		userID: 0,
		eventID: 0
	}
	const handleButtonLeave = () => {
		setButtonHoveredIndex(null);
	};

	const openIsWinnerDialog = async (row) => {
		const result = await showConfirmationDialog(
			'Confirmation',
			'Are you sure you want to make  winner?',
			'winner'
		)
		if (result === true) {
			EventWinner(row);

		}
	}
	const EventWinner = (index) => {
		const clickedCellData = eventWinnerList[index];
		eventWinnerModel.userID = clickedCellData.userID;
		eventWinnerModel.eventID = clickedCellData.eventID;

		markEventWinnerQuery.mutate(eventWinnerModel);
		const data = eventWinnerList;
		if (data?.find((x) => x.winnerRank === 1)) {
			setButtonText("2nd Winner")
		}
		else if (data?.find((x) => x.winnerRank === 2)) {
			setButtonText("3rd Winner")
		}
	}

	const buttonVisibility = (data) => {
		var data = data?.find((x) => x.winnerRank === 3);
		if (data != null) { return false }
		else {
			return true
		}
	}
	const todayDate = new Date().toISOString().slice(0, -8);
	const initGridRequestModel: GridEventWinnerModel = {
		eventID: 0,
		gender: '',
		ageGroup: ''
	}


	useEffect(() => {
		initGridRequestModel.eventID = Number(id)
		initGridRequestModel.gender = selectGenderValue ? selectGenderValue : ''
		initGridRequestModel.ageGroup = selectAgeGroupValue ? selectAgeGroupValue : ''
		getAllEventWinnersQuery.mutate(initGridRequestModel)
	}, [selectGenderValue, selectAgeGroupValue, markEventWinnerQuery.isSuccess])


	useEffect(() => {
		if (getAllEventWinnersQuery.data) {
			setEventWinnerList(getAllEventWinnersQuery?.data?.result)
			setShowWinningColumn(todayDate > eventDetail?.endDate)
			setShowButton(buttonVisibility(getAllEventWinnersQuery?.data?.result))

		}

	}, [getAllEventWinnersQuery.data, getAllEventWinnersQuery.isError, getAllEventWinnersQuery.isSuccess])

	const onSelectGenderChange = (selectGenderValue) => {
		if (selectGenderValue != null) {
			setSelectGenderValue(selectGenderValue)
			initGridRequestModel.gender = selectGenderValue
			initGridRequestModel.ageGroup = selectAgeGroupValue
		}
	}

	const onSelectAgeChange = (selectAgeGroupValue) => {
		if (selectAgeGroupValue != null) {
			setSelectAgeGroupValue(selectAgeGroupValue)
			initGridRequestModel.gender = selectGenderValue
			initGridRequestModel.ageGroup = selectAgeGroupValue
		}
	}

	const data = eventWinnerList.map((e, index) =>

		<tr key={index}
			onMouseEnter={() => handleButtonHover(index)}
			onMouseLeave={handleButtonLeave}>
			<td className="ps-5">{e.rank}</td>
			<td className="ps-5">
			<a href="javascript:void(0)" onClick={() => {
                          const additionalData = {
                            tabName: 'Overview', // Additional data you want to pass
                          };
                          navigate(`/users/detail/` + e.userID, { state: additionalData })
                        }} className="fs-6 text-gray-800 text-capitalize text-hover-primary mb-6">
                          {e.participantName}
                        </a>
				</td>
				<td className="ps-5">
				{e.totalDistance === 0 ? "N/A" : (e.unit === "km" ? e.totalDistance : e.unit === "m" ? e.totalDistance * 0.001 : e.totalDistance)}
				</td>
			<td className="ps-5">{ConvertTime(e.totalDuration)}</td>
			{/* <td className="ps-5">{e.totalSteps}</td> */}
			<td className="ps-5">{e.age}</td>
			<td className="ps-5" style={{ textAlign: 'center' }}>
				{e.gender === 'M' ? 'Male' : e.gender === 'F' ? 'Female' : '-'}
			</td>
			{showWinningColumn && e.winnerRank == undefined && e.winnerRank == null && showButton ? (
				<td className="ps-5" style={{ textAlign: 'center' }}>
					{
						<button
							className='btn btn-sm me-1 btn-primary'
							type='button'
							data-bs-toggle='tooltip'
							title='Winner'
							style={{ visibility: buttonHoveredIndex === index ? 'visible' : 'hidden' }}
							onClick={() => {
								openIsWinnerDialog(index);
							}}
						>
							{showButtontext}

						</button>
					}
				</td>
			) : (
				<td className="ps-5" style={{ textAlign: 'center' }}>
					{e.winnerRank == 1 ?
						<Icon icon='twemoji:1st-place-medal' width={31} /> :
						(e.winnerRank == 2 ? <Icon icon='twemoji:2nd-place-medal' width={31} /> : (e.winnerRank == 3 ? <Icon icon='fluent-emoji-flat:3rd-place-medal' width={31} /> : ""))
					}
				</td>
			)}
		</tr>
	)
	return (
		<>
			<div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
				<div className="card-body">
					<div className="d-flex flex-column flex-xl-row" id="myTabContent">
						<div className="flex-lg-row-fluid ">
							<div className='row'>
								<div className="float-start" style={{ width: "160px" }}>
									<select className="form-select mb-2 col-md-4" onChange={(e) => onSelectGenderChange(e.target.value)} data-control="select2" data-hide-search="true" data-placeholder="Select an option" id="kt_ecommerce_add_event_status_select" name="gender">
										<option value="">Select Gender</option>
										<option value="M">Male</option>
										<option value="F">Female</option>
									</select>
								</div>
								<div className="float-start" style={{ width: "160px" }}>
									<select className="form-select mb-2 col-md-4" onChange={(e) => onSelectAgeChange(e.target.value)} data-control="select1" data-hide-search="true" data-placeholder="Select an option" name="age">
										<option value="">Select Age</option>
										<option value="children">Childern</option>
										<option value="adults">Adult</option>
										<option value="young">Young</option>
										<option value="elder">Elder</option>
									</select>
								</div>
							</div>

							<Table striped hover responsive id="kt_table_customers_payment">
								<thead className="border-bottom border-gray-200 fs-7 fw-bold">
									<tr className="text-start text-muted text-uppercase gs-0">
										<th className="min-w-100px">Rank</th>
										<th>Participant</th>
										<th>Distance(KM)</th>
										<th>Duration(s)</th>
										<th>Age</th>
										<th style={{ textAlign: 'center' }}>Gender</th>
										{showWinningColumn && (
											<th className="min-w-100px" style={{ textAlign: 'center' }}>Winner Position</th>
										)}
									</tr>
								</thead>
								{(data.length == 0) ?

									getAllEventWinnersQuery.isLoading ?
										<tbody className="fs-6 fw-semibold text-gray-600" >

											<tr className="text-muted p-3">
												<td colSpan={showWinningColumn ? 7 : 6}>
													<Skeleton animation="wave" variant="rect" width="100%" height={40} />
												</td>
											</tr>
										</tbody>
										:
										<tbody role="rowgroup" className="text-gray-600 fw-bold">
											<tr className="text-muted p-3">
												<td colSpan={showWinningColumn ? 7 : 6}>
													<p className="text-center">Winner information not found.</p>
												</td></tr>
										</tbody>
									:
									<tbody className="fs-6 fw-semibold text-gray-600" >
										{data}
									</tbody>
								}
							</Table>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default EventWinnerDetailPage