import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"

const TableLoader = (props: any) => {
    const { isLoading } = props
    const[styles,SetStyles] = useState({
        borderRadius: '0.475rem',
        boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
        backgroundColor: '#1e1e2d',
        color: '#015ecb',
        fontWeight: '500',
        margin: '0',
        width: '100%',
        height: '100%',
        padding: '1rem 2rem',
        top: '0',
        left: '0',
        opacity: '0.50',
    })
    useEffect(()=>{
        var themeMode = localStorage.getItem('kt_theme_mode_value');
        if(themeMode){
            if(themeMode === "dark")
            {
                SetStyles({...styles,backgroundColor:'#1e1e2d',color:'#015ecb'})
            }
            else
            {
                SetStyles({...styles,backgroundColor:'#fff',color:'#015ecb'})
            }
        }
    }, [localStorage.getItem('kt_theme_mode_value')])

    return (
        <>
            {isLoading && <div style={{ ...styles, position: 'absolute', textAlign: 'center' }} >
                <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    aria-hidden='true'
                    className="text-center"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%'
                    }}
                />
            </div>}

        </>
    )

}

export { TableLoader }
