import clsx from 'clsx';
import { EventRuleModel } from '../../../../services/Event/Event.type';

interface eventRule {
    eventRulesRow: EventRuleModel[],
    setEventRulesRow: any,
    HandleButton: any
}

const EventRulesPage = (props: eventRule) => {
    const { eventRulesRow, setEventRulesRow, HandleButton } = props
    if (eventRulesRow.length === 0) {
        setEventRulesRow([...eventRulesRow, { id: 0, eventID: 0, rule: '', isError: false }])
    }
    return (
        <>
            <div>
                <div className='row'>
                    <h4 style={{ paddingLeft: '40px' }}>
                        Event Rule
                    </h4>
                </div>

                <div className='row'>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='card card-flush'>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                        <div className="" data-kt-ecommerce-catalog-add-event="auto-options">
                                            <label className="required form-label">Add Event Rules</label>
                                            <div id="kt_ecommerce_add_event_options">
                                                <div className="form-group">
                                                    <div data-repeater-list="kt_ecommerce_add_event_options" className="d-flex flex-column gap-3">
                                                        {Array.isArray(eventRulesRow) && eventRulesRow?.map((rule, index) => (
                                                            <div key={index} className="form-group d-flex flex-wrap align-items-center gap-1">
                                                                <textarea
                                                                    style={{ width: '1150px', height: '100px' }}
                                                                    className={clsx(
                                                                        'form-control mw-100',
                                                                        ((rule.isError) ? 'is-invalid' : (!rule.isError && rule.rule) ? 'is-valid' : '')
                                                                    )}
                                                                    name={`eventRules[${index}].rule`}
                                                                    onChange={(e) => {
                                                                        if (e.target.value) {
                                                                            rule.isError = false
                                                                        }
                                                                        else {
                                                                            rule.isError = true
                                                                        }
                                                                        rule.rule = e.target.value
                                                                        setEventRulesRow([...eventRulesRow])
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        if (e.target.value) {
                                                                            rule.isError = false
                                                                        }
                                                                        else {
                                                                            rule.isError = true
                                                                        }
                                                                        rule.rule = e.target.value
                                                                        setEventRulesRow([...eventRulesRow])
                                                                    }}
                                                                    value={rule.rule}
                                                                />
                                                                {eventRulesRow != null && eventRulesRow.length > 1 && <button
                                                                    type="button"
                                                                    data-repeater-delete=""
                                                                    className="btn btn-sm btn-icon btn-light-danger"
                                                                    onClick={(e) => HandleButton(e, 'RemoveEventRule', index)} style={{ marginLeft: '12px' }}>
                                                                    <span className="svg-icon svg-icon-1">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                                                            <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </button>}
                                                                {rule.isError && <div className="text-danger fs-7">Please enter rule</div>}
                                                            </div>
                                                        ))}


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className='row mt-2'>
                                    <div className="form-group mt-5">
                                        <button
                                            type="button"
                                            data-repeater-create=""
                                            className="btn btn-sm btn-primary"
                                            onClick={(e) => HandleButton(e, 'AddEventRule', 0)}
                                        >
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" />
                                                </svg>
                                            </span>
                                            Add another rule
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventRulesPage;