/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { UserModel } from '../../../../services/User/User.type'
import { useNavigate } from "react-router"
import { toAbsoluteUrl } from '../../../helpers'

type Props = {
  className: string,
  latestSignupUsers: UserModel[]
}

const ListsWidget3: React.FC<Props> = ({ className, latestSignupUsers }) => {
  const navigate = useNavigate();

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>New Users</h3>
      </div>
      <div className='card-body pt-2'>
        {latestSignupUsers.map((x, index) => {
          return (
            <>
              <div className='d-flex align-items-center mb-8'>
                {/* <span className='bullet bullet-vertical h-40px bg-success'></span> */}
                <div className='symbol symbol-50px me-5'>
                  <img 
                    src={x.image ? x.image :  toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
                </div>
                <div className='flex-grow-1'>
                  <a href="javascript:void(0)" className='text-gray-800 text-hover-primary fw-bold fs-6' onClick={() => {
                    const additionalData = {
                      tabName: 'Overview', // Additional data you want to pass
                    };
                    navigate(`/users/detail/` + x?.id,{state:additionalData})
                  }}>
                    {x.firstName} {x.surname}
                  </a>
                  <span className='text-muted fw-semibold d-block'>Created in {x.createTimeDuration}</span>
                </div>
                <span className='badge badge-light-success fs-8 fw-bold'>{x.platForm}</span>
              </div>
            </>
          )
        })}

      </div>
    </div>
  )
}

export { ListsWidget3 }
