export interface WidgetModel {
    title: string,
    value: any
}

export interface ChartWidgetModel {
    y1Value: number,
    x1Value: number,
    x2Value: number,
    y2Value: number
}

export interface UserActivityCompleteWidgetModel {
    userID: number,
    firstName: string,
    surname: string,
    eventName: string,
    totalDistance: number,
    eventID: number,
    eventTypeID: number,
    completeDistance: number,
    activityTime: Date,
    unit: number,
    mobile: string,
    eventTypeName: string,
    image: string,
    completeDistancePercentage: number
}

export interface TransactionFilter {
    userID : number,
    eventID : number,
    fromDate : any | null,
    endDate : any | null
}

export interface UserTransactionCompleteWidgetModel {
    userID: number
    ID: number,
    eventID: number,
    transactionID: string,
    paymentDate: Date,
    paymentStatus: string,
    joiningFees: number
    firstName: string,
    surName: string,
    eventName: string,
    eventTypeID: number,
    eventTypeName: string,
}

export const GetEventTypeWiseUserCount = '/dashboardapi/eventtypewiseusercountwidget';
export const GetStaticsWidgetDetails = '/dashboardapi/statisticcountwidget'
export const GetLatestUserActivityHistory = '/dashboardapi/useractivitywidget'
export const GetLatestSignUpUser = '/dashboardapi/latestuserbysignupwidget'
export const GetAdminWidget = '/dashboardapi/adminwidget'
export const GetEventTypeWiseEventcount = '/dashboardapi/eventtypewiseeventcountwidget'
export const GetMonthWiseAmount = '/dashboardapi/getmonthwiseamountwidget'
export const GetMonthwiseEventAndParticipantCount = '/dashboardapi/getmonthwiseeventparticipantcountwidget'
export const GetUserActivityCompleteWidget = '/dashboardapi/getuseractivitycompletewidget'
export const GetUserTransactionWidget = '/dashboardapi/usertransactionwidget'
export const GetUserActivityFilterList = '/dashboardapi/getuseractivityfilterList'