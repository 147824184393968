import React from 'react';

import { useTableInstance } from './index';
import { cx } from '../../../utils/classnames';
import {Tooltip as ReactTooltip} from 'react-tooltip'

type TableInnerProps = {
  loading: boolean;
  entityLabel: string;
};

const TableInner: React.FC<TableInnerProps> = ({ loading, entityLabel }) => {
  const tableInstance = useTableInstance();

  if (!tableInstance) return null;

  const { rows, prepareRow, headerGroups, getTableProps, getTableBodyProps } =
    tableInstance;

  const isListEmpty = rows.length === 0;

  return (
    <div className='table-responsive'>
      <table
        {...getTableProps({
          className:
            'table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer',
        })}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                className: 'text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light',
              })}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                    className: cx(
                      column.canSort &&
                        `table-sort-${
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'desc'
                              : 'asc'
                            : ''
                        }`,`ps-5`
                    ),
                    ...(column.canSort
                      ? column.getSortByToggleProps({
                          "data-tooltip-id": "sortyBytooltip",
                          "data-tooltip-content": `Sort By ${
                            column.Header && (typeof column.Header === 'object' && 'props' in column.Header )
                            ? column.Header.props.children
                            : column.Header
                          }`,
                        })
                      : {}),
                  })}
                  
                >
                  {column.render('Header')}

                  <ReactTooltip id="sortyBytooltip"/>
                </th>
               
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className='text-gray-600 fw-bold'>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className='ps-5' {...cell.getCellProps({  style: {
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                    },})}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
         {isListEmpty && !loading ? (
        <tr className='text-muted p-3'>
          <td  colSpan={10}><p className={cx('text-center')}>No {entityLabel} found.</p></td> 
          </tr>
      ) : null} 
        </tbody>
      </table>
    </div>
  );
};

export default TableInner;
