import React, { useEffect, useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { useTableInstance } from './index'
import { KTIcon } from '../../../_metronic/helpers'
import { usePostQuery } from '../../../hooks/reactQueryHelper'
import { GetTransactionEventList, GetTransactionUserList } from '../../../services/User/User.type'
import { DateRangePicker } from 'react-date-range'
import { Form as BForm, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { Value } from 'sass'
import { applyUserSpecificDateFormat } from '../../../helper/utility'
import { GetAllEventWinners } from '../../../services/Event/Event.type'



export const TableGlobalSearch = (props: any) => {
  const {
    actionButtonHandler,
    serachtext,
    addBtnText,
    isAddbtnVisible,
    isDropDownVisible,
    onSelectUserChange,
    onSelectEventChange,
    onSelectEventTypeChange,
    onSelectGenderChange,
    onSelectAgeChange,
    selectAgeGroupValue,
    onChangePaymentDate,
    selectPaymentDate,
    selectUserId,
    selectEventId,
    selectEventType,
    selectGenderValue,
    dropDownVisibility,
    SelectDateVisibility,
    isDatepickerVisible,
  } = props
  const tableInstance = useTableInstance()

  const { setGlobalFilter, gotoPage } = tableInstance

  const handleChange = useAsyncDebounce((e) => setGlobalFilter(e), 600)
  const getTransactionUserListQuery: any = usePostQuery(
    'getTransactionUserList',
    GetTransactionUserList,
    {}
  )
  const getAllEventWinnersQuery: any = usePostQuery('getAllEventWinnersQuery', GetAllEventWinners, {})

  const getTransactionEventListQuery: any = usePostQuery(
    'getTransactionEventList',
    GetTransactionEventList,
    {}
  )

  const [userList, setUserList] = useState<any>([])
  const [eventList, setEventList] = useState<any>([])
  const [lastSearch, setLastSeatch] = useState<string>()

  useEffect(() => {
    if (selectPaymentDate) {
      gotoPage(0)
    }

  }, [selectPaymentDate])


  useEffect(() => {
    if (selectEventType) {
      gotoPage(0)
    }

  }, [selectEventType])

  useEffect(() => {
    if (selectUserId) {
      gotoPage(0)
    }

  }, [selectUserId])

  useEffect(() => {
    if (selectEventId) {
      gotoPage(0)
    }

  }, [selectEventId])

  useEffect(() => {
    if (isDropDownVisible) {
      if (dropDownVisibility.includes('eventDropDown')) getTransactionEventListQuery.mutate()
      if (dropDownVisibility.includes('userDropDown') || dropDownVisibility.includes('paymentDate')) getTransactionUserListQuery.mutate()
      if (dropDownVisibility.includes('genderDropDown') || dropDownVisibility.includes('ageDropDown')) getAllEventWinnersQuery.mutate()
    }
  }, [isDropDownVisible])


  useEffect(() => {
    if (getTransactionUserListQuery.data) {
      setUserList(getTransactionUserListQuery?.data?.result)
    }
  }, [getTransactionUserListQuery.data])

  useEffect(() => {
    if (getTransactionEventListQuery.data) {
      setEventList(getTransactionEventListQuery?.data?.result)
    }
  }, [getTransactionEventListQuery.data])

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative my-1'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            type='text'
            data-kt-event-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search'
            onChange={(e) => {
              if ((e.target.value.trim() !== '' && e.target.value.trim() !== null) || e.target.value.trim() !== lastSearch) {
                setLastSeatch(e.target.value.trim())
                handleChange(e.target.value.trim())
              }
            }}
          />
        </div>
      </div>
      <div className='card-toolbar'>

        <div
          className='d-flex flex-row-reverse align-items-baseline'
          style={{ gap: '10px' }}
          data-kt-event-table-toolbar='base'
        >
          {isAddbtnVisible && (
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                actionButtonHandler(e, 'Add')
              }}
            >
              <KTIcon iconName='plus' className='fs-2' />
              {addBtnText}
            </button>
          )}
          {isDropDownVisible && dropDownVisibility.includes('eventDropDown') && (
            <div className='col-md-5' style={{ marginLeft: '10px' }}>
              <select
                className='form-select mb-2 col-md-4'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Select an option'
                id='kt_ecommerce_add_event_status_select'
                name='eventId'
                value={selectEventId}
                onChange={(e) => onSelectEventChange(e.target.value)}
              >
                <option key='' value=''>
                  Select Event
                </option>
                {eventList.map((x) => (
                  <option key={x.id} value={x.id} title={x.name}>
                    {x.name.length > 20 ? x.name.substring(0, 20) + '...' : x.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {isDropDownVisible && dropDownVisibility.includes('userDropDown') && (
            <div className='col-md-4'>
              <select
                className='form-select mb-2 col-md-4'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Select an option'
                id='kt_ecommerce_add_event_status_select'
                name='userId'
                value={selectUserId}
                onChange={(e) => onSelectUserChange(e.target.value)}
              >
                <option key='' value=''>
                  Select User
                </option>
                {userList.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.firstName} {x.surname}
                  </option>
                ))}
              </select>
            </div>
          )}
          {isDropDownVisible && dropDownVisibility.includes('genderDropDown') && (
            <div className='col-md-6' style={{ width: '160px' }}>
              <select
                className='form-select mb-2 col-md-4'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Select an option'
                id='kt_ecommerce_add_event_status_select'
                name='gender'
                value={selectGenderValue}
                onChange={(e) => onSelectGenderChange(e.target.value)}
              >
                <option key='' value=''>
                  Select Gender
                </option>
                <option key='1' value='M'>
                  Male
                </option>
                <option key='2' value='F'>
                  Female
                </option>
              </select>
            </div>
          )}
          {isDropDownVisible && dropDownVisibility.includes('ageDropDown') && (
            <div className='col-md-6' style={{ width: '160px' }}>
              <select
                className='form-select mb-2 col-md-4'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Select an option'
                id='kt_ecommerce_add_event_status_select'
                name='ageGroup'
                value={selectAgeGroupValue}
                onChange={(e) => onSelectAgeChange(e.target.value)}
              >
                <option key='' value=''>
                  Select AgeGroup
                </option>
                <option key='1' value='Children'>
                  children
                </option>
                <option key='2' value='Adults'>
                  adults
                </option>
                <option key='3' value='Young'>
                young
                </option>
                <option key='4' value='Elder'>
                  elder
                </option>
              </select>
            </div>
          )}
          {isDropDownVisible && dropDownVisibility.includes('eventTypeDropDown') && (
            <div className='col-md-6' style={{ marginLeft: '12px' }}>
              <select
                className='form-select mb-2 col-md-4'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Select an option'
                id='kt_ecommerce_add_event_status_select'
                name='eventTypeID'
                value={selectEventType}
                onChange={(e) => onSelectEventTypeChange(e.target.value)}
              >
                <option key='' value=''>
                  Select Event Type
                </option>
                <option key='1' value='1'>
                  Cycling
                </option>
                <option key='2' value='2'>
                  Walking
                </option>
                <option key='3' value='3'>
                  Running
                </option>
              </select>
            </div>
          )}

          {isDropDownVisible && dropDownVisibility?.includes('paymentDate') && (
            <div className='col-md-5' style={{ marginLeft: '10px' }}>
              <BForm.Group
                className='fv-row mb-8'
                controlId='paymentDate'
              >

                <BForm.Control
                  type='date'
                  name='paymentDate'
                  placeholder='dd-mm-yyy'
                  onChange={(e) => {
                    onChangePaymentDate(e.target.value)
                  }}
                  value={selectPaymentDate}
                  max={new Date().toISOString().split('T')[0]}
                />

              </BForm.Group>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
