import React, { FC } from 'react';
import TransactionPage from "./TransactionPage";
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

const TransactionPageWrapper: React.FC = (props: any) => {
    const widgetsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator : true,
            isActive: false,
        }
    ];

    return(
        <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}> Transactions </PageTitle>
            <TransactionPage/>
        </>
    )
}

export default TransactionPageWrapper;