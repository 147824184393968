import moment from 'moment'
import { applyUserSpecificDateFormat, applyUserSpecificDateTimeFormat, currencyPrefix } from '../../../../helper/utility'
import Skeleton from '@material-ui/lab/Skeleton'
import { Tooltip } from 'react-tooltip'
import SetInnerHTML from '../../../components/SetInnerHTML/SetInnerHTML'
const EventOverviewPage = (Props: any) => {
  const { eventDetail, getEventQuery } = Props
  const renderSkeleton = (
    <div className='col-lg-10'>
      <Skeleton width={200} height={20} />
    </div>
  )

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>Name</label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {eventDetail?.name ? eventDetail?.name : '-'}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>Event Type</label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10 d-flex align-items-center'>
                <span className='fw-semibold text-gray-800 fs-6'>
                  {eventDetail?.eventTypeName ? eventDetail?.eventTypeName : '-'}{' '}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>Description</label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-semibold fs-6 text-gray-800'>
                  {eventDetail?.description ? <SetInnerHTML inneHtml={eventDetail?.description} /> : '-'}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>Start Date</label>

            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {' '}
                  {eventDetail?.startDate
                    ? moment(eventDetail?.startDate).format(applyUserSpecificDateTimeFormat())
                    : '-'}{' '}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>End Date</label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {' '}
                  {eventDetail?.endDate
                    ? moment(eventDetail?.endDate).format(applyUserSpecificDateTimeFormat())
                    : '-'}{' '}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>Duration</label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {eventDetail?.duration ? eventDetail?.duration : '-'}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>Distance</label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {eventDetail?.totalDistance
                    ? eventDetail?.totalDistance + ' ' + eventDetail?.unit
                    : '-'}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>Joining Fee</label>

            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                {
                  <span className='fw-semibold fs-6 text-gray-800'>
                    {eventDetail?.joiningFees > 0 ? (
                      <SetInnerHTML inneHtml={currencyPrefix() + ' ' + `${eventDetail?.joiningFees}`} />
                    ) : (
                      'Free'
                    )}
                  </span>
                }
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='col-lg-2 fw-semibold text-muted'>Max Participants</label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-semibold fs-6 text-gray-800'>
                  {eventDetail?.maxParticipants ? eventDetail?.maxParticipants : '-'}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <Tooltip id='tooltip' content='' />
            <label className='col-lg-2 fw-semibold text-muted'>
              Registration Closing Date
              <span
                data-tooltip-id='tooltip'
                data-tooltip-content='Event registration closing date.'
              >
                <i className='bi bi-info-circle-fill ms-2 '></i>
              </span>
            </label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {' '}
                  {eventDetail?.registrationClosingDate
                    ? moment(eventDetail?.registrationClosingDate).format(applyUserSpecificDateFormat())
                    : '-'}{' '}
                </span>
              </div>
            )}
          </div>
          <div className='row mb-10'>
            <label className='col-lg-2 fw-semibold text-muted'>Rewards</label>
            {getEventQuery.isLoading ? (
              renderSkeleton
            ) : (
              <div className='col-lg-10'>
                <span className='fw-semibold fs-6 text-gray-800'>
                  {eventDetail?.rewards ? <SetInnerHTML inneHtml={eventDetail?.rewards} /> : '-'}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default EventOverviewPage
