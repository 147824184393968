import { CellProps, Column } from 'react-table'
import { KTCardBody } from '../../../_metronic/helpers'
import { Fragment, useEffect, useMemo, useState } from 'react'
import Table from '../../components/Table'
import useTableState from '../../components/Table/useTableState'
import TableInner from '../../components/Table/TableInner'
import { TablePagination } from '../../components/Table/TablePagination'
import { GetAllOtpRequests } from '../../../services/OtpRequest/OtpRequest.type'
import clsx from 'clsx'
import { TableGlobalSearch } from '../../components/Table/TableGlobalSearch'
import { usePostQuery } from '../../../hooks/reactQueryHelper'
import moment from 'moment'
import { GridRequestModel, GridResponseModel, SortModel } from '../../../services/Common/Common.type'
import { TableLoader } from '../../components/Table/TableLoader'
import { applyUserSpecificDateTimeFormat } from '../../../helper/utility'
import { Icon } from '@iconify/react'
import { Tooltip as ReactTooltip } from "react-tooltip";

const OtpRequestTable = (Props: any) => {
  const getAllOtpRequests: any = usePostQuery('getAllOtpRequests', GetAllOtpRequests, {})
  const [tableInitialState, tableState, handleTableStateChange] = useTableState<object>({
    pageIndex: 0,
  })
  const initGridResponseModel: GridResponseModel = {
    count: 0,
    currentPage: 0,
    result: [],
    totalPages: 0,
  }
  const [otpRequestList, setOtpRequestList] = useState<GridResponseModel>(initGridResponseModel)
  const { sort, page, limit, search } = tableState
  const initSortModel: SortModel = {
    sort: '',
    fields: '',
  }

  const initGridRequestModel: GridRequestModel = {
    page: page ?? 0,
    limit: limit ?? 0,
    search: search ? search : '',
    sort: sort ? sort : initSortModel,
    id: 0,
    eventId: 0,
    eventTypeID: 0,
    paymentDate: null,
    isInvited: false,
    isJoined: false
  }

  useEffect(() => {
    initGridRequestModel.page = page ?? 0
    initGridRequestModel.limit = limit ?? 0
    if (sort) initGridRequestModel.sort = sort
    if (search) initGridRequestModel.search = search
    getAllOtpRequests.mutate(initGridRequestModel)
  }, [page, limit, sort, search])

  useEffect(() => {
    if (getAllOtpRequests.data) {
      setOtpRequestList(getAllOtpRequests?.data?.result)
    }
  }, [getAllOtpRequests.data, getAllOtpRequests.isError, getAllOtpRequests.isSuccess])

  const columns = useMemo<Column<any>[]>(
    () => [{
      Header: `Mobile`,
      accessor: 'mobile',
      Cell: (props: CellProps<any>) => {
        return <span>{props?.row?.original?.mobile ? props?.row?.original?.mobile : '-'}</span>
      },
    },
    {
      Header: `OTP`,
      accessor: 'otpCode',
      Cell: (props: CellProps<any>) => {
        return <span>{props?.row?.original?.otpCode ? props?.row?.original?.otpCode : '-'}</span>
      },
    },
    {
      Header: `Expiry Date`,
      accessor: 'otpExpiry',
      Cell: (props: CellProps<any>) => {
        return (
          <span>
            {' '}
            {props?.row?.original?.otpExpiry
              ? moment(props?.row?.original?.otpExpiry).format(applyUserSpecificDateTimeFormat())
              : '-'}
          </span>
        )
      },
    },
    {
      Header: `Request Type`,
      accessor: 'otpRequestType',
      Cell: (props: CellProps<any>) => {
        return (
          <span>{`${props?.row?.original?.otpRequestType ? props?.row?.original?.otpRequestType : '-'
            }`}</span>
        )
      },
    },
    {
      Header: `created Date`,
      accessor: 'createdDate',
      Cell: (props: CellProps<any>) => {
        return (
          <span>
            {' '}
            {props?.row?.original?.createdDate
              ? moment(props?.row?.original?.createdDate).format(
                applyUserSpecificDateTimeFormat()
              )
              : '-'}
          </span>
        )
      },
    },
    {
      Header: `Verified?`,
      accessor: 'isVerified',
      disableSortBy: true,
      Cell: (props: CellProps<any>) => {
        const className = props?.row?.original?.isVerified ? 'success' : 'danger'
        return (
          <>
            <span
              data-tooltip-id="my-tooltip" data-tooltip-content={props?.row?.original?.isVerified === true ? 'Verified' : 'Not Verified'}
              className={clsx('badge badge-circle fs-1', `badge-light-${className}`, `fw-bolder`)}
            >
              {props?.row?.original?.isVerified ? (
                <Icon icon='simple-line-icons:check' />
              ) : (
                <Icon icon='radix-icons:cross-circled' />
              )}
            </span>
          </>
        )
      },
    },
    {
      Header: `Device Type`,
      accessor: 'deviceType',
      Cell: (props: CellProps<any>) => {
        return <span>{props?.row?.original?.deviceType ? props?.row?.original?.deviceType : '-'}</span>
      },
    },
    ],
    []
  )

  return (
    <><ReactTooltip id="my-tooltip" content="" />
      <Table
        columns={columns}
        data={otpRequestList?.result ?? []}
        initialState={tableInitialState}
        pageCount={otpRequestList?.totalPages}
        onTableStateChange={handleTableStateChange}
      >
        <Fragment>
          <TableGlobalSearch serachtext={'Search'} />
          <KTCardBody className='py-4'>
            <div className='table-responsive'>
              <TableInner loading={false} entityLabel='OTP Request'></TableInner>
            </div>
            <TablePagination pageOptions={[5, 10, 20, 50]} />
            <TableLoader isLoading={getAllOtpRequests?.isLoading} />
          </KTCardBody>
        </Fragment>
      </Table></>
  )
}

export { OtpRequestTable }
