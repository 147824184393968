
import { isDevelopment, isProduction, isLocal } from './mode';

const BASE_LOCAL_ENDPOINT = 'https://localhost:7093';
const BASE_PROD_ENDPOINT = 'https://iamonthemove.com/api';
const BASE_DEV_ENDPOINT = 'http://dev.iamonthemove.com/api';
export const BASE_ENDPOINT = isDevelopment() ? BASE_DEV_ENDPOINT : isProduction() ? BASE_PROD_ENDPOINT :isLocal() ?  BASE_LOCAL_ENDPOINT : BASE_DEV_ENDPOINT;

const WEB_LOCAL_ENDPOINT = 'http://localhost:3012';
const WEB_PROD_ENDPOINT = 'https://iamonthemove.com';
const WEB_DEV_ENDPOINT = 'http://dev.iamonthemove.com';
export const WEB_ENDPOINT = isDevelopment() ? WEB_DEV_ENDPOINT : isProduction() ? WEB_PROD_ENDPOINT :isLocal() ?  WEB_LOCAL_ENDPOINT : WEB_DEV_ENDPOINT;