import Swal, { SweetAlertIcon } from 'sweetalert2'
function toastNotification(icon: SweetAlertIcon, title: string) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
    });
    Toast.fire(title, '',icon)
}
export default toastNotification;
