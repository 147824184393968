import { Form as BForm } from 'react-bootstrap'
import clsx from 'clsx'
const EventDescriptionPage = (props: any) => {
  const { values, touched, errors, handleChange, setFieldValue, descriptionValidationMessage } = props

  const handlekeypresschanges = (event) => {
    let keySign = event.key;
    if (keySign === "<" || keySign === ">")
      event.preventDefault();
  };
  return (
    <>
      <div>
        <div className='row'>
          <h2 style={{ paddingLeft: '40px' }}>
            Event Description
          </h2>
        </div>
        <div className='row'>
          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
            <div className='card card-flush'>

              <div className='card-body'>
                <div className='row'>
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <BForm.Group className='fv-row'>
                      <BForm.Label className='required form-label'>Description </BForm.Label>
                      <BForm.Control
                        as='textarea'
                        type='textarea'
                        name='description'
                        className={clsx(
                          'form-control',
                          { 'is-invalid': ((touched.description && errors.description) || (descriptionValidationMessage && (values.description === null || values.description === ''))) },
                          {
                            'is-valid': touched.description && !errors.description && (values.description !== null && values.description !== '' && values.description !== undefined),
                          }
                        )}
                        style={{ height: '150px' }}
                        placeholder='Description'
                        onChange={handleChange}
                        onKeyPress={handlekeypresschanges}
                        maxLength={5000}
                        defaultValue={values.description}
                        onBlur={(e) => {
                          setFieldValue('description', e.target.value)
                        }}
                      ></BForm.Control>
                      {descriptionValidationMessage && (values.description === null || values.description === '') && <div className='text-danger fs-7'>Please enter Description</div>}
                    </BForm.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
export default EventDescriptionPage
